/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { BufferGeometry, Intersection, Line, Raycaster, Scene, Vector3 } from "three"
import { PartConnectionType } from "../../../state/scene/types"
import {
    getConnectionMarkerName,
    getOtherPartOfTheConnection,
    isInConnection
} from "../../../state/scene/util"
import { EnvHelper } from "../../../../common/utils/EnvHelper"
import { MeshUtils } from "../../../utils/MeshUtils"
import {
    ConnectorValues,
    GenericPartState,
    Marker,
    PartTypeEnum,
    SegmentedTubePart,
    SegmentedTubeValues,
    TubeMarkerEnum,
    TubeValues,
} from "../../../utils/Types"
import {
    SegmentedTubeMarkers
} from "../../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import { innerToOuter } from "../../../utils/MarkerUtil"
import { SegmentedTubeSectionType } from "../../../../common/api/Types"

const getSeparatedConnections = (
    connections: PartConnectionType[],
    actualPartId: string,
    previousPartId: string
) => {
    const actualPartConnections = connections.filter(
        c => isInConnection(c, actualPartId))

    const previousPartConnection = actualPartConnections
        .find(connection => isInConnection(connection, previousPartId))!
    const remainingConnections = actualPartConnections
        .filter(connection => connection !== previousPartConnection)

    return { previousPartConnection, remainingConnections, }
}

export const getPartsToMoveRecursive = (props: {
    step: number,
    previousPartId: string,
    actualPartId: string,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[],
    maxStep: number,
    previousPartsIds: string[],
}): string[] | undefined => {
    const { step, previousPartId, actualPartId, getPart, connections, maxStep, previousPartsIds, } = props

    if (step > maxStep) {
        return undefined
    }
    if(previousPartsIds.includes(actualPartId)){
        return undefined
    }

    const actualPart = getPart(actualPartId)
    const { remainingConnections, }
        = getSeparatedConnections(connections, actualPartId, previousPartId)

    if (actualPart?.type === PartTypeEnum.connector
        || actualPart?.type === PartTypeEnum.segmentedTube
    ) {
        let partsToMove: (string[] | undefined)[] = []

        for(let i=0; i<remainingConnections.length; i++) {
            const nexPartResult = getPartsToMoveRecursive({
                step: step + 1,
                previousPartId: actualPartId,
                actualPartId: getOtherPartOfTheConnection(remainingConnections[i], actualPartId),
                getPart,
                connections,
                maxStep,
                previousPartsIds: [...previousPartsIds, actualPartId,],
            })
            if(nexPartResult) {
                partsToMove.push(nexPartResult)
            } else {
                partsToMove = [undefined,]
                i = remainingConnections.length
            }
        }

        if (partsToMove.includes(undefined)) {
            return undefined
        } else {
            return [...(partsToMove as string[][]).flat(1), actualPartId,]
        }
    } else if (remainingConnections.length === 0) {
        return [actualPartId,]
    } else {
        const partsToMove = getPartsToMoveRecursive({
            step: step + 1,
            previousPartId: actualPartId,
            actualPartId: getOtherPartOfTheConnection(remainingConnections[0], actualPartId),
            getPart,
            connections,
            maxStep,
            previousPartsIds: [...previousPartsIds, actualPartId,],
        })

        return partsToMove ? [...partsToMove, actualPartId,] : undefined
    }
}

const hasFreeMarker = (connections: PartConnectionType[]) => {
    return connections.length < 2
}

const getFreeMarker = (connections: PartConnectionType[], partId: string) => {
    return connections.some(
        connection => isInConnection(connection, partId, TubeMarkerEnum.BOTTOM))
        ? TubeMarkerEnum.TOP : TubeMarkerEnum.BOTTOM
}

const getConnectedTubeFloatingMarker = (
    actualPartConnections: PartConnectionType[],
    partId: string,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[]
) => {
    let floatingMarkers = actualPartConnections.map(connection => {
        const actualMarker = getConnectionMarkerName(connection, partId)
        return {
            markerToMove: actualMarker === TubeMarkerEnum.BOTTOM
                ? TubeMarkerEnum.BOTTOM : TubeMarkerEnum.TOP,
            partsToMove: getPartsToMoveRecursive({
                step: 1,
                previousPartId: partId,
                actualPartId: getOtherPartOfTheConnection(connection, partId),
                getPart: getPart,
                connections: connections,
                maxStep: EnvHelper.mmLengthSteps,
                previousPartsIds: [partId,],
            }),
        }
    })

    floatingMarkers = floatingMarkers
        .filter(fm => fm.partsToMove)

    floatingMarkers.sort((a, b) => a.partsToMove!.length - b.partsToMove!.length)

    return floatingMarkers[0]
}

const getLengthFloatingMarker = (props: {
    part: TubeValues,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[],
}) => {
    const actualPartConnections = props.connections.filter(
        c => isInConnection(c, props.part.id))
    if (hasFreeMarker(actualPartConnections)) {
        return {
            markerToMove: getFreeMarker(actualPartConnections, props.part.id),
            partsToMove: [],
        }
    } else {
        return getConnectedTubeFloatingMarker(
            actualPartConnections,
            props.part.id,
            props.getPart,
            props.connections
        )
    }
}

const getRotationFloatingMarker = (
    floatingMarkers: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[]
) => {
    const notFloatingMarkers = floatingMarkers.filter(fm => !fm.partsToMove)
    let rotationMarker: string | undefined
    let markersToMove: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[] = floatingMarkers.filter(fm => fm.partsToMove)
    switch (notFloatingMarkers.length) {
        case 0: {
            const connectedMarker = floatingMarkers.find(
                fm => fm.partsToMove && fm.partsToMove?.length > 0)
            if (connectedMarker) {
                rotationMarker = connectedMarker.marker.name
            } else {
                rotationMarker = floatingMarkers[0].marker.name
            }
            markersToMove = floatingMarkers.filter(fm => fm.marker.name !== rotationMarker)
            break
        }
        case 1: {
            rotationMarker = notFloatingMarkers[0].marker.name
            break
        }
        case 2: {
            if (notFloatingMarkers[1].marker.oppositeOf
                && notFloatingMarkers[0].marker.name.includes(
                    notFloatingMarkers[1].marker.oppositeOf
                )
            ) {
                rotationMarker = notFloatingMarkers[0].marker.name
            } else {
                rotationMarker = undefined
            }
            break
        }
        default:
            rotationMarker = undefined
            break
    }
    if (rotationMarker) {
        return {
            rotationMarker,
            partsToMove: (markersToMove.map(markers => markers.partsToMove) as string[][]).flat(1),
        }
    } else {
        return undefined
    }
}


const getLengthSegmentedTubesFloatingMarker = (
    floatingMarkers: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[]
) => {
    if (floatingMarkers.some(fm => !fm.partsToMove)) {
        return undefined
    }
    const notFloatingMarkers = floatingMarkers.filter(fm => !fm.partsToMove)
    let sliderMarker: string | undefined
    let markersToMove: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[] = floatingMarkers
    switch (notFloatingMarkers.length) {
        case 0: {
            const connectedMarker = floatingMarkers.find(
                fm => fm.partsToMove && fm.partsToMove?.length > 0)
            if (connectedMarker) {
                sliderMarker = connectedMarker.marker.name
            } else {
                sliderMarker = floatingMarkers[0].marker.name
            }
            markersToMove = floatingMarkers
            break
        }
        case 1: {
            sliderMarker = notFloatingMarkers[0].marker.name
            break
        }
        case 2: {
            if (notFloatingMarkers[1].marker.oppositeOf
                && notFloatingMarkers[0].marker.name.includes(
                    notFloatingMarkers[1].marker.oppositeOf
                )
            ) {
                sliderMarker = notFloatingMarkers[0].marker.name
            } else {
                sliderMarker = undefined
            }
            break
        }
        default:
            sliderMarker = undefined
            break
    }

    if (sliderMarker) {
        return {
            sliderMarker,
            partsToMove: (markersToMove.map(markers => markers.partsToMove) as string[][]).flat(1),
        }
    } else {
        return undefined
    }
}

const getSliderFloatingMarker = (
    floatingMarkers: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[]
) => {
    if (floatingMarkers.some(fm => !fm.partsToMove)) {
        return undefined
    }
    const notFloatingMarkers = floatingMarkers.filter(fm => !fm.partsToMove)
    let sliderMarker: string | undefined
    let markersToMove: {
        partsToMove: string[] | undefined,
        marker: Marker,
    }[] = floatingMarkers.filter(fm => fm.partsToMove)
    switch (notFloatingMarkers.length) {
        case 0: {
            const connectedMarkers = floatingMarkers.filter(
                fm => fm.partsToMove && fm.partsToMove?.length > 0)
            connectedMarkers.forEach(({ marker, }) => {
                markersToMove = markersToMove.concat(floatingMarkers.filter(fm => fm.marker.name === marker.name))
            })
            if (connectedMarkers.length) {
                sliderMarker = connectedMarkers[0].marker.name
            } else {
                sliderMarker = floatingMarkers[0].marker.name
            }
            break
        }
        case 1: {
            sliderMarker = notFloatingMarkers[0].marker.name
            break
        }
        case 2: {
            if (notFloatingMarkers[1].marker.oppositeOf
                && notFloatingMarkers[0].marker.name.includes(
                    notFloatingMarkers[1].marker.oppositeOf
                )
            ) {
                sliderMarker = notFloatingMarkers[0].marker.name
            } else {
                sliderMarker = undefined
            }
            break
        }
        default:
            sliderMarker = undefined
            break
    }
    if (sliderMarker) {
        return {
            sliderMarker,
            partsToMove: Array.from(new Set((markersToMove.map(markers => markers.partsToMove) as string[][]).flat(1))),
        }
    } else {
        return undefined
    }
}

const getLengthAxisMarker = ({ connections, getPart, part, markers, }: {
    part: SegmentedTubeValues,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[],
    markers: SegmentedTubeMarkers,
}) => {
    const markersName = Object.keys(markers).reduce((acc, current) => {
        return acc.concat([markers[current].inner!.name, markers[current].outer!.name,])
    }, [] as string[])
    const actualPartConnections = connections.filter(
        c => {
            let segmentedTubeConnection = undefined
            if (c.partA.partId === part.id) {
                segmentedTubeConnection = c.partA
            } else if (c.partB.partId === part.id) {
                segmentedTubeConnection = c.partB
            }
            return !!segmentedTubeConnection
                && markersName.includes(segmentedTubeConnection.markerName)
        })

    if (actualPartConnections.length === 0) {
        return {
            sliderMarker: part.markers[0].name,
            partsToMove: [],
        }
    }

    const floatingMarkers = actualPartConnections.map(c => {
        let partsToMove: string[] | undefined = []
        const segmentedTubeCon = c.partA.partId === part.id ? c.partA : c.partB
        const marker = part.markers.find(
            ({ name, }) => innerToOuter(name) === innerToOuter(segmentedTubeCon.markerName)
        )!

        partsToMove = getPartsToMoveRecursive({
            step: actualPartConnections.length,
            previousPartId: part.id,
            actualPartId: getOtherPartOfTheConnection(c, part.id),
            getPart: getPart,
            connections: connections,
            maxStep: EnvHelper.mmSTLengthSteps,
            previousPartsIds: [part.id,],
        })

        return {
            partsToMove: partsToMove,
            marker,
        }
    })

    return getLengthSegmentedTubesFloatingMarker(floatingMarkers)
}

const getSlideAxisMarker = ({ connections, getPart, part, sliderPartId, }: {
    part: ConnectorValues | SegmentedTubePart,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[],
    sliderPartId?: string,
}) => {
    const actualPartConnections = connections.filter(
        c => {
            const segmentedTubeId = c.partA.partId === part.id ? c.partB.partId : c.partA.partId
            return isInConnection(c, part.id) && sliderPartId !== segmentedTubeId

        })

    if (actualPartConnections.length === 0) {
        return {
            sliderMarker: part.markers[0].name,
            partsToMove: [],
        }
    }


    const floatingMarkers = part.markers.map(marker => {
        const isMiddle = marker.position === SegmentedTubeSectionType.MIDDLE
        const connectionsByMarker = actualPartConnections.filter(c => {
            const connection = {
                partA: {
                    ...c.partA,
                    markerName: c.partA.markerName.split("_")[0],
                },
                partB: {
                    ...c.partB,
                    markerName: c.partB.markerName.split("_")[0],
                },
            }

            return isInConnection(isMiddle ? connection : c, part.id, marker.name)
        })


        let partsToMove: string[] | undefined = []
        let moreThanMax = false
        if (connectionsByMarker.length) {
            connectionsByMarker.every(c => {
                const rec = getPartsToMoveRecursive({
                    step: 1,
                    previousPartId: part.id,
                    actualPartId: getOtherPartOfTheConnection(c, part.id),
                    getPart: getPart,
                    connections,
                    maxStep: EnvHelper.maxSegmentedTubeSlideSteps,
                    previousPartsIds: [part.id,],
                })

                if (!rec) {
                    moreThanMax = true
                    return false
                }
                partsToMove = partsToMove?.concat(rec)
                return true
            })
        }
        return {
            partsToMove: moreThanMax ? undefined : partsToMove,
            marker,
        }
    })
    return getSliderFloatingMarker(floatingMarkers)
}

const getRotationAxisMarker = (props: {
    part: ConnectorValues,
    getPart: (partId: string) => GenericPartState | undefined,
    connections: PartConnectionType[],
}) => {
    const actualPartConnections = props.connections.filter(
        c => isInConnection(c, props.part.id))

    if (actualPartConnections.length === 0) {
        return {
            rotationMarker: props.part.markers[0].name,
            partsToMove: [],
        }
    }
    const floatingMarkers = props.part.markers.map(marker => {
        const connection = actualPartConnections.find(
            connection => isInConnection(connection, props.part.id, marker.name))
        let partsToMove: string[] | undefined = []
        if (connection) {
            partsToMove = getPartsToMoveRecursive({
                step: 1,
                previousPartId: props.part.id,
                actualPartId: getOtherPartOfTheConnection(connection, props.part.id),
                getPart: props.getPart,
                connections: props.connections,
                maxStep: EnvHelper.mmRotationSteps,
                previousPartsIds: [props.part.id,],
            })
        }
        return {
            partsToMove: partsToMove,
            marker,
        }
    })

    return getRotationFloatingMarker(floatingMarkers)
}

export const getDistanceToElongate = (
    rayStateObject: Intersection,
    markerPosition: Vector3,
    elongationDirection: Vector3,
    lengthDirection: number,
    scene: Scene
) => {
    const auxPoint = new Vector3()
    auxPoint.addVectors(
        markerPosition,
        elongationDirection.multiplyScalar(lengthDirection < 0 ? -1 : 1)
    )
    const geometry = new BufferGeometry().setFromPoints([markerPosition, auxPoint,])
    const line = new Line(geometry)
    scene.add(line)

    const collidedMarkerPosition = MeshUtils.copyWorldPosition(rayStateObject.object)
    const collidedMarkerDirection = MeshUtils.copyWorldDirection(rayStateObject.object)

    const ray = new Raycaster(collidedMarkerPosition, collidedMarkerDirection)

    const intersection = ray.intersectObject(line)

    let distance = 0
    if(intersection.length > 0) {
        const intersectionPoint = intersection[0].point
        distance = intersectionPoint.distanceTo(markerPosition)
    }

    scene.remove(line)

    return distance
}

export const LengthUtils = {
    getLengthFloatingMarker,
    getRotationAxisMarker,
    getDistanceToElongate,
    getSlideAxisMarker,
    getLengthAxisMarker,
}