import message from "antd/lib/message"
import React, { FormEvent, useState, } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import Button from "../../../common/components/Button"
import { Title } from "../../../common/components/Global"
import { ShareIcon, ShareLinkIcon } from "../../../common/components/icons/Icons"
import { EnvHelper } from "../../../common/utils/EnvHelper"
import { RoutesHelper } from "../../../common/utils/routesHelper"
import { events, useEventsData } from "../../../common/utils/rudderAnalyticsUtils"
import { getUrlWithQueriesToPreserve } from "../../../common/utils/utils"

type Props = {
    onCancel: () => void,
    onSuccess: () => void,
    title: string,
    userId: string,
    designId: string,
    submitButtonText: string,
}


const modalContainer = "modal-share-container"

export const SharePortal = () => {
    return <div id={modalContainer}></div>
}

export const ShareModal = (props: Props) => {
    const eventsData = useEventsData()

    const route = EnvHelper.appUrl
    const generatedRoute = getUrlWithQueriesToPreserve(RoutesHelper.generateIdRoute(
        {
            userId: props.userId,
            designId: props.designId,
        },
    ))
    const makeLink = `${route}${generatedRoute}`

    const onSubmit = (event: FormEvent) => {
        event.preventDefault()
        navigator.clipboard.writeText(makeLink)
        message.success("Link copied to clipboard")
        events.shareDesign(eventsData())
        props.onCancel()
    }
    const getModal = () => {

        return (
            <Background>
                <Modal>
                    <Title>{props.title}</Title>
                    <Form onSubmit={onSubmit}>
                        <TextInput
                            readOnly
                            value={makeLink}
                            id="designNameInput"
                        />
                        <ButtonsWrapper>
                            <Button
                                color="#0088ff"
                                $loadingColor="#fff"
                                action="primary"
                                $full={true}
                                type="submit"
                            >
                                <ShareLinkIcon color="white" />
                                {props.submitButtonText}
                            </Button>
                            <Button
                                $borderColor="#0088ff"
                                $labelColor="#0088ff"
                                action="secondary"
                                $full={true}
                                onClick={props.onCancel}>Cancel</Button>
                        </ButtonsWrapper>
                    </Form>
                </Modal>
            </Background>
        )
    }

    return <>
        {ReactDOM.createPortal(getModal(), document.getElementById(modalContainer)!)
        }
    </>
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #0000006b;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    width: 300px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 22px 39px -19px #0000008f;
`

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`

const TextInput = styled.input`
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #bbbbbb;
    padding: 8px;
    border-radius: 8px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
`

export const ShareButton = (props: {
    userId: string,
    designId: string,
    disabled: boolean,
}) => {
    const [showShare, setShowShare,] = useState(false)

    const handleLogin = () => {
        setShowShare(true)
    }

    const handleClose = () => {
        setShowShare(false)
    }

    return <>
        {showShare && <ShareModal
            onCancel={handleClose}
            onSuccess={handleClose}

            submitButtonText="Copy Link"
            title="Share Project"
            userId={props.userId}
            designId={props.designId}
        />}
        {<Button
            $ifMobile={<ShareIcon color="black" />}
            disabled={props.disabled}
            onClick={handleLogin}>Share</Button>
        }
    </>
}