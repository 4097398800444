/**
 * Opposed to the RadixTooltip component, this one wraps the content as a trigger
 * and receives the tooltip content as a prop
 */
import React from "react"
import * as Tooltip from "@radix-ui/react-tooltip"
import styled from "styled-components"

interface ContentTooltipProps {
    children: JSX.Element;
    tooltipContent: JSX.Element | string;
    side?: "left" | "right" | "top" | "bottom";
    sideOffset?: number;
    showIcon?: boolean;
    disabled?: boolean;
}

const ContentTooltip = (props: ContentTooltipProps) => {
    const { side = "left", sideOffset = 5, showIcon = true, disabled = false, } = props

    const handleOnClick = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation()
    }

    if (disabled) {
        return (props.children)
    }

    return (
        <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <Trigger>
                        {props.children}
                    </Trigger>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <TooltipContent onClick={handleOnClick} side={side} sideOffset={sideOffset}>
                        {props.tooltipContent}
                        <TooltipArrow className="TooltipArrow" />
                    </TooltipContent>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    )
}

const TooltipContent = styled(Tooltip.Content)`
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
    line-height: 1.5;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, 
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 5;
    max-width: 300px;
`

const TooltipArrow = styled(Tooltip.Arrow)`
    fill: rgba(0, 0, 0, 0.8);
`

const Trigger = styled.div`
    top: 0;
    z-index: 2;
    right: 8px;
`

export default ContentTooltip
export type { ContentTooltipProps, }