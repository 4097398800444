import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import Button from "../../../common/components/Button"

type Props = {
    title: string,
    message: string,
    confirmButtonText: string,
    confirmButtonColor: string,
    onConfirm: () => void,
    onCancel: () => void,
    loading: boolean,
}

const ConfirmModal = (props: Props) => {
    const modalContainer = document.getElementById("modals-container")

    const getModal = () => {
        return <Background>
            <Modal>
                <Title>{props.title ? props.title : "Confirm"}</Title>
                <Message>{props.message ? props.message : ""}</Message>
                <ButtonsWrapper>
                    <Button
                        $borderColor="#0088ff"
                        $labelColor="#0088ff"
                        action="secondary"
                        $full={true}
                        onClick={props.onCancel}>Cancel</Button>
                    <Button
                        color={props.confirmButtonColor ? props.confirmButtonColor : "#0088ff"}
                        action="primary"
                        $full={true}
                        type="submit"
                        onClick={props.onConfirm}
                        loading={props.loading}
                        disabled={props.loading}
                    >
                        {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
                    </Button>
                </ButtonsWrapper>
            </Modal>
        </Background>
    }

    return <>
        {ReactDOM.createPortal(getModal(), modalContainer!)}
    </>
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #0000006b;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    width: 300px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 22px 39px -19px #0000008f;
`

const Title = styled.h1`
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 16px;
`

const Message = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    text-align: center;
    color: #4A5568
`

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
`

export default ConfirmModal