/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import { Plane } from "@react-three/drei"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  Box3,
  Box3Helper,
  Color,
  DoubleSide,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Quaternion,
  Vector3
} from "three"
import { useThree } from "@react-three/fiber"
import {
  attachTubeTrackerTo,
  checkExactSnap,
  checkSnap,
  createShape,
  DRAG_VALUE_OFFSET,
  getDrag,
  getLengthOnRelease,
  getMarkers,
  getOriginMarker,
  getSnap,
  getTubeInternals,
  handleTubeSwap,
  invertTube,
  proyectRay,
  removeGuidelines,
  setMaxPossibleLength,
  setPosition,
  setTubeLength,
  setTubeLengthSimple,
  setTubeRotation,
  SNAP_VALUE_OFFSET,
} from "./utils/TubeUtils"
import useCollision from "../../../../../../../providers/collisionProvider/useCollision"
import useSelector from "../../../../../../../providers/mouseProvider/useSelector"
import { TubeInternalsType, TUBE_UI } from "./types/types"
import TubeUI from "./ui/TubeUI"
import useTube from "./utils/useTube"
import { MarkerType, PartTypeEnum, TubeMarkerEnum } from "../../../../../../../utils/Types"
import { addPartModal, isItemSelected } from "../../../../../../../state/atoms"
import { PartConnectionType, UnitType } from "../../../../../../../state/scene/types"
import { partConnections } from "../../../../../../../state/scene/selectors"
import {
  connectionTypesSelector,
  sizesSelector
} from "../../../../../../../state/initialDataSelectors"
import { ConnectorMarkerType } from "../connector/types/types"
import { initialData } from "../../../../../../../state/atoms"

import useCamera from "../../../../../../../providers/cameraProvider/useCamera"
import {
  getConnectionMarkerName,
  getOtherPartOfTheConnection,
  isInConnection,
  useGetMarkerData
} from "../../../../../../../state/scene/util"
import {
  MarkerUserData,
  MAX_POSSIBLE_LENGTH_REDUCTION
} from "../../../../../../../utils/MarkerUtil"
import { useNewPart, useSetSnap, useUnsnap } from "../../../../../../../state/scene/setters"
import useModal from "../../../../../../../../common/providers/modalProvider/useModal"
import { getFreeMarkers, handleCollisionOnCreation } from "../../../../../../../utils/PartUtils"
import {
  useRegisterMultipleMovement
} from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { SoundHelper } from "../../../../utils/SoundHelper"
import useWoodTexture from "../hooks/useWoodTexture"
import NewPartButtonTube from "./newPartButton"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { SELECTED_PART_COLOR } from "../../../../../../../../common/utils/utils"
import { useComponentRegistry, useRegisterComponent } from
  "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { MultiSelectContext } from
  "../../../../../../../providers/multiselectProvider/MultiSelectProvider"
import hotkeys from "hotkeys-js"
import useGetDebugVariables from "../../../../utils/useGetDebugVariables"

interface Props {
  id: string;
  handleDeletePart: (id: string) => void;
}

const Model: React.FC<Props> = (props) => {
  const { scene, } = useThree()
  const { showModal, } = useModal()

  const [tubeUI, setTubeUI,] = useState<TUBE_UI>(TUBE_UI.NONE)
  const [initiated, setInitiated,] = useState(false)
  const [shouldIgnoreGuidelines, setShouldIgnoreGuidelines,] = useState(false)

  const { tube, updateTubeValues, } = useTube(props.id)
  const [color, updateColor,] = useState<Color | string>()

  const { material, woodTexture, } = useWoodTexture(tube.color, 8, 8)
  const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(tube.id))
  const setNewConnectionData = useSetRecoilState(addPartModal)
  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const partConnectionsValue = useRecoilValue(partConnections(tube.id))
  const getMarkerData = useGetMarkerData()
  const sizes = useRecoilValue(sizesSelector)
  const setSnap = useSetSnap()
  const unsnap = useUnsnap()
  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin


  const allParts = useRecoilValue(initialData)

  const tubeInternalsRef = useRef<TubeInternalsType>(getTubeInternals())
  const tubeMeshRef = useRef<Mesh>()
  const [isColliding, setIsColliding,] = useState(false)

  const { fit, getInAutofocusMode, setInAutofocusMode,
    addMeshToSceneBounds, getBoundingBoxForConnector, } = useCamera()

  const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
  const { unregister, } = useComponentRegistry()
  const multiSelectContext = useContext(MultiSelectContext)

  const wasDuplicated = !!tube.duplicatedFrom

  const [uiVisible, setUiVisible,] = useState(false)
  const [unit, updateUnit,] = useState(tube.partUnits ?? "in")

  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getLatestStatusofDuplication = () => {
    return multiSelectContext?.checkDuplicationStatus()
  }

  const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      (window as any).requestIdleCallback(callback, { timeout, })
    } else {
      setTimeout(callback, timeout)
    }
  }


  const onCollide = (colliding: boolean) => {
    setIsColliding(colliding)
  }

  const handleTubeClick = () => {
    setIsSelected(true)
    multiSelectContext?.setTransformMode("off")
    setTubeUI(TUBE_UI.SLIDER)
    setInAutofocusMode(true)
  }

  const {
    updateCollider,
    buildCollider,
    deleteCollider, } = useCollision(
      tube.id,
      tubeMeshRef,
      tubeMeshRef,
      onCollide,
      tube.type,
    )
  const { registerRegularMesh, } = useSelector(tube.id, handleTubeClick)

  const setInternals = (newTubeInternals: TubeInternalsType) => {
    tubeInternalsRef.current = newTubeInternals
  }

  const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({
    tube,
    tubeInternalsRef,
    scene,
    buildCollider,
    updateCollider: (connectedParts: string[]) => updateCollider(undefined, connectedParts),
    setInternals,
    compatibilityList: connectionTypes,
  })
  useRegisterMultipleMovement({
    id: props.id,
    attachToMarker: (marker: Mesh) => {
      const origin = getOriginMarker(tubeInternalsRef)
      marker.attach(origin)
    },
    detachFromMarker: (
      marker: Mesh,
      connections: PartConnectionType[],
      getExactSnaps?: boolean,
      checkCollisions?: boolean,
      resetGuidelines?: boolean,
    ) => {
      const tubeConnections = connections
        .filter(c => isInConnection(c, tube.id))
        .map(c => {
          return {
            connectedPartId: getOtherPartOfTheConnection(c, tube.id),
            connectedMarkerName: getConnectionMarkerName(c, tube.id)!,
          }
        })
      const newConnections = detachFromMarker(
        marker,
        tubeConnections.map(c => c.connectedPartId),
        tubeConnections.map(c => c.connectedMarkerName),
        checkCollisions,
        resetGuidelines
      )
      return newConnections
    },
    checksOnLengthMove: (
      elongationDirection: Vector3,
      connections: PartConnectionType[],
      lengthDirection: number
    ) => {
      return {
        ...MultipleMovementUtils.checkSnap({
          tube,
          scene,
          tubeInternalsRef,
          setInternals,
          elongationDirection,
          compatibilityList: connectionTypes,
        }),
        ...MultipleMovementUtils.checkAlignments({
          tube,
          scene,
          tubeInternalsRef,
          setInternals,
          elongationDirection,
          connections,
          lengthDirection,
        }),
      }
    },
    checksOnSegmentedTubeLengthMove: (
      intersectableMeshes: Object3D[],
      connections: PartConnectionType[]
    ) => {
      MultipleMovementUtils.checkAlignments({
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
        intersectableMeshes,
      })
    },
    checksOnRotationMove: (connections: PartConnectionType[],) => {
      MultipleMovementUtils.checkAlignments({
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
      })

      if (
        checkExactSnap(
          scene,
          tubeInternalsRef.current,
          getFreeMarkers(
            tube,
            connections.filter(c => isInConnection(c, tube.id))
              .map(c => getConnectionMarkerName(c, tube.id)!)
          ),
          tube,
          connectionTypes,
        ).length > 0
      ) {
        SoundHelper.playUnsnap()
      }
    },
    getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
      return MultipleMovementUtils.getMaxLength({
        direction,
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
        compatibilityList: connectionTypes,
      })
    },
    getPosAndRot: () => {
      const origin = getOriginMarker(tubeInternalsRef)
      const pos = MeshUtils.copyWorldPosition(origin!)
      origin!.rotateY(MathUtils.degToRad(-180))
      const rot = MeshUtils.copyWorldQuaternion(origin!)
      origin!.rotateY(MathUtils.degToRad(180))
      return { pos, rot, }
    },
  })

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    if (isSelected) {
      if (tube.loaded) {
        setTubeUI(TUBE_UI.SLIDER)
      }
      if (getInAutofocusMode()) {
        fit(tubeMeshRef.current!, tubeMeshRef.current!.matrixWorld)
      }
    }
    if (!isSelected) {
      removeGuidelines(tubeInternalsRef, scene, setInternals)
      setTubeUI(TUBE_UI.NONE)
    }
    updateColor(getColor())
  }, [isSelected,])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      event.preventDefault()
      handleTubeDelete()
    }

    if (isSelected) {
      hotkeys("delete,backspace", handleKeyPress)
    }

    // Cleanup function
    return () => {
      hotkeys.unbind("delete,backspace", handleKeyPress)
    }
  }, [isSelected,])

  const setSnapState = (length: number, connection: PartConnectionType) => {
    setSnap(props.id, length, connection)
  }

  useEffect(() => {

    if (partConnectionsValue.length < 2) {
      const { snapState, } = tubeInternalsRef.current
      if ((snapState.isSnapped && tube.length < snapState.maxPossibleLength - DRAG_VALUE_OFFSET)
        || !snapState.targetMarker) {
        tubeInternalsRef.current.snapState.isSnapped = false
      }
      if (!tubeInternalsRef.current.snapState.isSnapped) {
        if (tubeInternalsRef.current.movableMarkerMesh?.name === tube.originMarkerName) {
          handleInvertWithStateSave()
        }
        const tubeMarkerName = tubeInternalsRef.current.movableMarkerMesh?.name!
        const markerIsAvailable = !partConnectionsValue.some(
          ({ partMarkerName, }) => partMarkerName === tubeMarkerName
        )
        if (markerIsAvailable) {
          setTubeLength({
            length: tube.length,
            tube,
            scene,
            tubeInternalsRef,
            tubeMeshRef,
            setInternals,
            setSnap: setSnapState,
            updateCamera,
            compatibilityList: connectionTypes,
            ignoreGuidelines: shouldIgnoreGuidelines,
            unit: tube.partUnits ?? "in",
          })
          setShouldIgnoreGuidelines(false)
        } else {
          tubeInternalsRef.current.snapState.isSnapped = true
          setTubeLengthSimple(
            tube.length,
            tubeInternalsRef,
            tubeMeshRef,
            updateCamera,
            tube.partUnits ?? "in",
          )
        }
      }
    } else {
      const { dragState, mmSnapState, } = tubeInternalsRef.current
      let mmLength = tube.length
      if (mmSnapState.isSnapped
        && mmSnapState.maxPossibleLength - DRAG_VALUE_OFFSET < tube.length) {
        mmLength = mmSnapState.maxPossibleLength
      } else if (tubeInternalsRef.current.dragState.drag) {
        mmLength = dragState.dragValue
      }

      setTubeLengthSimple(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef,
        updateCamera,
        tube.partUnits ?? "in",
      )
    }
  }, [tube.length,])

  useEffect(() => {
    if (initiated) {
      const origin = getOriginMarker(tubeInternalsRef)
      setPosition(origin, tube)
      origin!.rotateY(MathUtils.degToRad(-180))
      setTubeRotation(origin, tube)
      origin!.rotateY(MathUtils.degToRad(180))
      const bounder = getBoundingBoxForConnector(tubeMeshRef.current!)
      setBoundingBox(bounder)
    }
  }, [tube.position, tube.rotation,])

  useEffect(() => {
    const { origin, movable, } = getMarkers(tube, tubeInternalsRef)
    const { snapState, mmSnapState, } = tubeInternalsRef.current
    tubeInternalsRef.current.movableMarkerMesh = movable
    const tubeMesh = tubeMeshRef.current

    origin!.rotation.set(0, MathUtils.degToRad(180), 0)

    attachTubeTrackerTo(tubeMesh!, movable!, origin!)

    if (tubeMesh) {
      setPosition(origin, tube)
      setTubeRotation(origin, tube)
      origin!.rotateY(MathUtils.degToRad(180))
      tubeInternalsRef.current.shape = createShape(tube)
      setMaxPossibleLength(tube.maxLength, snapState)
      setMaxPossibleLength(tube.maxLength, mmSnapState)
      setTubeLength({
        length: tube.length,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera,
        compatibilityList: connectionTypes,
        unit: tube.partUnits ?? "in",
      })
      registerRegularMesh()
      if (!tube.loaded && !wasDuplicated) {
        setIsSelected(true)
        setTubeUI(TUBE_UI.SLIDER)
      }
      setInitiated(true)
      removeGuidelines(tubeInternalsRef, scene, setInternals)
      if (!wasDuplicated) {
        handleCollisionOnCreation({
          buildCollider,
          updateCollider,
          deleteCollider,
          partType: PartTypeEnum.tube,
          onRemove: handleTubeDelete,
          showModal,
        })
      }
      addMeshToSceneBounds()
      const bounder = getBoundingBoxForConnector(tubeMeshRef.current!)
      setBoundingBox(bounder)
      setInAutofocusMode(true)
    }

    return () => {
      deleteCollider()
      if (tubeInternalsRef.current) {
        removeGuidelines(tubeInternalsRef, scene, setInternals)
      }
    }
  }, [])

  const handleMouseDown = (e: number, partsToMove?: string[]) => {
    if (partsToMove) {
      tubeInternalsRef.current.snapState.isSnapped = false
      tubeInternalsRef.current.snapState.maxLengthSetted = false
      tubeInternalsRef.current.snapState.maxPossibleLength = tube.maxLength
      tubeInternalsRef.current.snapState.targetMarker = null
      proyectRay(scene, tubeInternalsRef, setInternals)
      checkSnap(e,
        tube, tubeInternalsRef, tubeMeshRef.current, setInternals, setSnapState, scene)
    }
  }

  const updateCamera = (mesh?: Mesh, matrixWorld?: Matrix4) => {
    if (getInAutofocusMode()) {
      fit(
        mesh ? mesh : tubeMeshRef.current!,
        matrixWorld ? matrixWorld : tubeMeshRef.current!.matrixWorld
      )
    }
  }

  // eslint-disable-next-line complexity
  const handleTubeLength = (
    length: number,
    handleMovement: (
      partsIds: string[],
      onDrag: (distance: number) => void,
      onSnap: (newConnections: PartConnectionType) => void,
      direction: Vector3
    ) => void,
    partsToMove?: string[],
    ignoreGuidelines?: boolean,
  ) => {
    const { snapState, dragState, mmSnapState, } = tubeInternalsRef.current

    setIsSelected(true)

    if (snapState.isSnapped && length < snapState.maxPossibleLength - SNAP_VALUE_OFFSET) {
      tubeInternalsRef.current.snapState.isSnapped = false
      unsnap(props.id, [tubeInternalsRef.current.movableMarkerMesh!.name,])
      SoundHelper.playSnap()
    }
    if (snapState.hasCollied && length < snapState.maxPossibleLength) {
      tubeInternalsRef.current.snapState.hasCollied = false
    }
    if (dragState.drag
      && (
        length < dragState.dragValue - DRAG_VALUE_OFFSET
        || length > dragState.dragValue + DRAG_VALUE_OFFSET
      )) {
      tubeInternalsRef.current.dragState.drag = false
      tubeInternalsRef.current.dragState.dragValue = 0
    }
    if (partsToMove && partsToMove.length > 0) {
      if (mmSnapState.isSnapped && length < mmSnapState.maxPossibleLength - SNAP_VALUE_OFFSET) {
        tubeInternalsRef.current.mmSnapState.isSnapped = false
        tubeInternalsRef.current.mmSnapState.newConnection = undefined
        SoundHelper.playSnap()
      }
      let mmLength = length
      if (mmSnapState.isSnapped
        && mmSnapState.maxPossibleLength - DRAG_VALUE_OFFSET < length) {
        mmLength = mmSnapState.maxPossibleLength
      } else if (tubeInternalsRef.current.dragState.drag) {
        mmLength = dragState.dragValue
      }
      setTubeLengthSimple(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef,
        updateCamera,
        tube.partUnits ?? "in",
      )
      const handleDrag = MultipleMovementUtils.getHandleDrag(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef.current!,
        setInternals
      )
      const handleSnap = MultipleMovementUtils.getHandleSnap(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef.current!,
        setInternals
      )
      const direction = MeshUtils.copyWorldDirection(tubeInternalsRef.current.movableMarkerMesh!)
      handleMovement(partsToMove, handleDrag, handleSnap, direction)
    } else if (length >= snapState.maxPossibleLength) {
      setTubeLength({
        length: snapState.maxPossibleLength,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera,
        compatibilityList: connectionTypes,
        unit: tube.partUnits ?? "in",
      })
    } else if (!dragState.drag && !snapState.isSnapped && !snapState.hasCollied) {
      setTubeLength({
        length,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera,
        buildCollider,
        updateCollider,
        compatibilityList: connectionTypes,
        ignoreGuidelines,
        unit: tube.partUnits ?? "in",
      })
    }
  }

  const handleUnitsChange = (unit: string) => {
    //console.log("handleUnitsChange", unit)
    updateTubeValues(tube.id, (t) => {
      t.partUnits = unit
    })
    updateUnit(unit)
  }

  const handleTubeState = (length: number, ignoreGuidelines?: boolean) => {
    const { markerTopMesh, snapState, dragState, } = tubeInternalsRef.current
    if (markerTopMesh) {
      const positionMarker = MeshUtils.copyWorldPosition(markerTopMesh)
      if (ignoreGuidelines) {
        setShouldIgnoreGuidelines(true)
      }
      updateTubeValues(tube.id, (t) => {
        t.length = getLengthOnRelease(length, snapState, dragState)
        t.marker = tube.marker
      })

      tubeInternalsRef.current.dragState = {
        drag: false, dragValue: 0,
      }
    }
    buildCollider()
    updateCollider(undefined, [snapState.targetMarker?.object.userData.partId,])
    removeGuidelines(tubeInternalsRef, scene, setInternals)
  }

  const getMarker = (markerType: TubeMarkerEnum, rotation: number) => {
    const markerIsAvailable = !partConnectionsValue.some(
      ({ partMarkerName, }) => partMarkerName === markerType
    )

    const userData: MarkerUserData = {
      userDataType: "MarkerUserData",
      id: tube.marker.id,
      type: MarkerType.COLLISION,
      partId: tube.id,
      partApiId: tube.apiTypeId,
      partType: PartTypeEnum.tube,
      sizeId: tube.marker.sizeId,
      innerOuter: ConnectorMarkerType.outer,
      iELength: tube.marker.iELenght,
      markerName: markerType,
    }
    return (
      <Plane
        ref={(r) => {
          markerType === TubeMarkerEnum.TOP
            ? tubeInternalsRef.current.markerTopMesh = r as Mesh
            : tubeInternalsRef.current.markerBottomMesh = r as Mesh
        }}
        rotation={[0, MathUtils.degToRad(rotation), 0,]}
        args={[0.01, 0.01,]}
        userData={userData}
        name={markerType}>
        <Plane args={[80, 80,]} userData={{ type: MarkerType.COLLISION_PLANE, tubeID: tube.id, }}>
          <meshPhongMaterial
            attach="material"
            alphaTest={0}
            visible={false}
            color={"#ffbd08"}
            side={DoubleSide} />
        </Plane>
        <meshPhongMaterial
          attach="material"
          color={"#ff0808"}
          alphaTest={0}
          visible={false}
          side={DoubleSide}
        />
        {isSelected && markerIsAvailable
          && <NewPartButtonTube
            trackType={markerType}
            tubeInternalsRef={tubeInternalsRef}
            clearTubeUI={setTubeUI}
            setIsSelected={setIsSelected}
            tube={tube}
          />
        }
      </Plane>
    )
  }

  const handleTubeDelete = () => {
    props.handleDeletePart(tube.id)
    unregister(tube.id)
  }

  const handleSwap = () => {
    handleTubeSwap(
      { ...tube, type: PartTypeEnum.tube, },
      partConnectionsValue,
      connectionTypes,
      sizes,
      getMarkerData,
      setNewConnectionData,
      scene,
      tubeMeshRef.current!,
    )
  }

  const handleInvertWithStateSave = () => {
    handleInvert(tube.originMarkerName)
    const { origin, } = getMarkers(tube, tubeInternalsRef)
    const newPos = MeshUtils.copyWorldPosition(origin!)
    origin!.rotateY(MathUtils.degToRad(-180))
    const newRot = MeshUtils.copyWorldQuaternion(origin!)
    origin!.rotateY(MathUtils.degToRad(180))
    updateTubeValues(tube.id, (t) => {
      t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
      t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
    })
  }

  const handleInvert = (newOrigin: string) => {
    invertTube(
      newOrigin
        === TubeMarkerEnum.TOP ? TubeMarkerEnum.BOTTOM : TubeMarkerEnum.TOP,
      tubeMeshRef,
      tubeInternalsRef,
      scene
    )
  }

  useEffect(() => {
    if (initiated && tube.originMarkerName === tubeInternalsRef.current.movableMarkerMesh?.name) {
      invertTube(
        tube.originMarkerName
          === TubeMarkerEnum.TOP ? TubeMarkerEnum.BOTTOM : TubeMarkerEnum.TOP,
        tubeMeshRef,
        tubeInternalsRef,
        scene)
      const { origin, } = getMarkers(tube, tubeInternalsRef)
      const newPos = MeshUtils.copyWorldPosition(origin!)
      origin!.rotateY(MathUtils.degToRad(-180))
      const newRot = MeshUtils.copyWorldQuaternion(origin!)
      origin!.rotateY(MathUtils.degToRad(180))
      updateTubeValues(tube.id, (t) => {
        t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
        t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
      })
    }
  }, [tube.originMarkerName,])

  const getColor = () => {
    if (isSelected) {
      return new Color(SELECTED_PART_COLOR)
    } else {
      return material ? "#fff" : new Color(tube.color)
    }
  }
  const originalColor = () => {
    updateColor(getColor())
  }

  const getPartInfo = () => {
    return tube
  }

  const updateInitialMarkerName = (newMarkerName: TubeMarkerEnum) => {
    setUItoNone()
    updateTubeValues(tube.id, (t) => {
      t.originMarkerName = newMarkerName
    })
  }

  const getBoundingBox = () => {
    const bounder = getBoundingBoxForConnector(tubeMeshRef.current!)
    setBoundingBox(bounder)
    return bounder
  }

  const findPartById = (apiTypeId: string) => {
    return allParts?.parts.find(part => part.id === apiTypeId)
  }

  const createPart = useNewPart()

  const duplicatePart = (historyKey: string, position: Vector3, originMarkerName?: string,
    markerOffset?: Vector3, rotation?: Quaternion, length?: number) => {
    const matchingPart = findPartById(tube.apiTypeId)
    if (!matchingPart) {
      console.warn("part no longer exists and cannot be duplicated", tube.apiTypeId)
      return
    }
    const defaultQuaternion = new Quaternion()
    let newPart: any
    if (matchingPart && position) {
      newPart = {
        part: {
          ...matchingPart,
        },
        posAndRot: {
          inner: {
            pos: position,
            rot: rotation || defaultQuaternion,
          },
          outer: {
            pos: position,
            rot: rotation || defaultQuaternion,
          },
        },
      }
    }
    if (matchingPart && !position) {
      newPart = {
        part: {
          ...matchingPart,
        },
        posAndRot: {
          inner: {
            pos: new Vector3(0, 0, 0),
            rot: defaultQuaternion,
          },
          outer: {
            pos: new Vector3(0, 0, 0),
            rot: defaultQuaternion,
          },
        },
      }
    }
    if (originMarkerName) {
      newPart.originMarkerName = originMarkerName
    }
    if (length) {
      newPart.length = length
    }
    newPart.duplicatedFrom = tube.id
    const { newPartId, } = createPart(newPart, historyKey)
    return newPartId
  }

  const setUItoNone = () => {
    setTubeUI(TUBE_UI.NONE)
    setIsSelected(false)
  }

  // eslint-disable-next-line max-len
  const updatePositionAndRotation = async (position: Vector3, rotation: Quaternion, markerOffset?: Vector3, historyKey?: string): Promise<void> => {
    await new Promise((resolve, reject) => {
      // Check if already unmounted
      if (!mountedRef.current) {
        reject(new Error("Component unmounted"))
        return
      }

      requestAnimationFrame(() => {
        // Check again after frame
        if (!mountedRef.current) {
          reject(new Error("Component unmounted"))
          return
        }

        updateTubeValues(tube.id, (t) => {
          t.position = position
          t.rotation = rotation
        }, false, historyKey)

        const idleCallback = () => {
          // Final check before resolving
          if (!mountedRef.current) {
            reject(new Error("Component unmounted"))
            return
          }

          updateCollider()
          resolve(undefined)
        }

        if ("requestIdleCallback" in window) {
          requestIdleCallback(idleCallback, { timeout: 100, })
        } else {
          setTimeout(idleCallback, 100)
        }
      })
    }).catch(err => {
      // Handle cancellation - you can either:
      // 1. Silently ignore: do nothing
      // 2. Log for debugging
      console.debug("Position update cancelled:", err.message)
      // 3. Rethrow if you want upstream handlers to know
      // throw err
    })
  }

  const getMesh = (color?: string) => {
    const mesh = tubeMeshRef.current?.parent
    if (tubeMeshRef.current && color) {
      (tubeMeshRef.current.material as MeshBasicMaterial).color.set(color)
    }
    return mesh
  }

  const getAllMarkers = () => {
    const topMesh = tubeInternalsRef.current.markerTopMesh
    const bottomMesh = tubeInternalsRef.current.markerBottomMesh

    // Create middle marker by cloning top marker
    if (topMesh && bottomMesh) {
      // Get world positions
      const topPos = new Vector3()
      const bottomPos = new Vector3()
      topMesh.getWorldPosition(topPos)
      bottomMesh.getWorldPosition(bottomPos)

      // Calculate middle position
      const middlePos = new Vector3()
      middlePos.lerpVectors(topPos, bottomPos, 0.5)

      // Clone top marker and set position
      const middleMesh = topMesh.clone()
      middleMesh.name = "tube_middleMarker"
      middleMesh.position.copy(middlePos)

      return [topMesh, middleMesh, bottomMesh,]
    }

    return [topMesh, bottomMesh,].filter(Boolean)
  }

  useRegisterComponent(props.id, {
    updateColor, originalColor, deletePart: handleTubeDelete,
    getPartInfo, updateInitialMarkerName,
    getBoundingBox,
    duplicatePart, setUItoNone,
    updatePositionAndRotation, getAllMarkers, getMesh,
  },)

  const getInternals = () => {
    return tubeInternalsRef
  }

  const setMaxLengthAsCurrentLength = () => {
    tubeInternalsRef.current.snapState.maxLengthSetted = true
    tubeInternalsRef.current.snapState.maxPossibleLength
      = tube.length - MAX_POSSIBLE_LENGTH_REDUCTION
    tubeInternalsRef.current.snapState.isSnapped = true
  }

  useEffect(() => {
    if (isSelected) {
      setUiVisible(true)
      if (isAdmin) {
        console.log(tube.id, "is selected", tube)
      }
      setTubeUI(TUBE_UI.NONE)
      const timer = setTimeout(() => {
        setTubeUI(TUBE_UI.SLIDER)
      }, 150)
      return () => clearTimeout(timer)
    } else {
      // Delay hiding UI to allow for any final updates
      const timer = setTimeout(() => {
        setUiVisible(false)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isSelected,])


  return (
    <React.Fragment>
      {getMarker(TubeMarkerEnum.BOTTOM, 0)}
      {getMarker(TubeMarkerEnum.TOP, 0)}
      <mesh
        ref={(r) => { tubeMeshRef.current = r as Mesh }}
        userData={{ type: "COLLISION_TUBE", }}
        name={props.id}>
        <meshMatcapMaterial
          map={material && woodTexture ? woodTexture : null}
          attach="material"
          opacity={0.4}
          color={color} />
      </mesh>
      {uiVisible && <TubeUI
        getTubeInternals={getInternals}
        tube={tube}
        tubeUI={tubeUI}
        setTubeUI={setTubeUI}
        getDrag={() => getDrag(tubeInternalsRef.current.dragState)}
        getSnap={() => getSnap(tubeInternalsRef.current.snapState)}
        handleTubeDelete={handleTubeDelete}
        handleTubeSwap={handleSwap}
        handleTubeLength={handleTubeLength}
        handleTubeState={handleTubeState}
        handleMouseDown={handleMouseDown}
        handleInvert={handleInvert}
        buildCollider={buildCollider}
        updateCollider={updateCollider}
        setMaxLengthAsCurrentLength={setMaxLengthAsCurrentLength}
        setInternals={setInternals}
        onDuplicate={multiSelectContext?.duplicateSelectedParts ?? (() => { })}
        setIdsAsHighlightedAndTurnOnControl
        ={multiSelectContext?.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
        transformMode={multiSelectContext?.transformMode ?? "off"}
        unsnap={unsnap}
        hasTubeConnections={partConnectionsValue.length > 0}
        updateUnit={handleUnitsChange}
        unit={unit as UnitType}
      />}
    </React.Fragment>
  )
}

export default Model