/* eslint-disable max-statements */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, { MutableRefObject, useEffect, useMemo, useRef, useState, } from "react"
import { Html } from "@react-three/drei"
import { ConnectorInternalsType, CONNECTOR_UI } from "../types/types"
import { AnimatePresence } from "framer-motion"
import ReactDOM from "react-dom"
import {
    Box3, BufferGeometry, InstancedMesh, Material, MathUtils,
    Mesh, Object3D, Vector3
} from "three"
import {
    ActionsContainer,
} from "../../ActionPanelStyles"
import {
    useMultipleMovement
} from "../../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { ConnectorValues } from "../../../../../../../../utils/Types"
import { radToDeg } from "three/src/math/MathUtils"
import useHandleRotation from "../utils/useHandleRotation"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { useSlide } from "../../../../../../../../providers/slideProvider/useSlide"
import SegmentedSlider from "../../SegmentedSlider"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import { EnvHelper } from "../../../../../../../../../common/utils/EnvHelper"
import useHandleSlide from "../utils/useHandleSlide"
import { getInitialMarker } from "../utils/ConnectorUtils"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { useThree } from "@react-three/fiber"
import { MultipleMovementUtils } from "../utils/MultipleMovementUtils"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { useRecoilState, useRecoilValue } from "recoil"
import RotationSlider from "../../sliders/RotationSlider"
import ActionsUI from "./ActionsUI"
import CancelUI from "./CancelUI"
import useUnitConversion from "../../../../../utils/UnitUtils"
import type { UnitType } from "../../../../../../../../state/scene/types"
import DragSlide, { DragSliderRef } from "../../../../../../../../providers/moveProvider/DragSlider"
import { DragPoint, FreePositions } from "../../../../../../../../providers/moveProvider/types"
import { connectionIndexSelector } from "../../../../../../../../state/scene/selectors"
import { useUnsnap } from "../../../../../../../../state/scene/setters"
import { ReactNode, ReactPortal } from "react"
import hotkeys from "hotkeys-js"
import { useGlobalAnimation } from "../../utils/animations/GlobalAnimationProvider"
import useCamera from "../../../../../../../../providers/cameraProvider/useCamera"
import { SegmentedTubeMarkers } from "../../segmentedTube/types/types"
import UnsnapButton from "../../../../../../../../providers/moveProvider/UnsnapButton"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { isInner } from "../../../../../../../../utils/MarkerUtil"
import { useAlignment } from "../../../../../../../../providers/alignmentProvider/useAlignment"
import { AlignmentPosition } from "../../segmentedTube/ui/AlignmentOptionsUI"
import { useLevaControls } from "../../../../../../../../providers/debugProvider/useLevaControls"
import { checkIfMiddleWithoutBoundary } from "../../../../../../../../providers/moveProvider/meshHelpers"
import { useDebugTools } from "../../../../../../../../providers/debugProvider/useDebugTools"

type RotationProps = {
    handleRotation: (value: number, rotationMarker?: Mesh) => void,
    handleRotationStart: () => void,
}

type SwapProps = {
    showSwap: false,
} | {
    showSwap: true,
    handleConnectorSwap: () => void,
}

type Props = {
    connector: ConnectorValues,
    connectorInternalsRef: MutableRefObject<ConnectorInternalsType>,
    attachedMarker: MutableRefObject<Mesh | undefined>,
    connectorUI: CONNECTOR_UI,
    setConnectorUI: (ui: CONNECTOR_UI) => void,
    handleConnectorDelete: () => void,
    handleConnectorState: () => void,
    handleMouseUp: () => void,
    attachEverythingTo: (marker: Mesh) => void,
    detachEverythingFrom: (marker: Mesh) => void,
    buildCollider: () => void,
    updateCollider: (removePart?: (() => void), newConnectedParts?: string[]) => void,
    checkExactSnap: () => PartConnectionType[],
    updateTransforms: () => void,
    instancedMesh: InstancedMesh<BufferGeometry, Material | Material[]> | undefined,
    onUserRotationApplied: () => void,
    setIsSelected: (isSelected: boolean) => void,
    setIdsAsHighlightedAndTurnOnControl: (ids: string[],
        control: "selection" | "translate" | "rotate") => void,
    boundingBox: Box3 | null,
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void,
    transformMode: string,
    setRotationMarker: () => void,
} & RotationProps & SwapProps


const action_variants = {
    "visible": {
        y: "0%",
    },
    "hidden": {
        y: "130%",
    },
}

const transition = {
    duration: 0.5,
    ease: [0.54, 0.01, 0.61, 1,],
}


const ConnectorUI = ({ connector, instancedMesh, ...props }: Props) => {
    const {
        canEditRotation,
        canEditSlide,
        attachToMove,
        detachMarkers,
        handleRotationMovement,
        handleSlideMovement,
        saveRotationChanges,
        saveRotationChangesModified,
        saveSegmentedTubeSliderChanges,
    } = useMultipleMovement(connector.id)
    const { enableCamera, } = useCamera()
    const actions_child = document.getElementById("actions-container")!
    const { scene, camera, } = useThree()
    const connections = useRecoilValue(allConnections)
    const markerMeshesWithoutMiddleSegments = useRef<Object3D[]>([])
    const { unit, updateUnit, } = useUnitConversion()
    const [connectionIndex, setConnectionIndex,]
        = useRecoilState(connectionIndexSelector(connector.id,))
    const slide = useSlide(connector.id, connectionIndex)
    const canSlide = slide.canSlide()
    const [slidePartId, setSlidePartId,] = useState(canSlide.sliderPartId)
    const unsnap = useUnsnap()
    const selectedNameRoot = document.getElementById("selected-part-name-root")!
    const slideRefAreAttached = useRef(false)
    const slideRefMustDetach = useRef(false)
    const attachToMoveRef = useRef({
        partsToMove: [] as string[],
        marker: undefined as Mesh | undefined,
    })
    const detachMarkersRef = useRef({
        partsToDetach: [] as string[],
        marker: undefined as Mesh | undefined,
    })
    const [refreshKey, setRefreshKey,] = useState(0)
    const { align, getPoints, } = useAlignment()
    const { triggerAnimation, } = useGlobalAnimation()
    const { debugAlignment, } = useLevaControls()
    const { drawVector3Point, } = useDebugTools()
    const [positionChanged, setPositionChanged,] = useState(false)
    const dragSliderRef = useRef<DragSliderRef>(null)

    const getSlider = () => {
        if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER) {
            return (
                <SegmentedSlider
                    connectedToSegmentedParts={canSlide.connectedToSegmentedParts}
                    connectionIndex={connectionIndex}
                    setConnectionIndex={setConnectionIndex}
                    onAlignmentSelect={handleAlignmentSelect}
                    onAlignmentHover={handleAlignmentHover}
                    rotationPoint={slide.getSlideSide(canSlide.markerName!)}
                    camera={camera}
                    unit={["cm", "in",].includes(canSlide.slidePartUnits || "")
                        ? canSlide.slidePartUnits as "cm" | "in"
                        : undefined}
                    debugAlignment={debugAlignment || false}
                    canUseAlignment={canUseAlignment}
                    positionChanged={positionChanged}
                />)
        }
        if (props.connectorUI === CONNECTOR_UI.SLIDER
            && !!multipleMove?.partsToMove && !connector.rotationDisabled) {
            return <RotationSlider
                onFinishEditing={removeModal}
                handleMouseUp={props.handleMouseUp}
                handleMouseDown={props.handleRotationStart}
                onRotationChange={handleConnectorRotation}
                onRotationSave={handleSaveRotation}
                getRotation={getRotation}
                steps={connector.rotationSteps || EnvHelper.rotationStep}
            />
        }

        return <CancelUI onFinishEditing={() => props.setConnectorUI(CONNECTOR_UI.CLOSE)} />
    }

    const onPositionChange = (value: FreePositions) => {
        handleSlideMovementAndUpdatePosition(value.position)
        setPositionChanged(true)
    }

    const onUnsnap = (value?: FreePositions) => {
        if (value && value.position) {
            handleSlideMovementAndUpdatePosition(value.position)
        }
        props.setConnectorUI(CONNECTOR_UI.NONE)
        unsnap(connector.id)
        setTimeout(() => {
            enableCamera()
            props.setIdsAsHighlightedAndTurnOnControl([connector.id,], "translate")
            // props.setConnectorUI(CONN ECTOR_UI.SLIDER)
        }, 350)
    }

    const onDragEnd = (point: DragPoint) => {
        const meshNameParts = point.meshName.split("_")
        if (meshNameParts[1]) {
            const numericPart = meshNameParts[1].replace(/[^-\d]/g, "")
            const number = Number(numericPart)
            handleSegmentedMouseUp(number)
        } else {
            handleSegmentedMouseUp(null)
        }
        setPositionChanged(false)
    }

    const { initialRotation,
        actualRotation,
        updateActualRotation,
        multipleMove,
        resetMultipleMove,
        setNewChangesToSave,
        setUserRotation,
        isInitialRotationSet,
    } = useHandleRotation({
        connector: connector,
        connectorUI: props.connectorUI,
        attachedMarker: props.attachedMarker,
        connectorInternalsRef: props.connectorInternalsRef,
        attachEverythingTo: props.attachEverythingTo,
        detachEverythingFrom: props.detachEverythingFrom,
        buildCollider: props.buildCollider,
        updateCollider: props.updateCollider,
        canEditRotation,
        saveRotationChanges: saveRotationChangesModified,
        handleConnectorState: props.handleConnectorState,
        checkExactSnap: props.checkExactSnap,
        setConnectorUI: props.setConnectorUI,
        slideEnabled: canSlide.slideEnabled,
        attachToMove,
        detachMarkers,
    })

    const handleSlide = useHandleSlide({
        connector: connector,
        connectorUI: props.connectorUI,
        attachedMarker: props.attachedMarker,
        connectorInternalsRef: props.connectorInternalsRef,
        attachEverythingTo: props.attachEverythingTo,
        detachEverythingFrom: props.detachEverythingFrom,
        buildCollider: props.buildCollider,
        updateCollider: props.updateCollider,
        savePositionRotationChanges: saveRotationChangesModified,
        handleConnectorState: props.handleConnectorState,
        checkExactSnap: props.checkExactSnap,
        setConnectorUI: props.setConnectorUI,
        canEditSlide,
        saveSegmentedTubeSliderChanges,
        sliderPartId: slidePartId,
        attachToMove,
        detachMarkers,
        connectionIndex,
    })

    const handleSlideRef = useRef(handleSlide)

    useEffect(() => {
        handleSlideRef.current = handleSlide
    }, [handleSlide,])

    const removeModal = () => {
        props.setConnectorUI(CONNECTOR_UI.CLOSE)
    }

    useEffect(() => {
        setSlidePartId(canSlide.sliderPartId)
    }, [canSlide.sliderPartId, connectionIndex,])

    const getRotationMeshName = (meshName: string): string => {
        const numberMatch = meshName.match(/\d+/)
        if (numberMatch) {
            const number = numberMatch[0]
            return `rotation${number}`
        }
        return meshName
    }

    const findRotationMarkerWithinRef = () => {
        const markers = props.connectorInternalsRef.current.markers
        const rotationMarkerName = connector.rotationMarkerName
        //console.log("looking for this rotation marker name", rotationMarkerName)
        return Object.values(markers).find((marker: Mesh) => marker.name.toLowerCase().includes(rotationMarkerName))
    }

    const handleConnectorRotation = (value: number, debug = false) => {
        if (multipleMove?.partsToMove && multipleMove.partsToMove.length > 0) {
            if (connector.specialRotationMarker) {
                const rotationMarker = findRotationMarkerWithinRef()
                if (rotationMarker) {
                    attachToMove(multipleMove.partsToMove, rotationMarker)
                    props.handleRotation(value + radToDeg(initialRotation),
                        rotationMarker)
                }

            } else {
                attachToMove(multipleMove.partsToMove, props.attachedMarker.current!)
                props.handleRotation(value + radToDeg(initialRotation),
                    props.attachedMarker.current!)
            }
            handleRotationMovement(
                multipleMove?.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
            if (connector.specialRotationMarker) {
                const rotationMarker = findRotationMarkerWithinRef()
                if (rotationMarker) {
                    detachMarkers(multipleMove!.partsToMove, rotationMarker)
                    //console.log(rotationMarker, "rotationMarker")
                }
            } else {
                detachMarkers(multipleMove!.partsToMove, props.attachedMarker.current!)
            }
        } else {
            props.handleRotation(value + radToDeg(initialRotation),)
        }
        updateActualRotation(value)
    }

    const handleSaveRotation = (value: number) => {
        if (multipleMove?.partsToMove && multipleMove.partsToMove.length > 0) {
            attachToMove(multipleMove.partsToMove, props.attachedMarker.current!)
            const newConnections
                = detachMarkers(
                    multipleMove!.partsToMove,
                    props.attachedMarker.current!,
                    true,
                    true,
                    false
                )
            setNewChangesToSave(newConnections)
            props.updateCollider()
        }
        updateActualRotation(value)
    }

    const handleSwap = () => {
        if (props.showSwap) {
            breadcrumb({
                message: "Click on swap",
                level: "info",
                data: {
                    partId: connector.id,
                    partName: connector.name,
                },
            })
            resetMultipleMove()
            props.handleConnectorSwap()
        }
    }

    const handleDelete = () => {
        resetMultipleMove()
        props.handleConnectorDelete()
    }

    const getRotation = () => {
        return actualRotation.rotation
    }

    const handleSlideMovementAndUpdatePosition = (newWorldPosition: Vector3) => {
        //console.log("handleSlideMovementAndUpdatePosition")
        if (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0) {

            const currentPartsToMove = handleSlideRef.current.multipleMove.partsToMove
            const currentMarker = props.attachedMarker.current!

            // Check if the values are different from the last time
            if (currentPartsToMove !== attachToMoveRef.current.partsToMove
                || currentMarker !== attachToMoveRef.current.marker) {
                // Only run attachToMove if values are different
                if (slideRefAreAttached.current && slideRefMustDetach.current) {
                    detachMarkers(currentPartsToMove,
                        currentMarker)
                    slideRefMustDetach.current = false
                    slideRefAreAttached.current = true
                }
                attachToMove(currentPartsToMove, currentMarker)
                slideRefAreAttached.current = true

                // Store the new values in the ref
                attachToMoveRef.current = {
                    partsToMove: currentPartsToMove,
                    marker: currentMarker,
                }
            }

            props.attachedMarker.current!.position.copy(newWorldPosition)
            props.updateTransforms()
            handleSlideMovement(
                handleSlideRef.current.multipleMove.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
            if (currentPartsToMove !== detachMarkersRef.current.partsToDetach
                || currentMarker !== detachMarkersRef.current.marker) {

                //console.log("detach case")

                // Only run detachMarkers if values are different
                //detachMarkers(currentPartsToMove, currentMarker)

                // Store the new values in the ref
                detachMarkersRef.current = {
                    partsToDetach: currentPartsToMove,
                    marker: currentMarker,
                }

                slideRefMustDetach.current = true
            }
        } else {
            props.attachedMarker.current!.position.copy(newWorldPosition)
            props.updateTransforms()
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef: props.connectorInternalsRef,
                connections,
                setInternals: (newConnectorInternals: ConnectorInternalsType) => {
                    props.connectorInternalsRef.current = newConnectorInternals
                },
                intersectableMeshes: markerMeshesWithoutMiddleSegments.current,
            })
        }
        if (dragSliderRef.current) {
            dragSliderRef.current.updateDraggableObjectCenter(newWorldPosition)
        }
    }

    const handleMultipleMove = (currentPartsToMove: string[], currentMarker: Mesh) => {
        // Check if values are different from last time
        if (currentPartsToMove !== attachToMoveRef.current.partsToMove
            || currentMarker !== attachToMoveRef.current.marker) {

            // Handle detachment if needed
            if (slideRefAreAttached.current && slideRefMustDetach.current) {
                detachMarkers(currentPartsToMove, currentMarker)
                slideRefMustDetach.current = false
                slideRefAreAttached.current = true
            }

            // Attach parts
            attachToMove(currentPartsToMove, currentMarker)
            slideRefAreAttached.current = true

            // Update ref with new values
            attachToMoveRef.current = {
                partsToMove: currentPartsToMove,
                marker: currentMarker,
            }

        }
    }

    const handleSegmentedMouseUp = (value: number | null) => {
        if (canSlide.slideEnabled) {
            const initialMarker = getInitialMarker(
                connector, props.connectorInternalsRef.current.markers)
            const newWorldPosition = MeshUtils.copyWorldPosition(initialMarker)

            slide.saveNewPosition(canSlide.sliderPartId!, value, newWorldPosition)

            if (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove.partsToMove.length > 0) {
                handleSlideRef.current.setNewChangesToSave([])
            }
        }
    }

    useEffect(() => {
        if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER
            || props.connectorUI === CONNECTOR_UI.SLIDER) {
            markerMeshesWithoutMiddleSegments.current
                = MeshUtils.getMarkerMeshesWithoutMiddleSegments(scene)
        }
        if (props.connectorUI === CONNECTOR_UI.SLIDER) {
            //console.log("slider")
            if (connector.specialRotationMarker) {
                const rotationMarker = findRotationMarkerWithinRef()
                if (props.attachedMarker.current!.name !== rotationMarker!.name) {
                    if (rotationMarker) {
                        props.setRotationMarker()
                    }
                }
            }
        }
        if (slideRefMustDetach.current && detachMarkersRef.current.marker) {
            detachMarkers(detachMarkersRef.current.partsToDetach,
                detachMarkersRef.current.marker)
            slideRefMustDetach.current = false
            slideRefAreAttached.current = false
        }
        //this is to refresh the dragSlide component
        if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER) {
            setTimeout(() => {
                setRefreshKey((prev) => prev + 1)
            }, 100)
        }
    }, [props.connectorUI, connectionIndex,])

    useEffect(() => {
        if (props.connectorUI === CONNECTOR_UI.ACTIONS) {

            if (canSlide.slideEnabled) {
                props.setConnectorUI(CONNECTOR_UI.SEGMENTED_SLIDER)
            } else if (!!multipleMove?.partsToMove) {
                props.setConnectorUI(CONNECTOR_UI.SLIDER)
            }
        }
    }, [props.connectorUI, connectionIndex,])

    useEffect(() => {
        if (connector.userRotation && isInitialRotationSet) {
            setUserRotation(connector.userRotation)
            handleConnectorRotation(connector.userRotation)
            handleSaveRotation(connector.userRotation)
            props.onUserRotationApplied()
        }
    }, [isInitialRotationSet,])

    let slideEnabled = canSlide.slideEnabled
        && (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove?.partsToMove.length
            <= EnvHelper.maxSegmentedTubeSlideSteps)

    useEffect(() => {
        slideEnabled = canSlide.slideEnabled
            && (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove?.partsToMove.length
                <= EnvHelper.maxSegmentedTubeSlideSteps)
    }, [handleSlideRef.current.multipleMove?.partsToMove,])

    const slidingSides = useMemo(() => {
        // we grab the slide sides manually because they are not available in the canSlide object
        // canSlide only gets registered segTubes that allow sliding (connectors do not)
        const markers = props.connectorInternalsRef.current.markers
        const markersToSlide
            = Object.values(markers).filter((marker: Mesh) =>
                marker.name.includes(canSlide.markerName!))
        const obj: SegmentedTubeMarkers = {}
        markersToSlide.forEach((marker: Mesh) => {
            const inner = isInner(marker.name)
            const nameParts = marker.name.split(inner ? "inner" : "outer")

            const number = Number(nameParts[1])
            obj[number.toString()] = {
                "outer": marker,
                "inner": marker,
            }
        })
        return obj
    }, [props.connectorInternalsRef.current.markers, connectionIndex,])


    const canUseAlignment = useMemo(() => {
        const otherPart = slide.getSlideSide(canSlide.markerName!)
        const thisPart = canSlide.slideSides

        if (!otherPart || !thisPart) {
            return false
        }

        const firstSourceKey = Object.keys(otherPart)[0]
        const firstTargetKey = Object.keys(thisPart)[0]
        const sourceMeshLayer = otherPart[firstSourceKey].mesh
        const targetMeshLayer = thisPart[firstTargetKey].mesh
        if (!sourceMeshLayer || !targetMeshLayer) {
            return false
        }

        return true
    }, [canSlide.markerName, canSlide.slideSides,])

    const getAlignmentProperties = () => {
        const otherPart = {
            marker: slide.getSlideSide(canSlide.markerName!)!,
            mergedMesh: canSlide.slideMergedMesh,
            instanceIndex: canSlide.sliderInstanceIndex,
        }

        const thisPart = {
            marker: canSlide.slideSides,
            instanceIndex: canSlide.slidingInstanceIndex,
            mergedMesh: canSlide.slidingMergedMesh,
        }

        return { otherPart, thisPart, }
    }

    const attachOriginToPoint = (attachmentPoint: Object3D, anchorPoint: Vector3, newPoint: Vector3) => {
        // Store original parent for cleanup
        const originalParent = attachmentPoint.parent

        // Create an anchor point at the specified position
        const anchor = new Object3D()
        anchor.name = "anchorPoint"
        anchor.position.copy(anchorPoint)

        // drawVector3Point(newPoint, "green", 0.001, 1000)

        // Attach the attachment point to the new anchor
        anchor.attach(attachmentPoint)
        anchor.position.copy(newPoint)

        props.updateTransforms()

        // Restore the original parent
        if (originalParent) {
            originalParent.attach(attachmentPoint)
        }
    }

    const handleAlignmentSelect = (position: AlignmentPosition, offsetX: number, offsetY: number) => {
        const { otherPart, thisPart, } = getAlignmentProperties()

        if (!otherPart.mergedMesh || !thisPart.mergedMesh || !otherPart.marker || !thisPart.marker) {
            return
        }

        const result = align(
            { marker: otherPart.marker, mergedMesh: otherPart.mergedMesh, },
            { marker: thisPart.marker, mergedMesh: thisPart.mergedMesh, instanceIndex: thisPart.instanceIndex, },
            position,
            {
                offsetX: offsetX,
                offsetY: offsetY,
            }
        )

        // drawVector3Point(result.slidingPoint, "blue", 0.005, 1000)
        // drawVector3Point(result.surfacePoint, "red", 0.005, 1000)

        if (result?.slidingPointWithOffset && result?.surfacePoint) {
            if (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove.partsToMove.length > 0) {
                handleMultipleMove(handleSlideRef.current.multipleMove.partsToMove,
                    props.attachedMarker.current!)
            }
            attachOriginToPoint(props.attachedMarker.current!, result.surfacePoint, result.slidingPointWithOffset)

            // props.attachedMarker.current!.material.visible = true
            // props.attachedMarker.current!.material.depthTest = false
            // props.attachedMarker.current!.material.transparent = true
            // props.attachedMarker.current!.visible = true

            const newWorldPosition = MeshUtils.copyWorldPosition(props.attachedMarker.current!)
            handleSlideMovementAndUpdatePosition(newWorldPosition)

            onDragEnd({
                meshName: canSlide.sliderMarkerName!,
                position: newWorldPosition,
            })
        }
    }

    const handleAlignmentHover = (position: AlignmentPosition | null) => {
        const { otherPart, thisPart, } = getAlignmentProperties()

        if (!otherPart.mergedMesh || !thisPart.mergedMesh || !otherPart.marker || !thisPart.marker) {
            return
        }

        const result = getPoints(
            { marker: otherPart.marker, mergedMesh: otherPart.mergedMesh, },
            { marker: thisPart.marker, mergedMesh: thisPart.mergedMesh, instanceIndex: thisPart.instanceIndex, }
        )

        const point = result.source?.pointsWithLabels[position!]
        if (point) {
            triggerAnimation("alignmentHighlight", undefined, undefined, "blue", point)
        }
    }

    return (
        <>
            {
                canSlide.slideEnabled && props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER
                && <DragSlide
                    key={refreshKey}
                    mesh={[instancedMesh!,]}
                    onPositionChange={onPositionChange}
                    onDragEnd={onDragEnd}
                    slideSide={slide.getSlideSide(canSlide.markerName!)}
                    segmentLength={canSlide.sliderSectionLength!}
                    draggableObjectCenter={props.attachedMarker.current!}
                    startLength={canSlide.sliderStartLength!}
                    endLength={canSlide.sliderEndLength!}
                    boundingBox={props.boundingBox}
                    onUnsnap={onUnsnap}
                    slidingSides={slidingSides}
                    ref={dragSliderRef}
                />
            }
            <Html wrapperClass={"neutralHTML"}>
                {
                    ReactDOM.createPortal(
                        <AnimatePresence>
                            {
                                (props.connectorUI !== CONNECTOR_UI.NONE
                                    && props.connectorUI !== CONNECTOR_UI.CLOSE)
                                && <ActionsContainer
                                    transition={transition}
                                    variants={action_variants}
                                    initial={"hidden"}
                                    animate={"visible"}
                                    exit={"hidden"}
                                    $partUI={props.connectorUI}                                >
                                    <ActionsUI
                                        connectorUI={props.connectorUI}
                                        canSlide={canSlide.slideEnabled}
                                        onRemove={handleDelete}
                                        setConnectorUI={props.setConnectorUI}
                                        canRotate={!!multipleMove?.partsToMove
                                            && !connector.rotationDisabled}
                                        canSwap={props.showSwap && canSlide.canSwap}
                                        onSwap={handleSwap}
                                        onDuplicate={props.onDuplicate}
                                        partId={connector.id}
                                        setIdsAsHighlightedAndTurnOnControl
                                        ={props.setIdsAsHighlightedAndTurnOnControl}
                                        setIsSelected={props.setIsSelected}
                                        transformMode={props.transformMode}
                                    />
                                    {getSlider()}
                                </ActionsContainer>
                            }
                        </AnimatePresence>,
                        actions_child
                    ) as ReactPortal
                }
                {selectedNameRoot && ReactDOM.createPortal(
                    slideEnabled && <UnsnapButton onClick={onUnsnap} />,
                    selectedNameRoot
                )}
            </Html>
        </>
    )
}

export default ConnectorUI
