import React, { FC } from "react"
import styled from "styled-components"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { Typography } from "antd"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { BuyPartsButton } from "./BuyPartsButton"
import { usePartsList } from "../usePartsList"
import { rowTemplate, partsListStyles } from "./partsListHelpers"
import { NoPartsInScene } from "./NoPartsInScene"
import { EnvHelper } from "../../../../../../../../common/utils/EnvHelper"

interface PartsListProps {
  parts: PartTypeAPI[];
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
}
export const PartsToBuyList: FC<PartsListProps> = ({ parts, shopifyIds, }) => {

  const {
    getRows,
    partsInStock,
    partsNoStock,
  } = usePartsList({ parts, shopifyIds, allowCm: false, })

  const inStockRows = getRows(partsInStock).map(r => rowTemplate({ ...r, inStock: true, }, true))
  const noStockRows = getRows(partsNoStock).map(r => rowTemplate({ ...r, inStock: false, }, true))

  if (!EnvHelper.storeIsOpen) {
    return <ComingSoonText level={5}>Coming Soon</ComingSoonText>
  }

  if (partsInStock.length + noStockRows.length === 0) {
    return <NoPartsInScene />
  }

  return (
    <>
      <PartsListText>
        You can order the parts from us or go to your local hardware store! {" "}
        <QuestionsLink href="mailto:orders@craftyamigo.com" target="blank_">
          Questions?
        </QuestionsLink>
      </PartsListText>
      <partsListStyles.RowsContainer>
        {
          !!partsInStock.length
          && <PartsInStockWrapper>
            <partsListStyles.Labels>
              <partsListStyles.Label bold>In Stock</partsListStyles.Label>
              <partsListStyles.LabelRightAlined>Qty</partsListStyles.LabelRightAlined>
            </partsListStyles.Labels>
            <PartsListItemWrapper>{inStockRows}</PartsListItemWrapper>
            <BuyPartsWrapper>
              <BuyPartsButton parts={partsInStock} shopifyIds={shopifyIds} />
              <USAOnlyText type="secondary">USA only for now</USAOnlyText>
            </BuyPartsWrapper>
          </PartsInStockWrapper>
        }
        {
          !!partsNoStock.length
          && <PartsNoStockWrapper>
            <partsListStyles.Labels>
              <partsListStyles.Label bold>Out of Stock / We Don't Carry</partsListStyles.Label>
              <partsListStyles.LabelRightAlined>Qty</partsListStyles.LabelRightAlined>
            </partsListStyles.Labels>
            <PartsListItemWrapper>{noStockRows}</PartsListItemWrapper>
          </PartsNoStockWrapper>
        }
      </partsListStyles.RowsContainer>
    </>

  )
}

const PartsInStockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

const PartsListItemWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const BuyPartsWrapper = styled.div`
  padding: 16px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: solid 1px #E2E8F0;
  border-top: solid 1px #E2E8F0;
`

const PartsNoStockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

const USAOnlyText = styled(Typography.Text)`
  color: #B1B1B1;
  font-size: 12px;
  margin-left: 11px;
  margin-top: 8px;
`

const PartsListText = styled(Typography.Text)`
  font-size: 12px;
  padding: 8px 16px 0 19px;
`

const QuestionsLink = styled(Typography.Link)`
  color: black !important;
  text-decoration: underline !important;
  font-weight: bolder !important;
`

const ComingSoonText = styled(Typography.Title)`
  width: 100%;
  padding: 16px 16px 0 16px;
  text-align: center;
  `