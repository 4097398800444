import React, { FC, ReactNode, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg"
import dompurify from "dompurify"
import { BottomSheet } from "./BottomSheet/BottomSheet"
import isMobile from "ismobilejs"
import RadixTooltip from "./Tooltip/RadixTooltip"

interface CardContainerProps {
    withMoreCards?: Boolean;
    disabled?: Boolean;
    isGroup?: boolean;
}

export interface CardProps {
    imageSrc?: string;
    withMoreCards?: Boolean;
    text: string | ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    disabled?: boolean;
    isGroup?: boolean;
    furtherInfo?: string;
    tooltipText?: string;
    highlight?: boolean;
}

const Content = (props: { content: string, }) => {
    return useMemo(
        () => <TooltipContent
            dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(props.content, { ADD_ATTR: ["target",], }),
            }} />,
        [props.content,]
    )
}

const Card: FC<CardProps> = (props: CardProps) => {
    const [isButtonSheetShowed, setIsButtonSheetShowed,] = useState(false)

    const handleOnclick = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setIsButtonSheetShowed(!isButtonSheetShowed)
    }

    return (
        <Container
            onClick={(props.disabled && !props.isGroup) ? () => { } : props.onClick}
            disabled={props.disabled}
            isGroup={props.isGroup}
            style={props.highlight ? { backgroundColor: "rgba(255, 0, 0, 0.7)", } : {}}
        >
            <MainCard title={props.tooltipText}>
                <ImageContent withMoreCards={props.withMoreCards}>
                    <Image crossOrigin="anonymous" src={props.imageSrc} />
                    {props.withMoreCards && <React.Fragment>
                        <BackgroundCard style={{
                            background: "white",
                            top: "19px",
                            transform: "scale(0.85)",
                        }} />
                        <BackgroundCard style={{
                            background: "white",
                            top: "8px",
                            transform: "scale(0.95)",
                        }} />
                    </React.Fragment>}
                    <BackgroundCard />
                    {!props.isGroup && props.furtherInfo
                        && <React.Fragment>
                            {
                                !isMobile(window.navigator).phone
                                && <RadixTooltip>
                                    <Content content={props.furtherInfo} />
                                </RadixTooltip>
                            }
                            {
                                isMobile(window.navigator).phone
                                && <InfoIconContainer onClick={(e) => handleOnclick(e)}>
                                    <InfoIcon pointerEvents="none" />
                                </InfoIconContainer>
                            }
                            {
                                isMobile(window.navigator).phone && isButtonSheetShowed
                                && <BottomSheet
                                    children={<Content content={props.furtherInfo} />}
                                    isOpen={isButtonSheetShowed}
                                    onClose={(e) => handleOnclick(e)}
                                    title="Further Information"
                                />}
                        </React.Fragment>}
                </ImageContent>
                <TextContent>
                    {props.text}
                </TextContent>
            </MainCard>
        </Container>
    )
}

const Image = styled.img`
    object-fit: scale-down;
    width: calc(100% - 2px);
    left: 1px;
    height: 100%;
    border-radius: 15px;
    z-index: 1;
    position: relative;
`

const ImageContent = styled.div<CardContainerProps>`
    aspect-ratio: 1/1;
    margin: 5px;
    box-sizing: border-box;
    position: relative;
`

const BackgroundCard = styled.div`
    background: white;
    border: solid 1px #0000001f;
    box-shadow: 0 10px 15px -19px black;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Container = styled.div<CardContainerProps>`
    cursor: pointer;
    border-radius: 6px;
    background: white;
    transition: all 0.16s ease-in-out;

    &:hover {
        background: #f3f3f3;
        transform: scale(1.05);
        z-index: 3;
    }

    ${props => (props.disabled && !props.isGroup) && css`
        cursor: not-allowed;
    `}
`

const MainCard = styled.div`
`

const InfoIconContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 8px;
  z-index: 4;
`

const TooltipContent = styled.div`
a {
        color: #6D6D6D;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
            color: #6D6D6D;
        }
    }
`

export const TextContent = styled.div`
    padding: 8px;
`

export const Text = styled.div<{ bold?: boolean, }>`
    white-space: nowrap;
    max-width: 118px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`

export default Card