import React, { useState, useEffect } from "react"
import Loading from "../../../../../common/components/Loading"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border: none !important;
  box-shadow: none !important;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.3);
`

interface ImageAnimationProps {
    interval?: number;
    images?: (string | undefined)[];
    autoplay?: boolean;
    onClick?: () => void;
}

const ImageAnimation = ({
    interval = 100,
    images = [],
    autoplay = true,
    onClick,
}: ImageAnimationProps) => {
    const [currentImageIndex, setCurrentImageIndex,] = useState(0)
    const [isAnimating, setIsAnimating,] = useState(autoplay)
    const [loading, setLoading,] = useState(false)
    const dynamicInterval = images.length <= 5 ? 500 : 100

    useEffect(() => {
        let timer: number
        if (isAnimating) {
            timer = setInterval(() => {
                setCurrentImageIndex((prevIndex) => {
                    if (!images.length) {
                        return 0
                    }
                    return (prevIndex + 1) % images.length
                })
            }, dynamicInterval) as unknown as number
        }

        return () => clearInterval(timer)
    }, [images.length, isAnimating,])

    const handleMouseEnter = () => {
        if (!autoplay) {
            setIsAnimating(true)
        }
    }

    useEffect(() => {
        if (images.length > 1) {
            setLoading(false)
        }
    }, [images.length,])

    const handleMouseLeave = () => {
        if (!autoplay) {
            setIsAnimating(false)
        }
    }

    if (!images.length) {
        return null
    }

    return (
        <Container onClick={onClick}>
            <StyledImage
                src={images[currentImageIndex]}
                alt="Animated content"
                onMouseMove={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            {loading
                && <Overlay>
                    <Loading color="#fff" />
                </Overlay>
            }
        </Container>
    )
}

export default ImageAnimation
