import React, { FC } from "react"
import {
  ActionSlot,
  Main,
  Header as HeaderStyled,
  CloseButton
} from "../../../../../../../common/components/DrawerShared"

interface HeaderProps {
  onClose: () => void;
}

export const Header: FC<HeaderProps> = ({ onClose, }) => {
  return (
    <HeaderStyled noBorderBottom>
      <Main flexEnd={true}>
        <ActionSlot>
          <CloseButton onClick={onClose} >
            Close
          </CloseButton>
        </ActionSlot>
      </Main>
    </HeaderStyled>
  )
}