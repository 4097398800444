import { Button, Input, Form, } from "antd"
import React, { FC, ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import logoImg from "../../common/assets/logo_desk.svg"
import { EnvHelper } from "../../common/utils/EnvHelper"
import { getLogged, setLogged as setLoggedCache } from "../../common/utils/LocalPersistence"

interface PasswordGuardProps {
  children: ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 300px;

  form > * {
    width: 100%;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 120px;
  margin-top: -10rem;
`

const Logo = styled.img`
  width: 100%;
  max-height: 100px;
`

export const PasswordGuard: FC<PasswordGuardProps> = ({ children, }) => {
  const [form,] = Form.useForm<{ password: string, }>()
  const [logged, setLogged,] = useState(false)
  const [invalidPassword, setInvalidPassword,] = useState<boolean>(false)

  useEffect(() => {
    if (EnvHelper.password && getLogged() === EnvHelper.password) {
      setLogged(true)
    }
  }, [])

  const onFinish = ({ password, }: { password: string, }) => {
    if (password === EnvHelper.password) {
      setLoggedCache(password)
      setLogged(true)
    } else {
      setInvalidPassword(true)
      form.validateFields(["password",])
    }
  }

  if (logged || !EnvHelper.password) {
    return <>{children}</>
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo src={logoImg} />
      </LogoWrapper>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please input your password!", },
            () => ({
              validator: () => {
                if (invalidPassword) {
                  return Promise.reject(new Error("Invalid Password"))
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Password"
            autoFocus
            onChange={() => setInvalidPassword(false)} />
        </Form.Item>
        <Button type="primary" htmlType="submit">Enter</Button>
      </Form>
    </Wrapper>
  )
}