import {
    MaterialAPI,
    PartTypeAPI,
    SubClassAPI,
} from "../../../../../common/api/Types"
import { GenericPartState } from "../../../../utils/Types"

export function getPredominantMaterials(
    sceneParts: Record<string, GenericPartState>,
    allParts: PartTypeAPI[] | undefined,
    allMaterials: MaterialAPI[] | undefined,
    allSubclasses: SubClassAPI[] | undefined,) {
    const materialCounts: { [key: string]: number, } = {}
    const subclassCounts: { [key: string]: number, } = {}

    Object.values(sceneParts).forEach(part => {
        const apiTypeId = part.apiTypeId
        const matchingPart = allParts?.find(p => p.id === apiTypeId)

        if (matchingPart) {
            const materialId = matchingPart.materialId
            const subclassId = matchingPart.subclassId
            if (materialId) {
                materialCounts[materialId] = (materialCounts[materialId] || 0) + 1
            }
            if (subclassId) {
                subclassCounts[subclassId] = (subclassCounts[subclassId] || 0) + 1
            }
        }
    })

    const predominantMaterial = Object.keys(materialCounts).reduce((a, b) => {
        return (materialCounts[a] > materialCounts[b] ? a : b)
    }, "")
    const predominantSubclass = Object.keys(subclassCounts).reduce((a, b) =>  {
        return (subclassCounts[a] > subclassCounts[b] ? a : b)
    }, "")

    const predominantMaterialName = allMaterials?.find(m => {
        return m.id === predominantMaterial
    })?.materialName
    const predominantSubclassName = allSubclasses?.find(m => {
        return m.id === predominantSubclass
    })?.subclassName

    return {
        material: predominantMaterialName,
        submaterial: predominantSubclassName,
    }
}