/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"

interface Props {
    color?: string;
}

const Loading = (props: Props) => {
    const loaderIcon = () => {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8163 9.18101C17.0697 9.18101 17.2764 9.3866 17.2626 9.63962C17.1799 11.1476 16.6911 12.6084 15.8438 13.8655C14.911 15.2496 13.586 16.3237 12.0389 16.9501C10.4917 17.5765 8.79273 17.7267 7.15965 17.3814C5.52658 17.0362 4.03379 16.2112 2.87255 15.0121C1.71131 13.8131 0.934519 12.2947 0.6417 10.6514C0.348881 9.00811 0.553374 7.31482 1.22898 5.78849C1.90458 4.26215 3.02052 2.9723 4.43382 2.08419C5.71736 1.27762 7.19307 0.835785 8.70293 0.801478C8.95627 0.795722 9.15513 1.00893 9.14702 1.2622V1.2622C9.13891 1.51547 8.92685 1.71273 8.67356 1.7202C7.34652 1.75929 6.05085 2.15185 4.92206 2.86117C3.6635 3.65204 2.66973 4.80068 2.06809 6.15991C1.46646 7.51913 1.28435 9.02704 1.54511 10.4904C1.80587 11.9538 2.49762 13.306 3.53173 14.3737C4.56583 15.4415 5.89519 16.1762 7.34947 16.4836C8.80375 16.7911 10.3167 16.6574 11.6945 16.0995C13.0723 15.5417 14.2521 14.5852 15.0829 13.3526C15.828 12.2471 16.2618 10.9647 16.3434 9.63957C16.3589 9.38665 16.5629 9.18101 16.8163 9.18101V9.18101Z" fill={props.color ? props.color : "black"} />
            </svg>
        )
    }
    return (
        <Container>
            {loaderIcon()}
        </Container>
    )
}

const Container = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 0.5s linear 0s infinite normal;
    @keyframes rotate {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
`

export const LoadingContent = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export default Loading