/* eslint-disable no-console */
import React, { useEffect } from "react"
import { useThree } from "@react-three/fiber"

const DrawCalls = () => {
    const { gl, } = useThree()

    useEffect(() => {
        const p = document.getElementById("drawcalls")
        if (p) {
            setInterval(() => {
                p.innerText = `draw calls: ${gl.info.render.calls}`
            }, 2000)
        }
    }, [])

    return null
}

export default DrawCalls