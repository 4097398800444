/* eslint-disable max-statements */
import {
  Mesh,
  Raycaster,
  Scene,
  Euler,
} from "three"
import { ApiClient } from "../../../../../../../../../common/api/ApiClient"
import {
  ConnectionTypeAPI,
  PartTypeAPI,
  SizeAPI
} from "../../../../../../../../../common/api/Types"
import { ModalType } from "../../../../../../../../../common/providers/modalProvider/modalProvider"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { isInConnection } from "../../../../../../../../state/scene/util"
import { PosAndRotType } from "../../../../../../../../state/types"
import { EnvHelper } from "../../../../../../../../../common/utils/EnvHelper"
import {
  getMarkerRef,
  getMarkerUserData,
  isMarkerUserData,
  outerToInner
} from "../../../../../../../../utils/MarkerUtil"
import { ObjDictionary } from "../../../../../../../../../common/utils/utils"
import { roundLength } from "../../../../../../../../utils/utils"
import { isCollineal, metersToInch } from "../../../../../utils/utilsThree"
import { ModalState } from "../../../../../../../../../common/providers/modalProvider/modalProvider"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { PartTypeEnum } from "../../../../../../../../utils/Types"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"

export type IsExpandAplicableInfo = {
  isApplicable: true,
  distance: number,
  originConnection: {
    markerName: string,
    typeId: string,
    sizeId: string,
    partId: string,
    apiId: string,
  },
  oppositeConnection: {
    markerName: string,
    partId: string,
    typeId: string,
    sizeId: string,
    posAndRot: PosAndRotType,
  },
  someTube: boolean,
} | {
  isApplicable: false,
}

export const isExpandReduceApplicable = (
  marker: Mesh | null,
  ray: Raycaster,
  scene: Scene,
  allConnections: PartConnectionType[],
  connectionTypesDictionary: ObjDictionary<ConnectionTypeAPI>
): IsExpandAplicableInfo => {
  if (marker) {
    const markerWorldPosition = MeshUtils.copyWorldPosition(marker)
    const markerWorldDirection = MeshUtils.copyWorldDirection(marker)
    ray.set(markerWorldPosition, markerWorldDirection)

    const objects = ray.intersectObjects(scene.children)
    const markerUserData = getMarkerUserData(marker)

    const filteredObjects = objects.filter((o) => {
      return o.face && isMarkerUserData(o.object)
    }).filter((o) => {
      const objectUserData = getMarkerUserData(o.object)
      return (objectUserData.partId !== markerUserData.partId)
        && (objectUserData.type === "COLLISION_MARKER")
    })
      .filter((o) => {
        const markerDirection = MeshUtils.copyWorldDirection(marker)
        const markerPosition = MeshUtils.copyWorldPosition(marker)
        const objectDirection = MeshUtils.copyWorldDirection(o.object)
        const objectPosition = MeshUtils.copyWorldPosition(o.object)
        return isCollineal(
          markerDirection, markerPosition, objectDirection, objectPosition
        )
      })
      .filter((o) =>
        o.distance !== 0
      )
      .filter((o) => {
        const userData = getMarkerUserData(o.object)
        return !allConnections.some(c => isInConnection(c, userData.partId, userData.id))
      })

    if (filteredObjects.length > 0) {
      const filteredObject = filteredObjects[0]
      const markerPosition = MeshUtils.copyWorldPosition(filteredObject.object)
      const markerRotation = MeshUtils.copyWorldQuaternion(filteredObject.object)

      const markerRotationToEuler = new Euler()
      markerRotationToEuler.setFromQuaternion(markerRotation)
      const filteredObjectUserData = getMarkerUserData(filteredObject.object)

      const innerOppositeMarker = getMarkerRef(
        scene,
        filteredObjectUserData.partId,
        outerToInner(filteredObject.object.name))!
      const markerInnerPosition = MeshUtils.copyWorldPosition(innerOppositeMarker)
      const markerInnerRotation = MeshUtils.copyWorldQuaternion(innerOppositeMarker)

      const someTube = markerUserData.partType === PartTypeEnum.tube
        || filteredObjectUserData.partType === PartTypeEnum.tube

      return {
        isApplicable: true,
        distance: filteredObject.distance,
        originConnection: {
          markerName: markerUserData.markerName,
          typeId: markerUserData.id,
          sizeId: markerUserData.sizeId,
          partId: markerUserData.partId,
          apiId: markerUserData.partApiId,
        },
        oppositeConnection: {
          markerName: filteredObjectUserData.markerName,
          partId: filteredObjectUserData.partId,
          typeId: filteredObjectUserData.id,
          sizeId: filteredObjectUserData.sizeId,
          posAndRot: {
            outer: {
              pos: markerPosition,
              rot: markerRotation,
            },
            inner: {
              pos: markerInnerPosition,
              rot: markerInnerRotation,
            },
          },
        },
        someTube,
      }
    }
  }
  return { isApplicable: false, }
}

export const getPartsWithOppositeCompatibility = async (
  originConnectionTypeId: string,
  originConnectionSizeId: string,
  oppositeConnectionTypeId: string,
  oppositeConnectionSizeId: string,
  connectionTypesDictionary: ObjDictionary<ConnectionTypeAPI>,
  sizes: ObjDictionary<SizeAPI>,
  distance: number
): Promise<{
  simpleCompatibleParts: PartTypeAPI[],
  bothSidesCompatibleParts: PartTypeAPI[],
}> => {
  const maxDistanceExpandReduce = EnvHelper.maxDistanceExpandReduceInInches
  const parts = await ApiClient.getParts(
    sizes, {
    sizeId: originConnectionSizeId,
    compatibleConnections: connectionTypesDictionary[originConnectionTypeId].compatibleWithMulti,
  })

  const bothSidesCompatibleParts = parts.filter(part => {
    return part.connections.some(connection => {
      if (connectionTypesDictionary[originConnectionTypeId]
        .compatibleWithMulti.includes(connection.connectorId)
        && connection.size.id === originConnectionSizeId
      ) {
        const oppositeConnection = part.connections.find(con => con.placeholderId
          .includes(connection.oppositeOf!))
        if (oppositeConnection) {
          if (oppositeConnection?.size.id === oppositeConnectionSizeId
            && connectionTypesDictionary[oppositeConnection.connectorId].compatibleWithMulti.some(
              compatible => compatible === oppositeConnectionTypeId)) {
            const distanceToExpandReduce
              = roundLength(distance * metersToInch)
              - (connection.fullLeg - (connection.iELength + oppositeConnection.iELength))
            return Math.abs(distanceToExpandReduce) <= maxDistanceExpandReduce
          }
        }
      }
      return false
    })
  })

  return ({
    simpleCompatibleParts: parts.filter(part => !bothSidesCompatibleParts.includes(part)),
    bothSidesCompatibleParts,
  })

}


//Expand reduce to fit case new tube
export const getAvailableDistanceToFit = (
  selectedPartLength: number,
  distance: number,
): number => {
  if (distance - selectedPartLength > 0 || distance - selectedPartLength < 0) {
    return distance
  }
  return 0
}



export const showAlertAndExecuteExpandReduce = (
  props: {
    showModal: (
      props: ModalState
    ) => void,
    createOrEditTube: (distanceToExpandReduce?: number) => void,
    addPart: () => void,
    distanceToExpandReduce: number,
    reduceExpandCase: string,
    closeModal: () => void,
  }
) => {

  const { createOrEditTube,
    showModal,
    addPart,
    distanceToExpandReduce,
    reduceExpandCase,
    closeModal,
  } = props
  showModal(
    {
      title: `${reduceExpandCase} Tube to Fit`,
      subtitle: `Would you like to ${reduceExpandCase.toLowerCase()}
        the tube to fit to the other part's end?`,
      onConfirm: () => {
        //Ok
        breadcrumb({
          message: `User clicks ok on ${reduceExpandCase.toLowerCase()}`,
          level: "info",
        })
        excecuteExpandReduce(createOrEditTube, distanceToExpandReduce, closeModal)
      },
      onCancel: () => {
        addPart()
        closeModal()
      },
      type: ModalType.confirm,
    }
  )
}

export const excecuteExpandReduce = (
  createOrEditTube: (distanceToExpandReduce?: number) => void,
  distanceToExpandReduce: number,
  closeModal: () => void,
) => {
  createOrEditTube(distanceToExpandReduce)
  closeModal()
}
