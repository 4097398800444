import styled from "styled-components"

export const Title = styled.h1`
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 16px;
`