import React, { useState, } from "react"
import RotationSlider from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/RotationSlider"
import RotationAxesButtons from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/RotationAxesButtons"
import { ROTATION_AXES_ENUM } from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import { MathUtils, } from "three"


interface Props {
  onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void;
}

const MultiSelectionRotation: React.FC<Props> = ({ onRotationSliderChange, }) => {
  const [rotationAxis, setRotationAxis,] = useState<ROTATION_AXES_ENUM>(ROTATION_AXES_ENUM.X)
  const [rotation, setRotation,] = useState<number>(0)
  const [lastRotationValue, setLastRotationValue,] = useState(0)

  const steps = 15

  const onRotationChange = (value: number) => {
    setRotation(value)
  }

  const onRotationSave = (value: number) => {
    //console.log(rotationAxis, "rotationAxis in handleMouseUp")
    //console.log(rotation, "rotation in handleMouseUp")
    if (rotationAxis && rotation !== null) {
      const rotationDelta = rotation - lastRotationValue
      const rotationRadians = MathUtils.degToRad(rotationDelta)

      // Apply rotation change
      onRotationSliderChange(rotationAxis, rotationRadians)

      // Update last rotation value
      setLastRotationValue(rotation)
    }
    else {
      console.warn("handleMouseUp: rotationAxis or rotation is undefined")
    }

  }

  const handleMouseDown = () => {
  }

  const handleMouseUp = () => {
  }

  const onFinishEditing = () => {
  }

  const getRotation = () => {
    return rotation
  }

  const getRotationAxesButtons = () => {
    return <RotationAxesButtons
      onRotationAxisChange={onRotationAxisChange}
      useAxisColors={true}
    />
  }

  const setInitialRotation = (userRotation: number) => {
    //onRotationChange(userRotation)
    //setLastRotationValue(userRotation)
  }

  const setXAsRotationAxis = () => {
    setRotationAxis(ROTATION_AXES_ENUM.X)
  }

  const setYAsRotationAxis = () => {
    setRotationAxis(ROTATION_AXES_ENUM.Y)
  }

  const setZAsRotationAxis = () => {
    setRotationAxis(ROTATION_AXES_ENUM.Z)
  }

  const onRotationAxisChange = (axis: ROTATION_AXES_ENUM) => {
    switch (axis) {
      case ROTATION_AXES_ENUM.X:
        setXAsRotationAxis()
        break
      case ROTATION_AXES_ENUM.Y:
        setYAsRotationAxis()
        break
      case ROTATION_AXES_ENUM.Z:
        setZAsRotationAxis()
        break
      default:
        throw new Error("Rotation axis mus't be X, Y or Z")
    }
  }


  return (
    <RotationSlider
      onRotationChange={onRotationChange}
      disabled={false}
      auxButtons={getRotationAxesButtons()}
      onRotationSave={onRotationSave}
      getRotation={getRotation}
      steps={steps}
      onFinishEditing={onFinishEditing}
      handleMouseDown={handleMouseDown}
      handleMouseUp={handleMouseUp}
    />
  )
}

export default MultiSelectionRotation