import React, { FC } from "react"
import Card from "../../../../../../../common/components/Card"
import { CategoryTypeAPI, PartTypeAPI } from "../../../../../../../common/api/Types"
import { Text } from "../../../../../../../common/components/Card"
import { breadcrumb } from "../../../../../../../common/utils/sentrySetup"

export interface CategoryCardProps extends CategoryTypeAPI {
    onClick: () => void;
    parts: PartTypeAPI[];
    disabled?: boolean;
}

const CategoryCard: FC<CategoryCardProps> = (props: CategoryCardProps) => {
    const text = () => {
        return (
            <Text >
                {`(${props.parts.length}) ${props.name}`}
            </Text>
        )
    }

    const handleClick = () => {
        props.onClick()
        breadcrumb({
            message: "Click on Category",
            level: "info",
            data: {
                categoryId: props.id,
                categoryName: props.name,
            },
        })
    }
    return (
        <Card
            text={text()}
            withMoreCards
            onClick={handleClick}
            imageSrc={props.image}
            disabled={props.disabled}
            isGroup={true}
            tooltipText={`(${props.parts.length}) ${props.name}s`}
        />
    )
}
export default CategoryCard
