import React, { useState, useMemo } from "react"
import { Object3D, Vector3, Event as ThreeEvent, MeshBasicMaterial, Quaternion } from "three"
import { Html } from "@react-three/drei"
import { SegmentedTubeMarkers } from "../../segmentedTube/types/types"
import Marker from "./Marker"

const MarkerHelpers = ({
    slideSide,
    onDotClick,
    attachmentPoint,
    viewInnerMarkers,
    viewOuterMarkers,
}: {
    slideSide: SegmentedTubeMarkers | undefined,
    onDotClick?: (key: string) => void,
    attachmentPoint: Object3D | undefined,
    viewInnerMarkers: boolean,
    viewOuterMarkers: boolean,
}) => {
    const [hoveredDot, setHoveredDot,]
        = useState<{ key: string, type: "outer" | "inner", position: Vector3, } | null>(null)

    const originalMaterial = useMemo(() => {
        if (!slideSide) { return null }
        const firstKey = Object.keys(slideSide)[0]
        const material = slideSide[firstKey]?.outer?.material
        return Array.isArray(material) ? material[0] : material as MeshBasicMaterial
    }, [slideSide,])

    const wireframeMaterialRed = useMemo(() => {
        if (!originalMaterial) { return null }
        const material = (originalMaterial as MeshBasicMaterial).clone()
        material.wireframe = true
        material.visible = true
        material.depthTest = false
        material.depthWrite = false
        return material
    }, [originalMaterial,])

    const wireframeMaterialGreen = useMemo(() => {
        if (!originalMaterial) { return null }
        const material = (wireframeMaterialRed as MeshBasicMaterial).clone()
        material.color.set("green")
        return material
    }, [originalMaterial,])

    if (!slideSide || typeof slideSide !== "object" || Object.keys(slideSide).length === 0) {
        return null
    }

    const handleDotClick = (key: string) => {
        onDotClick?.(key)
    }

    const handleDotHover = (key: string, type: "outer" | "inner") => {
        setHoveredDot({
            key,
            type,
            position: slideSide[key]![type]!.getWorldPosition(new Vector3()),
        })
        if (slideSide?.[key]?.[type]) {
            if (type === "inner" && wireframeMaterialGreen) {
                slideSide[key]![type]!.material = wireframeMaterialGreen
                slideSide[key]![type]!.visible = true
            } else if (wireframeMaterialRed) {
                slideSide[key]![type]!.material = wireframeMaterialRed
                slideSide[key]![type]!.visible = true
            }
        }
    }

    const handleDotLeave = (key: string, type: "outer" | "inner") => {
        if (slideSide?.[key]?.[type] && originalMaterial) {
            slideSide[key]![type]!.material = originalMaterial
        }
        setHoveredDot(null)
    }

    const getDotColor = (
        type: "outer" | "inner",
        isHovered: boolean,
        isOuterAttachmentPoint: boolean,
        isInnerAttachmentPoint: boolean
    ) => {
        if (isHovered && hoveredDot?.type === type) {
            return "yellow"
        }
        if (type === "outer" && isOuterAttachmentPoint) {
            return "red"
        }
        if (type === "inner" && isInnerAttachmentPoint) {
            return "red"
        }
        return type === "outer" ? "blue" : "green"
    }

    return (
        <group>
            {Object.keys(slideSide).map((key, index) => {
                const outerMarker = slideSide[key]?.outer
                const innerMarker = slideSide[key]?.inner
                const outerMarkerName = outerMarker?.name
                const innerMarkerName = innerMarker?.name

                const isOuterAttachmentPoint = attachmentPoint?.name === outerMarkerName
                const isInnerAttachmentPoint
                    = attachmentPoint?.name === innerMarkerName

                const isHovered
                    = (hoveredDot?.key === key && hoveredDot?.type === "outer")
                    || (hoveredDot?.key === key && hoveredDot?.type === "inner")

                const dotSize: [number, number, number] = [0.0025, 0.0025, 0.001,]

                return (
                    <React.Fragment key={index}>
                        {viewOuterMarkers && outerMarker && (
                            <Marker
                                position={outerMarker.getWorldPosition(new Vector3())}
                                quaternion={outerMarker.getWorldQuaternion(new Quaternion())}
                                color={getDotColor(
                                    "outer",
                                    isHovered,
                                    isOuterAttachmentPoint,
                                    isInnerAttachmentPoint
                                )}
                                dotSize={dotSize}
                                onClick={() => handleDotClick(slideSide[key]!.outer!.name)}
                                onPointerOver={() => handleDotHover(key, "outer")}
                                onPointerOut={() => handleDotLeave(key, "outer")}
                            />
                        )}

                        {viewInnerMarkers && innerMarker && (
                            <Marker
                                position={innerMarker.getWorldPosition(new Vector3())}
                                quaternion={innerMarker.getWorldQuaternion(new Quaternion())}
                                color={getDotColor(
                                    "outer",
                                    isHovered,
                                    isOuterAttachmentPoint,
                                    isInnerAttachmentPoint
                                )}
                                dotSize={dotSize}
                                onClick={() => handleDotClick(slideSide[key]!.inner!.name)}
                                onPointerOver={() => handleDotHover(key, "inner")}
                                onPointerOut={() => handleDotLeave(key, "inner")}
                            />
                        )}

                        {hoveredDot && (
                            <Html
                                as="div"
                                scale={0.001}
                                position={hoveredDot.position.clone().add(
                                    new Vector3(0.05, 0.05, 0)
                                )}
                            >
                                <h1>
                                    {hoveredDot.type} {hoveredDot.key}
                                </h1>
                                <h1>{slideSide[hoveredDot.key]?.[hoveredDot.type]?.name}</h1>
                            </Html>
                        )}
                    </React.Fragment>
                )
            })}
        </group>
    )
}

export default MarkerHelpers
