import React, { useEffect, useState } from "react"
import { Camera } from "three"
import AlignmentPointsHUD from "./AlignmentPointsHUD"
import OffsetControls from "./OffsetControls"
import { SegmentedTubeMarkers } from "../types/types"
import {
    FlexContainer,
    Section,
    ContentContainer,
    Label,
    HUDContainer,
} from "./SegmentedSliderStyles"
import { convertInToCm } from "../../../../../utils/UnitUtils"

export type AlignmentPosition =
    | "top-left" | "top" | "top-right"
    | "left" | "center" | "right"
    | "bottom-left" | "bottom" | "bottom-right"

interface Props {
    onAlignmentSelect: (
        position: AlignmentPosition,
        offsetX: number,
        offsetY: number,
        unit: "cm" | "in",
    ) => void;
    onAlignmentHover: (position: AlignmentPosition | null) => void;
    hoveredPoint?: AlignmentPosition | null;
    rotationPoint?: SegmentedTubeMarkers | null;
    camera: Camera | undefined;
    unit: "cm" | "in";
    debugAlignment: boolean;
    canUseAlignment?: boolean;
    positionChanged: boolean;
}

const AlignmentOptionsUI: React.FC<Props> = ({
    onAlignmentSelect,
    onAlignmentHover,
    hoveredPoint,
    rotationPoint,
    camera,
    unit = "in",
    debugAlignment,
    canUseAlignment = true,
    positionChanged,
}) => {
    const [offsetX, setOffsetX,] = useState(0)
    const [offsetY, setOffsetY,] = useState(0)
    const [activeAlignment, setActiveAlignment,] = useState<AlignmentPosition | null>(null)

    const applyOffset = (position: AlignmentPosition, newX?: number, newY?: number) => {
        const x = newX ?? offsetX
        const y = newY ?? offsetY

        let calculatedX = x / 100
        let calculatedY = y / 100

        if (unit === "in") {
            calculatedX = convertInToCm(calculatedX)
            calculatedY = convertInToCm(calculatedY)
        }
        onAlignmentSelect(position, calculatedX, calculatedY, unit)
    }

    useEffect(() => {
        if (positionChanged) {
            setOffsetX(0)
            setOffsetY(0)
            setActiveAlignment(null)
        }
    }, [positionChanged,])

    const handleOffsetChange = (
        value: number,
        setter: (value: number) => void,
        position: AlignmentPosition | null,
        shouldTriggerAlignment: boolean
    ) => {
        setter(value)

        // If no alignment is selected, default to center
        const alignmentPosition = position || "center"
        if (!position) {
            setActiveAlignment("center")
        }

        if (shouldTriggerAlignment) {
            applyOffset(alignmentPosition,
                setter === setOffsetX ? value : offsetX,
                setter === setOffsetY ? value : offsetY
            )
        }
    }

    const handleAlignmentSelect = (position: AlignmentPosition) => {
        setOffsetX(0)
        setOffsetY(0)
        setActiveAlignment(position)
        applyOffset(position, 0, 0)
    }

    const handleOffsetKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && activeAlignment) {
            applyOffset(activeAlignment)
        }
    }

    if (!canUseAlignment) {
        return null
    }

    return (
        <FlexContainer>
            <Section>
                <ContentContainer>
                    <HUDContainer>
                        <AlignmentPointsHUD
                            onAlignmentSelect={handleAlignmentSelect}
                            onAlignmentHover={onAlignmentHover}
                            rotationPoint={rotationPoint}
                            originalCamera={camera}
                            activeAlignment={activeAlignment}
                            debugAlignment={debugAlignment}
                        />
                    </HUDContainer>
                </ContentContainer>
                <Label>Align</Label>
            </Section>

            <Section>
                <ContentContainer>
                    <OffsetControls
                        offsetX={offsetX}
                        offsetY={offsetY}
                        onOffsetXChange={(value, shouldTriggerAlignment) =>
                            handleOffsetChange(
                                value,
                                setOffsetX,
                                activeAlignment,
                                shouldTriggerAlignment
                            )
                        }
                        onOffsetYChange={(value, shouldTriggerAlignment) =>
                            handleOffsetChange(
                                value,
                                setOffsetY,
                                activeAlignment,
                                shouldTriggerAlignment
                            )
                        }
                        unit={unit}
                        onKeyDown={handleOffsetKeyDown}
                    />
                </ContentContainer>
                <Label>Offset</Label>
            </Section>
        </FlexContainer>
    )
}

export default AlignmentOptionsUI