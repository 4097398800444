/* eslint-disable no-process-env */
const isProduction = process.env.NODE_ENV === "production"
const isDevelopment = process.env.NODE_ENV === "development" || !process.env.NODE_ENV

const password = process.env.REACT_APP_PASSWORD

const envType
    = (
        (isProduction && (process.env.REACT_APP_ENV_TYPE || "prod"))
        || (isDevelopment && "dev-local")
    ) as string

const buildName = `${envType}-${process.env.REACT_APP_VERSION || "Neutral"}`

const sentryIntegrationsSampleRate = (
    (process.env.REACT_APP_SENTRY_INTEGRATIONS_SAMPLERATE
        && parseInt(process.env.REACT_APP_SENTRY_INTEGRATIONS_SAMPLERATE, 10)
    ) || 0.1
)

const pathBaseName = (process.env.REACT_APP_PATH_BASE_NAME as string) || undefined

const {
    REACT_APP_FIREBASE_CONFIG,
    REACT_APP_ALGOLIA_CONFIG,
    REACT_APP_RUDDERANALYTICS_CONFIG,
    REACT_APP_SIZE_COLON,
} = process.env

if (!REACT_APP_ALGOLIA_CONFIG) {
    throw new Error("No algolia config provided")
}
if (!REACT_APP_FIREBASE_CONFIG) {
    throw new Error("No firebase config provided")
}
const algoliaConfig = JSON.parse(REACT_APP_ALGOLIA_CONFIG)
const firebaseConfig = JSON.parse(REACT_APP_FIREBASE_CONFIG)

let rudderAnalyticsConfig

if (REACT_APP_RUDDERANALYTICS_CONFIG) {
    rudderAnalyticsConfig = JSON.parse(REACT_APP_RUDDERANALYTICS_CONFIG)
}

const maxSegmentedTubeSlideSteps = process.env.REACT_APP_MM_SEGMENTED_TUBE_SLIDE_STEP

const sizeColon = JSON.parse(REACT_APP_SIZE_COLON || "[]") as string[]

export const EnvHelper = {
    isProduction,
    isDevelopment,
    password,
    envType: process.env.REACT_APP_ENV_TYPE,
    buildName,
    sentryIntegrationsSampleRate,
    pathBaseName,
    rotationStep: parseInt(process.env.REACT_APP_ROTATION_STEP!, 10),
    maxSegmentedTubeSlideSteps: parseInt(maxSegmentedTubeSlideSteps!, 10) || 10,
    firebaseConfig,
    algoliaConfig,
    rudderAnalyticsConfig,
    rotationGuidelineDistance: parseFloat(process.env.REACT_APP_ROTATION_GUIDELINE_DISTANCE!),
    maxDistanceExpandReduceInInches: parseInt(
        process.env.REACT_APP_EXPAND_REDUCE_DISTANCE_IN_INCHES!, 10
    ),
    appUrl: process.env.REACT_APP_APP_URL,
    shopUrl: process.env.REACT_APP_SHOP_URL,
    collisionSteps: parseInt(process.env.REACT_APP_COLLISION_STEPS || "5", 10),
    mmLengthSteps: parseInt(process.env.REACT_APP_MM_LENGTH_STEPS || "3", 10),
    mmSTLengthSteps: parseInt(process.env.REACT_APP_MM_ST_LENGTH_STEPS || "2", 10),
    mmRotationSteps: parseInt(process.env.REACT_APP_MM_ROTATION_STEPS || "1", 10),
    sizeColon,
    useRudderAnalytics: process.env.REACT_APP_USE_RUDDER_ANALYTICS,
    googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
    mainEnvironment: process.env.REACT_APP_ENVIRONMENT_NAME,
    appCheckVariable: process.env.REACT_APP_APPCHECK_TOKEN,
    isBrickdesigner: process.env.REACT_APP_ENVIRONMENT_NAME === "brickdesigner",
    storeIsOpen: process.env.REACT_APP_STORE_IS_OPEN === "true",
}