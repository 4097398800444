import React from "react"
import * as Tooltip from "@radix-ui/react-tooltip"
import styled from "styled-components"
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg"

interface Props {
    children: JSX.Element;
    overridePosition?: boolean;
}

const RadixTooltip = (props: Props) => {

    const handleOnClick = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation()
    }

    return (
        <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <Trigger overridePosition={props.overridePosition}>
                        <InfoIcon pointerEvents="none" />
                    </Trigger>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <TooltipContent onClick={handleOnClick} side="left" sideOffset={5}>
                        {props.children}
                        <TooltipArrow className="TooltipArrow" />
                    </TooltipContent>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    )
}

const TooltipContent = styled(Tooltip.Content)`
    border-radius: 4px;
    padding: 16px;
    font-size: 15px;
    line-height: 1.5;
    color: var(--violet11);
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, 
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 5;
    max-width: 300px;
`

const TooltipArrow = styled(Tooltip.Arrow)`
    fill: white;
`

const Trigger = styled.div<Props>`
    position: ${(props) => (props.overridePosition ? "inherit" : "absolute")};
    bottom: 0;
    z-index: 2;
    right: 8px;
    cursor: pointer;
`

export default RadixTooltip