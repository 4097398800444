/* eslint-disable no-console */
import { selector, selectorFamily } from "recoil"
import {
    assertPartType,
    ConnectorPart,
    GenericPartState,
    PartTypeEnum,
    TubePart
} from "../../utils/Types"
import { partsSelector, sceneAtom,  } from "./atoms"
import equals from "fast-deep-equal"
import { isInConnection } from "./util"
import { ConnectionOfPart, PartConnectionType } from "./types"
import { selectedItemID, connectionIndexState } from "../atoms"
import { historyAtom } from "../../components/main/DesignScreen/History"


export const connectionIndexSelector = selectorFamily<number, string>({
    key: "connectionIndexSelector",
    get: (id) => ({ get, }) => {
        const state = connectionIndexState(id)
        return get(state)
    },
    set: (id) => ({ set, }, newValue) => {
        const state = connectionIndexState(id)
        set(state, newValue,)
    },
})


export const checkExistenceSelector = selectorFamily<boolean, { id: string, }>({
    key: "checkExistenceSelector",
    get: ({ id, }) => ({ get, }) => {
        const part = get(partsSelector({ id, }))
        return !!part
    },
})
export const undoRedoInProgressSelector = selector({
    key: "undoRedoInProgressSelector",
    get: ({ get, }) => {
        const history = get(historyAtom)
        return history.undoRedoInProgress
    },
})

export const undoRedoChangeSelector = selector({
    key: "undoRedoChangeSelector",
    get: ({ get, }) => {
        const history = get(historyAtom)
        return history.changeInProgress
    },
})

export const existentPartSelector = selectorFamily<GenericPartState, { id: string, }>({
    key: "existentPartSelector",
    get: ({ id, }) => ({ get, }) => {
        const existingPart = get(partsSelector({ id, }))
        if (!existingPart) {
            console.error("Part not existent", { id, })
            throw new Error("Part not existent")
        }
        return existingPart
    },
})

export const isItATube = selectorFamily<TubePart | undefined, { id: string, }>({
    key: "isItATubeSelector",
    get: ({ id, }) => ({ get, }) => {
        const existingPart = get(partsSelector({ id, }))
        if (!existingPart) {
            return undefined
        }
        return existingPart.type === PartTypeEnum.tube ? existingPart : undefined
    },
})

export const connectorSelector = selectorFamily<ConnectorPart, { id: string, }>({
    key: "selector_connector",
    get: ({ id, }) => ({ get, }) => {
        const part = get(existentPartSelector({ id, }))
        assertPartType(part, PartTypeEnum.connector)
        return part
    },
})

export const tubeSelector = selectorFamily<TubePart, { id: string, }>({
    key: "selector_tube",
    get: ({ id, }) => ({ get, }) => {
        const part = get(existentPartSelector({ id, }))
        assertPartType(part, PartTypeEnum.tube)
        return part
    },
})

export const allConnections = selector<PartConnectionType[]>({
    key: "allConnections",
    get: ({ get, }) => {
        return get(sceneAtom).connections
    },
})

export const partConnections = (() => {
    const cache: Record<string, ConnectionOfPart[]> = {}

    return selectorFamily<ConnectionOfPart[], string>({
        key: "partConnections",
        get: (id) => ({ get, }) => {
            const connections = get(sceneAtom).connections
            const partConnections: PartConnectionType[]
                = connections.filter(c => isInConnection(c, id))
            const ret = partConnections.map(({ partA, partB, }) => {
                if (partA.partId === id) {
                    return {
                        partMarkerName: partA.markerName,
                        destinationPartId: partB.partId,
                        destinationMarkerName: partB.markerName,
                        slidePosition: partB.slidePosition,

                    }
                }
                return {
                    partMarkerName: partB.markerName,
                    destinationPartId: partA.partId,
                    destinationMarkerName: partA.markerName,
                    slidePosition: partB.slidePosition,
                }
            })

            if (cache[id] && equals(cache[id], ret)) {
                return cache[id]
            } else {
                cache[id] = ret
                return ret
            }

        },
    })
})()

export const selectedPartSelector = selector<GenericPartState | null>({
    key: "selectedPartSelector",
    get: ({ get, }) => {
        const selectedPartId = get(selectedItemID)
        if (!selectedPartId) {
            return null
        }

        return get(partsSelector({ id: selectedPartId, })) || null
    },
})

export const designNameSelector = selector<string | undefined>({
    key: "designNameSelector",
    get: ({ get, }) => {
        return get(sceneAtom).name
    },
})