/* eslint-disable max-len */
import React, { FC } from "react"
import { useRecoilValue } from "recoil"
import { CategoryTypeAPI } from "../../../../../../common/api/Types"
import MessageBox from "../../MessageBox"
import { BoldText } from "../../../../../../common/components/DrawerShared"
import { connectionTypesSelector, sizesSelector } from "../../../../../state/initialDataSelectors"
import { EnvHelper } from "../../../../../../common/utils/EnvHelper"

export enum DescriptionCase {
  SWAP = "SWAP",
  INCOMPATIBLE = "INCOMPATIBLE",
  INITIAL = "INITIAL",
}

export interface Props {
  category: CategoryTypeAPI | undefined;
  case: DescriptionCase;
  sizeId: string;
  connectionId: string;

  compatibleConnectionsId: string[];
}

const getProperAPreposition = (str: string) => (/[aeiou]/i.test(str[0]) ? "an" : "a")

const Description: FC<Props> = (props: Props) => {
  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const sizeTypes = useRecoilValue(sizesSelector)

  const sizeName = sizeTypes[props.sizeId].hideName ? "" : sizeTypes[props.sizeId].friendlyName
  const connectionName = connectionTypes[props.connectionId].connectionName
  const compatibleConnectionsNames = props.compatibleConnectionsId?.map((id) => connectionTypes[id].connectionName)


  switch (props.case) {
    case DescriptionCase.SWAP:
      return <MessageBox message={`${props.category
        ? props.category.name
        : "All parts"} that can be swaped for`}
      />
    case DescriptionCase.INCOMPATIBLE:
      return <IncompatibleDescription
        {...props}
        sizeName={sizeName}
        hideName={sizeTypes[props.sizeId].hideName}
        connectionName={connectionName}
        compatibleConnectionsNames={compatibleConnectionsNames}
      />
    default: {
      return <MessageBox message={
        <> {`${props.category
          ? props.category.name
          : "Parts"} `}
          compatible with
          {` ${getProperAPreposition(connectionName)} `}
          <BoldText> {`${sizeName}${EnvHelper.sizeColon.includes(sizeName.toLocaleLowerCase()) ? ":" : ""} ${connectionName}`} </BoldText>
          connection.
        </>}
      />
    }
  }
}

const IncompatibleDescription = (props: Props & {
  sizeName: string,
  connectionName: string,
  hideName: boolean | undefined,
  compatibleConnectionsNames: string[],
}) => {

  const isLast = (index: number) => index === props.compatibleConnectionsNames.length - 1
  const isFirst = (index: number) => index === 0

  return <MessageBox message={
    <>
      {`${props.category ? props.category.name : "All parts"} `}
      listed here are
      <BoldText> incompatible </BoldText> with a
      <BoldText> {props.hideName ? `${props.sizeName} ` : ""} {props.connectionName} </BoldText>
      connection because they don’t have
      {props.compatibleConnectionsNames?.map((name, i) => (
        <span key={i}>
          {!isFirst(i) && !isLast(i) && ","}
          {!isFirst(i) && isLast(i) && " or"}
          {` ${getProperAPreposition(name)} `}
          <BoldText>{props.hideName ? `${props.sizeName} ` : ""} {name}</BoldText>
        </span>
      ))} connection.
      <br />
      <br />
      If you’d like to choose one of the parts listed below, you will first need to use an adaptor,
      reducer or expander under the
      <BoldText> Compatible Parts </BoldText>
      tab to transition to a compatible connection.
    </>}
  />
}


export default Description