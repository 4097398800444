import React, { useState, useEffect, ReactNode } from "react"
import { useControls, Leva, button } from "leva"
import { atom, useRecoilState, useSetRecoilState, useRecoilValue, useRecoilCallback } from "recoil"
import { sceneAtom } from "../../state/scene/atoms"

// Define atoms
const adminModeState = atom({
    key: "adminModeState", // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
})

const controlsState = atom({
    key: "controlsState",
    default: {}, // default empty object, adjust as needed
})

type MarkerControlsConfig = {
    viewHelpers: boolean,
    viewInnerMarkers: boolean,
    viewOuterMarkers: boolean,
    viewAttachmentPoint: boolean,
    viewOriginPoint: boolean,
    hideMergedMeshes: boolean,
    viewLocalAxis: boolean,
};

type CameraControlsConfig = {
    useCameraUp: boolean,
    useFit: boolean,
    yOffset: number,
    xOffset: number,
    zOffset: number,
    removeCameraLock: boolean,
    polarRotateSpeed: number,
    azimuthRotateSpeed: number,
    cameraPadding: number,
}

type MoveControlsConfig = {
    viewDragMesh: boolean,
    viewSurfaceMesh: boolean,
    viewSlidePoints: boolean,
    viewBoundingBoxPoints: boolean,
    viewSurfacePoints: boolean,
    viewIntersectionPoints: boolean,
    stepDensity: {
        value: number,
        min: number,
        max: number,
        step: number,
    } | number,
    debugAlignment: boolean,
};

type AppControlsConfig = {
    printJson: any,
    viewTubeNames: boolean,
    showStats: boolean,
    showGlobalAxes: boolean,
};

export const DebugState = () => {
    const [adminMode, setAdminMode,] = useRecoilState(adminModeState)
    const sceneData = useRecoilValue(sceneAtom)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        setAdminMode(urlParams.get("admin") === "true")
    }, [setAdminMode,])

    const printJsonCallback = useRecoilCallback(({ snapshot, }) => async () => {
        const latestSceneData = await snapshot.getPromise(sceneAtom)
        const sceneDataJSON = JSON.stringify(latestSceneData, null, 2)
        const blob = new Blob([sceneDataJSON,], { type: "application/json", })
        const url = URL.createObjectURL(blob)
        window.open(url, "_blank")
    }, [])

    const markerControlsConfig: MarkerControlsConfig = {
        viewHelpers: false,
        viewInnerMarkers: false,
        viewOuterMarkers: false,
        viewAttachmentPoint: false,
        viewOriginPoint: false,
        hideMergedMeshes: false,
        viewLocalAxis: false,
    }

    const cameraControlsConfig: CameraControlsConfig = {
        useCameraUp: false,
        useFit: false,
        yOffset: 1.5,
        xOffset: 1.5,
        zOffset: 1.5,
        removeCameraLock: true,
        polarRotateSpeed: 5,
        azimuthRotateSpeed: 5,
        cameraPadding: 2,
    }


    const moveControlsConfig: MoveControlsConfig = {
        viewDragMesh: false,
        viewSurfaceMesh: false,
        viewSlidePoints: false,
        viewBoundingBoxPoints: false,
        viewSurfacePoints: false,
        viewIntersectionPoints: false,
        stepDensity: {
            value: 8,
            min: 1,
            max: 10,
            step: 1,
        },
        debugAlignment: false,
    }

    const AppControlsConfig: AppControlsConfig = {
        printJson: button(printJsonCallback),
        viewTubeNames: false,
        showStats: false,
        showGlobalAxes: false,
    }

    const markerControls = useControls("Marker Options", markerControlsConfig, {
        collapsed: true,
    })
    const cameraControls = useControls("Camera Options", cameraControlsConfig, {
        collapsed: true,
    })
    const moveControls = useControls("Move Options", moveControlsConfig, {
        collapsed: true,
    })
    const appControls = useControls("App Options", AppControlsConfig, {
        collapsed: true,
    })

    const setControls = useSetRecoilState(controlsState)
    useEffect(() => {
        setControls({
            ...markerControls,
            ...cameraControls,
            ...moveControls,
            ...appControls,
        })
    }, [markerControls, cameraControls, moveControls, appControls, setControls,])

    return (
        <div style={{ position: "absolute", left: 10, top: 50, width: "300px", zIndex: 100, }}>
            <Leva fill hidden={!adminMode} collapsed={true} />
        </div>
    )
}

export const useLevaControls = () => {
    const controls = useRecoilValue(controlsState)
    return controls as MarkerControlsConfig
        & CameraControlsConfig
        & MoveControlsConfig
        & AppControlsConfig
}