import { useRecoilValue } from "recoil"
import { unitSelector } from "../../../../state/scene/atoms"
import { useUpdateUnit } from "../../../../state/scene/setters"

const INCH_TO_CM = 2.54

export const convertCmToIn = (value: number): number => {
    const cm = (value / INCH_TO_CM)
    const converted = cm

    return converted
}

export const convertInToCm = (value: number): number => {
    const inches = (value * INCH_TO_CM)
    const converted = inches

    return converted
}

const useUnitConversion = () => {
    const unit = useRecoilValue(unitSelector)
    const updateUnit = useUpdateUnit()
    const convertToUnit = (value: number): number => {
        return unit === "cm" ? convertCmToIn(value) : value
    }

    const convertFromUnit = (value: number): number => {
        return unit === "cm" ? convertInToCm(value) : value
    }

    return { convertToUnit, convertFromUnit, unit, updateUnit, }
}

export default useUnitConversion