import "./App.css"
import "./common/api/firebase/firebaseSetup"
import React, { useEffect, } from "react"
import "./common/api/firebase/firebaseSetup"
import { enablePatches } from "immer"
import Main from "./builder/exportables"
import { message } from "antd"
import {
  SyncAuthStateWithRecoil
} from "./common/state/LoginState/SyncAuthStateWithRecoil"
import { ErrorBoundary } from "./ErrorBoundary"
import { useRecoilValue } from "recoil"
import { designNameSelector } from "./builder/exportables"
import { PasswordGuard } from "./manager/components/PasswordGuard"
import { initRudderAnalytics, initAnalytics } from "./common/utils/rudderAnalyticsUtils"
import { Manager } from "./manager/components/Manager"
import { DebugState } from "./builder/providers/debugProvider/useLevaControls"
import { App as AntdApp, ConfigProvider } from "antd"
import { StyleSheetManager } from "styled-components"


enablePatches()

initAnalytics()

function App() {
  const designName = useRecoilValue(designNameSelector)
  useEffect(() => {
    const vh = window.innerHeight
    document.body.style.setProperty("--vh", `${vh}px`)
    message.config({ maxCount: 1, })
  }, [])

  useEffect(() => {
    if (designName) {
      document.title = `CraftyAmigo - ${designName}`
    } else {
      document.title = "CraftyAmigo"
    }
  }, [designName,])

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roboto, sans-serif",
        },
      }}
    >
      <StyleSheetManager>
        <AntdApp>
          <ErrorBoundary>
            <DebugState />
            <PasswordGuard>
              <SyncAuthStateWithRecoil />
              <Main />
              <Manager />
            </PasswordGuard>
          </ErrorBoundary>
        </AntdApp>
      </StyleSheetManager>
    </ConfigProvider>
  )
}

export default App