import { useState, useEffect, useCallback } from "react"

interface PinchZoomState {
    scale: number;
    isPinching: boolean;
}

const usePinchZoom = (element: HTMLElement | null, options = { minScale: 0.5, maxScale: 3, }) => {
    const [state, setState,] = useState<PinchZoomState>({ scale: 1, isPinching: false, })
    const [initialDistance, setInitialDistance,] = useState<number | null>(null)

    const getDistance = useCallback((touches: TouchList): number => {
        const dx = touches[0].clientX - touches[1].clientX
        const dy = touches[0].clientY - touches[1].clientY
        return Math.sqrt(dx * dx + dy * dy)
    }, [])

    const handleTouchStart = useCallback((e: TouchEvent) => {
        if (e.touches.length === 2) {
            setInitialDistance(getDistance(e.touches))
            setState(prev => ({ ...prev, isPinching: true, }))
        }
    }, [getDistance,])

    const handleTouchMove = useCallback((e: TouchEvent) => {
        if (e.touches.length === 2 && initialDistance !== null) {
            const currentDistance = getDistance(e.touches)
            const scale
                = Math.min(
                    Math.max(currentDistance / initialDistance, options.minScale
                    ), options.maxScale)
            setState(prev => ({ ...prev, scale, }))
        }
    }, [getDistance, initialDistance, options.minScale, options.maxScale,])

    const handleTouchEnd = useCallback(() => {
        setInitialDistance(null)
        setState(prev => ({ ...prev, isPinching: false, }))
    }, [])

    useEffect(() => {
        if (!element) { return }

        element.addEventListener("touchstart", handleTouchStart)
        element.addEventListener("touchmove", handleTouchMove)
        element.addEventListener("touchend", handleTouchEnd)

        return () => {
            element.removeEventListener("touchstart", handleTouchStart)
            element.removeEventListener("touchmove", handleTouchMove)
            element.removeEventListener("touchend", handleTouchEnd)
        }
    }, [element, handleTouchStart, handleTouchMove, handleTouchEnd,])

    return state
}

export default usePinchZoom