import { useEffect, useRef } from "react"
import { Mesh, Scene } from "three"

const useClonedScene = (originalScene: Scene) => {
    const clonedSceneRef = useRef<Scene | null>(null)

    useEffect(() => {
        // Clone the original scene
        clonedSceneRef.current = originalScene.clone(true)

        // Cleanup function
        return () => {
            if (clonedSceneRef.current) {
                clonedSceneRef.current.traverse((object) => {
                    if (object.type === "Mesh") {
                        const mesh = object as Mesh
                        mesh.geometry?.dispose()
                        if (Array.isArray(mesh.material)) {
                            mesh.material.forEach((material) => material.dispose())
                        } else {
                            mesh.material?.dispose()
                        }
                    }
                })
                clonedSceneRef.current = null
            }
        }
    }, [originalScene,])

    return clonedSceneRef
}

export default useClonedScene