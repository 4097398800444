import React, { FormEvent, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import Button from "./Button"
import { Title } from "./Global"

type Props = {
    onCancel: () => void,
    onRename: (text: string) => void,
    submitButtonText?: string,
    title?: string,
    inputInitialValue?: string,
    loading: boolean,
}

const NameModal = (props: Props) => {

    const modalContainer = document.getElementById("modals-container")
    const [name, setName,] = useState(props.inputInitialValue ? props.inputInitialValue : "")


    const onSubmit = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()
        props.onRename(name)
    }

    const getModal = () => {
        return (
            <Background>
                <Modal>
                    <Title>{props.title ? props.title : "Rename Design"}</Title>
                    <Form onSubmit={onSubmit}>
                        <TextInput
                            id="designNameInput"
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                        <ButtonsWrapper>
                            <Button
                                color="#0088ff"
                                $loadingColor="#fff"
                                action="primary"
                                $full={true}
                                loading={props.loading}
                                disabled={props.loading}
                                type="submit">
                                {props.submitButtonText ? props.submitButtonText : "Rename"}
                            </Button>
                            <Button
                                $borderColor="#0088ff"
                                $labelColor="#0088ff"
                                action="secondary"
                                $full={true}
                                onClick={props.onCancel}>Cancel</Button>
                        </ButtonsWrapper>
                    </Form>
                </Modal>
            </Background>
        )
    }

    return <>
        {ReactDOM.createPortal(getModal(), modalContainer!)}
    </>
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #0000006b;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    width: 300px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 22px 39px -19px #0000008f;
`

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`

const TextInput = styled.input`
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #bbbbbb;
    padding: 8px;
    border-radius: 8px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
`

export default NameModal