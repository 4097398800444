import { atom, selector, selectorFamily } from "recoil"
import { GenericPartState, PartIdType } from "../../utils/Types"
import { sceneDefault, SceneType, UnitType } from "./types"
import { Box3, Vector3 } from "three"

export const sceneAtom = atom<SceneType>({
    key: "sceneAtom",
    default: sceneDefault,
})

export const scenePartsSelector = selector<Record<string, GenericPartState>>({
    key: "sceneParts",
    get: ({ get, }) => {
        return get(sceneAtom).parts
    },
})

interface SizeStringType {
    readout: string;
    width: number;
    height: number;
    depth: number;
    unit: string;
    imperial?: {
        width: number,
        height: number,
        depth: number,
        readout: string,
    };
    metric?: {
        width: number,
        height: number,
        depth: number,
        readout: string,
    };
}

export const sizeStringAtom = atom<SizeStringType>({
    key: "sizeString",
    default: {
        readout: "",
        width: 0,
        height: 0,
        depth: 0,
        unit: "",
        imperial: {
            width: 0,
            height: 0,
            depth: 0,
            readout: "",
        },
        metric: {
            width: 0,
            height: 0,
            depth: 0,
            readout: "",
        },
    },
})

export const partsIdsSelector = selector<PartIdType[]>({
    key: "partsIdsSelector",
    get: ({ get, }) => {
        return get(sceneAtom).partsIds
    },
})

export const partsSelector
    = selectorFamily<GenericPartState | undefined, { id: string, } | undefined | null>({
        key: "partsSelector",
        get: (params) => ({ get, }) => {
            if (params) {
                return get(sceneAtom).parts[params.id]
            } else {
                return undefined
            }
        },
    })

export const unitSelector = selector<UnitType>({
    key: "unitSelector",
    get: ({ get, }) => {
        return get(sceneAtom).unit || "in"
    },
})

export const showCameraControlsState = atom<boolean>({
    key: "showCameraControlsState",
    default: false,
})

export const boundingBoxAtom = atom<any>({
    key: "boundingBox",
    default: { box: new Box3(), show: false, },
})

export const emptyPositionForNewPartAtom = atom<Vector3>({
    key: "emptyPositionForNewPart",
    default: new Vector3(0, 0, 0,),
})

export const isSaveInProgressAtom = atom<boolean>({
    key: "isSaveInProgress",
    default: false,
})