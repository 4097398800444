import { Howl } from "howler"
import unsnap_audio from "../../../../assets/audio/unsnap.mp3"
import snap_audio from "../../../../assets/audio/snap.mp3"

const unsnapSound = new Howl({
    src: [unsnap_audio,],
})

const snapSound = new Howl({
    src: [snap_audio,],
  })

const playSnap = () => {
    snapSound.play()
}

const playUnsnap = () => {
    unsnapSound.play()
}

export const SoundHelper = {
   playSnap,
   playUnsnap,
}