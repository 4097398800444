import { produce } from "immer"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import { UnsavedChangesModal } from "../../components/Designs/unsavedChangesModal"
import { pendingChangesAtom } from "../../../builder/exportables"
import { useUnmountDesign } from "../../../builder/exportables"
import { getUrlWithQueriesToPreserve } from "../../../common/utils/utils"
import Button from "../../../common/components/Button"
import {
    ActionSlot,
    Container,
    Content,
    Header,
    Main,
    Overlay,
    Title
} from "../../../common/components/DrawerShared"
import { layoutState } from "../../../builder/exportables"
import { LoginButton, } from "../../../common/components/LoginPopup/LoginPopUp"
import { RoutesHelper } from "../../../common/utils/routesHelper"


const LoginDrawerMobile = () => {
    const overlay = document.getElementById("drawer-overlay")
    const [layoutValue, setLayout,] = useRecoilState(layoutState)
    const [pendingChanges, setPendingChanges,] = useRecoilState(pendingChangesAtom)
    const [showUnsavedChanges, setShowUnsavedChanges,] = useState(false)
    const navigate = useNavigate()
    const unmount = useUnmountDesign()


    const handleClose = () => {
        setLayout(produce((draft) => {
            draft.isDrawerOpen = false
        }))
    }


    if (!layoutValue.isDrawerOpen) {
        return null
    }

    const goToNewDesign = () => {
        setShowUnsavedChanges(false)
        setPendingChanges(false)
        unmount()
        navigate(getUrlWithQueriesToPreserve(RoutesHelper.main))
    }

    const handleNewDesign = () => {
        if (pendingChanges) {
            setShowUnsavedChanges(true)
        } else {
            goToNewDesign()
        }
    }

    const handleNotContinueOnDesignChange = () => {
        setShowUnsavedChanges(false)
    }

    return (
        <Container>
            <Header>
                <Main>
                    <Title>Welcome to CraftyAmigo</Title>
                    <ActionSlot>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </ActionSlot>
                </Main>
            </Header>
            <Content>
                <ButtonContainer>
                    <Button
                        $full={true}
                        $labelColor="#242424"
                        $borderColor="#E2E8F0"
                        onClick={handleNewDesign}>
                        + New Design
                    </Button>

                    <LoginButton renderButton={(handleLogin) =>
                        <Button
                            $full={true}
                            action="primary"
                            color="#0088ff"
                            onClick={handleLogin}>Sign up / Login</Button>}
                    />
                </ButtonContainer>
            </Content>
            {overlay && <>
                {ReactDOM.createPortal(<Overlay onClick={handleClose} />, overlay)}
                {showUnsavedChanges
                    && <UnsavedChangesModal
                        onConfirm={goToNewDesign} onCancel={handleNotContinueOnDesignChange} />
                }
            </>}
        </Container>
    )
}

const ButtonContainer = styled.div`
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;
            padding: 16px 16px 0px 16px;
            height: 100%;
            `

export default LoginDrawerMobile