/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
    Mesh,
    Vector3,
    ArrowHelper,
    Group,
} from "three"
import { useThree } from "@react-three/fiber"
import { useDebugTools } from "../debugProvider/useDebugTools"
import {
    getConnectedFaceGeometry,
    NinePointsGeoResult,
} from "./alignmentGeometry"
import { SegmentedTubeMarkers }
 from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import { useLevaControls } from "../debugProvider/useLevaControls"

interface AlignmentInput {
    mergedMesh: Mesh;
    marker: SegmentedTubeMarkers;
    instanceIndex?: number;
}

interface AlignmentPoints {
    source: (NinePointsGeoResult & {
        pointsWithLabels: Record<AlignmentAxis, Vector3>,
    }) | null;
    target: (NinePointsGeoResult & {
        pointsWithLabels: Record<AlignmentAxis, Vector3>,
    }) | null;
}

interface AlignmentResult {
    slidingPoint: Vector3;
    surfacePoint: Vector3;
    slidingPointCenter: Vector3;
    slidingPointCenterWithOffset: Vector3;
    slidingPointWithOffset: Vector3;
}

type AlignmentAxis =
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center"
    | "bottom"
    | "right"
    | "top"
    | "left";

const alignmentAxisToPointIndex: Record<AlignmentAxis, number> = {
    "top-left": 0,
    "top-right": 1,
    "bottom-right": 3,
    "bottom-left": 2,
    "right": 4,
    "bottom": 5,
    "left": 6,
    "top": 7,
    "center": 8,
}

// Add new interface for alignment options
interface AlignmentOptions {
    offsetX?: number;
    offsetY?: number;
}

export function useAlignment() {
    const { scene, } = useThree()
    const { drawVector3Point, } = useDebugTools()
    const { debugAlignment, } = useLevaControls()

    const getPoints = (source: AlignmentInput, target: AlignmentInput): AlignmentPoints => {
        if (!source.mergedMesh || !target.mergedMesh) {
            return {
                source: null,
                target: null,
            }
        }

        const firstSourceKey = Object.keys(source.marker)[0]
        const firstTargetKey = Object.keys(target.marker)[0]
        const sourceOuter = source.marker[firstSourceKey].outer
        const targetOuter = target.marker[firstTargetKey].outer
        const sourceMeshLayer = source.marker[firstSourceKey].mesh
        const targetMeshLayer = target.marker[firstTargetKey].mesh

        if (!sourceOuter || !targetOuter) {
            return {
                source: null,
                target: null,
            }
        }

        // Get marker's local axes in world space for source
        const sourceMarkerRight = new Vector3(1, 0, 0)
        const sourceMarkerUp = new Vector3(0, 1, 0)
        const sourceWorldMatrix = sourceOuter.matrixWorld.clone()
        const sourcePos = new Vector3()
        sourceOuter.getWorldPosition(sourcePos)

        sourceMarkerRight.applyMatrix4(sourceWorldMatrix).sub(sourcePos)
.normalize()
        sourceMarkerUp.applyMatrix4(sourceWorldMatrix).sub(sourcePos)
.normalize()

        // Create reference orientation from source marker
        const referenceOrientation = {
            forward: sourceMarkerUp,
            right: sourceMarkerRight,
        }

        const sourcePoints = getConnectedFaceGeometry(
            sourceOuter as Mesh,
            sourceMeshLayer!,
            source.instanceIndex,
            referenceOrientation,
            scene,
            debugAlignment,
        )

        // Add debug visualization for reference orientation
        if (debugAlignment) {
            const orientationGroup = new Group()
            orientationGroup.position.copy(sourcePoints[8]) // Position at source center point

            // Create arrows for each axis of the reference orientation
            // const arrowLength = 0.1
            // const axes = [
            //     { color: 0xff0000, dir: new Vector3(1, 0, 0), }, // X axis - red
            //     { color: 0x00ff00, dir: new Vector3(0, 1, 0), }, // Y axis - green
            //     { color: 0x0000ff, dir: new Vector3(0, 0, 1), },  // Z axis - blue
            // ]

            // axes.forEach(({ color, dir, }) => {
            //     const rotatedDir = dir.clone().applyQuaternion(referenceOrientation)
            //     const arrow = new ArrowHelper(
            //         rotatedDir,
            //         new Vector3(0, 0, 0),
            //         arrowLength,
            //         color,
            //         arrowLength * 0.2,
            //         arrowLength * 0.1
            //     )
            //     orientationGroup.add(arrow)
            // })

            scene.add(orientationGroup)
        }

        const targetPoints = getConnectedFaceGeometry(
            targetOuter as Mesh,
            targetMeshLayer!,
            target.instanceIndex,
            referenceOrientation,
            scene,
            debugAlignment,
        )

        if (debugAlignment) {
            const colors = [
                0xff0000, // corner 1 - red
                0xff6600, // corner 2 - orange
                0xffcc00, // corner 3 - yellow-orange
                0xffff00, // corner 4 - yellow
                0x00ff00, // midpoint 1 - green
                0x00ffcc, // midpoint 2 - turquoise
                0x0099ff, // midpoint 3 - light blue
                0x6600ff, // midpoint 4 - purple
                0xff00ff, // center - magenta
            ]

            targetPoints.forEach((p, i) => {
                drawVector3Point(p, colors[i], 0.001, 5000)
            })
            sourcePoints.forEach((p, i) => {
                drawVector3Point(p, colors[i], 0.001, 5000)
            })
        }

        const createPointsWithLabels = (points: Vector3[]) => {
            const labels: Record<AlignmentAxis, Vector3> = {
                "top-left": points[0],
                "top-right": points[1],
                "bottom-right": points[3],
                "bottom-left": points[2],
                "right": points[4],
                "bottom": points[5],
                "left": points[6],
                "top": points[7],
                "center": points[8],
            }
            return labels
        }

        return {
            source: sourcePoints.length ? {
                points: sourcePoints,
                center: sourcePoints[8],
                cornerVertices: sourcePoints.slice(0, 4),
                midpoints: sourcePoints.slice(4, 8),
                pointsWithLabels: createPointsWithLabels(sourcePoints),
            } : null,
            target: targetPoints.length ? {
                points: targetPoints,
                center: targetPoints[8],
                cornerVertices: targetPoints.slice(0, 4),
                midpoints: targetPoints.slice(4, 8),
                pointsWithLabels: createPointsWithLabels(targetPoints),
            } : null,
        }
    }

    const align = (
        source: AlignmentInput,
        target: AlignmentInput,
        axis: AlignmentAxis,
        options: AlignmentOptions = {}
    ): AlignmentResult | null => {
        if (!source?.marker || !target?.marker || !source.mergedMesh || !target.mergedMesh) {
            return null
        }

        const points = getPoints(source, target)

        if (!points.source || !points.target) {
            return null
        }

        // Calculate the base alignment first
        const pointIndex = alignmentAxisToPointIndex[axis]
        const slidingPoint = points.source.points[pointIndex]
        const surfacePoint = points.target.points[pointIndex]
        const targetOffset = new Vector3().subVectors(points.target.center, surfacePoint)
        const slidingPointCenter = new Vector3().addVectors(slidingPoint, targetOffset)

        // Create a separate point for offset calculations
        const slidingPointCenterWithOffset = slidingPointCenter.clone()
        const slidingPointWithOffset = slidingPoint.clone()

        // Handle offsets in target's local space
        if (options.offsetX || options.offsetY) {
            const markerMesh = target.marker["0"]?.outer as Mesh
            const markerMatrix = markerMesh.matrixWorld.clone()

            // Extract the local axes from the matrix
            const right = new Vector3()
            const forward = new Vector3()  // This will be our up vector from the matrix
            const unused = new Vector3()
            markerMatrix.extractBasis(right, forward, unused)  // Y axis is our forward direction

            // Calculate the total offset
            const offsetVector = new Vector3()

            if (options.offsetX) {
                offsetVector.add(right.multiplyScalar(options.offsetX))
            }

            if (options.offsetY) {
                offsetVector.add(forward.multiplyScalar(options.offsetY))
            }

            // Apply the offset to the offset point only
            slidingPointCenterWithOffset.add(offsetVector)
            slidingPointWithOffset.add(offsetVector)

            if (debugAlignment) {
                const arrowLength = Math.max(0.05, offsetVector.length())

                // Show the right direction (local X axis)
                const rightHelper = new ArrowHelper(
                    right.clone().normalize(),
                    surfacePoint,
                    arrowLength,
                    0xff0000  // Red for X-axis
                )

                // Show the forward direction (was Y axis in the matrix)
                const forwardHelper = new ArrowHelper(
                    forward.clone().normalize(),
                    surfacePoint,
                    arrowLength,
                    0x00ff00  // Green for Y-axis (our forward)
                )

                // Show the actual offset vector
                const offsetHelper = new ArrowHelper(
                    offsetVector.clone().normalize(),
                    surfacePoint,
                    offsetVector.length(),
                    0x0000ff  // Blue for the actual offset
                )

                scene.add(rightHelper)
                scene.add(forwardHelper)
                scene.add(offsetHelper)
            }
        }

        if (debugAlignment) {
            debugFaceNormals(target.mergedMesh)

            // Draw debug visualization
            drawVector3Point(slidingPoint, 0x00ff00, 0.002, 10000) // Original sliding point
            drawVector3Point(surfacePoint, 0x0000ff, 0.002, 10000) // Surface point
            drawVector3Point(slidingPointCenter, 0xff0000, 0.003, 10000)
            drawVector3Point(slidingPointCenterWithOffset, 0xff00ff, 0.003, 10000)

            // Draw the alignment offset vector
            const arrowHelper = new ArrowHelper(
                targetOffset.clone().normalize(),
                surfacePoint,
                targetOffset.length(),
                0xffff00
            )
            // scene.add(arrowHelper)
        }

        return {
            slidingPoint,
            surfacePoint,
            slidingPointCenter,
            slidingPointCenterWithOffset,
            slidingPointWithOffset,
        }
    }

    // Helper function to project a vector onto a plane
    const projectVectorOntoPlane = (vector: Vector3, normal: Vector3): Vector3 => {
        const projected = vector.clone()
        const dot = projected.dot(normal)
        return projected.sub(normal.clone().multiplyScalar(dot))
    }

    return {
        align,
        getPoints,
    }
}

// Helper function to visualize face normals
function debugFaceNormals(mesh: Mesh): void {
    const faceNormals = {
        right: new Vector3(1, 0, 0),
        left: new Vector3(-1, 0, 0),
        top: new Vector3(0, 1, 0),
        bottom: new Vector3(0, -1, 0),
        front: new Vector3(0, 0, 1),
        back: new Vector3(0, 0, -1),
    }

    Object.entries(faceNormals).forEach(([_, normal,]) => {
        const normalWorld = normal.clone().applyQuaternion(mesh.quaternion)
        const arrowHelper = new ArrowHelper(
            normalWorld,
            mesh.position,
            0.2,
            0xffff00
        )
        mesh.parent?.add(arrowHelper)
    })
}