import React from "react"
import styled, { css } from "styled-components"

enum MessageBoxVariant {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

interface Props {
    message: string | JSX.Element;
    variant?: MessageBoxVariant;
}

const MessageBox = (props: Props) => {
    return (
        <Container
            variant={props.variant}
        >
            {props.message}
        </Container>
    )
}

const getVariant = (variant: MessageBoxVariant) => {
    const ButtonByCase = {
        [MessageBoxVariant.PRIMARY]: (PrimaryStyles),
        [MessageBoxVariant.SECONDARY]: (SecondaryStyles),
    }
    return ButtonByCase[variant]
}

const Container = styled.div<{ variant?: MessageBoxVariant, }>`
    ${p => (getVariant(p.variant || MessageBoxVariant.PRIMARY))}
    width: 100%;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 6px;
`

const PrimaryStyles = () => css`
    color: #6d6d6d;
    background: white;
`

const SecondaryStyles = () => css`
    color: #3c3c3c;
    background: #f7f7f7;
`

export default MessageBox