import produce from "immer"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { onAuthChange } from "../../api/firebase/firebaseUtils"
import { authState } from "./RecoilAuthState"





export const SyncAuthStateWithRecoil = () => {
    const setAuth = useSetRecoilState(authState)
    useEffect(() => {
        onAuthChange((user) => {
            if (user) {
                setAuth(produce((draft) => {
                    draft.user = { ...user, }
                    draft.loaded = true
                }))
            } else {
                setAuth(produce((draft) => {
                    draft.user = null
                    draft.loaded = true
                }))
            }
        })

    }, [])

    return null

}