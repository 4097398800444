import React, { useEffect, useState } from "react"
import { roundLength } from "../../../../../../../../utils/utils"
import { LengthSliderConfig, SEGMENTED_TUBE_UI } from "../types/types"
import SegmentedTubeLengthSlider from "./SegmentedTubeLengthSlider"

interface Props {
    lengthSliderConfig: LengthSliderConfig & { onValueChange: (value: number) => void, };
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void;
    heightScaling: boolean | undefined;
    widthScaling: boolean | undefined;
    realWidth: number | undefined;
    realHeight: number | undefined;
    scalingDimension: "length" | "width" | "height";
    setScalingDimension: (dimension: "length" | "width" | "height") => void;
}

const LengthUI = (props: Props) => {

    const [conversionMultiplier, setConversionMultiplier,] = useState(1)
    const handleFinishEditing = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
    }

    const getEdgesLength = () => {
        return props.lengthSliderConfig.startSegmentLength
            + props.lengthSliderConfig.endSegmentLength
    }

    //value that shows in the display
    const getValue = () => {
        if (props.scalingDimension === "width" && props.lengthSliderConfig.modifiedWidth) {
            return props.lengthSliderConfig.modifiedWidth
        }
        if (props.scalingDimension === "height" && props.lengthSliderConfig.modifiedHeight) {
            return props.lengthSliderConfig.modifiedHeight
        }
        if (props.lengthSliderConfig.unitRealValue) {
            return props.lengthSliderConfig.unitRealValue
        }
        else {
            //console.warn("no props.lengthSliderConfig.unitRealValue")
            return roundLength(props.lengthSliderConfig.value
                * props.lengthSliderConfig.segmentLength
                + getEdgesLength())
        }
    }
    const [value, setValue,] = useState(getValue())

    useEffect(() => {
        setValue(getValue())
    }, [props.scalingDimension,])

    useEffect(() => {
        setConversionMultiplier(props.lengthSliderConfig.unit === "cm" ? 2.54 : 1)
    }, [props.lengthSliderConfig.unit,])

    const getValidMin = () => {
        if (props.scalingDimension !== "length") {
            return Number(0.1)
        }
        return roundLength(
            props.lengthSliderConfig.validMin * props.lengthSliderConfig.segmentLength
            + getEdgesLength()
        )
    }

    const getValidMax = () => {
        if (props.scalingDimension !== "length") {
            return Number(100)
        }
        return roundLength(props.lengthSliderConfig.maxMiddles
            * props.lengthSliderConfig.segmentLength
            + getEdgesLength())
    }

    const getMin = () => {
        if (props.scalingDimension !== "length") {
            return Number(0.1)
        }
        return (props.lengthSliderConfig.minMiddles || 1)
            * props.lengthSliderConfig.segmentLength * conversionMultiplier
    }
    const getMax = () => {
        if (props.scalingDimension !== "length") {
            return Number(100 * conversionMultiplier)
        }
        return (props.lengthSliderConfig.maxMiddles || 80)
            * props.lengthSliderConfig.segmentLength * conversionMultiplier
    }

    const getStep = () => {
        return props.lengthSliderConfig.segmentLength
    }

    const handleMouseUp = (value: number) => {
        props.lengthSliderConfig.setHideAddPartButtons(false)
        if (props.lengthSliderConfig.onMouseUp) {
            props.lengthSliderConfig.onMouseUp(value)
        }

    }

    const handleMouseDown = () => {
        if (props.lengthSliderConfig.onMouseDown) {
            props.lengthSliderConfig.onMouseDown()
        }
    }

    const handleChange = (value: number) => {
        props.lengthSliderConfig.onchange(value)
        props.lengthSliderConfig.onValueChange(value)
    }

    const isProcessing = React.useRef(false)

    const handleChangeFromInput = (value: number) => {
        if (isProcessing.current) {
            return
        }
        isProcessing.current = true
        handleMouseDown()

        setTimeout(() => {
            handleChange(value)
            setTimeout(() => {
                handleMouseUp(value)
                isProcessing.current = false
            }, 25)
        }, 25)
    }


    return (
        <SegmentedTubeLengthSlider
            value={getValue()}
            valueDirect={value}
            min={getMin()}
            max={getMax()}
            validMin={getValidMin()}
            validMax={getValidMax()}
            step={props.lengthSliderConfig.segmentLength}
            unit={props.lengthSliderConfig.unit}
            updateUnit={props.lengthSliderConfig.updateUnit}
            onMouseUp={handleMouseUp}
            onChange={handleChange}
            onInputChange={handleChangeFromInput}
            onMouseDown={handleMouseDown}
            onFinishEditing={handleFinishEditing}
            setDirection={props.lengthSliderConfig.setDirection}
            direction={props.lengthSliderConfig.direction}
            scalingDimension={props.scalingDimension}
            setScalingDimension={props.setScalingDimension}
            realWidth={props.realWidth}
            realHeight={props.realHeight}
            heightScaling={props.heightScaling}
            widthScaling={props.widthScaling}
        />
    )
}

export default LengthUI