import {
    Vector3,
    Raycaster,
    Intersection,
    Object3D, Event, Mesh, Shape, Line, ArrowHelper
} from "three"
import { PartConnectionType } from "../../../../../../../../state/scene/types"

export enum TUBE_UI {
    NONE = "NONE",
    SLIDER = "SLIDER"
}

export type SnapStateType = {
    isSnapped: boolean,
    hasCollied: boolean,
    maxPossibleLength: number,
    maxLengthSetted: boolean,
    targetMarker: Intersection<Object3D> | null,
}

type mmSnapStateType = SnapStateType & { newConnection: PartConnectionType | undefined, }

export type DragStateType = {
    drag: boolean,
    dragValue: number,
}

export type RayStateType = {
    worldMovableMarkerPosition: Vector3,
    worldMovableMarkerDirection: Vector3,
    ray: Raycaster,
    movableMarkerDirection: Vector3,
    collisionPlaneDirection: Vector3,
    colliderWorldPosition: Vector3,
    objects: Intersection<Object3D>[],
}

export type TubeInternalsType = {
    arrowHelper: ArrowHelper | null,
    markerTopMesh: Mesh | null,
    markerBottomMesh: Mesh | null,
    shape: Shape[],
    dragState: DragStateType,
    snapState: SnapStateType,
    guidelines: Line[],
    rayState: RayStateType,
    movableMarkerMesh: Mesh | null,
    mmSnapState: mmSnapStateType,
}