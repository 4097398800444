/* eslint-disable max-lines-per-function */
import React, { useState, useMemo } from "react"
import { Object3D, Vector3, Event as ThreeEvent, MeshBasicMaterial, Quaternion } from "three"
import { Html } from "@react-three/drei"
import { SegmentedTubeMarkers } from "../../segmentedTube/types/types"
import Marker from "./Marker"
import { FreePositions } from "../../../../../../../../providers/moveProvider/types"

const MarkerHelpersFreePositions = ({
    slideSide,
    onDotClick,
    points,
    attachmentPoint,
    viewInnerMarkers,
    viewOuterMarkers,
}: {
    slideSide: SegmentedTubeMarkers | undefined,
    points: FreePositions[],
    onDotClick?: (key: string, position: Vector3) => void,
    attachmentPoint: Object3D | undefined,
    viewInnerMarkers: boolean,
    viewOuterMarkers: boolean,
}) => {
    const [hoveredDot, setHoveredDot,]
        = useState<{ key: string, type: "outer" | "inner", position: Vector3, } | null>(null)

    const originalMaterial = useMemo(() => {
        if (!slideSide) { return null }
        const firstKey = Object.keys(slideSide)[0]
        const material = slideSide[firstKey]?.outer?.material
        return Array.isArray(material) ? material[0] : material as MeshBasicMaterial
    }, [slideSide,])

    const wireframeMaterialRed = useMemo(() => {
        if (!originalMaterial) { return null }
        const material = (originalMaterial as MeshBasicMaterial).clone()
        material.wireframe = true
        material.visible = true
        material.depthTest = false
        material.depthWrite = false
        return material
    }, [originalMaterial,])

    const wireframeMaterialGreen = useMemo(() => {
        if (!originalMaterial) { return null }
        const material = (wireframeMaterialRed as MeshBasicMaterial).clone()
        material.color.set("green")
        return material
    }, [originalMaterial, wireframeMaterialRed,])

    const handleDotClick = (point: FreePositions) => {
        onDotClick?.(point.meshName, point.position)
    }

    const handleDotHover = (key: string, type: "outer" | "inner") => {
        setHoveredDot({
            key,
            type,
            position: slideSide?.[key]![type]!.getWorldPosition(new Vector3()) || new Vector3(),
        })
        if (slideSide?.[key]?.[type]) {
            if (type === "inner" && wireframeMaterialGreen) {
                slideSide[key]![type]!.material = wireframeMaterialGreen
                slideSide[key]![type]!.visible = true
            } else if (wireframeMaterialRed) {
                slideSide[key]![type]!.material = wireframeMaterialRed
                slideSide[key]![type]!.visible = true
            }
        }
    }

    const handleDotLeave = (key: string, type: "outer" | "inner") => {
        if (slideSide?.[key]?.[type] && originalMaterial) {
            slideSide[key]![type]!.material = originalMaterial
        }
        setHoveredDot(null)
    }

    const getDotColor = (
        type: "outer" | "inner",
        isHovered: boolean,
        isOuterAttachmentPoint: boolean,
        isInnerAttachmentPoint: boolean
    ) => {
        if (isHovered && hoveredDot?.type === type) {
            return "yellow"
        }
        if (type === "outer" && isOuterAttachmentPoint) {
            return "red"
        }
        if (type === "inner" && isInnerAttachmentPoint) {
            return "red"
        }
        return type === "outer" ? "blue" : "green"
    }

    return (
        <group>
            {points.map((point, index) => {
                const outerMarkerName = point.meshName
                const innerMarkerName = point.meshName

                const isOuterAttachmentPoint = attachmentPoint?.name === outerMarkerName
                const isInnerAttachmentPoint
                    = attachmentPoint?.name === innerMarkerName

                const isHovered
                    = (hoveredDot?.key === point.meshName && hoveredDot?.type === "outer")
                    || (hoveredDot?.key === point.meshName && hoveredDot?.type === "inner")

                const dotSize: [number, number, number] = [0.0025, 0.0025, 0.001,]
                if (!point.marker) {
                    return null
                }

                const worldQuaternion = new Quaternion()
                point.marker.getWorldQuaternion(worldQuaternion)

                return (
                    <Marker
                        key={index}
                        position={point.position}
                        quaternion={worldQuaternion}
                        color=
                        {getDotColor("outer",
                            isHovered,
                            isOuterAttachmentPoint,
                            isInnerAttachmentPoint,
                        )}
                        dotSize={dotSize}
                        onClick={() => handleDotClick(point)}
                        onPointerOver={() => handleDotHover(point.key as string, "outer")}
                        onPointerOut={() => {
                            if (point.key) {
                                handleDotLeave(point.key as string, "outer")
                                setHoveredDot(null)
                            }
                        }}
                    />
                )
            })}

            {hoveredDot && (
                <Html
                    as="div"
                    scale={0.001}
                    position={hoveredDot.position.clone().add(
                        new Vector3(0.01, 0.01, 0)
                    )}
                >
                    <h1>
                        {hoveredDot.type} {hoveredDot.key}
                    </h1>
                    <h1>{slideSide?.[hoveredDot.key]?.outer?.name}</h1>
                </Html>
            )}
            )
        </group>
    )
}

export default MarkerHelpersFreePositions