import React, { useState } from "react"
import styled from "styled-components"
import ImageAnimation from "./ImageAnimation"
import BaseSlider from "../scene/part/parts/sliders/BaseSlider"
import { ConnectionType } from "./Connection"
import Button from "../../../../../common/components/Button"

type SingleConnectionProps = {
    isMobile: boolean,
    steps: number,
    connection: ConnectionType,
};

const SingleConnectionContainer = styled.div`
    position: relative;
    box-shadow: 0 8px 6px -6px #0000001a;
    border: solid 1px #00000029;
    border-radius: 6px;
`

const SliderContainer = styled.div`
    height: 50px;
    width: 85%;
    margin: 0 auto;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 16px 32px;
`

function SingleConnection(props: SingleConnectionProps) {
    const { steps, connection, isMobile, } = props
    const { onDegreeChange, onClick, images, } = connection
    const [degree, setDegree,] = useState(0)
    const [isHovered, setIsHovered,] = useState(false)
    const realSteps = steps || 15

    const onChange = (value: number) => {
        setDegree(value)

        onDegreeChange(value, connection)
    }
    const getValue = () => {
        return degree
    }
    const handleMouseUp = (e: number) => {
    }

    const onClickHandler = function () {
        onClick(degree)
    }

    return (
        <SingleConnectionContainer
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ImageAnimation onClick={onClickHandler} images={images} autoplay={true} />
            <SliderContainer>
                <BaseSlider
                    min={0}
                    max={360}
                    step={realSteps}
                    onChange={onChange}
                    handleMouseUp={handleMouseUp}
                    getValue={getValue}
                    marks={true}
                    checkSteps={true}
                />
            </SliderContainer>
            <ButtonContainer>
                <Button
                    action="primary"
                    $full={true}
                    onClick={onClickHandler}
                    color={"#0088ff"}
                    disabled={!isHovered && !isMobile}
                >
                    Select
                </Button>
            </ButtonContainer>
        </SingleConnectionContainer>
    )
}
export default SingleConnection