/* eslint-disable max-statements */
import React, { useState, useEffect, FC } from "react"
import ComboBox, { Option } from "./ComboBox"
import { useRecoilValue } from "recoil"
import {
  materialsSelector,
  sizesSelector,
  subclassesSelector
} from "../../../../../state/initialDataSelectors"
import { MaterialAPI, PartTypeAPI, SizeAPI, SubClassAPI } from "../../../../../../common/api/Types"
import { toDictionary } from "../../../../../../common/utils/utils"
import styled from "styled-components"
import SizesSelector from "./SizesSelector"

export const AllValues = "AllValues"

const adaptToOptions = <K extends string>(
  data: { [k in K]: string }[],
  valueKey: K,
  textKey: K
): Option[] => {
  return data.map((obj) => ({ text: obj[textKey], value: obj[valueKey], }))
}

export interface FiltersProps {
  filters: {
    materials: MaterialAPI[],
    subclasses: SubClassAPI[],
    sizes: SizeAPI[],
  };
  parts?: PartTypeAPI[];
  onChange: (values: {
    material: string, subclass: string, size: string,
  }) => void;
}

const Filters: FC<FiltersProps> = ({
  onChange,
  parts,
  filters,
}) => {
  let materials = useRecoilValue(materialsSelector)
  let subclasses = useRecoilValue(subclassesSelector)
  let sizesData = useRecoilValue(sizesSelector)

  if (filters.materials !== undefined) {
    materials = filters.materials
    subclasses = filters.subclasses!

    const sizesToDictionary = toDictionary(filters.sizes!, ({ id, }) => id)
    sizesData = sizesToDictionary
  }

  const sizes = Object.values(sizesData).sort((a, b) => a.size - b.size)
    .map(m => {
      return { id: m.id, size: m.friendlyName, }
    })

  parts?.some(p => p.materialId)


  const materialsAdapted = adaptToOptions(materials, "id", "materialName")
  const subclassesAdapted = adaptToOptions(subclasses, "id", "subclassName")
  const sizesAdapted = adaptToOptions(sizes, "id", "size",)

  materialsAdapted.unshift({
    text: "All Materials",
    value: AllValues,
  })
  subclassesAdapted.unshift({
    text: "All Types",
    value: AllValues,
  })

  sizesAdapted.unshift({
    text: "All Sizes",
    value: AllValues,
  })
  const [material, setMaterial,] = useState(materialsAdapted[0].value)
  const [subclass, setSubclass,] = useState(subclassesAdapted[0].value)
  const [size, setSizes,] = useState(sizesAdapted[0].value)

  useEffect(() => {
    onChange({
      material, subclass, size,
    })
  }, [material, subclass, size, parts,])

  return (
    <FiltersWrapper>
      <React.Fragment>
        <ComboBox
          value={material}
          setValue={setMaterial}
          options={materialsAdapted}
        />
        <ComboBox
          value={subclass}
          setValue={setSubclass}
          options={subclassesAdapted}
        />
        <SizesSelector
          value={size}
          setValue={setSizes}
          options={sizesAdapted}
        />
      </React.Fragment>
    </FiltersWrapper>
  )
}

export const SearchWrapper = styled.div`
  width:100%
`

export const SearchInput = styled.input`
  width:100%;
  padding: 7px;
  border-color: #bfbfbf;
  border-width:1px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
`

const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

export default Filters
