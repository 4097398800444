import React, { createContext, useRef } from "react"
import { Intersection, Mesh, Object3D, Quaternion, Vector3 } from "three"
import { PartConnectionType } from "../../state/scene/types"
import { filterWithValue } from "../../../common/utils/utils"

interface Props {
    children: React.ReactNode;
}

type DragType = { drag: false, }
    | { drag: true, dragDistance: number, }

type SnapType = { snap: false, }
    | {
        snap: true,
        snapDistance: number,
        selectedObject?: Intersection,
        newConnections: PartConnectionType[],
    }

type CorrectionsType = DragType & SnapType

export type MM_PartType = {
    id: string,
    attachToMarker: (marker: Mesh) => void,
    detachFromMarker: (
        marker: Mesh,
        connections: PartConnectionType[],
        getExactSnaps?: boolean,
        checkCollisions?: boolean,
        resetGuidelines?: boolean
    ) => PartConnectionType[],
    updateTransforms?: () => void,
    checksOnLengthMove: (
        elongationDirection: Vector3,
        connections: PartConnectionType[],
        lengthDirection: number,
    ) => CorrectionsType,
    checksOnSegmentedTubeLengthMove: (
        intersectableMeshes: Object3D[],
        connections: PartConnectionType[],
        chackSnaps?: boolean
    ) => void,
    checksOnRotationMove: (
        connections: PartConnectionType[],
        intersectableMeshes: Object3D[]
    ) => void,
    getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => number | null,
    getPosAndRot: () => { pos: Vector3, rot: Quaternion, },
}

type MultipleMovementContextType = {
    registerPart: (props: MM_PartType) => void,
    unregisterPart: (partId: string) => void,
    getParts: (partsIds: string[]) => MM_PartType[],
} | undefined

export const MultipleMovementContext = createContext<MultipleMovementContextType>(undefined)

const MultipleMovementProvider: React.FC<Props> = ({ children, }) => {
    const partsList = useRef<MM_PartType[]>([])

    const registerPart = (props: MM_PartType) => {
        partsList.current.push({
            id: props.id,
            attachToMarker: props.attachToMarker,
            detachFromMarker: props.detachFromMarker,
            updateTransforms: props.updateTransforms,
            checksOnLengthMove: props.checksOnLengthMove,
            checksOnSegmentedTubeLengthMove: props.checksOnSegmentedTubeLengthMove,
            checksOnRotationMove: props.checksOnRotationMove,
            getMaxLength: props.getMaxLength,
            getPosAndRot: props.getPosAndRot,
        })
    }

    const unregisterPart = (partId: string) => {
        partsList.current = partsList.current.filter(part => part.id !== partId)
    }

    const getParts = (partsIds: string[]) => {
        return filterWithValue(partsIds.map(id => partsList.current.find(part => part.id === id)!))
    }

    const init = () => {
        return {
            registerPart,
            unregisterPart,
            getParts,
        }
    }

    return <MultipleMovementContext.Provider value={init()} children={children} />
}

export default MultipleMovementProvider
export type { CorrectionsType, }