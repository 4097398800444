/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { Html } from "@react-three/drei"
import { AnimatePresence } from "framer-motion"
import ReactDOM from "react-dom"
import {
    LengthSliderConfig,
    RotationSliderConfig,
    SegmentedTubeInfo,
    SEGMENTED_TUBE_UI,
    SegmentedTubeMarker,
    ROTATION_AXES_ENUM
} from "../types/types"
import ActionsUI from "./ActionsUI"
import LengthUI from "./LengthUI"
import { ActionsContainer } from "../../ActionPanelStyles"
import RotationSlider from "./RotationSlider"
import { useSlide } from "../../../../../../../../providers/slideProvider/useSlide"
import SegmentedSlider from "./SegmentedSlider"
import { SegmentedTubePart, XYZ, XYZW } from "../../../../../../../../utils/Types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import useHandleSlide from "../utils/useHandleSlide"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import {
    Object3D,
    Event,
    Mesh,
    MathUtils,
    Vector3,
    BufferGeometry,
    InstancedMesh,
    Material,
    Box3
} from "three"
import {
    useMultipleMovement
} from "../../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { getMarkerNumber, getMarkerRef, isInner } from "../../../../../../../../utils/MarkerUtil"
import { EnvHelper } from "../../../../../../../../../common/utils/EnvHelper"
import { useThree } from "@react-three/fiber"
import useUnitConversion from "../../../../../utils/UnitUtils"
import type { UnitType } from "../../../../../../../../state/scene/types"
import DragSlide, { DragSliderRef } from "../../../../../../../../providers/moveProvider/DragSlider"
import { DragPoint, FreePositions } from "../../../../../../../../providers/moveProvider/types"
import MarkerHelpers from "../../utils/markerHelpers/MarkerHelpers"
import { useLevaControls } from "../../../../../../../../providers/debugProvider/useLevaControls"
import useCamera from "../../../../../../../../providers/cameraProvider/useCamera"
import { connectionIndexSelector } from "../../../../../../../../state/scene/selectors"
import { messageUtils } from "../../../../../../../../components/main/DesignScreen/scene/LowerRightMessages"

import ConnectorUI from "../../connector/ui/ConnectorUI"
import { useRecoilState } from "recoil"
import AttachmentPointDebugger from "./AttachmentPointDebugger"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import hotkeys from "hotkeys-js"
import { useGlobalAnimation } from "../../utils/animations/GlobalAnimationProvider"
import { getPointsArrayForSingleEnd } from "../../../../../../../../providers/moveProvider/meshBuilderEnds"
import UnsnapButton from "../../../../../../../../providers/moveProvider/UnsnapButton"
import AlignmentOptionsUI, { AlignmentPosition } from "./AlignmentOptionsUI"
import { useAlignment } from "../../../../../../../../providers/alignmentProvider/useAlignment"
import { useDebugTools } from "../../../../../../../../providers/debugProvider/useDebugTools"
import AlignmentPointHighlight from "./AlignmentPointHighlight"
import { checkIfMiddleWithoutBoundary } from "../../../../../../../../providers/moveProvider/meshHelpers"

export const action_variants = {
    "visible": {
        y: "0%",
    },
    "hidden": {
        y: "130%",
    },
}

export const transition = {
    duration: 0.5,
    ease: [0.54, 0.01, 0.61, 1,],
}

interface Props {
    lengthSliderConfig: LengthSliderConfig;
    rotationSliderConfig: RotationSliderConfig
    & {
        canRotate: () => boolean,
        getRotationAxesButtons: () => JSX.Element | undefined,
    };
    tubeUI: SEGMENTED_TUBE_UI;
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void;
    onRemove: () => void;
    tube: SegmentedTubePart;
    tubeInfo: MutableRefObject<SegmentedTubeInfo>;
    middleMeshes: MutableRefObject<Object3D[]>;
    canEditSlide: (sliderPartId?: string | undefined) => {
        sliderMarker: string,
        partsToMove: string[],
    } | undefined;
    saveSegmentedTubeSliderChanges: (partIds: string[], newConnections: PartConnectionType[]) =>
        void;
    saveRotationChanges: (newConnectorValues: {
        posAndRot: {
            pos: XYZ,
            rot: XYZW,
        },
        rotationMarkerName: string,
    }, partIds: string[], newConnections: PartConnectionType[]) => void;
    updateTransforms: () => void;
    attachToMove: ReturnType<typeof useMultipleMovement>["attachToMove"];
    handleSlideMovement: ReturnType<typeof useMultipleMovement>["handleSlideMovement"];
    detachMarkers: ReturnType<typeof useMultipleMovement>["detachMarkers"];
    onSlidePositionChange: () => void;
    onSlideSave: () => void;
    boundingBox: Box3 | null;
    middleInstanceMesh: InstancedMesh<BufferGeometry, Material | Material[]> | undefined;
    startInstanceMesh: InstancedMesh<BufferGeometry, Material | Material[]> | undefined;
    endInstanceMesh: InstancedMesh<BufferGeometry, Material | Material[]> | undefined;
    handleNewAttachmentPoint: (meshName: string,
        sliderPartId: string | undefined, position?: Vector3, ignoreHistory?: boolean, historyKey?: string) => void;
    unsnap: (partId: string, markerNames?: string[], historyKey?: string) => void;
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[],
        control: "selection" | "translate" | "rotate") => void;
    setIsSelected: (isSelected: boolean) => void;
    onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void;
    transformMode: string;
    removeMarkerOffset: (historyKey?: string) => void;
    scalingDimension: "length" | "width" | "height";
    setScalingDimension: (dimension: "length" | "width" | "height") => void;
}

type ToSaveLengthType = { changes: true, value: number, } | { changes: false, }

const SegmentedTubeUI = (props: Props) => {
    const { scene, camera, } = useThree()

    const [toSaveLength, setToSaveLength,] = useState<ToSaveLengthType>({ changes: false, })
    const [toSaveSlide, setToSaveSlide,] = useState(false)
    const actions_child = document.getElementById("actions-container")!
    const selectedNameRoot = document.getElementById("selected-part-name-root")!
    const [connectionIndex, setConnectionIndex,]
        = useRecoilState(connectionIndexSelector(props.tube.id,))
    const slide = useSlide(props.tube.id, connectionIndex,)
    const canSlide = slide.canSlide()
    const { viewInnerMarkers, viewOuterMarkers, debugAlignment, } = useLevaControls()
    const { enableCamera, } = useCamera()
    const { drawVector3Point, } = useDebugTools()
    const [slidePartId, setSlidePartId,] = useState(canSlide.sliderPartId)
    const { align, getPoints, } = useAlignment()
    const { triggerAnimation, } = useGlobalAnimation()
    const dragSliderRef = useRef<DragSliderRef>(null)
    const [positionChanged, setPositionChanged,] = useState(false)

    const handleSlide = useHandleSlide({
        tube: props.tube,
        tubeUI: props.tubeUI,
        tubeInfo: props.tubeInfo,
        savePositionRotationChanges: props.saveRotationChanges,
        setTubeUI: props.setTubeUI,
        canEditSlide: props.canEditSlide,
        saveSegmentedTubeSliderChanges: props.saveSegmentedTubeSliderChanges,
        sliderPartId: slidePartId,
        connectionIndex: connectionIndex,
    })

    const handleSlideRef = useRef(handleSlide)

    useEffect(() => {
        handleSlideRef.current = handleSlide
    }, [handleSlide,])

    useEffect(() => {
        setSlidePartId(canSlide.sliderPartId)
    }, [canSlide.sliderPartId, connectionIndex,])

    const [slideEnabled, setSlideEnabled,] = useState(() => canSlide.slideEnabled
        && ((!!handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove?.partsToMove.length
            <= EnvHelper.maxSegmentedTubeSlideSteps)
            || handleSlideRef.current.multipleMove?.partsToMove.length === 0)
    )

    useEffect(() => {
        if (toSaveLength.changes
            && props.tubeUI !== SEGMENTED_TUBE_UI.SLIDER
        ) {
            props.lengthSliderConfig.setAttachmentPointToRef()
            props.lengthSliderConfig.saveLength(toSaveLength.value)
            setToSaveLength({ changes: false, })
        }
        if (toSaveSlide && props.tubeUI !== SEGMENTED_TUBE_UI.SEGMENTED_SLIDER) {
            props.onSlideSave()
            setToSaveSlide(false)
        }
    }, [props.tubeUI,])



    useEffect(() => {
        const isSlideEnabled = canSlide.slideEnabled
        const hasPartsToMove = !!handleSlideRef.current.multipleMove?.partsToMove
        const partsToMoveLength = handleSlideRef.current.multipleMove?.partsToMove.length ?? 0
        const isWithinMaxSlideSteps = partsToMoveLength <= EnvHelper.maxSegmentedTubeSlideSteps
        const isPartsToMoveLengthZero = partsToMoveLength === 0
        const shouldEnableSlide = isSlideEnabled
            && ((hasPartsToMove && isWithinMaxSlideSteps) || isPartsToMoveLengthZero)

        setSlideEnabled(shouldEnableSlide)

    }, [handleSlideRef.current.multipleMove, canSlide.slideEnabled,])


    const handleValueChange = (value: number) => {
        setToSaveLength({ changes: true, value, })
    }

    const onDragEnd = (point: DragPoint, newAttachmentPoint?: FreePositions | undefined, ignoreHistory?: boolean, historyKey?: string) => {
        const historyKeyToUse = historyKey ?? crypto.randomUUID()
        if (newAttachmentPoint && newAttachmentPoint.meshName) {
            props.handleNewAttachmentPoint(
                newAttachmentPoint.meshName,
                canSlide.sliderPartId,
                newAttachmentPoint.position,
                ignoreHistory,
                historyKeyToUse,
            )
        }
        const meshNameParts = point.meshName.split("_")
        if (meshNameParts[1]) {
            const numericPart = meshNameParts[1].replace(/[^-\d]/g, "")
            const number = Number(numericPart)
            handleSegmentedMouseUp(number, false, historyKeyToUse)
        } else {
            handleSegmentedMouseUp(null, false, historyKeyToUse)
        }
        setPositionChanged(false)
    }

    const handleNewAttachmentPoint = (markerName: string, position?: Vector3, ignoreHistory?: boolean, historyKey?: string) => {
        props.handleNewAttachmentPoint(
            markerName,
            canSlide.sliderPartId,
            position,
            ignoreHistory,
            historyKey,
        )
    }

    const onPositionChange = (value: FreePositions) => {
        handleSlideMovementAndUpdatePosition(value.position)
        setPositionChanged(true)
        // if (newAttachmentPoint && newAttachmentPoint.name !== canSlide.slidedMarkerName!) {
        //     props.handleNewAttachmentPoint(newAttachmentPoint.name)
        // }
    }

    const onUnsnap = (value?: FreePositions) => {
        const historyKeyToUse = crypto.randomUUID()
        messageUtils.custom("Unsnapping parts...", {
            duration: 0.5, showSpinner: true, forceShow: true,
        })
        if (value && value.position) {
            handleSlideMovementAndUpdatePosition(value.position)
        }
        handleSegmentedMouseUp(null, false, historyKeyToUse)

        // ideally would open freeform editing here
        props.setTubeUI(SEGMENTED_TUBE_UI.NONE)
        props.removeMarkerOffset(historyKeyToUse)
        setTimeout(() => {
            // timeouts are to prevent timing issues where the UI has not updated and the unsnap
            // is called before the UI is set to none
            props.unsnap(props.tube.id, undefined, historyKeyToUse)
            messageUtils.custom(`Unsnapped ${props.tube.name}...`, {
                duration: 1, forceShow: true,
            })
            setTimeout(() => {
                messageUtils.custom("You can now move and rotate this part freely!", {
                    duration: 2, forceShow: true,
                })
            }, 1000)
            // enableCamera() is necessary because the timeout allows pointermoves to happen
            // and the camera will be disabled by the dragcontrols - remove after UI timeout fix
            enableCamera()
            setTimeout(() => {
                props.setIdsAsHighlightedAndTurnOnControl([props.tube.id,], "translate")
                // props.setTubeUI(SEGMENTED_TUBE_UI.ACTIONS)
            }, 350)
        }, 100)
    }

    const handleSegmentedMouseUp = (value: number | null, ignoreHistory?: boolean, historyKey?: string) => {
        props.lengthSliderConfig.setHideAddPartButtons(false)
        if (canSlide.slideEnabled) {
            const { current, } = props.tubeInfo
            const newWorldPosition = MeshUtils.copyWorldPosition(current.attachmentPoint!)
            let newWorldRotation = undefined
            if (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove.partsToMove.length > 0
            ) {
                current.attachmentPoint!.rotateY(MathUtils.degToRad(-180))
                newWorldRotation = MeshUtils.copyWorldQuaternion(current.attachmentPoint!)
                current.attachmentPoint!.rotateY(MathUtils.degToRad(180))
            }

            slide.saveNewPosition(canSlide.sliderPartId!, value, newWorldPosition, newWorldRotation, ignoreHistory, historyKey)

            if (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove.partsToMove.length > 0
            ) {
                const newConnections = props.detachMarkers(
                    handleSlideRef.current.multipleMove!.partsToMove,
                    getSlideMarker()!,
                    true,
                    false,
                    false
                )
                handleSlideRef.current.setNewChangesToSave(newConnections)
            } else {
                setToSaveSlide(true)
            }
        }
    }

    const handleSegmentedFinishEditing = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
    }

    const getSlideMarker = () => {
        const markerName = canSlide.markerName!
        const markerNumber = Number(getMarkerNumber(markerName))
        const innerOuter = isInner(markerName) ? "inner" : "outer"
        let marker
        if (props.tubeInfo.current!.startSection[markerNumber]) {
            marker = props.tubeInfo.current!.startSection[markerNumber][innerOuter]!
        } else if (props.tubeInfo.current!.endSection[markerNumber]) {
            marker = props.tubeInfo.current!.endSection[markerNumber][innerOuter]!
        } else {
            const split = markerName.split("_")
            const markerMSNumber = getMarkerNumber(split[0])
            const markerMSSideNumber = split[1]
            const section = props.tubeInfo.current!.middleSection
            marker = section[markerMSNumber][markerMSSideNumber][innerOuter]
        }
        return marker
    }

    const slidedMarkerNameRef = useRef(canSlide.slidedMarkerName)
    const [slidedMarkerPosition, setSlidedMarkerPosition,] = useState<Vector3 | null>(null)

    useEffect(() => {
        slidedMarkerNameRef.current = canSlide.slidedMarkerName
        if (slidedMarkerNameRef.current) {
            const slidedMarker = getMarkerRef(scene, props.tube.id, slidedMarkerNameRef.current)
            setSlidedMarkerPosition(MeshUtils.copyWorldPosition(slidedMarker))
        }
    }, [canSlide.slidedMarkerName,])

    const getSlidedMarkerPos = () => {
        const slidedMarker = getMarkerRef(scene, props.tube.id, slidedMarkerNameRef.current!)
        return MeshUtils.copyWorldPosition(slidedMarker)
    }

    const handleSlideMovementAndUpdatePosition = (newWorldPosition: Vector3) => {
        const slidedMarker = getMarkerRef(scene, props.tube.id, slidedMarkerNameRef.current!)
        if (!slidedMarker) {
            return
        }
        const slidedMarkerPos = MeshUtils.copyWorldPosition(slidedMarker)
        const slidedMarkerRotation = MeshUtils.copyWorldDirection(slidedMarker)
        const auxPoint = new Mesh()
        scene.add(auxPoint)
        auxPoint.position.copy(slidedMarkerPos)
        auxPoint.rotation.setFromVector3(slidedMarkerRotation)
        auxPoint.attach(props.tubeInfo.current.attachmentPoint!)
        auxPoint.position.copy(newWorldPosition)
        // drawVector3Point(newWorldPosition, scene, 0x00FFFF, 0.0019)

        const newPos = MeshUtils.copyWorldPosition(props.tubeInfo.current.attachmentPoint!)
        // drawVector3Point(newPos, scene, 0xFF00FF, 0.0018)

        const newQuat = MeshUtils.copyWorldQuaternion(props.tubeInfo.current.attachmentPoint!)
        props.tubeInfo.current.attachmentPoint!.removeFromParent()
        scene.add(props.tubeInfo.current.attachmentPoint!)
        props.tubeInfo.current.attachmentPoint!.position.copy(newPos)
        props.tubeInfo.current.attachmentPoint!.setRotationFromQuaternion(newQuat)

        props.updateTransforms()

        if (handleSlideRef.current?.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0
        ) {
            props.handleSlideMovement(
                handleSlideRef.current.multipleMove!.partsToMove,
                props.middleMeshes.current,
                true
            )
        } else {
            props.onSlidePositionChange()
        }
    }

    const handleAlignmentMovement = (newWorldPosition: Vector3) => {
        if (!newWorldPosition) {
            console.warn("No new world position provided to handleAlignmentMovement")
            return
        }

        const slidedMarker = getMarkerRef(scene, props.tube.id, slidedMarkerNameRef.current!)
        if (!slidedMarker) {
            return
        }

        // Get the current position and rotation of the slided marker
        const slidedMarkerPos = MeshUtils.copyWorldPosition(slidedMarker)
        const slidedMarkerRotation = MeshUtils.copyWorldDirection(slidedMarker)

        // Create auxiliary point to handle the transformation
        const auxPoint = new Mesh()
        scene.add(auxPoint)
        auxPoint.position.copy(slidedMarkerPos)
        auxPoint.rotation.setFromVector3(slidedMarkerRotation)

        // Attach and move the attachment point
        auxPoint.attach(props.tubeInfo.current.attachmentPoint!)
        auxPoint.position.copy(newWorldPosition)

        props.updateTransforms()

        if (handleSlideRef.current?.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0
        ) {
            props.handleSlideMovement(
                handleSlideRef.current.multipleMove!.partsToMove,
                props.middleMeshes.current,
                true
            )
        } else {
            props.onSlidePositionChange()
        }
    }

    const handleNewSegmentPosition = (newPosition: number) => {
        const newWorldPosition
            = slide.getNewWorldPosition(
                newPosition,
                canSlide.sliderPartId!,
                canSlide.sliderMarkerName!
            )
        handleSlideMovementAndUpdatePosition(newWorldPosition)
    }

    const handleSegmentedMouseDown = () => {
        props.lengthSliderConfig.setHideAddPartButtons(true)
        if (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0
        ) {
            props.attachToMove(handleSlideRef.current.multipleMove!.partsToMove, getSlideMarker()!)
        }
    }

    const disabledText = slideEnabled ? undefined : (canSlide.slideEnabled
        ? "Slide is disabled for connected tubes"
        : "You can only slide parts that are connected to the middle of another part")

    const getSlider = () => {
        switch (props.tubeUI) {
            case SEGMENTED_TUBE_UI.SEGMENTED_SLIDER:
                return <SegmentedSlider
                    onMouseDown={handleSegmentedMouseDown}
                    onMouseUp={handleSegmentedMouseUp}
                    onNewPosition={handleNewSegmentPosition}
                    handleFinishEditing={handleSegmentedFinishEditing}
                    startLength={canSlide.sliderStartLength!}
                    segmentLength={canSlide.sliderSectionLength!}
                    {...slide.getPositionsToSlide(canSlide.markerName!)}
                    disabledText={disabledText}
                    useDragSlide={true}
                    connectedToSegmentedParts={canSlide.connectedToSegmentedParts}
                    connectionIndex={connectionIndex}
                    setConnectionIndex={setConnectionIndex}
                    onAlignmentSelect={handleAlignmentSelect}
                    onAlignmentHover={handleAlignmentHover}
                    rotationPoint={slide.getSlideSide(canSlide.markerName!)}
                    camera={camera}
                    unit={["cm", "in",].includes(canSlide.slidePartUnits || "")
                        ? canSlide.slidePartUnits as "cm" | "in"
                        : undefined}
                    debugAlignment={debugAlignment}
                    canUseAlignment={canUseAlignment}
                    positionChanged={positionChanged}
                />
            case SEGMENTED_TUBE_UI.ROTATION:
                return <RotationSlider
                    {...props.rotationSliderConfig}
                    auxButtons={
                        props.rotationSliderConfig.getRotationAxesButtons()}
                    disabled={!props.rotationSliderConfig.canRotate()}
                />
            case SEGMENTED_TUBE_UI.SLIDER:
                return <LengthUI
                    lengthSliderConfig={{
                        ...props.lengthSliderConfig,
                        onValueChange: handleValueChange,
                    }}
                    setTubeUI={props.setTubeUI}
                    heightScaling={props.tube.heightScaling}
                    widthScaling={props.tube.widthScaling}
                    realWidth={props.tube.realWidth}
                    realHeight={props.tube.realHeight}
                    scalingDimension={props.scalingDimension}
                    setScalingDimension={props.setScalingDimension}
                />
            default:
                return null
        }
    }


    const getTubeLength = () => {
        const { tube, } = props
        return (tube.length + tube.lengthNegativeSide)
            * tube.segmentLength
            + tube.startSegmentLength
            + tube.endSegmentLength
    }

    useEffect(() => {
        if (slideEnabled) {
            messageUtils.custom("Use the unsnap button to move freely!", {
                duration: 2.5,
                minTimeBetweenShows: 10,
                showUpTo: 3,
            })
        }
    }, [slideEnabled,])

    const getAlignmentProperties = () => {
        const otherPart  = {
            marker: slide.getSlideSide(canSlide.markerName!)!,
            mergedMesh: canSlide.slideMergedMesh,
            instanceIndex: canSlide.sliderInstanceIndex,
        }

        const thisPart = {
            marker: canSlide.slideSides,
            mergedMesh: canSlide.slidingMergedMesh,
        }

        if (!thisPart.marker) {
            // if the mesh is not there, then it won't be registered in the slide
            // so we need to get the marker from the start or end section
            // this is a case with 8/20
            const markerName = canSlide.markerName!
            const markerNumber = Number(getMarkerNumber(markerName))
            if (props.tubeInfo.current!.startSection[markerNumber]) {
                thisPart.marker = { "0": props.tubeInfo.current!.startSection[markerNumber], }
            } else if (props.tubeInfo.current!.endSection[markerNumber]) {
                thisPart.marker = { "0": props.tubeInfo.current!.endSection[markerNumber], }
            }
        }
        return { otherPart, thisPart, }
    }

    const handleAlignmentSelect = (position: AlignmentPosition, offsetX: number, offsetY: number) => {
        const { otherPart, thisPart, } = getAlignmentProperties()
        if (!otherPart.mergedMesh || !thisPart.mergedMesh || !otherPart.marker || !thisPart.marker) { return }

        handleSegmentedMouseDown()
        const result = align(
            { marker: otherPart.marker, mergedMesh: otherPart.mergedMesh!, instanceIndex: otherPart.instanceIndex, },
            { marker: thisPart.marker, mergedMesh: thisPart.mergedMesh!, },
            position,
            {
                offsetX: offsetX,
                offsetY: offsetY,
            }
        )

        if (!result?.slidingPoint) {
            console.warn("No sliding point provided to handleAlignmentMovement")
            return
        }


        // First make the attachment point move to the alignment point of itself
        handleNewAttachmentPoint(canSlide.markerName!, result?.surfacePoint)

        // Then move the whole piece to the alignment point of the other part
        handleAlignmentMovement(result?.slidingPoint!)

        // Then make the attachment point move to the center of itself after positioning
        handleNewAttachmentPoint(canSlide.markerName!, result?.slidingPointCenter!)

        // Then move the whole piece to the alignment point of the other part with offset
        handleAlignmentMovement(result?.slidingPointCenterWithOffset!)

        dragSliderRef.current?.updateDraggableObjectCenter(result?.slidingPointCenterWithOffset!)

        onDragEnd({
            meshName: canSlide.sliderMarkerName!,
            position: result?.slidingPoint!,
        }, {
            position: result?.slidingPointCenterWithOffset!,
            meshName: canSlide.markerName!,
            })
    }

    const handleAlignmentHover = (position: AlignmentPosition | null) => {
        const { otherPart, thisPart, } = getAlignmentProperties()
        if (!otherPart.mergedMesh || !thisPart.mergedMesh || !otherPart.marker || !thisPart.marker) { return }
        const result = getPoints(
            { marker: otherPart.marker, mergedMesh: otherPart.mergedMesh!, instanceIndex: otherPart.instanceIndex, },
            { marker: thisPart.marker, mergedMesh: thisPart.mergedMesh!, }
        )
        const point = result.source?.pointsWithLabels[position!]
        if (point) {
            triggerAnimation("alignmentHighlight", undefined, undefined, "blue", point)
        }
    }

    const canUseAlignment = useMemo(() => {
        const otherPart = slide.getSlideSide(canSlide.markerName!)
        const thisPart = canSlide.slideSides

        if (!otherPart || !thisPart) {
            return false
        }

        const firstSourceKey = Object.keys(otherPart)[0]
        const firstTargetKey = Object.keys(thisPart)[0]
        const sourceMeshLayer = otherPart[firstSourceKey].mesh
        const targetMeshLayer = thisPart[firstTargetKey].mesh

        if (!sourceMeshLayer || !targetMeshLayer) {
            return false
        }

        return true
    }, [canSlide.markerName, canSlide.slideSides,])

    return (
        <>
            {(viewInnerMarkers || viewOuterMarkers)
                && props.tubeUI !== SEGMENTED_TUBE_UI.SEGMENTED_SLIDER
                && <MarkerHelpers
                    slideSide={canSlide.slideSides}
                    attachmentPoint={props.tubeInfo.current.attachmentPoint!}
                    viewInnerMarkers={viewInnerMarkers}
                    viewOuterMarkers={viewOuterMarkers}
                    onDotClick={handleNewAttachmentPoint}
                />
            }
            <AttachmentPointDebugger
                attachmentPoint={props.tubeInfo.current.attachmentPoint}
                originPoint={props.tubeInfo.current.originPoint}
                tubeName={props.tube.name}
            />
            {
                slideEnabled && props.tubeUI === SEGMENTED_TUBE_UI.SEGMENTED_SLIDER
                && <DragSlide
                    ref={dragSliderRef}
                    mesh={[props.tubeInfo.current.mergedMesh!,]}
                    slidingSides={canSlide.slideSides}
                    onPositionChange={onPositionChange}
                    onUnsnap={onUnsnap}
                    slideSide={slide.getSlideSide(canSlide.markerName!)}
                    onDragStart={handleSegmentedMouseDown}
                    onDragEnd={onDragEnd}
                    segmentLength={canSlide.sliderSectionLength!}
                    draggableObjectCenter={props.tubeInfo.current.attachmentPoint!}
                    startLength={canSlide.sliderStartLength!}
                    endLength={canSlide.sliderEndLength!}
                    onNewAttachmentPoint={handleNewAttachmentPoint}
                    pieceLength={getTubeLength()}
                    boundingBox={props.boundingBox}
                    slidedMarkerPosition={slidedMarkerPosition}
                    getSlidedMarkerPos={getSlidedMarkerPos}
                />
            }
            <Html wrapperClass={"neutralHTML"}>
                {ReactDOM.createPortal(
                    <AnimatePresence>
                        {
                            (props.tubeUI !== SEGMENTED_TUBE_UI.CLOSE
                                && props.tubeUI !== SEGMENTED_TUBE_UI.NONE)
                            && <ActionsContainer
                                transition={transition}
                                variants={action_variants}
                                initial={"hidden"}
                                animate={"visible"}
                                exit={"hidden"}
                                $partUI={props.tubeUI}
                            >
                                <ActionsUI
                                    tubeUI={props.tubeUI}
                                    canSlide={slideEnabled}
                                    onRemove={props.onRemove}
                                    setTubeUI={props.setTubeUI}
                                    canRotate={props.rotationSliderConfig.canRotate()}
                                    partId={props.tube.id}
                                    onDuplicate={props.onDuplicate}
                                    setIdsAsHighlightedAndTurnOnControl
                                    ={props.setIdsAsHighlightedAndTurnOnControl}
                                    setIsSelected={props.setIsSelected}
                                    transformMode={props.transformMode}
                                    showDimensionsLabel={!!(props.tube.heightScaling || props.tube.widthScaling)}
                                />
                                {getSlider()}
                            </ActionsContainer>
                        }
                    </AnimatePresence>, actions_child)}
                {selectedNameRoot && ReactDOM.createPortal(
                    slideEnabled && <UnsnapButton onClick={onUnsnap} />,
                    selectedNameRoot
                )}
            </Html>
        </>

    )
}

export default SegmentedTubeUI