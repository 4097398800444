import { useRecoilValue } from "recoil"
import useSWR from "swr"
import { useState, useEffect } from "react"
import { ApiClient } from "../../../../../../../common/api/ApiClient"
import {
  connectionTypesSelector,
  sizesSelector
} from "../../../../../../state/initialDataSelectors"
import { AddPartState } from "../../../../../../state/types"
import { getCompatibleParts } from "../../../../../../utils/swapUtils"
import {
  getPartsWithOppositeCompatibility
} from "../../../scene/part/parts/tube/utils/ReduceExpandToFit"

export const useLoadModalData = ({ source, }: AddPartState["step1"], userQuery: string) => {
  const sizes = useRecoilValue(sizesSelector)
  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const [debouncedUserQuery, setDebouncedUserQuery,] = useState(userQuery)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedUserQuery(userQuery)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [userQuery,])

  const { data, error, } = useSWR(`${source?.markerId}${source?.sizeId}${debouncedUserQuery}`,
  async () => {
    let typeSize
    let compatibleConnections
    let sizeIgnoreSizeCompatibility
    if (source) {
      //console.log("source", source)
      sizeIgnoreSizeCompatibility = await ApiClient.getSizeIgnore(source.markerId)
      compatibleConnections = await ApiClient.getCompatibleConnections(source.markerId)
      typeSize = {
        sizeId: source.sizeId,
        compatibleConnections,
      }
    }
    if (source?.swap) {
      return {
        parts: await getCompatibleParts(
          source.swap.connectionTypesDictionary,
          source.swap.sizes,
          source.swap.connectionATypeId,
          source.swap.connectionASizeId,
          source.swap.connectionBTypeId,
          source.swap.connectionBSizeId,
          source.swap.maxLength,
          debouncedUserQuery // Use debouncedUserQuery here
        ), compatibleConnections,
      }
    } else if (source?.expandReduceToFitInfo.isApplicable) {
      const expandPartsLists = await getPartsWithOppositeCompatibility(
        source?.expandReduceToFitInfo.originConnection.typeId,
        source.expandReduceToFitInfo.originConnection.sizeId,
        source?.expandReduceToFitInfo.oppositeConnection.typeId,
        source.expandReduceToFitInfo.oppositeConnection.sizeId,
        connectionTypes,
        sizes,
        source.expandReduceToFitInfo.distance,
      )

      return {
        parts: expandPartsLists.simpleCompatibleParts,
        suggestedParts: expandPartsLists.bothSidesCompatibleParts,
        compatibleConnections,
      }
    }
    return {
      parts: await ApiClient.getParts(sizes, typeSize, debouncedUserQuery,
        sizeIgnoreSizeCompatibility ?? undefined)
        .then(parts => {
          return parts
        }),
      compatibleConnections,
      sizeIgnoreSizeCompatibility,
    }
  })

  if (!data) {
    return {
      data: null,
      error: null,
    } as const
  } else if (error) {
    return {
      data: null,
      error: error,
    } as const
  }
  return {
    data: {
      parts: data.parts,
      suggestedParts: data.suggestedParts,
      compatibleConnections: data.compatibleConnections,
      sizeIgnoreSizeCompatibility: data.sizeIgnoreSizeCompatibility,
    },
    error: null,
  } as const
}