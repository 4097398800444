import React from "react"
import { ButtonLinksStyles } from "./ButtonLinksStyles"

export type ButtonLink = {
    href: string,
    label: string,
    target?: string,
    disabled?: boolean,
    separator?: boolean,
    icon?: React.FunctionComponent,
}

type Props = {
    listOfLinks: ButtonLink[],
    isMobile?: boolean,
}

const ButtonLinks = (props: Props) => {
    return <React.Fragment>
        {props.listOfLinks.map((link, index) => {
            return <> <ButtonLinksStyles.Link key={index}
                disabled={link.disabled}
                isMobile={props.isMobile}
                href={link.href}
                target={link.target}
            >
                {link.icon && <ButtonLinksStyles.Icon> <link.icon /> </ButtonLinksStyles.Icon>}
                {link.label}
            </ButtonLinksStyles.Link>
                {link.separator && <ButtonLinksStyles.Separator />}
            </>
        })}
    </React.Fragment>
}

export default ButtonLinks