/* eslint-disable max-len */
/* eslint-disable no-negated-condition */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useState, useMemo, useEffect } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import ConnectionModal from "../../ConnectionModal/ConnectionModal"
import { ConnectionTypeAPI, MaterialAPI, PartTypeAPI, SizeAPI, SubClassAPI } from "../../../../../../common/api/Types"
import { GroupedBy, ObjDictionary, recursiveGroupByList } from "../../../../../../common/utils/utils"
import Filters, { AllValues, FiltersProps, SearchWrapper, SearchInput } from "./Filters"
import { addPartModal, initialData, renderConnectionsData, userQueryState } from "../../../../../state/atoms"
import Empty from "./Empty"
import Description, { DescriptionCase } from "./Description"
import { categoriesSelector, connectionTypesSelector } from "../../../../../state/initialDataSelectors"
import { AddPartState } from "../../../../../state/types"
import DescriptionExpandReduce from "./ExpandReduceModal/DescriptionExpandReduce"
import produce from "immer"
import { layoutState } from "../../../../../state/layoutState"
import PartsDrawer from "./ui/PartsDrawer"
import { addPartCaseEnum, useAddPart } from "./utils/AddPart"
import CategoryParts from "./CategoryParts"
import CategoriesAndParts from "./CategoriesAndParts"
import { useLoadModalData } from "./utils/LoadModalData"
import { DescriptionBodyContainer, StyickyTitle } from "../../../../../../common/components/DrawerShared"
import { ApiClient } from "../../../../../../common/api/ApiClient"
import {
  materialsSelector,
  sizesSelector,
  subclassesSelector
} from "../../../../../state/initialDataSelectors"
import { scrollContainerId } from "./utils/helper"
import { events, useEventsData } from "../../../../../../common/utils/rudderAnalyticsUtils"
import { breadcrumb } from "../../../../../../common/utils/sentrySetup"
import * as Sentry from "@sentry/react"
import { EnvHelper } from "../../../../../../common/utils/EnvHelper"

export type PartsListType = {
  key: string,
  values: PartTypeAPI[] | PartsListType[],
}

const PartModalChooseNewPartConnector = (
  props: Required<AddPartState>["step2"]
    & {
      source: NonNullable<AddPartState["step1"]["source"]>,
      clearModal: () => void,
      onBack: () => void,
      sizeIgnoreSizeCompatibility?: boolean,
    }
) => {
  //console.log("Props accessible by PartModalChooseNewPartConnector when printing connection modal", props)
  return <ConnectionModal
    partToAdd={props.partToAdd}
    ignoreSizeCompatibility={props.sizeIgnoreSizeCompatibility}
    compatibleConnectionsIds={props.compatibleConnections}
    source={props.source}
    onClose={props.clearModal}
    onBack={props.onBack}
    swap={props.source.swap}
    sizeId={props.sizeId}
  />
}

const filterParts = (
  partsToFilter: PartTypeAPI[],
  params?: Parameters<FiltersProps["onChange"]>[0]
) => {
  return params
    ? partsToFilter.filter(({ materialId, subclassId, connections, }) => {
      return (!params.size
        || params.size === AllValues
        || (params.size.includes(",")
          ? params.size.split(",").some(s => connections.map(c => c.size.id).includes(s))
          : connections.map(c => c.size.id).includes(params.size)))
        && (!params.material || params.material === AllValues || params.material === materialId)
        && (!params.subclass || params.subclass === AllValues || params.subclass === subclassId)
    })
    : partsToFilter
}

const filtersOptionsMaterials = (
  parts: PartTypeAPI[],
  materials: MaterialAPI[],
  params?: Parameters<FiltersProps["onChange"]>[0],
) => {
  const filteredParts = filterParts(parts, params ? { ...params, material: AllValues, } : undefined)
  return materials.filter(m => filteredParts.some(p => m.id === p.materialId))
}

const filterOptionsSubclass = (
  parts: PartTypeAPI[],
  subclasses: SubClassAPI[],
  params?: Parameters<FiltersProps["onChange"]>[0],
) => {
  const filteredParts = filterParts(parts, params ? { ...params, subclass: AllValues, } : undefined)
  return subclasses.filter(s => filteredParts.some(p => s.id === p.subclassId))
}

const filterOptionsSizes = (
  parts: PartTypeAPI[],
  sizes: SizeAPI[],
  params?: Parameters<FiltersProps["onChange"]>[0],
) => {
  const filteredParts = filterParts(parts, params ? { ...params, size: AllValues, } : undefined)
  return sizes.filter(s => filteredParts.some(p => p.connections.some(c =>
    s.friendlyName === c.size.friendlyName)))
}

const filteredPartsMissingSomething = (
  parts: PartTypeAPI[],
  connectionTypes: ObjDictionary<ConnectionTypeAPI>,
) => {
  const correctParts: PartTypeAPI[] = []
  const incorrectParts: PartTypeAPI[] = []

  parts.forEach((part, idx, arr) => (!part.connections.some(con => !connectionTypes[con.connectorId])
  ? correctParts : incorrectParts).push(part))

  incorrectParts.forEach(incorrectPart => {
    const message = `There's something wrong with ${incorrectPart.name}. This could be a missing connection type.`
    if (!EnvHelper.isProduction) {
      console.error(message, incorrectPart)
    } else {
      Sentry.captureEvent({message,})
    }
  })
  return correctParts
}

const PartModalChoosePart = (props: {
  info: AddPartState["step1"],
  clearModal: () => void,
  onBack: () => void,
  userQuery: string,
}) => {

  const [userQuery, setUserQuery,] = useRecoilState(userQueryState)

  const { error, data: partsData, } = useLoadModalData(props.info, userQuery)
  //console.log("Parts Data Loaded from PartModalChoosePart:", partsData)
  const [filters, setFilters,] = useState<Parameters<FiltersProps["onChange"]>[0] | undefined>()

  const categories = useRecoilValue(categoriesSelector)
  const materialsData = useRecoilValue(materialsSelector)
  const subclassesData = useRecoilValue(subclassesSelector)
  const sizesData = useRecoilValue(sizesSelector)
  const initialDataValue = useRecoilValue(initialData)
  const eventsData = useEventsData()
  const connectionTypes = useRecoilValue(connectionTypesSelector)

  const [selectedCategoryId, setSelectedCategoryId,] = useState<{
    categoryId: PartTypeAPI["id"],
    materialId: string,
    subclassId: string,
  } | null>(null)

  const handleAddPart = useAddPart()

  const partData = props.info

  const selectedCategory = categories.find(({ id, }) => id === selectedCategoryId?.categoryId)

  const partsFilteredGroupedByCategory = useMemo(() => {
    if (!partsData) {
      return null
    }
    const partsInCategory = selectedCategoryId
      ? partsData.parts.filter(part =>
          part.categories.includes(selectedCategoryId.categoryId)
          && part.materialId === selectedCategoryId.materialId
          && part.subclassId === selectedCategoryId.subclassId
      )
      : partsData.parts

      const expandReduceToFitInfo = partData.source?.expandReduceToFitInfo
      const correctParts = filteredPartsMissingSomething(partsInCategory, connectionTypes)
      const filteredParts = filterParts(correctParts, filters)

      const filteredMaterials = filtersOptionsMaterials(correctParts , materialsData!, filters)
      const filteredSubclass = filterOptionsSubclass(correctParts, subclassesData!, filters)
      const filteredSizes = filterOptionsSizes(correctParts, Object.values(sizesData!), filters)

      const partsFilteredGrouped
      = recursiveGroupByList(filteredParts,
        [({ materialId, }) => materialId,
        ({ subclassId, }) => subclassId,
        ({ categories, }) => categories,])

    let suggestedPartsFilteredGrouped = undefined
    if (expandReduceToFitInfo?.isApplicable && partsData.suggestedParts) {
      const suggestedFilteredParts = filterParts(partsData.suggestedParts, filters)
      suggestedPartsFilteredGrouped = recursiveGroupByList(suggestedFilteredParts,
        [({ materialId, }) => materialId,
        ({ subclassId, }) => subclassId,
        ({ categories, }) => categories,])
    }

    let incompatibleParts = initialDataValue?.parts?.filter(p => {
      return !partsData?.parts?.find(p2 => p2.id === p.id)
    })

    if(incompatibleParts) {
      incompatibleParts = filteredPartsMissingSomething(incompatibleParts, connectionTypes)
    }

    let incompatiblePartsFilteredGrouped = undefined
    if (incompatibleParts) {
      const incompatiblePartsFiltered = filterParts(incompatibleParts, filters)
      incompatiblePartsFilteredGrouped = recursiveGroupByList(incompatiblePartsFiltered,
        [({ materialId, }) => materialId,
        ({ subclassId, }) => subclassId,
        ({ categories, }) => categories,])
    }

    return {
      partsFilteredGrouped,
      suggestedPartsFilteredGrouped,
      filteredMaterials,
      filteredSubclass,
      filteredSizes,
      incompatiblePartsFilteredGrouped,
    }

  }, [partsData, filters,])

  const hideCategoryParts = () => {
    setSelectedCategoryId(null)
  }

  const handleCategoryPartsOnBack = () => {
    setSelectedCategoryId(null)
  }

  const categoryOnClick = (partsByCategory: GroupedBy<PartTypeAPI>) => {
    setSelectedCategoryId({
      categoryId: partsByCategory.key,
      materialId: partsByCategory.values[0].materialId,
      subclassId: partsByCategory.values[0].subclassId,
    })
  }



  const partOnClick = (part: PartTypeAPI) => {
    const propsToAddPart = {
      part,
      hideCategoryParts,
      source: partData.source,
      clearModal: props.clearModal,
      partsData,
    }
    if (propsToAddPart.source?.swap) {
      const partsToSwap = {
        part: {
          id: part.id,
          friendlyName: part.name,
        },
        partToSwap: {
          id: propsToAddPart.source.swap.partToSwapId,
          friendlyName: part.name,
        },
      }
      breadcrumb({
        message: "Click on new part to swap",
        level: "info",
        data: partsToSwap,
      })
      events.swapPart(eventsData(partsToSwap))
      handleAddPart({
        ...propsToAddPart,
        case: addPartCaseEnum.SWAP,
        swap: propsToAddPart.source.swap,
        part,
      })
    } else if (partData.source) {
      events.addPart(eventsData({
        part: {
          id: part.id,
          friendlyName: part.name,
        },
      }))

      breadcrumb({
        message: "Click on part",
        level: "info",
        data: {
          id: part.id,
          name: part.name,
        },
      })
      handleAddPart({
        ...propsToAddPart,
        case: addPartCaseEnum.ADD_NEW_PART,
        part,
      })
    } else {

      breadcrumb({
        message: "Click on first part to add",
        level: "info",
        data: {
          id: part.id,
          name: part.name,
        },
      })
      handleAddPart({
        ...propsToAddPart,
        case: addPartCaseEnum.INITIAL_PART,
        part,
      })
    }
  }

  const onClose = () => {
    if (selectedCategoryId) {
      const scroll = document.getElementById(scrollContainerId)
      if (scroll) {
        scroll.scrollTop = 0
      }
      handleCategoryPartsOnBack()
    } else {
      hideCategoryParts()
      props.clearModal()
    }
  }

  const getPartsList = (parts: PartTypeAPI[] | PartsListType[], disabled?: boolean) => {
    if (error) {
      return error
    }
    if (parts && parts.length !== 0) {
      if (selectedCategoryId) {
        return (
          <CategoryParts
            parts={parts as PartTypeAPI[]}
            partOnClick={partOnClick}
            disabled={disabled}
          />
        )
      }
      if (categories.length) {
        return (
          <CategoriesAndParts
            categories={categories}
            partOnClick={partOnClick}
            categoryOnClick={categoryOnClick}
            partsGrouped={parts as PartsListType[]}
            disabled={disabled}
          />
        )
      }
    }
    return <Empty />
  }

  const getTitle = () => {
    return selectedCategory ? `All ${selectedCategory.name}` : "All Parts"
  }

  const getExpandReduceDescription = () => {
    if (partData.source
      && partData.source.expandReduceToFitInfo.isApplicable
      && partsFilteredGroupedByCategory?.suggestedPartsFilteredGrouped?.length
    ) {
      return (
        <React.Fragment>
          <StyickyTitle>
            Suggested parts (compatible by both sides)
          </StyickyTitle>
          <DescriptionBodyContainer>
            <DescriptionExpandReduce
              partId={partData.source!.partId}
              category={selectedCategory}
              oppositeId={partData.source.expandReduceToFitInfo.oppositeConnection.partId}
            />
          </DescriptionBodyContainer>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  const getFilters = () => {
    return ( <>
    <Filters
      onChange={setFilters}
      filters={{
        materials: partsFilteredGroupedByCategory?.filteredMaterials!,
        subclasses: partsFilteredGroupedByCategory?.filteredSubclass!,
        sizes: partsFilteredGroupedByCategory?.filteredSizes!,
      }}
    />
    <SearchWrapper>
    <SearchInput
      type="text"
      className="searchInput"
      placeholder="Search..."
      value={userQuery}
      onChange={(e) => setUserQuery(e.target.value)}
    />
    </SearchWrapper>
  </>
    )
  }

  const getDescription = (descriptionCase: DescriptionCase) => {
    if (partData.source) {
      return (
        <Description
          compatibleConnectionsId={partsData?.compatibleConnections || []}
          connectionId={partData.source.markerId}
          sizeId={partData.source.sizeId}
          category={selectedCategory}
          case={descriptionCase}
        />
      )
    }
    return null
  }

  const getPartsFiltered = (partsGrouped: PartsListType[]) => {
    if (selectedCategoryId && partsGrouped) {
      const getValues = (list: PartsListType[], key: string) => {
        return list.find(p => p.key === key)?.values || []
      }

      const materialParts
        = getValues(partsGrouped, selectedCategoryId.materialId) as PartsListType[]
      const subclassParts
        = getValues(materialParts, selectedCategoryId.subclassId) as PartsListType[]
      const categoryParts
        = getValues(subclassParts, selectedCategoryId.categoryId) as PartTypeAPI[] || []

      return ApiClient.removeDuplicatedParts(categoryParts)
    }
    return partsGrouped
  }

  const getContent = () => {
    const parts = getPartsFiltered(partsFilteredGroupedByCategory?.partsFilteredGrouped as PartsListType[])
    const suggestedParts
      = getPartsFiltered(partsFilteredGroupedByCategory?.suggestedPartsFilteredGrouped as PartsListType[])

    const isApplicableAndAreSuggestedParts = partData.source?.expandReduceToFitInfo.isApplicable
      && partsFilteredGroupedByCategory?.suggestedPartsFilteredGrouped?.length
      && (!selectedCategoryId || suggestedParts.length > 0)

    if (isApplicableAndAreSuggestedParts) {
      return <>
        {getExpandReduceDescription()}
        {getSuggestedPartsList(parts, suggestedParts)}
      </>
    } else {
      return <>
        {getDescription(!!partData.source?.swap
          ? DescriptionCase.SWAP
          : DescriptionCase.INITIAL)}
        {getPartsList(parts)}
      </>
    }
  }

  const getSuggestedPartsList = (
    parts: PartTypeAPI[] | PartsListType[],
    suggestedparts: PartTypeAPI[] | PartsListType[]
  ) => {
    if (partData.source
      && partData.source.expandReduceToFitInfo.isApplicable
      && partsData?.suggestedParts
    ) {
      return (
        <React.Fragment>
          {getPartsList(suggestedparts)}
          {(!selectedCategoryId || parts.length > 0) && <>
            <StyickyTitle>
              Other parts
            </StyickyTitle>
            <DescriptionBodyContainer>
              {getDescription(!!partData.source?.swap
                ? DescriptionCase.SWAP
                : DescriptionCase.INITIAL)}
            </DescriptionBodyContainer>
            {getPartsList(parts)}
          </>}
        </React.Fragment>
      )
    } else if (partData.source) {
      return (
        <>
          <br />
          <DescriptionBodyContainer>
            {getDescription(!!partData.source?.swap
              ? DescriptionCase.SWAP
              : DescriptionCase.INITIAL)}
          </DescriptionBodyContainer>
        </>
      )
    }
  }

  const getIncompatiblePartsList = () => {
    if (partData.source) {
      const incompatibleParts
        = getPartsFiltered(partsFilteredGroupedByCategory?.incompatiblePartsFilteredGrouped as PartsListType[])
      return (
        <>
          {getDescription(DescriptionCase.INCOMPATIBLE)}
          {incompatibleParts && getPartsList(incompatibleParts, true)}
        </>
      )
    }
  }

  const defaultTabs = {
    compatibleParts: {
      name: "Compatible Parts",
      render: () => <>
        {getContent()}
      </>,
      onClick: selectedCategoryId ? handleCategoryPartsOnBack : undefined,
    },
    incompatibleParts: {
      name: "Incompatible Parts",
      render: () => <>
        {getIncompatiblePartsList()}
      </>,
      onClick: selectedCategoryId ? handleCategoryPartsOnBack : undefined,
    },
  }
  return <PartsDrawer
    selectedCategoryId={selectedCategoryId?.categoryId}
    onClose={onClose}
    title={getTitle()}
    filters={getFilters()}
    defaultTabs={defaultTabs}
    isFirstPart={!partData.source}
    isLoading={!partsData?.parts}
  />
}

const PartModalWrapper = () => {
  const [partModal, setPartModal,] = useRecoilState(addPartModal)
  const resetConnectionModal = useResetRecoilState(renderConnectionsData)
  const setLayout = useSetRecoilState(layoutState)
  const [userQuery, setUserQuery,] = useRecoilState(userQueryState)
  const [sizeIgnoreSizeCompatibility, setSizeIgnoreSizeCompatibility,] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const fetchSizeIgnore = async () => {
      if (partModal?.step1.source) {
        //console.log("source marker", partModal.step1.source.markerId)
        const sizeIgnore = await ApiClient.getSizeIgnore(partModal.step1.source.markerId)
        //console.log("size ignore results", sizeIgnore)
        setSizeIgnoreSizeCompatibility(sizeIgnore ?? undefined)
      }
    }

    fetchSizeIgnore()
  }, [partModal,])

  const clearModal = () => {
    if (partModal?.step1.source?.swap) {
      partModal?.step1.source?.swap.makeVisible()
    }
    setPartModal(null)
    resetConnectionModal()
    setLayout(produce((draft) => {
      draft.isDrawerOpen = false
    }))
  }

  const backModal = () => {
    setPartModal({ step1: partModal!.step1, })
    resetConnectionModal()
  }

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    setUserQuery("")
    if (partModal !== null) {
      setLayout(produce((draft) => {
        draft.isDrawerOpen = true
      }))
    } else {
      setLayout(produce((draft) => {
        draft.isDrawerOpen = false
      }))
    }
  }, [partModal,])



  if (!partModal) {
    return <></>
  } else if (partModal.step2) {
    if (!partModal.step1.source) {
      throw new Error("No source part")
    }
    return <PartModalChooseNewPartConnector
      {...partModal.step2}
      source={partModal.step1.source}
      clearModal={clearModal}
      onBack={backModal}
      sizeIgnoreSizeCompatibility={sizeIgnoreSizeCompatibility}
    />
  } else {
    return <PartModalChoosePart
      info={partModal.step1}
      clearModal={clearModal}
      onBack={backModal}
      userQuery=""
    />
  }
}

export default PartModalWrapper