import { useRecoilCallback } from "recoil"
import { authState } from "../../../common/state/LoginState/RecoilAuthState"
import { partsSelector, scenePartsSelector } from "./atoms"

export const useGetPart = () => {
  return useRecoilCallback(
    ({ snapshot, }) => {
      return (partId: string) => {
        return snapshot.getLoadable(partsSelector({ id: partId, })).getValue()
      }
    }
  )
}

export const useGetPartsLength = () => {
  return useRecoilCallback(
    ({ snapshot, }) => () => {
      return Object.keys(snapshot.getLoadable(scenePartsSelector).getValue()).length
    }
  )
}

export const useGetUserData = () => {
  return useRecoilCallback(
    ({snapshot, }) =>  () => snapshot.getLoadable(authState).getValue().user
  )
}