import { useSetRecoilState } from "recoil"
import { PartTypeAPI } from "../../../../../../../../common/api/Types"
import { addPartModal } from "../../../../../../../state/atoms"
import { useSwapPart } from "../../../../../../../state/scene/setters"
import { AddPartState } from "../../../../../../../state/types"
import { CommonProps, SwapProps } from "../AddPart"

export const useHandleSwapPart = () => {
    const setPartModal = useSetRecoilState(addPartModal)
    const swapPart = useSwapPart()

    const getInitialConnectionForSwap = (
        swap: NonNullable<NonNullable<AddPartState["step1"]["source"]>["swap"]>,
        connections: PartTypeAPI["connections"]
    ) => {
        if(swap.connectionBTypeId) {
            return connections.find(c => c.oppositeOf)!
        } else {
            return connections[0]
        }
    }
    return (props: CommonProps & SwapProps) => {
        if(props.swap.connectionBTypeId || props.part.tube) {
            const initialConnection
            = getInitialConnectionForSwap(props.swap, props.part.connections)

            const expandReduce = props.swap.oppositeTubeId
            ? {
                partId: props.swap.oppositeTubeId,
                length: props.swap.actualLength
                - initialConnection.fullLeg + initialConnection.iELength,
            } : undefined

            swapPart(
                props.part,
                initialConnection.placeholderId,
                props.source!,
                expandReduce,
                props.source?.connectionLength,
            )
            props.clearModal()
        } else {
            props.source!.swap?.makeInvisible()
            setPartModal(p => ({
                ...p!,
                step2: {
                    compatibleConnections: props.partsData?.compatibleConnections!,
                    partToAdd: props.part,
                    sizeId: props.source!.sizeId!,
                },
            }))
        }
    }
}