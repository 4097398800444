import React, { useRef } from "react"
import {
    InputGroup,
    InputRow,
    Input,
    UnitLabel,
} from "./SegmentedSliderStyles"

interface Props {
    offsetX: number;
    offsetY: number;
    onOffsetXChange: (value: number, shouldTriggerAlignment: boolean) => void;
    onOffsetYChange: (value: number, shouldTriggerAlignment: boolean) => void;
    unit: "cm" | "in";
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

const OffsetControls: React.FC<Props> = ({
    offsetX,
    offsetY,
    onOffsetXChange,
    onOffsetYChange,
    unit = "in",
    onKeyDown,
}) => {
    const unitLabel = unit === "cm" ? "cm" : "in"
    const isSpinnerClick = useRef(false)
    const [localOffsetX, setLocalOffsetX,] = React.useState(offsetX.toString())
    const [localOffsetY, setLocalOffsetY,] = React.useState(offsetY.toString())

    React.useEffect(() => {
        setLocalOffsetX(offsetX.toString())
    }, [offsetX,])

    React.useEffect(() => {
        setLocalOffsetY(offsetY.toString())
    }, [offsetY,])

    const handleKeyDown = (
        e: React.KeyboardEvent,
        handler: (value: number, shouldTriggerAlignment: boolean) => void,
        currentValue: number
    ) => {
        if (onKeyDown) {
            onKeyDown(e)
        }

        if (e.key === "ArrowUp") {
            handler(currentValue + 1, true)
            e.preventDefault()
        } else if (e.key === "ArrowDown") {
            handler(currentValue - 1, true)
            e.preventDefault()
        }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.currentTarget
        const rect = input.getBoundingClientRect()
        const spinnerWidth = 20
        isSpinnerClick.current = e.clientX > rect.right - spinnerWidth
    }

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setter: (value: string) => void,
        handler: (value: number, shouldTriggerAlignment: boolean) => void
    ) => {
        const inputValue = e.target.value
        setter(inputValue)

        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
            return
        }

        const numericValue = parseFloat(inputValue)
        handler(numericValue, isSpinnerClick.current)
        isSpinnerClick.current = false
    }

    return (
        <InputGroup>
            <InputRow>
                <Input
                    type="number"
                    value={localOffsetX}
                    onChange={(e) => handleChange(e, setLocalOffsetX, onOffsetXChange)}
                    onKeyDown={(e) => handleKeyDown(e, onOffsetXChange, offsetX)}
                    onMouseDown={handleMouseDown}
                    step="1"
                />
                <UnitLabel>{unitLabel}</UnitLabel>
            </InputRow>
            <InputRow>
                <Input
                    type="number"
                    value={localOffsetY}
                    onChange={(e) => handleChange(e, setLocalOffsetY, onOffsetYChange)}
                    onKeyDown={(e) => handleKeyDown(e, onOffsetYChange, offsetY)}
                    onMouseDown={handleMouseDown}
                    step="1"
                />
                <UnitLabel>{unitLabel}</UnitLabel>
            </InputRow>
        </InputGroup>
    )
}

export default OffsetControls