import { useLoader } from "@react-three/fiber"
import { TextureLoader, RepeatWrapping } from "three"
import moon from "../../../../../../../assets/moonish-matcap.png"

const useMoonTexture = (u: number, v: number) => {
    const moonTexture = useLoader(TextureLoader, moon)
    moonTexture.wrapS = RepeatWrapping
    moonTexture.wrapT = RepeatWrapping
    moonTexture.repeat.set(u, v)

    return { moonTexture, }
}

export default useMoonTexture