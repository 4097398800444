import { useRecoilValue } from "recoil"
import { partsSelector } from "../../../../../../../../state/scene/atoms"
import { useUpdateTube } from "../../../../../../../../state/scene/setters"
import { TubePart, } from "../../../../../../../../utils/Types"

const useTube = (id: string) => {
    const tubeState = useRecoilValue(partsSelector({ id, }))
    const tube = tubeState as TubePart
    const updateTubeValues = useUpdateTube()

    return {
        tube,
        updateTubeValues,
    }
}

export default useTube