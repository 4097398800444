import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useState } from "react"
import {
    LeftContent,
    LeftRightContainer,
    ButtonContainer,
    RightContent,
    SliderContainer
} from "./ActionPanelStyles"
import Button from "../../../../../../../../../common/components/Button"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import DirectionSelector from "./DirectionSelector"
import NumberSlider from "../../sliders/Slider"
import { convertInToCm } from "../../../../../utils/UnitUtils"
import Dropdown from "antd/es/dropdown"
import Select from "antd/es/select"

interface Props {
    value: number;
    min: number;
    max: number;
    validMin: number;
    validMax: number;
    step: number;
    unit: string;
    updateUnit: (unit: string) => void;
    onMouseUp: (value: number) => void;
    onChange: (value: number) => void;
    onMouseDown: (value: number) => void;
    onInputChange: (value: number) => void;
    realWidth: number | undefined;
    realHeight: number | undefined;
    heightScaling: boolean | undefined;
    widthScaling: boolean | undefined;
    valueDirect: number;
    onFinishEditing: () => void;
    scalingDimension: "length" | "width" | "height";
    setScalingDimension: (dimension: "length" | "width" | "height") => void;
    setDirection: (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => void;
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>;
}

const SegmentedTubeLengthSlider = (props: Props) => {
    const [length, setLength,] = useState(props.value)
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)


    //update the value when scaling dimension updates
    useEffect(() => {
        setLength(props.valueDirect)
    }, [props.scalingDimension, props.valueDirect,])

    const updateValue = useCallback((newValue: number, useInputChange = false) => {
        setLength(newValue)
        if (!useInputChange) {
            props.onChange(newValue)
        }
        if (useInputChange) {
            props.onInputChange(newValue)
        }
    }, [props,])


    const debouncedUpdate = useCallback((newValue: number,
        forceImmediateUpdate = false) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current)
        }
        //I put 1 second because people complaining that it updates too faster
        //added the enter pressing to account for it
        debounceTimeoutRef.current = setTimeout(() => {
            updateValue(newValue, forceImmediateUpdate)
        }, 1000)
    }, [updateValue,])

    const handleOnChange = useCallback((value: number, debounce = false,
        forceImmediateUpdate = false) => {

        let validMinAdjusted = props.validMin
        let validMaxAdjusted = props.validMax

        if (props.unit === "cm") {
            validMinAdjusted = convertInToCm(validMinAdjusted)
            validMaxAdjusted = convertInToCm(validMaxAdjusted)
        }

        //debounced version was only used when user was typing. so now the user has to press Enter

        if (value >= validMaxAdjusted) {
            if (debounce) {
                //debouncedUpdate(validMaxAdjusted, forceImmediateUpdate)
            } else {
                updateValue(validMaxAdjusted, forceImmediateUpdate)
            }
        } else if (value <= validMinAdjusted) {
            if (debounce) {
                //debouncedUpdate(validMinAdjusted, forceImmediateUpdate)
            } else {
                updateValue(validMinAdjusted, forceImmediateUpdate)
            }
        } else if (debounce) {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
                debounceTimeoutRef.current = null
            }
            //debouncedUpdate(value, forceImmediateUpdate)
        } else {
            updateValue(value, forceImmediateUpdate)
        }
    }, [updateValue, props.validMax, props.validMin,])

    const dimensionOptions = useMemo(() => {
        const options = [{ value: "length", label: "Length", },]
        if (props.widthScaling) {
            options.push({ value: "width", label: "Width", })
        }
        if (props.heightScaling) {
            options.push({ value: "height", label: "Height", })
        }
        return options
    }, [props.widthScaling, props.heightScaling,])

    return <SliderContainer>
        <LeftRightContainer>
            <LeftContent>
                <NumberSlider
                    unit={props.unit}
                    updateUnit={props.updateUnit}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    handleMouseUp={props.onMouseUp}
                    onChange={handleOnChange}
                    onInputChange={props.onInputChange}
                    getValue={() => length}
                    inputNumber={true}
                    handleMouseDown={props.onMouseDown}
                    disabledTyping={false}
                />
            </LeftContent>
            {dimensionOptions.length > 1 && (
                <RightContent>
                    <Select
                        options={dimensionOptions}
                        value={props.scalingDimension}
                        onChange={props.setScalingDimension}
                        style={{ width: 88, }}
                    />
                </RightContent>
            )}
        </LeftRightContainer>
    </SliderContainer>
}

export default SegmentedTubeLengthSlider