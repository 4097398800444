import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { pendingChangesAtom } from "../../../../state/atoms"

const CloseingTabLogic = () => {
    const pendingChanges = useRecoilValue(pendingChangesAtom)

    useEffect(() => {
        /* Prevent close tab */
        const unloadCallback = (event: BeforeUnloadEvent) => {
            window.removeEventListener("beforeunload", (event) =>
                unloadCallback(event))
            if (pendingChanges) {
                event.preventDefault()
                event.returnValue = ""
                return ""
            }
        }
        window.removeEventListener("beforeunload", unloadCallback)
        if (pendingChanges) {
            window.addEventListener( "beforeunload", unloadCallback)
        }

        return () => window.removeEventListener("beforeunload", unloadCallback)
    }, [pendingChanges,])

    return null
}

export default CloseingTabLogic