import React from "react"
import { Typography } from "antd"
import styled from "styled-components"

export const NoPartsInScene = () => {
  return (
    <Wrapper>
      <Text>
        There are no parts in the scene. Start your new design by clicking the + button
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 16px 8px;
`

const Text = styled(Typography.Text)`
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`