import { useGLTF } from "@react-three/drei"
import { Group, Mesh } from "three"
import useCollision from "../../../../providers/collisionProvider/useCollision"
import React, { useEffect } from "react"
import useInstancedMesh from "../../../../providers/instancedMesh/useInstancedMesh"
import { PartTypeEnum } from "../../../../utils/Types"

interface Props {
    id: string;
}

const ModelTest = (props: Props) => {

    const handleConnectorClick = () => {

    }

    const onCollide = () => {
        // console.log(ids)
    }

    const getConnectedParts = () => {
        return []
    }

    // eslint-disable-next-line max-len
    const glft = useGLTF("https://firebasestorage.googleapis.com/v0/b/tubedesigner.appspot.com/o/Models%2FValve.glb?alt=media&token=961f022f-a021-466b-83c2-839e3ce40780")
    const {
        ref,
        updateTransforms,
        instancedMesh, } = useInstancedMesh(
            ((glft as any).nodes.Valve as Mesh),
            props.id,
            "test_model",
            handleConnectorClick
        )
    const {
        updateCollider,
        buildCollider, } = useCollision(
            props.id,
            ref,
            instancedMesh,
            onCollide,
            PartTypeEnum.connector,
        )


    useEffect(() => {
        ref.current!.position.set(Math.random() * 2, Math.random() * 2, Math.random() * 2)
        updateTransforms()
        buildCollider()
    }, [])

    return <group ref={(r) => { ref.current = r as Group }} />
}

export default ModelTest