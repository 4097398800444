import React from "react"
import { Html } from "@react-three/drei"
import styled from "styled-components"
import { Vector3 } from "three"
import { createGlobalStyle } from "styled-components"

type Props = {
    position: Vector3,
    text: string,
}

const GlobalStyle = createGlobalStyle`
  .html-wrapper {
    pointer-events: none;
  }
`

function TextLabel({ position, text, }: Props) {
    return (
        <>
            <GlobalStyle />
            <Html
                wrapperClass="html-wrapper"
                zIndexRange={[0, 0,]}
                position={position}
                className="overlay"
                center
                pointerEvents="none">
                <Label className="ui-element">
                    <p>{text}</p>
                </Label>
            </Html>
        </>
    )
}

const Label = styled.div`
    box-sizing: border-box;
    width: auto;
    min-width: 47px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 6.2px rgba(0, 0, 0, 0.05);
    border-radius: 9px;

    p {
        white-space: nowrap;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        padding: 0 5px;
        font-size: 12px;
        color: #6D6D6D;
        margin: 0;
        pointer-events: none;
    }

    * {
        pointer-events: none;
    }
`

export default TextLabel
