import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
`

export const Header = styled.div<{ noBorderBottom?: boolean, }>`
    display: flex;
    flex-direction: column;
    ${({ noBorderBottom, }) => (noBorderBottom ? null : "border-bottom: 1px solid #d4d4d4;")}
    padding: 0px;
    padding-bottom: 16px;
    gap: 8px;
`

export const Main = styled.div<{ flexEnd?: boolean, }>`
    display: flex;
    justify-content: ${({ flexEnd, }) => (flexEnd ? "flex-end" : "space-between")};
    align-items: center;
    flex-direction: row;
    padding: 16px 16px 0px 16px;
`

export const Extra = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 0px 16px 0px 16px;
`

export const ActionSlot = styled.div`
    min-width: 50px;
`

export const Title = styled.div`
    font-weight: regular;
    font-size: 20px;
`

export const Content = styled.div`
    overflow-y: scroll;
    height: 100%;
`

export const ListGroupWrapper = styled.div`
    border-top: 1px solid #00000012;
    padding: 20px 0;
`

export const ListGroupTitle = styled.div`
    font-weight: regular;
    padding-left: 16px;
    font-size: 16px;
    display: flex;
`

export const MaterialAdditionalInfo = styled.span`
    vertical-align:middle;
    margin-left:10px;
`

export const ListGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 8px;
    padding: 8px;
`

export const StyickyTitle = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    border-bottom: 1px solid #00000021;
    box-shadow: 0 -1px #00000021;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const DescriptionBodyContainer = styled.div`
    padding: 16px;
`

export const BoldText = styled.b``

export const LinkText = styled.a`
    color: #6D6D6D;
    font-weight: 700;
    text-decoration: underline;
    padding-left: 5px;
    padding-right: 3px;
    &:hover {
        color: #6D6D6D;
    }
`

export const CloseButton = styled.button`
    background: transparent;
    text-transform: uppercase;
    border: none;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    color: #A4A4A4;
    &:hover {
        color: #3B86F7;
    }
`


export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`