import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import lottie, { AnimationConfigWithPath, AnimationItem } from "lottie-web"
import { useIsMobile } from "../../../../../common/utils/useIsMobile"
import { useRecoilValue } from "recoil"
import { addPartModal } from "../../../../state/atoms"
import { events, useEventsData } from "../../../../../common/utils/rudderAnalyticsUtils"
import { breadcrumb } from "../../../../../common/utils/sentrySetup"

const getAnimationData = (container: AnimationConfigWithPath["container"]) => ({
    container,
    renderer: "svg",
    loop: false,
    autoplay: false,
    path: "https://tubedesigner.s3.amazonaws.com/Amigo-Bulb-Aninix.json",
} as AnimationConfigWithPath)
interface Props {
    onClick: () => void;
}

const StartButton = (props: Props) => {
    const animationRef = useRef<AnimationItem | null>(null)
    const addPartModalState = useRecoilValue(addPartModal)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const isMobile = useIsMobile()
    const eventsData = useEventsData()

    const lightOnAnimation = () => {
        animationRef.current!.setDirection(1)
        animationRef.current!.play()
    }

    const lightOffAnimation = () => {
        animationRef.current!.setDirection(-1)
        animationRef.current!.play()
    }

    const handleOnClick = () => {
        if (!isMobile) {
            breadcrumb({
                message: "Click on start button",
                level: "info",
            })
            props.onClick()
            events.clickStartCreating(eventsData())
            setTimeout(() => {
                lightOnAnimation()
            }, 10)
        }
    }

    const onMouseEnter = () => {
        lightOnAnimation()
    }

    const onMouseLeave = () => {
        lightOffAnimation()
    }

    const onComplete = () => {
        if (isMobile && animationRef.current!.playDirection === 1) {
            breadcrumb({
                message: "Click on start button",
                level: "info",
            })
            props.onClick()
        }
    }

    useEffect(() => {
        const { current: container, } = containerRef
        if (container) {
            animationRef.current = lottie.loadAnimation(getAnimationData(container))
            container.addEventListener("mouseenter", onMouseEnter)
            container.addEventListener("mouseleave", onMouseLeave)
            animationRef.current.addEventListener("complete", onComplete)
        }

        return () => {
            if (animationRef.current && container) {
                container.removeEventListener("mouseenter", onMouseEnter)
                container.removeEventListener("mouseleave", onMouseLeave)
                animationRef.current.removeEventListener("complete", onComplete)
                animationRef.current.destroy()
            }
        }
    }, [])

    useEffect(() => {
        if (!addPartModalState) {
            lightOffAnimation()
        }
    }, [addPartModalState,])

    return <Container isMobile={isMobile} ref={containerRef} onClick={handleOnClick} />
}

const Container = styled.div<{ isMobile: boolean, }>`
    height: ${({ isMobile, }) => (isMobile ? "45%" : "80%")};
    max-height: 400px;
    cursor: pointer;
    padding-left: ${({ isMobile, }) => (isMobile ? "8vw" : "0vw")};
    svg {
        transform: unset !important;
    }
`

export default StartButton