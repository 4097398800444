import React, { FC } from "react"
import Card from "../../../../../../../common/components/Card"
import { useRecoilValue } from "recoil"
import { PartTypeAPI } from "../../../../../../../common/api/Types"
import { GroupByList } from "../../../../../../../common/utils/utils"
import { connectionTypesSelector } from "../../../../../../state/initialDataSelectors"
import { Text } from "../../../../../../../common/components/Card"
import useGetDebugVariables from "../../../utils/useGetDebugVariables"

export interface PartCardProps extends PartTypeAPI {
  onClick: () => void;
  disabled?: boolean;
}

const PartCard: FC<PartCardProps> = (props: PartCardProps) => {
  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const connectionsGroupedById = GroupByList(
    props.connections,
    ({ connectorId, }) => connectorId
  )
  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin
  const connectionInfo = connectionsGroupedById.map(conn => {
    const connectionTypeName = connectionTypes[conn.values[0].connectorId].connectionName
    const firstConnection = connectionsGroupedById[0].values[0]
    const sizeName = firstConnection.size.hideName ? "" : firstConnection.size.friendlyName
    const amount = connectionsGroupedById[0].values.length
    return `${sizeName} ${connectionTypeName} X${amount}`
  })

  const text = (
    <React.Fragment>
      <Text title={props.name} bold>{props.name}</Text>
      {connectionInfo.map((connInfo, i) => {
        return <Text title={connInfo} key={i} >{connInfo}</Text>
      })}
    </React.Fragment>
  )

  const getTooltipText = () => {
    const title = props.name
    return `${title}\n${connectionInfo.join("\n")}`
  }

  if (props.hideFromSidebar && !isAdmin) {
    return null
  }

  return (
    <Card
      text={text}
      onClick={props.onClick}
      imageSrc={props.modelIcon}
      disabled={props.disabled}
      furtherInfo={props.furtherInfo}
      tooltipText={getTooltipText()}
      highlight={(isAdmin && props.hideFromSidebar) || false}
    />
  )
}

export default PartCard
