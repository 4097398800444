import React, { useRef } from "react"
import { createContext, useMemo } from "react"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

interface Props {
    children: React.ReactNode;
}

export const GLTFContext = createContext<undefined | {
    loader: GLTFLoader, modelsCache: React.MutableRefObject<ModelCahce[] | undefined>,
}>(undefined)

export type ModelCahce = {
    path: string,
    gltf: GLTF,
}

const GLTFLoaderProvider = (props: Props) => {
    const modelsCache = useRef<ModelCahce[]>([])
    const loader = useMemo(() => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.5.5/")
        const loader = new GLTFLoader();
        (loader as GLTFLoader).setDRACOLoader(dracoLoader)
        return loader
    }, [])

    return <GLTFContext.Provider value={{ loader, modelsCache, }}>
        {props.children}
    </GLTFContext.Provider>
}

export default GLTFLoaderProvider