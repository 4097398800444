import { Line3, Mesh, Vector3 } from "three"
import { MeshUtils } from "../../../../utils/MeshUtils"
import { roundNumber } from "../../../../utils/utils"

const isSameDirection = (a: Vector3, b: Vector3) => {
    return Number(roundNumber(a.dot(b), 3)) === 1
}

const isParallel = (a: Vector3, b: Vector3) => {
    const dotProduct = roundNumber(a.dot(b), 3)
    if (Number(dotProduct) === 1) {
        //parallel in the same direction
        return true
    }

    if (Number(dotProduct) === -1) {
        //parallel in different direction
        return true
    }

    return false
}


const isPerpendicular = (plane: Mesh | null, plane2: Mesh | null) => {
    if (plane && plane2) {

        const a = MeshUtils.copyWorldDirection(plane)
        const b = MeshUtils.copyWorldDirection(plane2)

        if (roundNumber(a.dot(b), 2) === 0) {
            return true
        }

        return false
    }
}

const isCollinealAndInFrontOf = (
    aDir: Vector3 | null,
    aPos: Vector3 | null,
    bDir: Vector3 | null,
    bPos: Vector3 | null
) => {
    if (aDir && aPos && bDir && bPos) {
        const distance = 100 // at what distance to determine pointB)

        const pointAux = new Vector3()
        const bAux = new Vector3(bDir.x, bDir.y, bDir.z)
        pointAux.addVectors(bPos, bAux.multiplyScalar(distance))

        const line = new Line3(bPos, pointAux)

        const result = new Vector3()
        line.closestPointToPoint(aPos, true, result)
        const distResult = result.distanceTo(aPos)

        if (
            roundNumber(distResult, 2) === 0
            && isParallel(aDir, bDir)
        ) {
            return true
        }
    }

    return false
}

//Collinear
const isCollineal = (
    aDir: Vector3 | null,
    aPos: Vector3 | null,
    bDir: Vector3 | null,
    bPos: Vector3 | null
) => {
    const aDirNeg = aDir ? new Vector3(aDir.x, aDir.y, aDir.z).negate() : null
    const bDirNeg = bDir ? new Vector3(bDir.x, bDir.y, bDir.z).negate() : null
    return isCollinealAndInFrontOf(aDir, aPos, bDir, bPos)
        || isCollinealAndInFrontOf(aDirNeg, aPos, bDirNeg, bPos)
}

// Transform one range to another
const scale = (
    numberToScale: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number
) => {
    return (numberToScale - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
}

const metersToInch = 39.37

export {
    isSameDirection,
    isParallel,
    isPerpendicular,
    isCollineal,
    scale,
    metersToInch,
}