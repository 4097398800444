import {
  Mesh,
  DoubleSide,
  MeshMatcapMaterial,
  PlaneGeometry,
  Quaternion,
  Vector3,
  Scene} from "three"
import { roundNumber } from "../../../../../../../utils/utils"

type Markers = {
  innerMarker: Mesh,
  outerMarker: Mesh,
}

export const getInitialRotationConnector = (markers: Markers, scene: Scene) => {
  const innerMarkerDirection = new Vector3()
  const outerMarkerDirection = new Vector3()

  markers.innerMarker.getWorldDirection(innerMarkerDirection)
  markers.outerMarker.getWorldDirection(outerMarkerDirection)

  const [inner, outer,]
    = getRotations([innerMarkerDirection, outerMarkerDirection,], scene)

  return {
    innerMarkerRotation: inner,
    outerMarkerRotation: outer,
  }
}

export const getInitialRotationTube = (tracker: Mesh, scene: Scene) => {
  const trackerDirection = new Vector3()
  tracker.getWorldDirection(trackerDirection)

  const [trackerRotation,] = getRotations([trackerDirection,], scene)

  return trackerRotation
}

const getRotations = (postions: Vector3[], scene: Scene) => {
  const planeGeom = new PlaneGeometry(0.1, 0.1)
  const planeMat = new MeshMatcapMaterial({ side: DoubleSide, color: "red", })
  const planeAux = new Mesh(planeGeom, planeMat)

  scene.add(planeAux)
  const rotations = postions.map(position => {
    planeAux.lookAt(new Vector3(
      roundNumber(position.normalize().x, 2),
      roundNumber(position.normalize().y, 2),
      roundNumber(position.normalize().z, 2),
    ))

    const rotation = new Quaternion()
    planeAux.getWorldQuaternion(rotation)

    return rotation
  })

  scene.remove(planeAux)
  return rotations
}