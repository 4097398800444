import { useSetRecoilState } from "recoil"
import { addPartModal } from "../../../../../../../state/atoms"
import { CommonProps } from "../AddPart"

export const useAddConnector = () => {
    const setPartModal = useSetRecoilState(addPartModal)
    return (props: CommonProps) => {
        setPartModal(p => ({
            ...p!,
            step2: {
                compatibleConnections: props.partsData!.compatibleConnections!,
                partToAdd: props.part,
                sizeId: props.source?.sizeId!,
            },
        }))
    }
}