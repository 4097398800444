/* eslint-disable max-lines-per-function */
import React from "react"
import { BottomSheetStyled } from "./BottomSheetStyles"
import { ReactComponent as CloseIcon } from "../../assets/icons/Cross.svg"
import ReactDOM from "react-dom"
import styled from "styled-components"

const bottomSheetLayer = "bottom-sheet-layer"

const Portal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    pointer-events: none;
`

const tooltipLayer = "tooltip-layer"

export const TooltipPortal = () => {
    return <Portal id={tooltipLayer} />
}

export const BottomSheetPortal = () => {
    return <BottomSheetStyled.Portal id={bottomSheetLayer} />
}

type BottomSheetProps = {
    children: JSX.Element,
    isOpen: boolean,
    onClose: (e: React.MouseEvent<unknown, MouseEvent>) => void,
    title?: string,
};

export const BottomSheet: React.FC<BottomSheetProps> = (props: BottomSheetProps) => {

    const overlay = document.getElementById(bottomSheetLayer)

    const handleOnClick = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation()
    }

    return (
        <>
            {overlay && ReactDOM.createPortal(
                <BottomSheetStyled.Container
                    aria-modal="true"
                    role="dialog"
                    aria-labelledby="BottomSheet-title"
                >
                    <BottomSheetStyled.OuterContent onClick={props.onClose} />
                    <BottomSheetStyled.AnimatedSheet onClick={handleOnClick}>
                        <BottomSheetStyled.Header>
                            <BottomSheetStyled.Title> {props.title} </BottomSheetStyled.Title>
                            <CloseIcon onClick={props.onClose} />
                        </BottomSheetStyled.Header>
                        <BottomSheetStyled.Content>
                            {props.children}
                        </BottomSheetStyled.Content>
                    </BottomSheetStyled.AnimatedSheet>
                </BottomSheetStyled.Container>,
                overlay
            )}
        </>
    )
}