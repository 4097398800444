import React from "react"
import ActionButton from "../../../common/components/ActionButton"
import { ActionButtonContainer } from
"../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/ActionPanelStyles"
import { ReactComponent as TrashIcon }
from "../../../common/assets/icons/Trash.svg"

interface Props {
activeTab: "move" | "rotate" | "duplicate" | "delete" | null;
onMoveClick: () => void;
onRotateClick: () => void;
onDuplicateClick: () => void;
onDeleteClick: () => void;
onClearClick: () => void;
}

const ActionsUI: React.FC<Props> = ({
  activeTab,
  onMoveClick,
  onRotateClick,
  onDuplicateClick,
  onClearClick,
  onDeleteClick,
}) => {
  return (
    <ActionButtonContainer>
      <ActionButton selected={activeTab === "move"} onClick={onMoveClick}>
        Move
      </ActionButton>
      <ActionButton selected={activeTab === "rotate"} onClick={onRotateClick}>
        Rotate
      </ActionButton>
      <ActionButton onClick={onDuplicateClick}>2x</ActionButton>
      <ActionButton onClick={onClearClick}>Deselect</ActionButton>
      <ActionButton onClick={onDeleteClick} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        <TrashIcon />
      </ActionButton>
    </ActionButtonContainer>
  )
}

export default ActionsUI