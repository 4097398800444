/* eslint-disable max-len */
import React, { createContext, useRef } from "react"
import { Mesh } from "three"


interface Props {
    debug: boolean;
    children: React.ReactNode;
}

export type Shape = {
    id: string,
    mesh: Mesh,
}

export type CollisionTuple = {
    [id: string]: {
        collisions: string[],
        update: () => void,
    },
}


export type CollisionContextType = {
    registerShape: (id: string, mesh: Mesh) => void,
    unregisterShape: (id: string) => void,
    getShapes: (id: string) => Shape[],
    isDebug: () => boolean,
    registerCollisionUpdate: (originId: string, update: () => void) => void,
    pushCollision: (originId: string, collisionsIds: string[]) => void,
    getPreviousCollisions: (originId: string) => string[],
    update: (id: string, ids: string[]) => void,
    printActiveCollisions: () => void,
} | undefined

export const collisionContext = createContext<CollisionContextType>(undefined)

const CollisionsProvider: React.FC<Props> = ({ children, debug, }) => {
    const shapes = useRef<Shape[]>([])
    const activeCollisions = useRef<CollisionTuple>({})

    const registerShape = (id: string, shape: Mesh) => {
        shapes.current.push({
            id, mesh: shape,
        })
    }

    const registerCollisionUpdate = (originId: string, update: () => void) => {
        activeCollisions.current[originId] = {
            collisions: [],
            update,
        }
    }

    const pushCollision = (originId: string, collisionsIds: string[]) => {
        activeCollisions.current[originId].collisions = []
        activeCollisions.current[originId].collisions = collisionsIds
        collisionsIds.forEach((id) => {
            activeCollisions.current[id].collisions.push(originId)
            activeCollisions.current[id].update()
        })
    }

    const getPreviousCollisions = (originId: string) => {
        return activeCollisions.current[originId].collisions
    }

    const update = (idd: string, ids: string[]) => {
        ids.forEach((id) => {
            const actv = activeCollisions.current[id].collisions
            activeCollisions.current[id].collisions = actv.filter((c) => c !== idd)
            activeCollisions.current[id].update()
        })
    }

    const printActiveCollisions = () => {
        console.log(activeCollisions.current)
    }

    const unregisterShape = (id: string) => {
        const index = shapes.current.findIndex((sh) => sh.id === id)
        shapes.current.splice(index, 1)
    }

    const getShapes = (id: string) => {
        return shapes.current.filter((sh) => sh.id !== id)
    }

    const isDebug = () => {
        return debug
    }

    const init = () => {
        return {
            registerShape,
            unregisterShape,
            getShapes,
            isDebug,
            registerCollisionUpdate,
            pushCollision,
            getPreviousCollisions,
            update,
            printActiveCollisions,
        }
    }

    return <collisionContext.Provider value={init()} children={children} />
}

export default CollisionsProvider