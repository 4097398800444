
import { User } from "firebase/auth"
import { atom } from "recoil"

export type AuthState = {
    user: User | null,
    loaded: boolean,
}

export const authState = atom<AuthState>({
    key: "authState",
    default: {
        user: null,
        loaded: false,
    },
})