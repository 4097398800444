/* eslint-disable no-process-env */
import { initializeApp,  } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { EnvHelper } from "../../utils/EnvHelper"
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check"

const app = initializeApp(EnvHelper.firebaseConfig)

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(EnvHelper.appCheckVariable!),
  isTokenAutoRefreshEnabled: true,
})

export const db = getFirestore(app)

export const auth = getAuth()

export default app