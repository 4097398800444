
import React from "react"
import { Route, Routes, } from "react-router-dom"
import { RoutesHelper } from "../../../common/utils/routesHelper"
import DesignScreen from "./DesignScreen/DesignScreen"

const Main = () => {
  // throw new Error("error test")
  return <Routes>
    <Route
      path={RoutesHelper.main}
      element={<DesignScreen />}
    >
      <Route
        path={RoutesHelper.idParam}
        element={<DesignScreen />}
      />
    </Route>
  </Routes>
}

export default Main