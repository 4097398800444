import React from "react"
import { UnitType } from "../../../../state/scene/types"
import styled from "styled-components"

interface UnitToggleProps {
    unit: string;
    updateUnit: (unit: string) => void;
}

const UnitToggle = ({ unit, updateUnit, }: UnitToggleProps) => {

    const handleToggle = (event: React.ChangeEvent<{ value: unknown, }>) => {
        updateUnit(event.target.value as UnitType)
    }

    return (
        <Select value={unit} onChange={handleToggle}>
            <option value="in">in</option>
            <option value="cm">cm</option>
        </Select>
    )
}

const Select = styled.select`
        border: none;
        background: transparent;
        color: inherit;
        font: inherit;
        cursor: pointer;
        padding: 0;
        outline: inherit;
    `

export default UnitToggle
