import { Html } from "@react-three/drei"
import React, { createContext, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import Button from "../../components/Button"
import { Title } from "../../components/DrawerShared"
import { motion } from "framer-motion"

interface Props {
    normal?: boolean;
    children: React.ReactNode; // Added children prop
}

type ModalProps = {
    onShowModal: (
        props: ModalState
    ) => void,
    onConfirm: () => void,
}

export enum ModalType {
    confirm = "confirm",
    info = "info",
    content = "content"
}

type ConfirmModalState = {
    type: ModalType.confirm,
    onCancel: undefined | (() => void),
    cancelText?: string,
}

type InfoModalState = {
    type: ModalType.info,
}

type ContentModalState = {
    type: ModalType.content,
}

export type ModalState = {
    title: string,
    subtitle: string,
    onConfirm: undefined | (() => void),
    confirmText?: string,
    content?: React.ReactNode,
    animate?: boolean,
} & (ConfirmModalState | InfoModalState | ContentModalState)

export type InstancedMeshContextType = ModalProps | undefined

export const modalContext = createContext<InstancedMeshContextType>(undefined)

const ModalProvider: React.FC<Props> = ({ children, normal, }) => {
    const modalContainer = document.getElementById("modals-container")

    const [showModal, setShowModal,] = useState<ModalState & { show: boolean, }>({
        title: "",
        subtitle: "",
        onConfirm: undefined,
        onCancel: undefined,
        show: false,
        type: ModalType.confirm,
        animate: false,
    })

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.7, },
        visible: { opacity: 1, scale: 1, },
        exit: { opacity: 0, scale: 0.7, },
    }

    const onShowModal = (
        props: ModalState
    ) => {
        setShowModal({
            ...props,
            show: true,
        })
    }

    const init = () => {
        return {
            onShowModal,
            onConfirm: handleConfirm,
        }
    }

    const handleConfirm = () => {
        setShowModal({
            ...showModal,
            show: false,
        })
        if (showModal.onConfirm) {
            showModal.onConfirm()
        }
    }

    const handleCancel = () => {
        setShowModal({
            ...showModal,
            show: false,
        })
        if (showModal.type === ModalType.confirm && showModal.onCancel) {
            showModal.onCancel()
        }
    }

    const getOkButton = () => {
        return <Button
            color="#0088ff"
            action="primary"
            onClick={handleConfirm}
        >
            Ok
        </Button>
    }

    const getButtons = () => {
        switch (showModal.type) {
            case ModalType.confirm:
                return <>
                    <Button
                        color="#FF0000"
                        action="primary"
                        onClick={handleCancel}>
                        {showModal.cancelText || "Cancel"}
                    </Button>
                    <Button
                        color="#0088ff"
                        action="primary"
                        onClick={handleConfirm}>
                        {showModal.confirmText || "Confirm"}
                    </Button>
                </>
            case ModalType.info:
                return getOkButton()
            case ModalType.content:
                return null
            default:
                return getOkButton()
        }
    }

    const getModal = () => {
        return (
            <Container>
                <Modal
                    variants={showModal.animate ? modalVariants : undefined}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    isContentModal={showModal.type === ModalType.content}>
                    {showModal.title && <Title>{showModal.title}</Title>}
                    <Subtitle>{showModal.subtitle}</Subtitle>
                    {showModal.content}
                    <Actions>
                        {getButtons()}
                    </Actions>
                </Modal>
            </Container>
        )
    }

    const getContent = () => {
        return showModal.show ? ReactDOM.createPortal(getModal(), modalContainer!) : null
    }

    return <modalContext.Provider value={init()}>
        {normal ? getContent() as React.ReactNode : <Html wrapperClass={"neutralHTML"}>
            {getContent() as React.ReactNode}
        </Html>}
        {children}
    </modalContext.Provider>
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: #00000045;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`

interface ModalStyleProps {
    isContentModal?: boolean;
}

const Modal = styled(motion.div) <ModalStyleProps>`
    background: white;
    border-radius: 6px;
    box-shadow: 0 11px 34px -9px #00000069;
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    max-width: ${props => (props.isContentModal ? "none" : "300px")};
`

const Subtitle = styled.div`
    
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`

export default ModalProvider