import React, { useRef } from "react"
import { Html } from "@react-three/drei"
import { Object3D, Vector3 } from "three"
import styled from "styled-components"
import { motion } from "framer-motion"

interface MoveOnboardingProps {
    center: Vector3;
    hide: boolean;
}

const MoveOnboarding = ({ center, hide, }: MoveOnboardingProps) => {
    const ref = useRef<HTMLDivElement>(null)

    return (
        <Html ref={ref} position={center}
            style={{ pointerEvents: "none", }} zIndexRange={[1, 0,]}>
            <motion.div
                initial={{ opacity: 1, }}
                animate={{ opacity: hide ? 0 : 1, }}
                transition={{ duration: 0.5, }}
                style={{ pointerEvents: "none", }}
            >
                <DragHandle>
                    Drag From Here
                </DragHandle>
            </motion.div>
        </Html >

    )
}


const DragHandle = styled.div`
    width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: white;
    border: 2px solid black;
    border-radius: 8px;
    font-weight: bold;
    position: relative;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -180%);
    pointer-events: none;

    &::after,
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }

    &::after {
        border-top: 14px solid white;
        z-index: 2;
        bottom: -12px;

    }

    &::before {
        border-top: 14px solid black;
        z-index: 1;
        bottom: -15px;
    }
`



export default MoveOnboarding
