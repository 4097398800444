import React, { FC } from "react"
import { useRecoilValue } from "recoil"
import { CategoryTypeAPI } from "../../../../../../../common/api/Types"
import MessageBox from "../../../MessageBox"
import { existentPartSelector } from "../../../../../../state/scene/selectors"

const getProperAPreposition = (str: string) => (/[aeiou]/i.test(str[0]) ? "an" : "a")

export interface DescriptionExpandReduceProps {
  partId: string;
  oppositeId: string;
  category: CategoryTypeAPI | undefined;
}

const DescriptionExpandReduce: FC<DescriptionExpandReduceProps> = ({
  partId,
  oppositeId,
  category,
}) => {
  const partName = useRecoilValue(existentPartSelector({ id: partId, }))
  const oppositeName = useRecoilValue(existentPartSelector({ id: oppositeId, }))

  const connectWith = `${getProperAPreposition(partName.name)}
  ${partName.name} and the opposite ${getProperAPreposition(oppositeName.name)} ${oppositeName.name}
   connection using expand/reduce to fit.  `
  const text = category ? `${category.name} ${connectWith}`
    : `All parts that can connect with ${connectWith}`

  return <MessageBox message={text} />
}

export default DescriptionExpandReduce