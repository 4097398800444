/* eslint-disable max-len */
import React from "react"
import { TreeSelect, Form } from "antd"
import styled from "styled-components"

export type Option = { text: string, value: string, }

type Props = {
  options: Option[],
  value: Option["value"],
  label?: React.ReactNode,
  setValue: React.Dispatch<React.SetStateAction<Option["value"]>>,
}

const CustomTreeSelect = styled(TreeSelect)`
  &&& {
    .ant-select-selector {
      border-radius: 5px !important;
      color: #818181;
      border: 1px solid #d9d9d9;
    }
    .ant-select-dropdown {
      left: auto !important;
      right: 0;
    }
  }
`

const FormItemStyled = styled(Form.Item)`
  display: block;
  min-width: 103px;
  border-radius: 5px !important;
  margin-right: 8px;
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  & > div:first-child {
    padding-bottom: 0;
  }
`
const getTreeData = (options: Props["options"]) => {
  const inchesOptions: any[] = []
  const metricOptions: any[] = []

  options.forEach(({ value, text, }) => {
    const option = { value, title: text, label: text, }
    if (text.includes('"') || /\b(in|In|IN)\b/.test(text) || text.includes("¨")) {
      inchesOptions.push(option)
    } else if (/\b(mm|cm|MM|CM)\b/.test(text)) {
      metricOptions.push(option)
    }
  })

  return [
    {
      title: "Inches",
      value: inchesOptions.map(o => o.value).join(","),
      children: inchesOptions,
      selectable: true,
    },
    {
      title: "Metric",
      value: metricOptions.map(o => o.value).join(","),
      children: metricOptions,
      selectable: true,
    },
  ]
}

const SizesSelector = ({ options, value, setValue, label, }: Props) => {
  const treeData = React.useMemo(() => getTreeData(options), [options,])

  const displayValue = React.useMemo(() => {
    const option = options.find(opt => opt.value === value)

    if (option) {
      return option.text
    }

    if (option === undefined) {
      const values = value.split(",")
      const firstValue = values[0]
      const option = options.find(opt => opt.value === firstValue)

      if (option) {
        const isInches = option.text.includes("\u0022") || option.text.includes("¨") || /\b(in|In|IN)\b/.test(option.text)
        const newTitle = isInches ? "Inches" : "Metric"
        return (newTitle)
      }
    }

  }, [options, value,])


  return (
    <FormItemStyled label={label}>
      <CustomTreeSelect
        value={displayValue}
        onChange={(newValue) => {
          const option = options.find(opt => opt.text === newValue)
          setValue(option ? option.value : newValue as string)
        }}
        dropdownMatchSelectWidth={false}
        treeData={treeData}
        treeDefaultExpandAll
        treeNodeLabelProp="label"
      />
    </FormItemStyled>
  )
}

export default SizesSelector