import React, { useState, useEffect, } from "react"
import { Html } from "@react-three/drei"
import ReactDOM from "react-dom"
import { AnimatePresence } from "framer-motion"
import { ActionsContainer }
  from "../../components/main/DesignScreen/scene/part/parts/ActionPanelStyles"
import ActionsUI from "./ActionsUI"
import { ROTATION_AXES_ENUM } from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import MultiSelectionRotation from "./MultiSelectionRotation"


interface Props {
  onDelete: () => void;
  changeTransformMode: (mode: "translate" | "rotate" | "scale" | "off") => void;
  changeSelectionMode: (selectionMode: boolean) => void;
  resetSelection: () => void;
  transformMode: "translate" | "rotate" | "scale" | "off";
  selectionMode: boolean;
  duplicateSelectedParts: (duplicateEverything?: boolean,
    duplicateSpecificPartsIds?: string[]) => void;
  hideMultiUIControls: boolean;
  setHideMultiUIControls: (hide: boolean) => void;
  onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void;

}

const MultiSelectUI: React.FC<Props> = ({ onDelete, changeTransformMode,
  duplicateSelectedParts, changeSelectionMode,
  transformMode, selectionMode, resetSelection, onRotationSliderChange, }) => {
  const [activeTab, setActiveTab,]
    = useState<"move" | "rotate" | "duplicate" | "delete" | null>(null)


  useEffect(() => {
    if (transformMode === "translate" && selectionMode === false) {
      setActiveTab("move")
    }
    if (transformMode === "rotate" && selectionMode === false) {
      setActiveTab("rotate")
    }
    if (transformMode === "off") {
      setActiveTab(null)
    }
  }, [selectionMode, transformMode,])

  const handleMoveClick = () => {
    setActiveTab(activeTab === "move" ? null : "move")
    changeTransformMode(activeTab === "move" ? "off" : "translate")
    changeSelectionMode(false)
  }

  const handleRotateClick = () => {
    setActiveTab(activeTab === "rotate" ? null : "rotate")
    changeTransformMode(activeTab === "rotate" ? "off" : "rotate")
    changeSelectionMode(false)
  }

  const handleDuplicateClick = () => {
    duplicateSelectedParts()
  }

  const handleDeleteClick = () => {
    onDelete()
  }
  const handleClearClick = () => {
    resetSelection()
  }


  return (
    <Html wrapperClass="neutralHTML">
      {ReactDOM.createPortal(
        <AnimatePresence>
          <ActionsContainer
            $partUI="multiselect"
            transition={{ duration: 0.2, }}
            variants={{
              visible: { y: "0%", },
              hidden: { y: "130%", },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ActionsUI
              activeTab={activeTab}
              onMoveClick={handleMoveClick}
              onRotateClick={handleRotateClick}
              onDuplicateClick={handleDuplicateClick}
              onDeleteClick={handleDeleteClick}
              onClearClick={handleClearClick}

            />
            {activeTab === "move"}
            {activeTab === "rotate"
              && <MultiSelectionRotation onRotationSliderChange={onRotationSliderChange} />}
          </ActionsContainer>
        </AnimatePresence>,
        document.getElementById("actions-container")!
      )}
    </Html>
  )
}

export default MultiSelectUI