import React, { FC } from "react"
import PartCard from "./cards/PartCard"
import { PartTypeAPI } from "../../../../../../common/api/Types"
import { ListGrid } from "../../../../../../common/components/DrawerShared"
import { getRecentParts } from "../../../../../../common/utils/RecentParts"
export interface CategoryPartsProps {
  parts: PartTypeAPI[];
  partOnClick: (part: PartTypeAPI) => void;
  disabled?: boolean;
}

const CategoryParts: FC<CategoryPartsProps>
  = (props: CategoryPartsProps) => {
    const recentParts = getRecentParts()
    const recentPartsIds = recentParts.map(p => p.id)
    const sortedParts = props.parts.sort((a, b) => {
      const aIndex = recentPartsIds.indexOf(a.id)
      const bIndex = recentPartsIds.indexOf(b.id)
      if (aIndex === -1 && bIndex === -1) { return 0 } // neither part is in recentParts
      if (aIndex === -1) { return 1 } // a is not in recentParts, but b is
      if (bIndex === -1) { return -1 } // b is not in recentParts, but a is
      return aIndex - bIndex // both parts are in recentParts, sort by index
    })

    return (
      <ListGrid>
        {sortedParts.map((part) => (
          <PartCard
            key={part.id}
            {...part}
            onClick={() => { props.partOnClick(part) }}
            disabled={props.disabled}
            furtherInfo={part.furtherInfo}
          />
        ))}
      </ListGrid>
    )
  }
export default CategoryParts
