/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react"
import { Color, Group, InstancedMesh, Matrix4, Mesh, Quaternion, Texture, Vector3 } from "three"
import { instancedMeshContext } from "./InstancedMeshProvider"

const useInstancedMesh = (
    mesh: Mesh,
    id: string,
    referenceName: string,
    onClick: (mesh: InstancedMesh, matrix: Matrix4) => void,
    withMaterial?: string,
    woodTexture?: Texture) => {
    const context = useContext(instancedMeshContext)
    const ref = useRef<Group>()
    const instancedMesh = useRef<InstancedMesh>()
    const index = useRef<number>()
    const lastMatrixWorld = useRef<Matrix4>()

    const updateTransforms = () => {
        if (instancedMesh.current && ref.current) {
            ref.current.updateWorldMatrix(true, true)
            lastMatrixWorld.current = ref.current.matrixWorld
            instancedMesh.current.setMatrixAt(index.current!, lastMatrixWorld.current)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const getIndex = () => {
        return index.current
    }

    const updateInstance = (newIndex: number) => {
        index.current = newIndex
        updateTransforms()
        instancedMesh.current?.computeBoundingSphere()
    }

    const deleteMesh = () => {
        if (context && instancedMesh.current) {
            context.deleteMesh(getReferenceName(), id)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const updateColor = (color: string) => {
        if (instancedMesh.current) {
            instancedMesh.current.setColorAt(index.current!, new Color(color))
            instancedMesh.current.instanceColor!.needsUpdate = true
        }
    }

    const createStandAloneMeshFromInstance = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current!, matrix)

            // Clone the original mesh
            const clonedMesh = mesh.clone()

            // Apply position, rotation, and scale from the matrix
            const position = new Vector3()
            const quaternion = new Quaternion()
            const scale = new Vector3()
            matrix.decompose(position, quaternion, scale)

            clonedMesh.position.copy(position)
            clonedMesh.quaternion.copy(quaternion)
            clonedMesh.scale.copy(scale)

            // Copy material from instanced mesh
            if (instancedMesh.current.material) {
                if (Array.isArray(instancedMesh.current.material)) {
                    clonedMesh.material = instancedMesh.current.material.map(mat => mat.clone())
                } else {
                    clonedMesh.material = instancedMesh.current.material.clone()
                }
            }

            return clonedMesh
        }

        console.warn("Failed to create stand-alone mesh: instancedMesh or index is not available")
        return null
    }


    const changeVisibilityOnSpecificIndex = (visible: boolean) => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)

            // Use the visible parameter directly to set the scale
            const scale = visible ? 1 : 0.00001
            matrix.scale(new Vector3(scale, scale, scale))

            instancedMesh.current.setMatrixAt(index.current, matrix)
            instancedMesh.current.instanceMatrix.needsUpdate = true
        }
    }

    const internalCallback = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current!, matrix)
            onClick(instancedMesh.current, matrix)
        }
    }

    const getMatrix = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current!, matrix)
            return matrix
        }
    }

    const getReferenceName = () => {
        if (withMaterial) {
            return `${referenceName}-mat-${withMaterial}`
        }

        return referenceName
    }

    useEffect(() => {
        if (context) {
            const instance = context.createMesh(
                id, getReferenceName(), mesh, internalCallback, updateInstance, woodTexture
            )
            instancedMesh.current = instance.instancedMesh
            index.current = instance.index
        }
    }, [])

    return {
        ref,
        updateTransforms,
        deleteMesh,
        instancedMesh,
        updateColor,
        internalCallback,
        getMatrix,
        getIndex,
        createStandAloneMeshFromInstance,
        changeVisibilityOnSpecificIndex,
    }
}

export default useInstancedMesh