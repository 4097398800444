import { SetStateAction } from "react"
import { Intersection, Line, Mesh, Object3D, Scene } from "three"
import { ConnectionTypeAPI, SegmentedTubeSectionType }
    from "../../../../../../../../../common/api/Types"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { SegmentedTubeValues } from "../../../../../../../../exportables"
import { ObjDictionary } from "../../../../../../../../../common/utils/utils"

export type SegmentedTubeMarker = {
    plus?: Mesh,
    inner: Mesh | undefined,
    outer: Mesh | undefined,
    mesh?: Mesh | undefined,
    mainModel?: Mesh | undefined,
}

export type SegmentedTubeMarkers = {
    [id: string]: SegmentedTubeMarker,
}

export type SegmentedTubeGuidelines = { start: Line[], middle: Line[], end: Line[], }

export type GetCorrectConnectionsPropsForAllIntersections = {
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    tube: SegmentedTubeValues,
    allMiddles?: boolean,
} & (
        {
            diagonal: false,
            intersections: {
                marker: Mesh,
                intersectedObjects: Intersection[] | undefined,
            }[],
        }
        | {
            diagonal: true,
            intersections: {
                marker: Mesh,
                intersectedObject: Intersection[] | undefined,
            }[],
        }
    )

export type SegmentedTubeInfo = {
    scene: Scene,
    blenderData: BlenderProcecedData[],
    attachmentPoint: Object3D | undefined,
    originPoint: Object3D | undefined,
    instancedMeshOrigins: Object3D[],
    middleSection: { [side: string]: SegmentedTubeMarkers, },
    startSection: SegmentedTubeMarkers,
    endSection: SegmentedTubeMarkers,
    newPartButtons: Object3D[],
    snapped: { start: boolean, end: boolean, },
    middlePositionsEdges: {
        max: number,
        min: number,
    },
    guidelines: Line[],
    multipleMove: {
        connected: boolean,
        tempConnections: PartConnectionType[],
        maxPossibleLength?: number,
        snapped: { start: boolean, end: boolean, },
    },
    diagonalEdges: {
        start: boolean,
        end: boolean,
    },
    mergedMesh: Mesh | undefined,
    mergedMeshOrigins: Object3D[],
    zeroLengthAttachmentPoint: Object3D | undefined,
}

export type MarkerCollectionBlenderProcecedData = {
    [id: string]: {
        inner?: {
            model: Object3D,
            type: MarkerType.INNER,
            id: string,
        },
        outer?: {
            model: Object3D,
            type: MarkerType.OUTER,
            id: string,
        },
    },
}

export type BlenderProcecedData = {
    sectionType: SectionType,
    mainModel: Mesh,
    markerCollection: MarkerCollectionBlenderProcecedData,
    sectionOrigin: Object3D,
}

export enum SectionType {
    START = "START",
    MIDDLE = "MIDDLE",
    END = "END"
}

export enum MarkerType {
    INNER = "INNER",
    OUTER = "OUTER",
    PLUS = "PLUS",
}

export enum SEGMENTED_TUBE_UI {
    NONE = "NONE",
    SLIDER = "SLIDER",
    ACTIONS = "ACTIONS",
    ROTATION = "ROTATION",
    ROTATION_MULTISELECT = "ROTATION_MULTISELECT",
    SEGMENTED_SLIDER = "SEGMENTED_SLIDER",
    CLOSE = "CLOSE",
}

export type LengthSliderConfig = {
    value: number,
    step: number,
    minMiddles: number,
    maxMiddles: number,
    validMax: number,
    validMin: number,
    onchange: (value: number) => void,
    segmentLength: number,
    scaledSegmentLength: number | undefined,
    startSegmentLength: number,
    endSegmentLength: number,
    unit: string,
    unitRealValue: number | undefined,
    updateUnit: (value: string) => void,
    saveLength: (value: number) => void,
    setHideAddPartButtons: (value: SetStateAction<boolean>) => void,
    onMouseUp?: (value: number) => void,
    onMouseDown?: () => void,
    setDirection: (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => void,
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    setAttachmentPointToRef: () => void,
    modifiedWidth: number | undefined,
    modifiedWidthUnits: string | undefined,
    modifiedHeight: number | undefined,
    modifiedHeightUnits: string | undefined,
}

export type RotationSliderConfig = {
    onRotationChange: (value: number) => void,
    onRotationSave: (value: number) => void,
    handleMouseDown: () => void,
    handleMouseUp: () => void,
    onFinishEditing: () => void,
    getRotation: () => number,
    steps: number,
}

export enum ROTATION_AXES_ENUM {
    X = "X",
    Y = "Y",
    Z = "Z"
}