import { useState, useEffect } from "react"
import { useThree } from "@react-three/fiber"
import {
    Scene, Vector3, SphereGeometry,
    MeshBasicMaterial, Mesh, Raycaster, BufferGeometry,
    LineBasicMaterial, Line
} from "three"

export function useDebugTools() {
    const { scene, } = useThree()

    function drawVector3Point(
        vector3Point: Vector3,
        color: number | string = 0xffff00,
        size = 0.002,
        duration = 500
    ) {
        const sphereGeometry = new SphereGeometry(size, 32, 32)
        sphereGeometry.translate(vector3Point.x, vector3Point.y, vector3Point.z)
        const sphereMaterial = new MeshBasicMaterial({ color, })
        sphereMaterial.depthTest = false
        const sphere = new Mesh(sphereGeometry, sphereMaterial)
        scene.add(sphere)
        setTimeout(() => {
            scene.remove(sphere)
        }, duration)
    }

    function visualizeRay(scene: Scene, raycaster: Raycaster, length = 10, autoRemove = true) {
        const origin = raycaster.ray.origin
        const direction = raycaster.ray.direction.clone().multiplyScalar(length)

        const points = []
        points.push(origin)
        points.push(new Vector3().addVectors(origin, direction))

        const geometry = new BufferGeometry().setFromPoints(points)
        const material = new LineBasicMaterial({ color: 0xff0000, })

        const line = new Line(geometry, material)
        scene.add(line)

        if (autoRemove) {
            setTimeout(() => scene.remove(line), 2000)
        }
    }

    function viewMarker(marker: Mesh) {
        const material = Array.isArray(marker.material) ? marker.material[0] : marker.material
        if (material instanceof MeshBasicMaterial) {
            material.visible = true
            material.color.set(0xff0000)
            material.depthTest = false
            material.transparent = true
            material.wireframe = true
        }
    }

    return {
        drawVector3Point,
        visualizeRay,
        viewMarker,
    }
}
