import { useRef, useEffect, useCallback } from "react"

type ComponentMethod = { name: string, fn: (...args: any[]) => any, }
type ComponentMethods = { [key: string]: (...args: any[]) => any, }

const registry = new Map<string, ComponentMethods>()

export const useComponentRegistry = () => {
  const register = (id: string, methods: ComponentMethods) => {
    registry.set(id, methods)
  }

  const getComponent = (id: string) => registry.get(id)

  const unregister = (id: string) => {
    registry.delete(id)
  }

  return { register, getComponent, unregister, }
}

export const useRegisterComponent = (id: string, methods: ComponentMethods) => {
  const { register, unregister, } = useComponentRegistry()

  const registerMethods = useCallback(() => {
    register(id, methods)
  }, [id, methods, register,])

  useEffect(() => {
    registerMethods()
    return () => unregister(id)
  }, [id, registerMethods, unregister,])

  return registerMethods
}