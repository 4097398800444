
import React from "react"
import styled, { keyframes } from "styled-components"
import logo_text from "../assets/craftyamigo.svg"
import logo_mobile from "../assets/logo_mob.svg"
import logo_desktop from "../assets/logo_desk.svg"


interface Props {
  isMobile?: boolean;
}

const Logo = (props: Props) => {
  return props.isMobile ? <LogoStyled />
    : <img src={logo_desktop} alt="Logo craftyamigo " />
}


const textLogoAnimation = keyframes`
  from  {opacity: 1;}
  to   {opacity: 0;}
`
const logoAnimation = keyframes`
  0%   {opacity: 0; width:130px; }
  75%   {opacity: 1;width: 130px;}
  100%   {opacity: 1;width: 30px;}
`
const LogoStyled = styled.div`
  width: 30px;
  height: 30px;
  animation-name: ${logoAnimation};
  animation-duration: 3s;animation-fill-mode: forwards;
  background-image: url(${logo_mobile});
  background-repeat: no-repeat;
  background-position: left;

:before{
    content: "";
    width: 130px;
    height: 30px;
    position: absolute;
  background-image: url(${logo_text});
  background-repeat: no-repeat;
  background-position: right;
    animation-delay: 2s;
    animation-duration: 1s;
    animation-name: ${textLogoAnimation};animation-fill-mode: forwards;
}

`
export default Logo