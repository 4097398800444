/* eslint-disable max-len */
/* eslint-disable max-statements */
import React, { createContext, useContext, useState, ReactNode } from "react"
import SnapAnimation from "./SnapAnimation"
import UnsnapAnimation from "./UnsnapAnimation"
import { useComponentRegistry } from "../../../../../../../../providers/multiselectProvider/useComponentMethods"
import { Mesh, Quaternion, Vector3 } from "three"
import AlignmentPointHighlight from "../../segmentedTube/ui/AlignmentPointHighlight"

export type AnimationType = "snap" | "unsnap" | "alignmentHighlight"

interface AnimationInstance {
    type: AnimationType;
    position: Vector3;
    rotation: Quaternion;
    color: string;
}

interface AnimationStrategy {
    getAnimationInstance: (
        partId?: string,
        markerName?: string,
        color?: string,
        position?: Vector3
    ) => AnimationInstance | null;
}

interface AnimationContextType {
    isAnimating: boolean;
    setIsAnimating: (value: boolean) => void;
    triggerAnimation: (
        type: AnimationType,
        partId?: string,
        markerName?: string,
        color?: string,
        animationPosition?: Vector3
    ) => void;
}

const AnimationContext = createContext<AnimationContextType | undefined>(undefined)

export const GlobalAnimationProvider: React.FC<{ children: ReactNode, }> = ({ children, }) => {
    const [isAnimating, setIsAnimating,] = useState(false)
    const [animations, setAnimations,] = useState<AnimationInstance[]>([])
    const { getComponent, } = useComponentRegistry()

    const findMarker = (partId: string, markerName: string): Mesh | null => {
        const part = getComponent(partId)
        const markers = part?.getAllMarkers()
        if (!markers) { return null }

        let marker = markers.find((m: Mesh) => m.name === markerName)

        // Try alternative marker names if not found
        if (!marker) {
            let alternativeMarkerName = markerName
            if (markerName?.includes("inner")) {
                alternativeMarkerName = markerName.replace("inner", "outer")
            } else if (markerName?.toLowerCase() === "inner") {
                alternativeMarkerName = "outer"
            }

            if (alternativeMarkerName !== markerName) {
                marker = markers.find((m: Mesh) => m.name === alternativeMarkerName)
            }
        }

        return marker || null
    }

    const strategies: Record<AnimationType, AnimationStrategy> = {
        snap: {
            getAnimationInstance: (partId, markerName, color = "green") => {
                if (!partId || !markerName) { return null }
                const marker = findMarker(partId, markerName)
                if (!marker) { return null }

                return {
                    type: "snap",
                    position: marker.getWorldPosition(new Vector3()),
                    rotation: marker.getWorldQuaternion(new Quaternion()),
                    color,
                }
            },
        },
        unsnap: {
            getAnimationInstance: (partId, markerName, color = "green") => {
                if (!partId || !markerName) { return null }
                const marker = findMarker(partId, markerName)
                if (!marker) { return null }

                return {
                    type: "unsnap",
                    position: marker.getWorldPosition(new Vector3()),
                    rotation: marker.getWorldQuaternion(new Quaternion()),
                    color,
                }
            },
        },
        alignmentHighlight: {
            getAnimationInstance: (_, __, color = "green", position) => {
                if (!position) { return null }

                return {
                    type: "alignmentHighlight",
                    position,
                    rotation: new Quaternion(),
                    color,
                }
            },
        },
    }

    const triggerAnimation = (
        type: AnimationType,
        partId?: string,
        markerName?: string,
        color = "green",
        animationPosition?: Vector3
    ) => {
        const strategy = strategies[type]
        const animation = strategy.getAnimationInstance(partId, markerName, color, animationPosition)

        if (!animation) { return }

        setIsAnimating(true)
        setAnimations(prev => {
            // Remove existing alignment animations if new animation is alignment type
            if (type === "alignmentHighlight") {
                return [...prev.filter(anim => anim.type !== "alignmentHighlight"), animation,]
            }
            return [...prev, animation,]
        })

        setTimeout(() => {
            setAnimations(prev =>
                prev.filter(anim =>
                    !(anim.position.equals(animation.position)
                        && anim.rotation.equals(animation.rotation))
                )
            )
            setAnimations(prev => {
                if (prev.length === 0) {
                    setIsAnimating(false)
                }
                return prev
            })
        }, 1000)
    }

    return (
        <AnimationContext.Provider value={{ isAnimating, setIsAnimating, triggerAnimation, }}>
            {children}
            {animations.map((anim, index) => (
                <React.Fragment key={index}>
                    {anim.type === "snap" && (
                        <SnapAnimation
                            position={anim.position}
                            rotation={anim.rotation}
                            color={anim.color}
                        />
                    )}
                    {anim.type === "unsnap" && (
                        <UnsnapAnimation
                            position={anim.position}
                            rotation={anim.rotation}
                            color={anim.color}
                        />
                    )}
                    {anim.type === "alignmentHighlight" && (
                        <AlignmentPointHighlight
                            position={anim.position}
                            color={anim.color}
                        />
                    )}
                </React.Fragment>
            ))}
        </AnimationContext.Provider>
    )
}

export const useGlobalAnimation = (): AnimationContextType => {
    const context = useContext(AnimationContext)
    if (context === undefined) {
        throw new Error("useGlobalAnimation must be used within a GlobalAnimationProvider")
    }
    return context
}

