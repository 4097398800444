import React from "react"
import { Vector3, Quaternion, MeshBasicMaterial } from "three"

interface MarkerProps {
    position: Vector3;
    quaternion: Quaternion;
    color: string;
    dotSize: [number, number, number];
    onClick: () => void;
    onPointerOver: () => void;
    onPointerOut: () => void;
}

const Marker: React.FC<MarkerProps> = ({
    position,
    quaternion,
    color,
    dotSize,
    onClick,
    onPointerOver,
    onPointerOut,
}) => {
    return (
        <mesh
            position={position}
            quaternion={quaternion}
            onClick={onClick}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
        >
            <boxGeometry args={dotSize} />
            <meshBasicMaterial
                color={color}
                depthTest={false}
                depthWrite={false}
            />
        </mesh>
    )
}

export default Marker