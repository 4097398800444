import { useEffect, useState } from "react"

const useViewOnly = () => {
    const [isViewOnly, setIsViewOnly,] = useState(false)

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const viewParam = urlParams.get("viewOnly")
        if(viewParam === "true"){
            setIsViewOnly(true)
        }
    }, [])

    return isViewOnly

}

export default useViewOnly