/* eslint-disable no-loop-func */
import React from "react"
import { SliderContainer, SliderText } from "./segmentedTube/ui/ActionPanelStyles"
import { PartConnectionType } from "../../../../../../state/scene/types"
import RelativeMove from "./tube/ui/RelativeMove"
import AlignmentOptionsUI, { AlignmentPosition } from "./segmentedTube/ui/AlignmentOptionsUI"
import { Camera } from "three"
import { Vector3 } from "three"
import { SegmentedTubeMarkers } from "./segmentedTube/types/types"
import {
    ContentContainer, FlexContainer, Label, Section,
} from "./segmentedTube/ui/SegmentedSliderStyles"

type SegmentedSliderProps = {
    connectedToSegmentedParts: PartConnectionType[] | undefined,
    connectionIndex: number,
    setConnectionIndex: (index: number) => void,
    onAlignmentSelect: (position: AlignmentPosition, offsetX: number, offsetY: number) => void,
    onAlignmentHover: (position: AlignmentPosition | null) => void,
    camera: Camera | undefined,
    rotationPoint: SegmentedTubeMarkers | null,
    unit?: "cm" | "in",
    debugAlignment?: boolean,
    canUseAlignment?: boolean,
    positionChanged: boolean,
}

const SegmentedSlider = (props: SegmentedSliderProps) => {
    const getSlider = () => {
        if (props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1) {
            return (
                <FlexContainer>
                    <AlignmentOptionsUI
                        onAlignmentSelect={props.onAlignmentSelect}
                        onAlignmentHover={props.onAlignmentHover}
                        camera={props.camera}
                        rotationPoint={props.rotationPoint}
                        unit={props.unit || "in"}
                        debugAlignment={props.debugAlignment || false}
                        canUseAlignment={props.canUseAlignment}
                        positionChanged={props.positionChanged}
                    />
                    <FlexContainer>
                        <Section>
                            <ContentContainer>
                                <RelativeMove
                                    connectedToSegmentedParts={props.connectedToSegmentedParts}
                            connectionIndex={props.connectionIndex}
                                    setConnectionIndex={props.setConnectionIndex}
                                />
                            </ContentContainer>
                            <Label>Move Relative To</Label>
                        </Section>
                    </FlexContainer>
                </FlexContainer>
            )
        }

        return (
            <FlexContainer>
                    <AlignmentOptionsUI
                        onAlignmentSelect={props.onAlignmentSelect}
                        onAlignmentHover={props.onAlignmentHover}
                        camera={props.camera}
                        rotationPoint={props.rotationPoint}
                        unit={props.unit || "in"}
                        debugAlignment={props.debugAlignment || false}
                        canUseAlignment={props.canUseAlignment}
                        positionChanged={props.positionChanged}
                    />
            </FlexContainer>
        )
    }

    return <SliderContainer>
        {getSlider()}
    </SliderContainer>
}

export default SegmentedSlider