import {
    AxesHelper,
    Box3,
    Group,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Quaternion,
    Vector3
} from "three"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"
import { DragMesh, FreePositions } from "./types"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { getFirstAndLastSides } from "./meshHelpers"

interface Side {
    outer: Mesh;
    [key: string]: any;
}

function getMeshCorners(sides: Record<string, Side>, threshold = 0): Vector3[] {
    const points: Vector3[] = []
    const firstAndLastSides = getFirstAndLastSides(sides)

    firstAndLastSides.forEach(key => {
        const outerMesh = sides[key].outer
        let realBoundary = convertInToCm(outerMesh.userData.boundary) / 100
        if (!realBoundary) {
            realBoundary = 0.1
        }

        // Add threshold to the boundary
        realBoundary += threshold

        const localPositivePosition = new Vector3(0, realBoundary, 0)
        const globalPositivePosition = outerMesh.localToWorld(localPositivePosition.clone())
        points.push(globalPositivePosition)

        // Negative side of the boundary
        const localNegativePosition = new Vector3(0, -realBoundary, 0)
        const globalNegativePosition = outerMesh.localToWorld(localNegativePosition.clone())
        points.push(globalNegativePosition)
    })

    return points
}

function getScaledPointsArray(points: Vector3[]): Vector3[] {
    const scale = 50
    const center = new Vector3()
    points.forEach((p: Vector3) => center.add(p))
    center.divideScalar(points.length)

    const scaledPoints = points.map((p: Vector3) => {
        const scaledPoint = p.clone().sub(center)
        scaledPoint.multiplyScalar(scale)
        return scaledPoint.add(center)
    })
    return scaledPoints
}

function getDragMeshForMiddle(
    sides: Record<string, Side>,
    viewSurfaceMesh = false,
    viewDragMesh = false
): DragMesh {
    const points = getMeshCorners(sides)
    const scaledPoints = getScaledPointsArray(points)
    const scaledGeometry = new ConvexGeometry(scaledPoints)
    const geometry = new ConvexGeometry(points)

    const meshMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: viewSurfaceMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })

    const scaledMeshMaterial = new MeshBasicMaterial({
        color: 0xff0000,
        opacity: viewDragMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })

    const scaledMesh = new Mesh(scaledGeometry, scaledMeshMaterial)
    const mesh = new Mesh(geometry, meshMaterial)
    scaledMesh.geometry.computeBoundingBox()
    const boundingBox = new Box3().setFromObject(mesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    return {
        dragMesh: scaledMesh,
        surfaceMesh: mesh,
        boundingBox,
        center,
    }
}

function getPointsArrayForMiddle(
    markerArray: Mesh[],
    segmentLength = 0.025,
    extendSurface = false,
    stepDensity = 1,
    pieceLength?: number,
    isMiddleWithoutBoundary?: boolean
): { freePositions: FreePositions[], boundingBoxPoints: FreePositions[], } {
    const points: FreePositions[] = []
    const step = convertInToCm(segmentLength) / 100 / stepDensity
    const markers = getExtraMarkers(markerArray, step, stepDensity - 1)
    let extendedPoints: FreePositions[] = []
    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100

    markers.forEach((marker: Mesh) => {
        const modelBoundary = marker.userData.boundary
        let realBoundary = convertInToCm(modelBoundary) / 100

        if (!modelBoundary) {
            realBoundary = step - 0.0001 // single line mesh
        }

        const pointsForMarker = getPointsForMarker(realBoundary, step, marker)
        points.push(...pointsForMarker)

        if (extendSurface && modelBoundary) {
            const extendedBoundary = realBoundary + pieceLengthInCm * tolerance
            const extendedPointsForMarker = getPointsForMarker(
                extendedBoundary,
                step,
                marker
            )
            extendedPoints.push(...extendedPointsForMarker)
        }
    })

    const firstMesh = markers[0]
    const lastMesh = markers[markers.length - 1]
    const modelBoundary = firstMesh.userData.boundary

    if (extendSurface && modelBoundary) {
        extendedPoints = extendPoints(
            extendedPoints,
            firstMesh,
            lastMesh,
            step,
            pieceLength
        )
    } else {
        extendedPoints = points
    }

    return { freePositions: extendedPoints, boundingBoxPoints: points, }
}

function getBoundingBoxPointsForMiddle(
    markerArray: Mesh[],
    segmentLength = 0.025,
    stepDensity = 1
): FreePositions[] { // Removed extendSurface and pieceLength
    const points: FreePositions[] = []
    const step = convertInToCm(segmentLength) / 100 / stepDensity
    const markers = getExtraMarkers(markerArray, step, stepDensity - 1)

    markers.forEach((marker: Mesh, index: number) => {
        const modelBoundary = marker.userData.boundary
        let realBoundary = convertInToCm(modelBoundary) / 100

        if (!modelBoundary) {
            realBoundary = step - 0.0001 // single line mesh
        }

        // Check if this is the first or last marker
        const isFirstMarker = index === 0
        const isLastMarker = index === markers.length - 1

        let pointsForMarker: FreePositions[]

        // If it's the first or last marker, we might want to adjust the boundary
        if (isFirstMarker || isLastMarker) {
            pointsForMarker = getPointsForMarker(realBoundary, step, marker)
        } else {
            pointsForMarker = getPointsForMarkerBoundingBox(realBoundary, step, marker)
        }
        points.push(...pointsForMarker) // Collect only bounding box points
    })

    return points // Return only bounding box points
}

function getExtraMarkers(
    markers: Mesh[],
    step: number,
    stepDensity: number
): Mesh[] {
    const extraMarkers: Mesh[] = []
    const group = new Group()
    const helper = new Object3D()
    group.add(helper)

    markers.forEach((marker) => {
        extraMarkers.push(marker)

        if (!marker.userData.boundary) {
            return
        }

        const markerWorldPosition = new Vector3()
        marker.getWorldPosition(markerWorldPosition)
        const markerWorldQuaternion = new Quaternion()
        marker.getWorldQuaternion(markerWorldQuaternion)

        group.position.copy(markerWorldPosition)
        group.quaternion.copy(markerWorldQuaternion)
        helper.position.set(0, 0, 0)

        for (let i = 0; i < stepDensity; i++) {
            helper.position.x += step

            const helperWorldPosition = new Vector3()
            helper.getWorldPosition(helperWorldPosition)

            const extraMarker = marker.clone()
            extraMarker.position.copy(helperWorldPosition)
            extraMarker.quaternion.copy(markerWorldQuaternion)
            extraMarkers.push(extraMarker)
        }
    })

    return extraMarkers
}

function getPointsForMarker(
    boundary: number,
    step: number,
    marker: Mesh
): FreePositions[] {
    const SCALE_FACTOR = 100000
    const meshName = marker.name
    const scaledStep = step * SCALE_FACTOR
    const scaledLimit = boundary * SCALE_FACTOR

    const numSteps = Math.floor(scaledLimit / scaledStep)
    const pointCount = numSteps * 2 + 1
    const points: FreePositions[] = new Array(pointCount)

    let index = 0
    const tempLocal = new Vector3()
    const tempGlobal = new Vector3()

    for (let i = -numSteps; i <= numSteps; i++) {
        const scaledValue = i * scaledStep
        const unscaledStep = scaledValue / SCALE_FACTOR
        tempLocal.set(0, unscaledStep, 0)
        marker.localToWorld(tempGlobal.copy(tempLocal))

        points[index++] = {
            meshName,
            position: tempGlobal.clone(),
            withinMesh: true,
            marker,
        }
    }

    return points
}

function getPointsForMarkerBoundingBox(
    boundary: number,
    step: number,
    marker: Mesh
): FreePositions[] {
    const SCALE_FACTOR = 100000
    const meshName = marker.name
    const scaledLimit = boundary * SCALE_FACTOR

    const points: FreePositions[] = []
    const tempLocal = new Vector3()
    const tempGlobal = new Vector3()

    // Generate only the boundary points
    const boundarySteps = [-scaledLimit, scaledLimit,]
    boundarySteps.forEach(scaledValue => {
        const unscaledStep = scaledValue / SCALE_FACTOR
        tempLocal.set(0, unscaledStep, 0)
        marker.localToWorld(tempGlobal.copy(tempLocal))

        points.push({
            meshName,
            position: tempGlobal.clone(),
            withinMesh: true,
            marker,
        })
    })

    return points
}
function extendPoints(
    points: FreePositions[],
    firstMesh: Mesh,
    lastMesh: Mesh,
    step: number,
    pieceLength: number | undefined
): FreePositions[] {
    const newPoints = [...points,]

    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100
    const repetitionsWithTolerance = Math.ceil(
        (pieceLengthInCm * tolerance) / step
    )

    const addExtendedPoints = (mesh: Mesh, direction: number) => {
        const basePosition = new Vector3()
        mesh.getWorldPosition(basePosition)
        const quaternion = new Quaternion()
        mesh.getWorldQuaternion(quaternion)

        for (let x = 1; x <= repetitionsWithTolerance; x++) {
            const offsetX = step * x * direction
            const offset = new Vector3(offsetX, 0, 0).applyQuaternion(quaternion)
            const position = basePosition.clone().add(offset)

            newPoints.push({
                meshName: mesh.name,
                position,
                withinMesh: false,
                marker: mesh,
            })
        }
    }

    addExtendedPoints(lastMesh, -1)
    addExtendedPoints(firstMesh, 1)

    return newPoints
}

export { getDragMeshForMiddle, getPointsArrayForMiddle, getBoundingBoxPointsForMiddle, }
