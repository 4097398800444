import React from "react"
import ActionButton from "../../../../../../../../../common/components/ActionButton"
import { ActionButtonContainer } from "../../segmentedTube/ui/ActionPanelStyles"
import { CONNECTOR_UI } from "../types/types"
import { ReactComponent as TrashIcon }
    from "../../../../../../../../../common/assets/icons/Trash.svg"

interface Props {
    connectorUI: CONNECTOR_UI;
    setConnectorUI: (ui: CONNECTOR_UI) => void;
    onRemove: () => void;
    onSwap: () => void;
    canRotate: boolean;
    canSlide: boolean;
    canSwap: boolean;
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void;
    partId: string;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[],
        control: "selection" | "translate" | "rotate") => void;
    setIsSelected: (isSelected: boolean) => void;
    transformMode: string;
}

const ActionsUI = (props: Props) => {
    const { canRotate, canSlide, canSwap, onSwap, transformMode, } = props
    const handleRemoveModal = () => {
        props.setConnectorUI(CONNECTOR_UI.NONE)
    }

    const handleDuplicate = () => {
        props.onDuplicate(false, [props.partId,])
    }

    const handleSwap = () => {
        props.setConnectorUI(CONNECTOR_UI.NONE)
        onSwap()
    }

    const handleShowSlider = () => {
        props.setConnectorUI(CONNECTOR_UI.SLIDER)
    }

    const handleShowSliderInST = () => {
        props.setConnectorUI(CONNECTOR_UI.SEGMENTED_SLIDER)
    }

    const handleSingleItemTransform = (mode: "translate" | "rotate") => {
        if (props.partId) {
            //need timeout for the colors not to go over each other
            props.setIdsAsHighlightedAndTurnOnControl([props.partId!,], mode,)
            props.setConnectorUI(CONNECTOR_UI.NONE)
        }
    }

    return (
        <ActionButtonContainer>
            {canSwap && <ActionButton
                onClick={handleSwap}>
                Swap</ActionButton>
            }
            {canRotate && <ActionButton
                selected={props.connectorUI === CONNECTOR_UI.SLIDER}
                onClick={handleShowSlider}
            >Rotate
            </ActionButton>}
            {canSlide && <ActionButton
                selected={props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER}
                onClick={handleShowSliderInST}
            >Move
            </ActionButton>}
            {props.partId && !props.canSlide && (
                <ActionButton
                onClick={() => handleSingleItemTransform("translate")}
                selected={props.transformMode === "translate"}
                >
                    Move
                </ActionButton>
            )}
            <ActionButton
                onClick={handleDuplicate}
            >2x
            </ActionButton>
            <ActionButton
                onClick={props.onRemove}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: 0,
                    paddingRight: 0,
                }}>
                <TrashIcon />
            </ActionButton>
        </ActionButtonContainer>
    )
}

export default ActionsUI