import React from "react"
import Slider from "rc-slider"
import isMobile from "ismobilejs"
import styled from "styled-components"

type BaseSliderProps = {
    min: number,
    max: number,
    step: number,
    onChange: (value: number) => void,
    handleMouseUp: (e: number) => void,
    getValue: () => number,
    marks?: boolean,
    checkSteps?: boolean,
    handleMouseDown?: (e: number) => void,
    onBeforeChange?: (value: number | number[]) => void,
    onAfterChange?: (value: number | number[]) => void,
    disabled?: boolean,
};

const BaseSlider: React.FC<BaseSliderProps> = (props) => {
    const { min, max, step, onChange, onBeforeChange, onAfterChange, disabled, } = props

    return (
        <SliderContainer>
            <Slider
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.getValue()}
                marks={props.marks ? {
                    90: {
                        style: { bottom: -26, }, label: "90°",
                    },
                    180: { style: { bottom: -26, }, label: "180°", },
                    270: { style: { bottom: -26, }, label: "270°", },
                } : undefined}
                dotStyle={{
                    height: 18,
                    bottom: -8,
                    borderRadius: 10,
                }}
                activeDotStyle={{
                    height: 18,
                    bottom: -8,
                    borderRadius: 10,
                    borderColor: "rgb(0, 136, 255)",
                }}
                onBeforeChange={(value) => {
                    if (!Array.isArray(value) && props.handleMouseDown) {
                        props.handleMouseDown(value)
                    }
                }}
                onChange={(value) => {
                    if (!Array.isArray(value)) {
                        if (props.checkSteps && value % props.step !== 0) {
                            return false
                        }
                        props.onChange(value)
                    }
                }}
                onAfterChange={(e) => {
                    if (!Array.isArray(e)) {
                        props.handleMouseUp(e)
                    }
                }}
                handleStyle={{
                    borderColor: "rgb(0 90 255)",
                    opacity: 1,
                    height: isMobile(window.navigator).phone ? 24 : 18,
                    width: isMobile(window.navigator).phone ? 24 : 18,
                    marginLeft: 0,
                    marginTop: isMobile(window.navigator).phone ? -10 : -7,
                    backgroundColor: "rgb(0 90 255)",
                    pointerEvents: "all",
                }}
                railStyle={{ pointerEvents: "none", }}
                trackStyle={{
                    pointerEvents: "none",
                    backgroundColor: "rgb(0, 136, 255)",
                    height: 5,
                }}
                disabled={props.disabled}
                style={{ backgroundColor: "transparent", }}
            />
        </SliderContainer>

    )
}

const SliderContainer = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    pointer-events: none;

    .rotation-mark {
        z-index: -10;
        height: 15px;
        width: 4px;
        background-color: #ddd;
        border-radius: 15px;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 6px;
        bottom: -20px;
    }

    .rc-slider-handle::before {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        background-color: transparent;
        pointer-events: auto;
    }
`

export default BaseSlider