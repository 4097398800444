import React from "react"
import Button from "../../../../../../../../../common/components/Button"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import styled from "styled-components"

interface RelativeMoveProps {
    connectedToSegmentedParts: PartConnectionType[] | undefined;
    connectionIndex: number;
    setConnectionIndex: (index: number) => void;
}

const Container = styled.div`
    padding: 0 16px;
`

const RelativeMove: React.FC<RelativeMoveProps> = (props) => {
    const handleButtonClick = (index: number) => {
        if (props.connectionIndex !== null) {
            props.setConnectionIndex(index)
        }
    }

    const renderButtons = () => {
        if (props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1) {
            return props.connectedToSegmentedParts
                .slice()
                .reverse()
                .map((_, index) => (
                    <Button
                        key={index}
                        onClick={() => handleButtonClick(index)}
                        style={{
                            backgroundColor: props.connectionIndex
                            === index ? "#0088ff" : "initial",
                            color: props.connectionIndex === index ? "white" : "initial",
                        }}
                    >
                        {String.fromCharCode(65
                        + ((props.connectedToSegmentedParts ?? []).length - 1 - index))}
                    </Button>
                ))
        }
        return null
    }

    return (
        <Container>
            {renderButtons()}
        </Container>
    )
}

export default RelativeMove