import React, { useEffect, useState } from "react"
import { useRecoilValue, } from "recoil"
import LoginForm from "./LoginForm"
import styled from "styled-components"
import Button from "../Button"
import { authState } from "../../state/LoginState/RecoilAuthState"
import ReactDOM from "react-dom"

type Props = {
    onCancel: () => void,
    onSuccess?: () => void,
}

const loginPortalId = "login-portal"

export const LoginPortal = () => {
    return <div id={loginPortalId}></div>
}

export const LoginPopUp = (props: Props) => {

    const auth = useRecoilValue(authState)

    useEffect(() => {
        if (auth.user && props.onSuccess) {
            props.onSuccess()
        }

    }, [auth.user,])

    return <>
        {ReactDOM.createPortal(
            <Background id={loginPortalId}>
                <Modal>
                    <LoginForm debug={false} />
                    <Button
                        $borderColor="#fe5a5a"
                        $labelColor="#fe5a5a"
                        action="secondary"
                        $full={true}
                        onClick={props.onCancel}>Cancel</Button>
                </Modal>
            </Background>,
            document.getElementById(loginPortalId)!
        )
        }
    </>
}


export const LoginButton = (props: {
    renderButton: (onClick: () => void) => React.ReactNode,
}) => {
    const [showLogin, setShowLogin,] = useState(false)

    const handleLogin = () => {
        setShowLogin(true)
    }

    const handleClose = () => {
        setShowLogin(false)
    }

    return <>
        {showLogin && <LoginPopUp
            onCancel={handleClose}
            onSuccess={handleClose}
        />}
        {props.renderButton(handleLogin)}
    </>
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #0000006b;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    width: 300px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 22px 39px -19px #0000008f;
`

