/* eslint-disable max-statements */
/* eslint-disable max-params */
import {
    Mesh,
    Vector3,
    Quaternion,
    Raycaster,
    Scene,
    Box3,
    Material,
    BufferGeometry,
    Line,
    LineBasicMaterial
} from "three"
import { FreePositions } from "./types"
import { getOBB, isInsideWithTolerance } from "./OBBBuilder"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"

// function visualizeRay(
//     raycaster: Raycaster,
//     scene: Scene | undefined,
//     length = 0.1,
//     color = 0xff0000,
//     duration?: number
// ) {
//     if (!window.scene) {return}

//     const origin = raycaster.ray.origin
//     const direction = raycaster.ray.direction.clone().multiplyScalar(length)

//     const points = []
//     points.push(origin)
//     points.push(new Vector3().addVectors(origin, direction))

//     const geometry = new BufferGeometry().setFromPoints(points)
//     const material = new LineBasicMaterial({ color, })

//     const line = new Line(geometry, material)
//     window.scene.add(line)

//     if (duration) {
//         setTimeout(() => window.scene.remove(line), duration)
//     }

//     return line
// }

type WalkBoundingBoxParams = {
    mesh: Mesh,
    obb: Mesh | undefined,
    onBoundingPoint: (position: Vector3) => void,
    onMiddlePoint: (position: Vector3) => void,
    stepSize: number,
    shouldDebug: boolean,
    referenceMesh?: Mesh,
};

function findEdges(obb: Mesh, start: Vector3, scene?: Scene) {
    const directions = [
        new Vector3(0, 1, 0),
        new Vector3(0, -1, 0),
        new Vector3(1, 0, 0),
        new Vector3(-1, 0, 0),
    ]

    const raycaster = new Raycaster()
    const startPosition = new Vector3().copy(start)
    const intersectedPoints: Vector3[] = []

    directions.forEach(direction => {
        const localDirection = direction.clone().applyQuaternion(obb.quaternion)
        raycaster.set(startPosition, localDirection)

        const intersects = raycaster.intersectObject(obb, true)
        if (intersects.length > 0) {
            const point = intersects[intersects.length - 1].point
            intersectedPoints.push(point)
        }
    })
    return intersectedPoints
}

function walkBoundingBox(params: WalkBoundingBoxParams): void {
    const {
        mesh,
        onBoundingPoint,
        onMiddlePoint,
        stepSize,
        obb,
        shouldDebug,
        referenceMesh,
    } = params

    if (shouldDebug) {
        // Only visualize the starting positions
        const worldPosition = new Vector3()
        referenceMesh?.getWorldPosition(worldPosition)
        console.log("mesh from walkBoundingBox - position", mesh.position)
        console.log("mesh from walkBoundingBox - parent", mesh.parent?.position)
        console.log("mesh from walkBoundingBox - parent parent", mesh.parent?.parent?.position)
    }

    if (!obb) {
        console.error("OBB is undefined")
        return
    }
    const boundingBox = new Box3().setFromObject(obb)

    const localPosition = referenceMesh ? referenceMesh.position : mesh.position
    const quaternion = referenceMesh ? referenceMesh.quaternion : mesh.quaternion
    const localXAxis = new Vector3(stepSize, 0, 0).applyQuaternion(quaternion)
    const localYAxis = new Vector3(0, stepSize, 0).applyQuaternion(quaternion)
    const parent = mesh.parent || mesh

    function walkYDirection(
        localPositionWithStepX: Vector3,
        startY: number,
        stepY: number,
        isMiddlePoint: boolean
    ): void {
        let j = startY
        let conditionMetY = true
        while (conditionMetY) {
            const localPositionWithStepY
                = localPositionWithStepX.clone().add(localYAxis.clone().multiplyScalar(j))
            const globalPositionWithStepY = parent.localToWorld(localPositionWithStepY.clone())

            if (isInsideWithTolerance(globalPositionWithStepY, boundingBox)) {
                onBoundingPoint(globalPositionWithStepY)

                if (isMiddlePoint) {
                    onMiddlePoint(globalPositionWithStepY)
                }
                j += stepY
            } else {
                conditionMetY = false
            }
        }
    }

    function walkDirection(startX: number, stepX: number): void {
        let i = startX
        let conditionMetX = true
        let isMiddlePoint = true
        while (conditionMetX) {
            const localPositionWithStepX
                = localPosition.clone().add(localXAxis.clone().multiplyScalar(i))
            walkYDirection(localPositionWithStepX, 0, 1, isMiddlePoint)
            walkYDirection(localPositionWithStepX, -stepX, -1, isMiddlePoint)

            const globalPositionWithStepX = parent.localToWorld(localPositionWithStepX.clone())

            if (isInsideWithTolerance(globalPositionWithStepX, boundingBox)) {
                onMiddlePoint(globalPositionWithStepX)
                i += stepX
                isMiddlePoint = false
            } else {
                conditionMetX = false
            }
        }
    }

    walkDirection(0, 1)
    walkDirection(-1, -1)
}

export function walkSurface(
    mesh: Mesh,
    stepSize = 1,
    expandOffset = 0,
    extendSurface = false,
    scene?: Scene,
    shouldDebug = false,
    referenceMesh?: Mesh
) {
    const globalPosition = new Vector3()
    mesh.getWorldPosition(globalPosition)
    const raycaster = new Raycaster()
    raycaster.near = 0
    raycaster.far = 0.1
    const perpendicularAxis = new Vector3(0, 0, 1)
    const points: Vector3[] = []
    const allPoints: Vector3[] = []
    const obbResult = getOBB(mesh, expandOffset)
    if (!obbResult) {
        console.error("Failed to get OBB")
        return { points: [], allPoints: [], }
    }
    if (shouldDebug) {
        // debugger
    }
    const { bboxMesh, bboxMeshExpanded, } = obbResult

    const worldQuaternion = new Quaternion()
    if (referenceMesh) {
        referenceMesh.getWorldQuaternion(worldQuaternion)
    } else {
        mesh.getWorldQuaternion(worldQuaternion)
    }

    const worldPerpendicularAxis = perpendicularAxis.clone().applyQuaternion(worldQuaternion)
    const direction = worldPerpendicularAxis.clone().negate()

    function onMiddlePoint(position: Vector3) {
        if (bboxMesh && bboxMesh.geometry instanceof BufferGeometry
            && (bboxMesh.material instanceof Material || Array.isArray(bboxMesh.material))) {
            const edgePoints = findEdges(bboxMesh, position, scene)
            edgePoints.forEach(p => {
                allPoints.push(p)
            })
            if (shouldDebug) {
                // debugger
            }
        } else {
            console.error("obb is undefined or does not have the expected type.")
        }
    }

    function onBoundingPoint(position: Vector3) {
        if (extendSurface) {
            points.push(position)
            return
        }

        const localXAxisOffset = new Vector3(0, 0, 0.01).applyQuaternion(worldQuaternion)
        const offsetPosition = position.clone().add(localXAxisOffset)

        raycaster.set(offsetPosition, direction)

        if (shouldDebug) {
            // debugger
            // window.drawVector3Point(offsetPosition, 0xff0000, 0.001, 5000)
        }
        // visualizeRay(raycaster, window.scene, 0.1, 0xff0000, 5000)

        const intersects = raycaster.intersectObject(mesh)
        if (intersects.length > 0) {
            // window.drawVector3Point(position, 0xff0000, 0.001, 5000)
            points.push(position)
        }
    }

    walkBoundingBox({
        mesh,
        obb: bboxMeshExpanded,
        onBoundingPoint,
        onMiddlePoint,
        stepSize,
        shouldDebug,
        referenceMesh,
    })
    return { points, allPoints, }
}

export function findPointsAlongAxis(points: FreePositions[], marker: Mesh) {
    const markerPosition = new Vector3()
    marker.getWorldPosition(markerPosition)
    const globalQuaternion = new Quaternion()
    marker.getWorldQuaternion(globalQuaternion)
    const markerXAxis = new Vector3(1, 0, 0).applyQuaternion(globalQuaternion)
        .normalize()

    return points.filter((point: FreePositions) => {
        const directionToPoint = point.position.clone().sub(markerPosition)
            .normalize()
        const dotProduct = directionToPoint.dot(markerXAxis)
        return Math.abs(dotProduct) > 0.999999
    })
}