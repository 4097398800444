import React, { useEffect, useState } from "react"
import { DesignTypeAPI } from "../../../common/api/Types"
import DesignsList from "./DesignsList/DesignList"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { layoutState } from "../../../builder/exportables"
import ReactDOM from "react-dom"
import { produce } from "immer"
import Button from "../../../common/components/Button"
import Loading from "../../../common/components/Loading"
import isMobile from "ismobilejs"
import {
    ActionSlot,
    Container,
    Content,
    Extra,
    Main,
    Header,
    Overlay,
    Title
} from "../../../common/components/DrawerShared"
import { useUnmountDesign } from "../../../builder/exportables"
import LoginDrawerMobile from "../Login/LoginDrawerMobile"
import { authState } from "../../../common/state/LoginState/RecoilAuthState"
import { logOut } from "../../../common/api/firebase/firebaseUtils"
import { DesignsApi } from "../../../common/api/firebase/designs"
import { pendingChangesAtom } from "../../../builder/exportables"
import { UnsavedChangesModal } from "./unsavedChangesModal"
import { getUrlWithQueriesToPreserve } from "../../../common/utils/utils"
import { events, useEventsData } from "../../../common/utils/rudderAnalyticsUtils"
import { RoutesHelper } from "../../../common/utils/routesHelper"
import { userDesigns } from "../../../builder/state/atoms"
import styled from "styled-components"

const Designs = (props: { onClose: () => void, }) => {
    const [showUnsavedChanges, setShowUnsavedChanges,] = useState(false)
    const pendingChanges = useRecoilValue(pendingChangesAtom)
    const overlay = document.getElementById("drawer-overlay")
    const [designs, setDesigns,] = useState<DesignTypeAPI[]>([])
    const [loading, setLoading,] = useState(true)
    const eventsData = useEventsData()
    const [userDesignsState, setDesignsRecoilState,] = useRecoilState(userDesigns)
    const { user, } = useRecoilValue(authState)

    useEffect(() => {
        const fetchDesigns = async () => {
            if (user) {
                const userDesigns = await DesignsApi.fetchUserDesigns()
                setDesigns(userDesigns)
                setDesignsRecoilState(userDesigns)
                setLoading(false)
            }
        }
        if (userDesignsState.length === 0) {
            fetchDesigns()
        } else {
            setLoading(false)
            setDesigns(userDesignsState)
        }
    }, [])

    const navigate = useNavigate()
    const unmount = useUnmountDesign()

    const handleDuplicateDesign = (positionId: string, copy: DesignTypeAPI) => {
        const position = designs.findIndex(d => d.id === positionId)
        designs.splice(position + 1, 0, copy)
        setDesigns([...designs,])
    }

    const removeDesign = (id: string) => {
        setDesigns(designs.filter(d => d.id !== id))
    }

    const goToNewDesign = () => {
        events.initialDesign(eventsData())
        props.onClose()
        unmount()
        navigate(getUrlWithQueriesToPreserve(RoutesHelper.main))
    }



    const handleNewDesign = () => {
        if (pendingChanges) {
            setShowUnsavedChanges(true)
        } else {
            goToNewDesign()
        }

    }

    const handleLogOut = () => {
        logOut()
    }

    const handleNotContinueOnDesignChange = () => {
        setShowUnsavedChanges(false)
    }

    return (
        <Container>
            <Header>
                <Main>
                    <Title>My Designs</Title>
                    <ActionSlot>
                        <Button onClick={props.onClose}>
                            Close
                        </Button>
                    </ActionSlot>
                </Main>
                <Extra>
                    <Button
                        $full={true}
                        $labelColor="#242424"
                        $borderColor="#E2E8F0"
                        onClick={handleNewDesign}>
                        + New Design
                    </Button>
                    {isMobile(window.navigator).phone && <Button
                        $full={true}
                        $labelColor="#242424"
                        $borderColor="#E2E8F0"
                        onClick={handleLogOut}>
                        Log Out
                    </Button>}
                </Extra>
            </Header>
            <Content>
                {
                    loading
                        ? <LoadingComponent />
                        : <DesignsList
                            designs={designs}
                            removeDesign={removeDesign}
                            onDuplicateDesign={handleDuplicateDesign}
                        />
                }
            </Content>
            {overlay && <>
                {ReactDOM.createPortal(<Overlay onClick={props.onClose} />, overlay)}
            </>}
            {showUnsavedChanges
                && <UnsavedChangesModal
                    onConfirm={goToNewDesign} onCancel={handleNotContinueOnDesignChange} />
            }
        </Container>
    )
}


const DesignsOrLogin = () => {
    const auth = useRecoilValue(authState)
    const [layoutValue, setLayout,] = useRecoilState(layoutState)

    const handleClose = () => {
        setLayout(produce((draft) => {
            draft.isDrawerOpen = false
            draft.showDesignsList = false
        }))
    }

    if (layoutValue.isDrawerOpen && layoutValue.showDesignsList) {
        if (!auth.user) {
            return <LoginDrawerMobile />
        }
        return <Designs onClose={handleClose} />
    }
    return null
}

const LoadingComponent = () => {
    return (
        <LoadingContainer>
            Loading designs...
            <Loading />
        </LoadingContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`

export default DesignsOrLogin