/* eslint-disable max-len */
/* eslint-disable default-case */
import { useEffect, useRef } from "react"
import { Box3, Box3Helper, BoxGeometry, Color, MathUtils, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, Quaternion, Scene, Sphere, Vector3 } from "three"
import { SegmentedTubeValues, XYZ, XYZW } from "../../../../../../../../exportables"
import useGLTFLoader from "../../../../../../../../providers/GLTFLoaderProvider/useGLTFLoader"
import useInstancedMeshSegmentedTubes from "../../../../../../../../providers/instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { innerToOuter } from "../../../../../../../../utils/MarkerUtil"
import { SectionType, SegmentedTubeInfo } from "../types/types"
import SegmentedTubeUtils from "../utils/SegmentedTubeUtils"
import useMoonTexture from "../../hooks/useMoonTexture"

interface Props {
    tube: SegmentedTubeValues;
    position?: Vector3;
    rotation?: Quaternion;
    degrees?: number;
    onMeshInstantiated: (mesh: Mesh, cleanup?: () => void, box?: Box3) => void;
    onRotationChange: (mesh: Mesh, cleanup?: () => void, box?: Box3) => void;
    color: string;
    scene: Scene;
}

const SegmentedTubeRender = (props: Props) => {
    const { scene, } = props
    const segmentedTubeRef = useRef<SegmentedTubeInfo>(SegmentedTubeUtils.initSegmentedTubeInfo(scene))
    const { loadModel, } = useGLTFLoader()
    const modifiedTube = {
        ...props.tube,
        name: `${props.tube.name}_preview`,
    }
    const { moonTexture, } = useMoonTexture(8, 8)


    /*const middlePartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const startPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const endPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()*/


    const createPivotPoint = () => {
        segmentedTubeRef.current.originPoint = new Object3D()
        segmentedTubeRef.current.originPoint.name = "Origin Point"
        scene.add(segmentedTubeRef.current.originPoint)
    }

    const loadModelsAndMarkers = async (modelPath: string) => {
        const gltf = await loadModel(modelPath)
        segmentedTubeRef.current.blenderData = SegmentedTubeUtils.processData(gltf)
    }

    /*const getProvider = (sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.END:
                return endPartInstancedMeshProvider
            case SectionType.MIDDLE:
                return middlePartInstancedMeshProvider
            case SectionType.START:
                return startPartInstancedMeshProvider
        }
    }*/

    /*const createPools = () => {
        segmentedTubeRef.current.blenderData.forEach((bd) => {
            SegmentedTubeUtils.createPool(
                `${modifiedTube.id}`,
                `${modifiedTube.name}_${bd.sectionType}`,
                bd.mainModel,
                scene,
                getProvider(bd.sectionType))
        })
    }*/

    const getAttachmentPointOrigin = () => {
        const outerInitialMaker = innerToOuter(modifiedTube.initialMarkerName)
        const originWithMarker = segmentedTubeRef.current.instancedMeshOrigins
            .find(origin => origin.children.some(c => c.name === outerInitialMaker))
        const childWithName = originWithMarker?.children.find(child => child.name === outerInitialMaker)
        return childWithName
    }

    const setPositionAndRotation = (position: XYZ, rotation: XYZW, degrees?: number) => {
        segmentedTubeRef.current.attachmentPoint?.position.copy(new Vector3(position.x, position.y, position.z))
        segmentedTubeRef.current.attachmentPoint?.quaternion.copy(new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w))
        segmentedTubeRef.current.attachmentPoint?.rotateY(MathUtils.degToRad(180))

        // apply rotation for preview alternatives
        if (degrees && segmentedTubeRef.current.attachmentPoint) {
            const currentRotationZ = segmentedTubeRef.current.attachmentPoint?.rotation.z
            const newRotationZ = currentRotationZ! + MathUtils.degToRad(degrees)
            segmentedTubeRef.current.attachmentPoint.rotation.z = newRotationZ
        }

        /*SegmentedTubeUtils.updateInstancedTransforms(
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            segmentedTubeRef)*/
    }

    const boundgBoxForCamera = new Box3()

    const createBoundingBox = () => {
        if (segmentedTubeRef.current.mergedMesh) {
            boundgBoxForCamera.makeEmpty()
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            boundgBoxForCamera.expandByObject(segmentedTubeRef.current.mergedMesh)
            const sphere = new Sphere()
            boundgBoxForCamera.getBoundingSphere(sphere)
            //const help = new Box3Helper(boundgBoxForCamera, new Color("blue"))
            //scene.add(help)
            return boundgBoxForCamera
        }
        const origins = segmentedTubeRef.current.instancedMeshOrigins
        const firstObject = origins[0]
        const lastObject = origins[origins.length - 1]
        if (firstObject && lastObject) {
            const firstMesh = firstObject.children.find(child => child.name.includes("mesh")) || firstObject.children[1]
            const lastMesh = lastObject.children.find(child => child.name.includes("mesh")) || lastObject.children[1]
            if (firstMesh && lastMesh) {
                (firstMesh as Mesh).geometry.computeBoundingBox();
                (lastMesh as Mesh).geometry.computeBoundingBox()

                boundgBoxForCamera.makeEmpty()

                boundgBoxForCamera.expandByObject(firstMesh)
                boundgBoxForCamera.expandByObject(lastMesh)

                // Uncoment to debug bounds
                //const help = new Box3Helper(clonedBox, new Color("blue"))
                //scene.add(help)
                return boundgBoxForCamera
            }
        }


        return new Box3()
    }

    const initialSetup = async () => {
        createPivotPoint()
        await loadModelsAndMarkers(modifiedTube.fileURL)
        //createPools()
        SegmentedTubeUtils.createInstancedMeshOrigins(
            modifiedTube,
            segmentedTubeRef,
            undefined,
            undefined,
            undefined,
            modifiedTube.length,
            modifiedTube.lengthNegativeSide,
            1,
            moonTexture,
            "yellow",
        )
        updateColor("yellow")

        const attachmentPoint = getAttachmentPointOrigin()

        //console.log(attachmentPoint, "attachmentPoint")

        SegmentedTubeUtils.setAtachmentPoint(attachmentPoint || segmentedTubeRef.current.instancedMeshOrigins[0].children[2], segmentedTubeRef)

        segmentedTubeRef.current.originPoint?.updateMatrixWorld(true)

        setPositionAndRotation(props.position || modifiedTube.position, props.rotation || modifiedTube.rotation, props.degrees)

        const createAndAddBox = () => {
            const emptyObject3d = new Object3D()
            segmentedTubeRef.current.originPoint?.attach(emptyObject3d)
        }

        createAndAddBox()

        //console.log("onMeshInstantiated", segmentedTubeRef.current.attachmentPoint, "attachmentPoint")
        props.onMeshInstantiated(attachmentPoint as Mesh, cleanup, createBoundingBox())

    }

    const colorMergedMesh = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh && segmentedTubeRef.current.mergedMesh.material) {
            (segmentedTubeRef.current.mergedMesh.material as MeshStandardMaterial).color.set(color)
        }
    }

    const updateColor = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh) {
            colorMergedMesh(color)
        }
    }


    const cleanup = (removeMergedMesh?: boolean) => {
        SegmentedTubeUtils.cleanUpPreviousOrigins(segmentedTubeRef)
        if (removeMergedMesh && segmentedTubeRef.current.mergedMesh) {
            //segmentedTubeRef.current.mergedMesh.removeFromParent()
        }
        //middlePartInstancedMeshProvider.deleteInstancedMesh()
        //startPartInstancedMeshProvider.deleteInstancedMesh()
        //endPartInstancedMeshProvider.deleteInstancedMesh()
    }

    useEffect(() => {
        if (!segmentedTubeRef.current.attachmentPoint) { return }
        setPositionAndRotation(props.position || modifiedTube.position, props.rotation || modifiedTube.rotation, props.degrees)
        segmentedTubeRef.current.attachmentPoint.updateMatrixWorld()
        props.onRotationChange(segmentedTubeRef.current.attachmentPoint as Mesh, cleanup, createBoundingBox())
    }, [props.degrees,])

    useEffect(() => {
        initialSetup()

        return () => {
            //SegmentedTubeUtils.cleanUpPreviousOrigins(segmentedTubeRef)
            //middlePartInstancedMeshProvider.deleteInstancedMesh()
            //startPartInstancedMeshProvider.deleteInstancedMesh()
            //endPartInstancedMeshProvider.deleteInstancedMesh()
            if (segmentedTubeRef.current.mergedMesh) {
                segmentedTubeRef.current.mergedMesh.removeFromParent()
            }
        }
    }, [])

    return null

}

export default SegmentedTubeRender