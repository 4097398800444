import { MutableRefObject } from "react"
import {
    Mesh,
    Object3D,
    Scene,
    Vector3
} from "three"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { ConnectorPart, ConnectorValues, MarkerType } from "../../../../../../../../utils/Types"
import { ConnectorInternalsType, RayStatesType, RayStateType } from "../types/types"
import {
    checkExactSnap,
    getRayStateDefault,
    isCompatibleWith,
    removeGuidelines
} from "./ConnectorUtils"
import { isCollineal, isParallel, isSameDirection } from "../../../../../utils/utilsThree"
import {
    getOtherPartOfTheConnection,
    isInConnection
} from "../../../../../../../../state/scene/util"
import {
    LengthUtils
} from "../../../../../../../../providers/multipleMovementProvider/utils/LengthUtils"
import { sort } from "../../tube/utils/TubeUtils"
import { filterWithValue, ObjDictionary } from "../../../../../../../../../common/utils/utils"
import { ConnectionTypeAPI } from "../../../../../../../../../common/api/Types"
import {
    addLines,
    getMarkerUserData,
    isMarkerUserData
} from "../../../../../../../../utils/MarkerUtil"
import { getFreeMarkers } from "../../../../../../../../utils/PartUtils"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"

const useDetachFromMarker = (props: {
    connector: ConnectorPart,
    attachedMarker: MutableRefObject<Mesh | undefined>,
    scene: Scene,
    buildCollider: () => void,
    updateCollider: (connectedParts: string[]) => void,
    connectorInternalsRef: MutableRefObject<ConnectorInternalsType>,
    setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
}) => {
    const {
        connector,
        attachedMarker,
        scene,
        buildCollider,
        updateCollider,
        connectorInternalsRef,
        setInternals,
        compatibilityList,
    } = props

    return (
        marker: Mesh,
        connectedParts: string[],
        connectedMarkers: string[],
        getExactSnaps?: boolean,
        checkCollisions?: boolean,
        resetGuidelines?: boolean
    ) => {
        if(resetGuidelines) {
            removeGuidelines(connectorInternalsRef, scene, setInternals)
        }
        const position = MeshUtils.copyWorldPosition(attachedMarker.current!)
        const rotation = MeshUtils.copyWorldQuaternion(attachedMarker.current!)
        scene.attach(attachedMarker.current!)
        attachedMarker.current?.position.set(position.x, position.y, position.z)
        attachedMarker.current?.quaternion.set(rotation.x, rotation.y, rotation.z, rotation.w)

        if(getExactSnaps) {
            const exactSnaps = checkExactSnap(
                scene,
                connector,
                connectorInternalsRef.current,
                getFreeMarkers(connector, connectedMarkers),
                connectedParts,
                compatibilityList
            )
            if(checkCollisions) {
                buildCollider()
                if(exactSnaps.length === 0) {
                    updateCollider(connectedParts)
                } else {
                    updateCollider([
                        ...connectedParts,
                        ...exactSnaps.map((c: any) => getOtherPartOfTheConnection(c, connector.id)),
                      ])
                    SoundHelper.playUnsnap()
                }
            }
            return exactSnaps
        }
        return []
    }
}

const proyectRays = (
    partId: string,
    scene: Scene,
    connectorInternals: ConnectorInternalsType,
    connections: PartConnectionType[],
    setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
    intersectableMeshes?: Object3D[]
) => {
    const { markers, guidelines, rayStates, } = connectorInternals
    guidelines.forEach((line) => {
        scene.remove(line)
    })
    const newRayStates: RayStatesType = {}
    Object.keys(markers).filter(
        m => !connections.some(
            connection => isInConnection(connection, partId, m)
        )
    )
    .forEach(m => {
        const marker = markers[m]
        if(!rayStates[m]) {
            rayStates[m] = getRayStateDefault()
        }
        marker.getWorldPosition(rayStates[m].worldMarkerPosition)
        marker.getWorldDirection(rayStates[m].worldMarkerDirection)
        rayStates[m].ray.set(rayStates[m].worldMarkerPosition, rayStates[m].worldMarkerDirection)
        const objects = rayStates[m].ray.intersectObjects(
            intersectableMeshes
            ? intersectableMeshes
            : scene.children)
        newRayStates[m] = rayStates[m]
        newRayStates[m].objects = objects
    })
    setInternals({
        ...connectorInternals,
        rayStates: newRayStates,
        guidelines: [],
    })
}

const areParallel = (rayState: RayStateType, markerMesh: Object3D, object: Object3D) => {
    markerMesh.getWorldDirection(rayState.worldMarkerDirection)
    object.getWorldDirection(rayState.colliderWorldDirection)

    return isParallel(
        rayState.worldMarkerDirection,
        rayState.colliderWorldDirection,
    )
}

const areCollineal = (markerMesh: Object3D, object: Object3D) => {
    const markerDir = MeshUtils.copyWorldDirection(markerMesh)
    const markerPos = MeshUtils.copyWorldPosition(markerMesh)
    const objectDir = MeshUtils.copyWorldDirection(object)
    const objectPos = MeshUtils.copyWorldPosition(object)

    return isCollineal(markerDir, markerPos, objectDir, objectPos)
}

const areSameDirection = (markerMesh: Object3D, object: Object3D) => {
    const markerDir = MeshUtils.copyWorldDirection(markerMesh)
    const objectDir = MeshUtils.copyWorldDirection(object)
    return isSameDirection(markerDir, objectDir)
}

const checkAlignments = (props: {
    connector: ConnectorValues,
    scene: Scene,
    connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
    connections: PartConnectionType[],
    setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
    elongationDirection?: Vector3,
    lengthDirection?: number,
    intersectableMeshes?: Object3D[],
}) => {
    const {
        connector,
        scene,
        connectorInternalsRef,
        connections,
        setInternals,
        elongationDirection,
        lengthDirection,
    } = props
    proyectRays(
        connector.id,
        scene,
        connectorInternalsRef.current,
        connections,
        setInternals,
        props.intersectableMeshes
    )
    const {rayStates, markers,} = connectorInternalsRef.current
    const alignments = Object.keys(rayStates).map(r => {
        const markerMesh = markers[r]
        const rayState = rayStates[r]
        if(!elongationDirection || !isCollineal(
            elongationDirection,
            rayState.worldMarkerPosition,
            rayState.worldMarkerDirection,
            rayState.worldMarkerPosition
        )) {
            const rayStateObject = rayState.objects.find(o => {
                return (((o.face) && (o.object.userData.partId !== connector.id)
                && markerMesh && (o.object.userData.type === MarkerType.COLLISION)))
            })
            const DISTANCE_TO_ACTIVATE_GUIDELINE = 20
            if(rayStateObject) {
                if (rayStateObject.distance < DISTANCE_TO_ACTIVATE_GUIDELINE) {
                    rayStateObject.object.getWorldDirection(rayState.colliderWorldDirection)
                    rayStateObject.object.getWorldPosition(rayState.colliderWorldPosition)
                    if(((!elongationDirection && areCollineal(markerMesh, rayStateObject.object))
                        || (elongationDirection
                         && areParallel(rayState, markerMesh, rayStateObject.object)))
                         && (!areSameDirection(markerMesh, rayStateObject.object))
                    ) {
                        const distanceToElongate = lengthDirection && elongationDirection
                        ? LengthUtils.getDistanceToElongate(
                            rayStateObject,
                            rayState.worldMarkerPosition,
                            elongationDirection,
                            lengthDirection,
                            scene
                        ) : 0
                        const guidelines = addLines(
                            scene,
                            [rayState.worldMarkerPosition,rayState.colliderWorldPosition,],
                            connectorInternalsRef
                        )
                        setInternals({
                            ...connectorInternalsRef.current,
                            guidelines,
                        })
                        if(!lengthDirection) {
                            return 0
                        }
                        return lengthDirection < 0 ? -distanceToElongate : distanceToElongate
                    }
                }
            }
        }
        return undefined
    })
    const dragDistance = alignments.find(a => a !== undefined)
    return dragDistance ? { drag: true, dragDistance, } : { drag: false, } as const
}

const checkSnap = (props: {
    connector: ConnectorValues,
    scene: Scene,
    connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
    connections: PartConnectionType[],
    setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
    compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
    elongationDirection: Vector3,
}) => {
    const {
        connector,
        scene,
        connectorInternalsRef,
        connections,
        setInternals,
        compatibilityList,
        elongationDirection,
    } = props
    proyectRays(connector.id, scene, connectorInternalsRef.current, connections, setInternals)

    const { rayStates, } = connectorInternalsRef.current

    const snaps = Object.keys(rayStates).map(r => {
        const marker = connector.markers.find(c => c.name === r)!
        const rayState = rayStates[r]

        if(marker && isCollineal(
            rayState.worldMarkerDirection,
            rayState.worldMarkerPosition,
            elongationDirection,
            rayState.worldMarkerPosition
        )) {
            const filteredObjects = rayState.objects.filter(o => {
                if (isMarkerUserData(o.object)) {
                  const userData = getMarkerUserData(o.object)
                  if (o.face
                    && (userData.id !== connector.id)
                    && (userData.type === MarkerType.COLLISION)
                    && isCompatibleWith(marker, o, compatibilityList!)
                  ) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return false
                }
            })

            filteredObjects.sort(sort)

            const rayStateObject = filteredObjects[0]

            const DISTANCE_TO_SNAP = 1

            if(rayStateObject) {
                rayStateObject.object.getWorldPosition(rayState.colliderWorldPosition)
                rayStateObject.object.getWorldDirection(rayState.colliderWorldDirection)
                if(isCollineal(
                    rayState.colliderWorldDirection,
                    rayState.colliderWorldPosition,
                    rayState.worldMarkerDirection,
                    rayState.worldMarkerPosition
                    ) && rayStateObject.distance < DISTANCE_TO_SNAP
                    && isMarkerUserData(rayStateObject.object)
                ) {
                    return {
                        snap: true,
                        snapDistance: rayStateObject.distance,
                        selectedObject: rayStateObject,
                        newConnections: [{
                            partA: {
                                partId: connector.id,
                                markerName: marker.name,
                            },
                            partB: {
                                partId: rayStateObject.object.userData.partId,
                                markerName: rayStateObject.object.userData.markerName,
                            },
                        },],
                    }
                }
            }
        }
        return undefined
    })

    const snapData = snaps.find(a => a)

    return snapData ? snapData : { snap: false, } as const
}

const getMaxLength = (props: {
    connector: ConnectorValues,
    scene: Scene,
    connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
    connections?: PartConnectionType[],
    setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
    compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
    direction: Vector3,
}) => {
    const {
        connector,
        scene,
        connectorInternalsRef,
        connections,
        setInternals,
        compatibilityList,
        direction,
    } = props
    if(connections) {
        proyectRays(connector.id, scene, connectorInternalsRef.current, connections, setInternals)

        const { rayStates, } = connectorInternalsRef.current

        const maxLengths = Object.keys(rayStates).map(r => {
            const marker = connector.markers.find(c => c.name === r)!
            const rayState = rayStates[r]

            if(marker && isCollineal(
                rayState.worldMarkerDirection,
                rayState.worldMarkerPosition,
                direction,
                rayState.worldMarkerPosition
            )) {
                const filteredObjects = rayState.objects.filter(o => {
                    if (isMarkerUserData(o.object)) {
                        const userData = getMarkerUserData(o.object)
                        o.object.getWorldPosition(rayState.colliderWorldPosition)
                        o.object.getWorldDirection(rayState.colliderWorldDirection)
                        if (o.face
                            && (userData.id !== connector.id)
                            && (userData.type === MarkerType.COLLISION)
                            && isCompatibleWith(marker, o, compatibilityList!)
                            && isCollineal(
                                rayState.colliderWorldDirection,
                                rayState.colliderWorldPosition,
                                rayState.worldMarkerDirection,
                                rayState.worldMarkerPosition
                            )
                        ) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                })
                filteredObjects.sort(sort)

                const rayStateObject = filteredObjects[0]

                if(rayStateObject) {
                    return rayStateObject.distance
                }
            }
            return null
        })
        if(!maxLengths) {
            return null
        }
        const filteredMaxLengths = filterWithValue(maxLengths)
        filteredMaxLengths.sort((a, b) => a - b)
        return filteredMaxLengths[0]
    }
    return null
}

export const MultipleMovementUtils = {
    useDetachFromMarker,
    checkAlignments,
    checkSnap,
    getMaxLength,
}