/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from "react"

type GetDataMutate = {
    mutate: () => void,
}
type GetDataResponseWithoutError<T>
    = (
        {
            loading: true,
            data: null,
        }
        |
        {
            loading: false,
            data: T,
        }
    )
    &
    GetDataMutate

type GetDataResponseError
    = {
        loading: false,
        error: true,
        data: null,
    }
    &
    GetDataMutate
type GetDataResponse<T> =
    GetDataResponseError
    |
    (
        GetDataResponseWithoutError<T> & {
            error: false,
        }
    )


type Params<T, Q> = {
    getData: (query: Q) => Promise<T>,
    key?: string,
    query: Q,
}


const getResponse = <T>(params: {
    error: boolean,
    loading: boolean,
    response: T | EmptyData,
    mutate: GetDataResponse<unknown>["mutate"],

}) => {
    const {
        response,
        error,
        mutate,
        loading,
    } = params
    if (error) {
        return {
            loading: false,
            error: true,
            data: null,
            mutate,
        } as const
    } else if (loading || response === emptyData) {
        return {
            loading: true,
            error: false,
            data: null,
            mutate,
        } as const
    } else {
        return {
            loading: false,
            error: false,
            data: response,
            mutate,
        } as const
    }
}


const emptyData = Symbol("emptyData")

type EmptyData = typeof emptyData

// eslint-disable-next-line max-lines-per-function
export const useGetData = <T, Q>(params: Params<T, Q>
): GetDataResponse<T> => {
    const [mutationCount, setMutationCount,] = useState<number>(0)
    const [response, setResponse,] = useState<T | EmptyData>(emptyData)
    const {
        error,
        loading,
        handleSuccess,
        handleStart,
        handleError,
        handleClear,
    } = useRequestState()

    useEffect(() => {
        let cancel = false;
        (async () => {
            try {
                handleStart()
                const r = await params.getData(params.query)
                if (!cancel) {
                    setResponse(r)
                    handleSuccess()
                }
            } catch (e) {
                console.error("e", e)
                handleError()
            }
        })()
        return () => {
            cancel = true
            setResponse(emptyData)
            handleClear()
        }
    }, [params.key ? params.key : params.query, mutationCount,])

    const mutate = useCallback(() => {
        setMutationCount(s => s + 1)
    }, [])

    return useMemo(() => {
        return getResponse({
            error,
            loading,
            mutate,
            response,
        })
    }, [error, loading, mutate, response,])
}

const useRequestState = () => {
    const [
        {
            loading,
            error,
        },
        setState,
    ] = useState<{
        loading: boolean,
        error: boolean,
    }>({
        loading: false,
        error: false,
    })

    const handleStart = () => {
        setState({
            loading: true,
            error: false,
        })
    }

    const handleSuccess = () => {
        setState({
            loading: false,
            error: false,
        })
    }

    const handleClear = () => {
        setState({
            loading: false,
            error: false,
        })
    }

    const handleError = () => {
        setState({
            loading: false,
            error: true,
        })
    }


    return {
        loading,
        error,
        handleStart,
        handleSuccess,
        handleError,
        handleClear,
    }
}