import React, { useEffect, useRef } from "react"
import { Mesh, Vector3 } from "three"
import useUnitConversion, { convertCmToIn } from "../../../../../../utils/UnitUtils"
import TShapedLine from "./TShapedLine"
import TextLabel from "./TextLabel"

interface MeasurementProps {
    pointA: Vector3;
    pointB: Vector3;
}

const Measurement = ({ pointA, pointB, }: MeasurementProps) => {
    const distance = pointA.distanceTo(pointB)
    const distanceCm = distance * 100
    const distanceIn = convertCmToIn(distanceCm)

    pointA.z -= 0.0005
    pointB.z -= 0.0005

    const { unit, } = useUnitConversion()

    const textRef = useRef<Mesh>(null)

    const labelPosition = new Vector3().addVectors(pointB, pointB.clone().sub(pointA)
        .normalize()
        .multiplyScalar(0.025))

    useEffect(() => {
        if (textRef.current) {
            const lookAtPointLocal = new Vector3(0, 0, -1)
            const lookAtPointWorld = textRef.current.localToWorld(lookAtPointLocal)
            textRef.current.lookAt(lookAtPointWorld)
        }
    }, [])

    const measurementNumber = unit === "in"
        ? parseFloat(distanceIn.toFixed(2)) : parseFloat(distanceCm.toFixed(2))
    const measurementUnit = unit === "in" ? "\"" : " cm"

    return (
        <>
            <TShapedLine pointA={pointA} pointB={pointB} />
            <TextLabel position={labelPosition} text={`${measurementNumber}${measurementUnit}`} />
            {/* <Sphere position={lookAtPoint} args={[0.01, 32, 32,]} /> */}
        </>
    )
}

export default Measurement