/* eslint-disable @typescript-eslint/no-explicit-any */

import { matchPath } from "react-router-dom"

export type ObjDictionary<T> = {
  [a: string]: T,
}

export const toDictionary = <T>(data: T[], getKey: (o: T) => string) => {
  return data.reduce<ObjDictionary<T>>((acc, curr) => {
    const key = getKey(curr)
    if (key) {
      acc[key] = curr
    }
    return acc
  }, {})
}

export type GroupedBy<T> = {
  key: any,
  values: T[],
};

type ObjGroup<T> = Record<string, T[]>;

export const GroupByList = <T>(data: T[], getKey: (o: T) => string | string[]) => {
  const order: string[] = []
  const dict = data.reduce<ObjGroup<T>>((acc, curr) => {
    const setData = (key: string) => {
      if (acc[key]) {
        acc[key].push(curr)
      } else {
        acc[key] = [curr,]
        order.push(key)
      }
    }
    const key = getKey(curr)
    if (key) {
      if (typeof key === "object") {
        key.forEach(k => setData(k))
      } else {
        setData(key)
      }
    }
    return acc
  }, {})

  return order.map((i) => ({
    key: i,
    values: dict[i],
  }))
}

export type GroupedData<T> = {
  key: string,
  values: T[] | GroupedData<T>[],
}

export const recursiveGroupByList = <T>(
  data: T[], getKeys: ((o: T) => string | string[])[]
) => {
  const [firstGetKey, ...restOfGetKeys] = getKeys
  let groupedData: GroupedData<T>[] = GroupByList(data, firstGetKey)
  if (restOfGetKeys.length > 0) {
    groupedData = groupedData.map(gd => {
      return { key: gd.key, values: recursiveGroupByList(gd.values as T[], restOfGetKeys), }
    })
  }
  return groupedData
}

export const filterWithValue = <T>(arr: (T | null | undefined)[]): T[] => {
  return arr.filter(t => t !== null && t !== undefined) as T[]
}

export const compare = <T>(getKey: (a: T) => string | number) => (a: T, b: T) => {
  if (getKey(a) < getKey(b)) {
    return -1
  }
  if (getKey(a) > getKey(b)) {
    return 1
  }
  return 0
}

export const mapAsync = async <T, R>(items: T[], f: (p: T) => Promise<R>): Promise<R[]> => {
  const ret: R[] = []
  for (let index = 0; index < items.length; index++) {
    const element = items[index]
    ret.push(await f(element))
  }

  return ret
}

export const flatten = <T>(i: T[][]) => {
  return i.reduce((ar, val) => {
    return ar.concat(val)
  }, [])
}

const URL_QUERIES_TO_PRESERVE = ["storeIsOpen",]

export const getUrlWithQueriesToPreserve = (url: string) => {
  const urlObject = new URL(window.location.href)
  const newUrlObject = new URL(url, window.location.origin)
  const queries = urlObject.searchParams
  URL_QUERIES_TO_PRESERVE.forEach(q => {
    const queryValue = queries.get(q)
    if (queryValue) {
      newUrlObject.searchParams.set(q, queryValue)
    }
  })
  return newUrlObject.pathname + newUrlObject.search
}

export const getUserDesignIdsFromUrl = () => {
  const match = matchPath("/design/:userId/:designId", window.location.pathname)

  if (match) {
    return {
      userId: match.params.userId,
      designId: match.params.designId,
    }
  }

  return undefined
}

export const arrayRange = (start: number, stop: number, step: number) => Array.from(
  { length: (stop - start) / step + 1, },
  (value, index) => start + index * step
)

export const SELECTED_PART_COLOR = "#00a2ff"