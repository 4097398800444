import { PartTypeAPI } from "../../../../../common/api/Types"
import { ModalType } from "../../../../../common/providers/modalProvider/modalProvider"
import React from "react"
import useModal from "../../../../../common/providers/modalProvider/useModal"
import { materialsSelector } from "../../../../state/initialDataSelectors"
import { useRecoilValue } from "recoil"

export const useOnboardingModal = () => {
  const { showModal, onConfirm, } = useModal()
  const materials = useRecoilValue(materialsSelector)

  const showOnboarding = (content: JSX.Element) => {
    showModal({
      title: "",
      subtitle: "",
      onConfirm: () => { },
      type: ModalType.content,
      content: content,
      animate: true,
    })
  }

  const shouldShowOnboarding = (
    part: PartTypeAPI,
    getOnboarding: Function,
    materialName: string
  ): boolean => {
    const partMaterial = materials.find(
      material => material.id === part.materialId
    )
    const hasSeenOnboarding = getOnboarding()
    const isMaterial = partMaterial?.materialName === materialName
    const urlParams = new URLSearchParams(window.location.search)
    const forceOnboardingParam = urlParams.get("forceOnboarding")
    return isMaterial && (!!forceOnboardingParam || !hasSeenOnboarding)
  }


  const reportPart = (part: PartTypeAPI) => {
    // turned off for now
  }

  return { reportPart, }
}
