import {
    areSameConnection
} from "../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { ModalState, ModalType } from "../../common/providers/modalProvider/modalProvider"
import { getOppositeTubeMarker } from "./MarkerUtil"
import { GenericPartState, PartTypeEnum, TubeMarkerEnum } from "./Types"

export const handleCollisionOnCreation = (props: {
    buildCollider: () => void,
    updateCollider: (removePart?: () => void) => void,
    deleteCollider: () => void,
    partType: PartTypeEnum,
    onRemove: () => void,
    showModal: (props: ModalState) => void,
}) => {
    const part = props.partType === PartTypeEnum.tube ? "Tube" : "Connector"
    props.buildCollider()
    props.updateCollider(() => {
        props.showModal({
            title: `${part} is colliding`,
            subtitle: `The ${part.toLowerCase()} you just placed collides with another part.
                You can either delete it or keep it and fix the colliding parts yourself.`,
            onCancel: props.onRemove,
            cancelText: "Remove part",
            type: ModalType.confirm,
            onConfirm: () => {},
            confirmText: "Keep part",
        })
    })
}

export const getFreeMarkers = (part: GenericPartState, connectedMarkers: string[]) => {
    if(part.type === PartTypeEnum.connector) {
        return part.markers.filter(m =>
            !connectedMarkers.some(cm => areSameConnection(cm, m.name)))
            .map(c => c.name)
    } else if(connectedMarkers.length === 0) {
        return [TubeMarkerEnum.BOTTOM, TubeMarkerEnum.TOP,]
    } else if(connectedMarkers.length === 1) {
        return [getOppositeTubeMarker(connectedMarkers[0] as TubeMarkerEnum),]
    } else {
        return []
    }
}

export const FIRST_PART_INITIAL_MARKER = "inner1"