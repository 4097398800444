import { useRef } from "react"

type DebugVarsType = {
    partCount: string | null,
    colliderDisplay: string | null,
    gpuData: string | null,
    isAdmin: boolean | null,
}

const useGetDebugVariables = () => {
    const debugVars = useRef<DebugVarsType>({
        partCount: null,
        colliderDisplay: null,
        gpuData: null,
        isAdmin: null,
    })

    const getVariables = () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        debugVars.current.colliderDisplay = urlParams.get("colliderDisplay")
        debugVars.current.gpuData = urlParams.get("gpuData")
        debugVars.current.partCount = urlParams.get("partCount")
        debugVars.current.isAdmin = urlParams.get("admin") === "true"
        return debugVars.current
    }

    return { getVariables, }
}

export default useGetDebugVariables