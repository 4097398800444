import React from "react"
import styled from "styled-components"
import { LoginPortal } from "../../common/components/LoginPopup/LoginPopUp"
import { SharePortal } from "./ShareModal/ShareModal"

const Modals = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: fit-content;
    height: fit-content;
`

export const Manager = () => {
    return (
        <>
            <Modals id="modals-container" />
            <Modals>
                <LoginPortal />
                <SharePortal />
            </Modals>
        </>
    )
}