/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-len */
import { MutableRefObject, useEffect, useState } from "react"
import { MathUtils, Mesh } from "three"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import {
    getOtherPartOfTheConnection,
    isInConnection
} from "../../../../../../../../state/scene/util"
import { innerToOuter } from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { ConnectorPart, ConnectorValues, XYZ, XYZW } from "../../../../../../../../utils/Types"
import { ConnectorInternalsType, CONNECTOR_UI } from "../types/types"
import {
    areSameConnection,
    getInitialMarker,
    getRotationMarker,
    resetMarkerValues,
    setMarkerDirAndPos
} from "./ConnectorUtils"

type UseHandleRotationProps = {
    connector: ConnectorValues,
    connectorUI: CONNECTOR_UI,
    attachedMarker: MutableRefObject<Mesh | undefined>,
    connectorInternalsRef: MutableRefObject<ConnectorInternalsType>,
    attachEverythingTo: (marker: Mesh) => void,
    detachEverythingFrom: (marker: Mesh) => void,
    buildCollider: () => void,
    updateCollider: (removePart?: (() => void), newConnectedParts?: string[]) => void,
    saveRotationChanges: (newConnectorValues: {
        posAndRot: {
            pos: XYZ,
            rot: XYZW,
        },
        rotationMarkerName: string,
        initialMarkerName: string,
    }, partIds: string[], newConnections: PartConnectionType[], specialRotationMarker?: boolean) => void,
    canEditRotation: () => {
        rotationMarker: string,
        partsToMove: string[],
    } | undefined,
    handleConnectorState: () => void,
    checkExactSnap: () => PartConnectionType[],
    setConnectorUI: (ui: CONNECTOR_UI) => void,
    slideEnabled: boolean,
    attachToMove: (partsIds: string[], marker: Mesh) => void,
    detachMarkers: (partsIds: string[], marker: Mesh, getExactSnaps?: boolean, checkCollisions?: boolean, resetGuidelines?: boolean) => PartConnectionType[],
}

const useHandleRotation = (props: UseHandleRotationProps) => {
    const [multipleMove, setMultipleMove,] = useState<{
        rotationMarker: string,
        partsToMove: string[],
        toSave: boolean,
        newConnections: PartConnectionType[],
    } | undefined>(undefined)

    useEffect(() => {
        return () => setMultipleMove(undefined)
    }, [])

    const [actualRotation, setActualRotation,]
        = useState({ markerName: props.attachedMarker.current?.name, rotation: 0, })
    const [initialRotation, setInitialRotation,] = useState(0)
    const [isInitialRotationSet, setIsInitialRotationSet,] = useState(false)

    const saveInitialRotation = (markerName: string) => {
        setInitialRotation(props.connectorInternalsRef.current.markers[markerName].rotation.z)
        setIsInitialRotationSet(true)
    }

    const updateActualRotation = (newValue: number) => {
        setActualRotation({ ...actualRotation, rotation: newValue, })
    }

    const resetMultipleMove = () => {
        setMultipleMove(undefined)
    }

    const setNewChangesToSave = (newConnections: PartConnectionType[]) => {
        setMultipleMove({ ...multipleMove!, toSave: true, newConnections, })
    }

    const changeAttachedMarker = (newAttachedMarker: Mesh) => {
        const posAux = MeshUtils.copyWorldPosition(newAttachedMarker)
        const rotAux = MeshUtils.copyWorldQuaternion(newAttachedMarker)
        resetMarkerValues(props.attachedMarker.current!)
        props.detachEverythingFrom(props.attachedMarker.current!)
        props.attachEverythingTo(newAttachedMarker)
        setMarkerDirAndPos(newAttachedMarker, rotAux, posAux)
    }

    const resetGuidelines = () => {
        if (multipleMove) {
            props.attachToMove(multipleMove?.partsToMove, props.attachedMarker.current!)
            props.detachMarkers(multipleMove?.partsToMove, props.attachedMarker.current!, true, false, true)
        }
    }

    const setUserRotation = (value: number) => {
        if (props.attachedMarker.current) {
            setActualRotation({ markerName: props.attachedMarker.current.name, rotation: value, })
            saveInitialRotation(props.attachedMarker.current!.name)
        }
    }

    const saveMultipleMoveRotation = () => {
        //console.log("saveMultipleMoveRotation")
        const initialMarker
            = getInitialMarker(props.connector, props.connectorInternalsRef.current.markers)

        //const rotationMarker = findRotationMarkerWithinRotationMarkerName()

        initialMarker.rotateY(MathUtils.degToRad(-180))
        const pos = MeshUtils.copyWorldPosition(initialMarker)

        const rot = props.connector.rotation
        initialMarker.rotateY(MathUtils.degToRad(180))

        //rotationMarker?.rotateY(MathUtils.degToRad(-180))
        //const rot = rotationMarker ? MeshUtils.copyWorldQuaternion(rotationMarker) : props.connector.rotation
        //rotationMarker?.rotateY(MathUtils.degToRad(180))


        const newExactSnaps = props.checkExactSnap()
        props.saveRotationChanges(
            {
                posAndRot: {
                    pos: { x: pos.x, y: pos.y, z: pos.z, },
                    rot: { x: rot.x, y: rot.y, z: rot.z, w: rot.w, },
                },
                rotationMarkerName: props.connector.rotationMarkerName,
                initialMarkerName: initialMarker.name,
            },
            multipleMove!.partsToMove,
            [...multipleMove!.newConnections, ...newExactSnaps,],
            props.connector.specialRotationMarker,
        )
        const newAttachedMarker = props.connector.rotationMarkerName
            || props.connector.initialMarkerName

        changeAttachedMarker(
            props.connectorInternalsRef.current.markers[innerToOuter(newAttachedMarker)])

        const newConnectedParts = [
            ...multipleMove!.newConnections
                .filter(c => isInConnection(c, props.connector.id))
                .map(c => getOtherPartOfTheConnection(c, props.connector.id)!),
            ...newExactSnaps.map(c => getOtherPartOfTheConnection(c, props.connector.id)!),
        ]
        props.buildCollider()
        props.updateCollider(undefined, newConnectedParts)
        setMultipleMove(undefined)
        resetGuidelines()
    }

    const getRotationMeshName = (meshName: string): string => {
        const numberMatch = meshName.match(/\d+/)
        if (numberMatch) {
            const number = numberMatch[0]
            return `rotation${number}`
        }
        return meshName
    }


    const findRotationMarkerWithinRef = (specificMarkerName?: string) => {
        const markers = props.connectorInternalsRef.current.markers
        const rotationMarkerName = getRotationMeshName(specificMarkerName || props.connector.initialMarkerName)
        //console.log("looking for this rotation marker name", rotationMarkerName)
        return Object.values(markers).find((marker: Mesh) => marker.name.toLowerCase().includes(rotationMarkerName))
    }

    useEffect(() => {
        const canEdit = props.canEditRotation()
        setMultipleMove(canEdit ? { ...canEdit, toSave: false, newConnections: [], } : undefined)
        if (props.connectorUI === CONNECTOR_UI.NONE && !isInitialRotationSet) {
            saveInitialRotation(props.attachedMarker.current!.name)
            setActualRotation({ markerName: props.attachedMarker.current?.name, rotation: 0, })
        }
        if (props.connectorUI === CONNECTOR_UI.AFTER_CREATION) {
            if (props.slideEnabled) {
                props.setConnectorUI(CONNECTOR_UI.SEGMENTED_SLIDER)
            } else if (!props.connector.rotationDisabled) {
                props.setConnectorUI(CONNECTOR_UI.SLIDER)
            }
        } if (props.connectorUI === CONNECTOR_UI.SLIDER) {
            //console.log(canEdit?.rotationMarker, "canEdit.rotationMarker")
            if (canEdit && canEdit.partsToMove && canEdit.partsToMove.length > 0 && !props.connector.specialRotationMarker) {
                if (props.attachedMarker.current?.name !== canEdit.rotationMarker) {

                    changeAttachedMarker(
                        props.connectorInternalsRef.current.markers[canEdit.rotationMarker])
                }
            } else if (canEdit && canEdit.partsToMove && canEdit.partsToMove.length > 0 && props.connector.specialRotationMarker) {
                const rotationMarker = findRotationMarkerWithinRef(canEdit.rotationMarker)
                //console.log(props.attachedMarker.current?.name, "useHandleRotation attachedMarker.current?.name")
                if (props.attachedMarker.current?.name !== rotationMarker?.name) {
                    //console.log("changing marker to ", rotationMarker, "in useHandleRotation")
                    changeAttachedMarker(rotationMarker!)
                }
            }

            else if (props.attachedMarker.current?.name !== props.connector.rotationMarkerName) {
                const newAttachedMarker = innerToOuter(props.connector.rotationMarkerName
                    || props.connector.initialMarkerName)

                changeAttachedMarker(
                    props.connectorInternalsRef.current.markers[newAttachedMarker])
            }
            if (!actualRotation.markerName
                || !props.attachedMarker.current?.name
                || !areSameConnection(actualRotation.markerName,
                    props.attachedMarker.current.name)
            ) {
                saveInitialRotation(props.attachedMarker.current!.name)
                setActualRotation({ markerName: props.attachedMarker.current?.name, rotation: 0, })
            }
        } if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER) {
            if (!actualRotation.markerName
                || !props.attachedMarker.current?.name
                || !areSameConnection(actualRotation.markerName,
                    props.attachedMarker.current.name)
            ) {
                saveInitialRotation(props.attachedMarker.current!.name)
                setActualRotation({ markerName: props.attachedMarker.current?.name, rotation: 0, })
            }
        }
        else if (multipleMove?.toSave) {
            saveMultipleMoveRotation()
        } else if (actualRotation.rotation !== 0) {
            props.handleConnectorState()
        } else if (props.connectorUI === CONNECTOR_UI.CLOSE) {
            props.setConnectorUI(CONNECTOR_UI.NONE)
            breadcrumb({
                message: "start handle connector state",
                level: "info",
            })
            if (!props.slideEnabled) {
                props.handleConnectorState()
            }
        }
    }, [props.connectorUI,])

    return {
        initialRotation,
        actualRotation,
        updateActualRotation,
        multipleMove,
        resetMultipleMove,
        setNewChangesToSave,
        setUserRotation,
        isInitialRotationSet,
    }
}

export default useHandleRotation