import React, { useMemo, useRef, useState } from "react"
import { useFrame } from "@react-three/fiber"
import { Html, Sphere } from "@react-three/drei"
import { Vector3, Object3D, Mesh } from "three"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { useLevaControls } from "../../../../../../../../providers/debugProvider/useLevaControls"

interface DebugSphereProps {
    pointName: string;
    point: Object3D | undefined;
    color: string;
    size: number;
    tubeName: string;
}

const DebugSphere: React.FC<DebugSphereProps> = ({ pointName, point, color, size, tubeName, }) => {
    const sphereRef = useRef<Mesh>(null)
    const [showLabel, setShowLabel,] = useState(false)
    const position = useMemo(() => {
        return point ? MeshUtils.copyWorldPosition(point) : new Vector3()
    }, [point, point?.position.x, point?.position.y, point?.position.z,])

    return (
        <>
            <Sphere
                name={`${pointName}-debug`}
                ref={sphereRef}
                args={[size,]}
                position={position}
                onPointerEnter={() => setShowLabel(true)}
                onPointerLeave={() => setShowLabel(false)}
            >
                <meshBasicMaterial color={color} depthTest={false} transparent={true} />
            </Sphere>
            {showLabel && (
                <Html
                    position={position}
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        transform: "translate3d(50%, -50%, 0)",
                        pointerEvents: "none",
                    }}
                >
                    {`${tubeName} - ${pointName}`}
                </Html>
            )}
        </>
    )
}

interface AttachmentPointDebuggerProps {
    attachmentPoint: Object3D | undefined;
    originPoint: Object3D | undefined;
    size?: number;
    tubeName: string;
}

const AttachmentPointDebugger: React.FC<AttachmentPointDebuggerProps> = ({
    attachmentPoint,
    originPoint,
    size = 0.001,
    tubeName,
}) => {
    const { viewAttachmentPoint, viewOriginPoint, } = useLevaControls()

    return (
        <>
            {viewAttachmentPoint && (
                <DebugSphere
                    pointName="attachment"
                    point={attachmentPoint}
                    color="purple"
                    size={size}
                    tubeName={tubeName}
                />
            )}
            {viewOriginPoint && (
                <DebugSphere
                    pointName="origin"
                    point={originPoint}
                    color="blue"
                    size={size}
                    tubeName={tubeName}
                />
            )}
        </>
    )
}

export default AttachmentPointDebugger