import React, { FC } from "react"
import ConfirmModal from "./ConfirmModal"

interface UnsavedChangesModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({ onConfirm, onCancel, }) => {
  return <ConfirmModal
    title="Unsaved changes"
    message={`You have unsaved changes, 
      if you click continue, your progress will be lost.`}
    confirmButtonText="Continue"
    confirmButtonColor="#FF0000"
    onConfirm={onConfirm}
    onCancel={onCancel}
    loading={false}
  />
}