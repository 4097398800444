import { useContext } from "react"
import { GLTFContext } from "./GLTFLoaderProvider"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"

const useGLTFLoader = () => {
    const gltfctx = useContext(GLTFContext)

    const loadModel = (modelPath: string) => {
        // TODO: Work on model cache in the context if the path exists

        if (!gltfctx || !gltfctx.loader) {
            throw new Error("Loader was undefined in useGLTFLoader")
        }

        const findCache = gltfctx.modelsCache.current?.find((mc) => mc.path === modelPath)

        return new Promise<GLTF>((resolve) => {
            if (findCache) {
                resolve(findCache.gltf)
            } else {
                gltfctx.loader.load(
                    modelPath,
                    (gltf) => {
                        gltfctx.modelsCache.current?.push({
                            path: modelPath,
                            gltf,
                        })
                        resolve(gltf)
                    })
            }
        })

    }

    return {
        loadModel,
    }
}

export default useGLTFLoader