import React from "react"
import ReactDOM from "react-dom"
import Button from "../../../../../../../common/components//Button"
import {
    ActionSlot, Container, Content,
    Extra, Header, Main, Title, Overlay,
    LoadingWrapper,
} from "../../../../../../../common/components/DrawerShared"
import Loading from "../../../../../../../common/components/Loading"
import { TabDefinition, TabNameBase, useTabs } from "../../Tabs/Tabs"
import { scrollContainerId } from "../utils/helper"
import ModalFooter from "./ModalFooter"
import { ReactComponent as CrossIcon } from "../../../../../../../common/assets/icons/Cross.svg"
import { ReactComponent as BackIcon } from "../../../../../../../common/assets/icons/Back.svg"

interface Props {
    selectedCategoryId: string | undefined;
    onClose: () => void;
    title: string;
    filters: JSX.Element;
    defaultTabs: Record<TabNameBase, TabDefinition>;
    isFirstPart: boolean;
    isLoading: boolean;
}

const PartsDrawer = (props: Props) => {
    const overlay = document.getElementById("drawer-overlay")

    const defaultTabName = Object.keys(props.defaultTabs)[0]

    const headerAndContentTabs = useTabs({
        tabs: props.defaultTabs || [],
        defaultTab: defaultTabName,
    })

    const headerAndContent = props.isFirstPart
        ? { headers: undefined, content: headerAndContentTabs.content, }
        : headerAndContentTabs

    return (
        <Container>
            <Header>
                <Main>
                    <Title>{props.title}</Title>
                    <ActionSlot>
                        <Button onClick={props.onClose}>
                            {props.selectedCategoryId ? <BackIcon /> : <CrossIcon />}
                        </Button>
                    </ActionSlot>
                </Main>
                {headerAndContent.headers}
                <Extra>
                    {props.filters}
                </Extra>
            </Header>
            <Content id={scrollContainerId}>
                {props.isLoading
                    ? <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                    : <> {
                        headerAndContent.content
                            .tabs[headerAndContent.content.current.currentOrDefault]
                            .render()
                    }
                        <ModalFooter />
                    </>
                }
            </Content>
            {overlay && (
                <>{ReactDOM.createPortal(<Overlay onClick={props.onClose} />, overlay)}</>
            )}
        </Container>
    )
}

export default PartsDrawer