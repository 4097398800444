/* eslint-disable max-statements */
import {
    Mesh,
    Vector3,
    Quaternion,
    InstancedMesh,
    Scene,
    Points,
    PointsMaterial,
    BufferGeometry,
    Color,
    AxesHelper,
} from "three"
import { getInstancedOBB, getOBB } from "../moveProvider/OBBBuilder"

export interface NinePointsGeoResult {
    points: Vector3[];
    center: Vector3;
    cornerVertices: Vector3[];
    midpoints: Vector3[];
}

export interface AlignmentPoints {
    corners: Vector3[];
    midpoints: Vector3[];
    center: Vector3;
}

export function getMarkerNormal(marker: Mesh): Vector3 {
    const normal = new Vector3(0, 0, 1)
    const worldQuat = new Quaternion()
    marker.getWorldQuaternion(worldQuat)
    return normal.applyQuaternion(worldQuat).normalize()
}

export function getGlobalOrientation(normal: Vector3): { forward: Vector3, right: Vector3, } {
    // Normalize the input normal
    const normalizedNormal = normal.clone().normalize()

    // Get the dominant axis and its sign
    const absX = Math.abs(normalizedNormal.x)
    const absY = Math.abs(normalizedNormal.y)
    const absZ = Math.abs(normalizedNormal.z)

    const right = new Vector3()
    let normalSign = 1

    // Determine which axis is dominant and its sign
    if (absY > absX && absY > absZ) {
        normalSign = Math.sign(normalizedNormal.y)
    } else if (absX > absZ) {
        normalSign = Math.sign(normalizedNormal.x)
    } else {
        normalSign = Math.sign(normalizedNormal.z)
    }

    // Always use consistent initial vectors regardless of normal direction
    right.set(0, 1, 0)

    // Project right vector onto the plane defined by normal
    right.sub(normalizedNormal.clone().multiplyScalar(right.dot(normalizedNormal)))
    right.normalize()

    // Get forward vector by crossing right with normal
    const forward = new Vector3().crossVectors(normalizedNormal, right)
.normalize()

    // Adjust vectors based on normal direction to maintain consistent orientation
    if (normalSign < 0) {
        right.negate()
        forward.negate()
    }

    return { forward, right, }
}

export function getConnectedFaceGeometry(
    marker: Mesh,
    mesh: Mesh,
    instanceIndex?: number,
    referenceOrientation?: { forward: Vector3, right: Vector3, },
    scene?: Scene,
    debugAlignment?: boolean,
): Vector3[] {
    // Ensure matrices are up to date
    mesh.updateWorldMatrix(true, true)
    marker.updateWorldMatrix(true, true)

    // Get marker's normal and position in world space
    const markerNormalWorld = getMarkerNormal(marker)
    const markerPosWorld = new Vector3()
    marker.getWorldPosition(markerPosWorld)

    // Calculate orientation from marker if not provided
    const markerRight = new Vector3(1, 0, 0)
    const markerUp = new Vector3(0, 1, 0)
    const worldMatrix = marker.matrixWorld.clone()
    markerRight.applyMatrix4(worldMatrix).sub(markerPosWorld)
.normalize()
    markerUp.applyMatrix4(worldMatrix).sub(markerPosWorld)
.normalize()

    const orientation = referenceOrientation ?? {
        forward: markerUp,
        right: markerRight,
    }

    // Get the OBB of the mesh
    let obb
    if (mesh.userData.type === "InstancedMesh") {
        obb = getInstancedOBB(mesh as InstancedMesh, instanceIndex!, 0, 0)
    } else {
        obb = getOBB(mesh, 0, 0)
    }
    if (!obb?.bboxMesh) {
        return []
    }

    if (scene && debugAlignment) {
        scene.add(obb.bboxMesh)
    }

    // Increase the tolerance slightly for vertex detection
    let PLANE_TOLERANCE = 0.0001 // Initial tolerance
    const MAX_TOLERANCE = 0.01 // Safe stop tolerance
    const positions = obb.bboxMesh.geometry.attributes.position
    let faceVertices: Vector3[] = []

    // Loop to increase tolerance until enough vertices are found
    while (faceVertices.length < 4 && PLANE_TOLERANCE <= MAX_TOLERANCE) {
        faceVertices = [] // Reset face vertices for each tolerance level

        // First pass: find vertices on the plane with detailed logging
        for (let i = 0; i < positions.count; i++) {
            const vertex = new Vector3()
            vertex.fromBufferAttribute(positions, i)
            vertex.applyMatrix4(obb.bboxMesh.matrixWorld)

            const toVertex = vertex.clone().sub(markerPosWorld)
            const distance = Math.abs(markerNormalWorld.dot(toVertex))

            if (debugAlignment) {
                console.log(`Vertex ${i}:`, {
                    position: vertex.clone(),
                    distance,
                    isOnPlane: distance < PLANE_TOLERANCE,
                })
            }

            if (distance < PLANE_TOLERANCE) {
                faceVertices.push(vertex)

                // Visualize found vertices
                if (scene && debugAlignment) {
                    const geometry = new BufferGeometry()
                    geometry.setFromPoints([vertex,])
                    const material = new PointsMaterial({ color: new Color("red"), size: 0.005, })
                    const point = new Points(geometry, material)
                    // scene.add(point)
                }
            }
        }

        // Remove duplicates
        faceVertices = removeDuplicateVertices(faceVertices)

        // Increase tolerance for the next iteration
        PLANE_TOLERANCE *= 1.5
    }

    if (faceVertices.length < 4) {
        console.warn("Not enough unique vertices found. Returning empty array.")
        return []
    }

    // Calculate center point
    const center = new Vector3()
    faceVertices.forEach(v => center.add(v))
    center.divideScalar(faceVertices.length)

    // Sort vertices based on marker's local space
    const cornerVertices = [...faceVertices,].sort((a, b) => {
        const aLocal = a.clone().sub(center)
        const bLocal = b.clone().sub(center)

        // Project points onto the right and up axes
        const aRight = aLocal.dot(orientation.right)
        const bRight = bLocal.dot(orientation.right)
        const aUp = aLocal.dot(orientation.forward)
        const bUp = bLocal.dot(orientation.forward)

        // Sort first by vertical position (up/down)
        if (Math.abs(aUp - bUp) > 0.001) {
            return bUp - aUp  // Higher points come first
        }
        // Then by horizontal position (left/right)
        return bRight - aRight
    })

    // Rearrange corners to follow the order: top-left, top-right, bottom-left, bottom-right
    const orderedCorners = [
        cornerVertices[0], // top-left
        cornerVertices[1], // top-right
        cornerVertices[2], // bottom-left
        cornerVertices[3], // bottom-right
    ]

    // Calculate edge midpoints in consistent order
    const midpoints = [
        new Vector3().addVectors(orderedCorners[1], orderedCorners[3])
.multiplyScalar(0.5), // right
        new Vector3().addVectors(orderedCorners[2], orderedCorners[3])
.multiplyScalar(0.5), // bottom
        new Vector3().addVectors(orderedCorners[0], orderedCorners[2])
.multiplyScalar(0.5), // left
        new Vector3().addVectors(orderedCorners[0], orderedCorners[1])
.multiplyScalar(0.5), // top
    ]

    // Return points in consistent order:
    // [0-3]: corners (top-left, top-right, bottom-left, bottom-right)
    // [4-7]: midpoints (right, bottom, left, top)
    // [8]: center
    return [
        ...orderedCorners,
        ...midpoints,
        center,
    ]
}

function removeDuplicateVertices(vertices: Vector3[], epsilon = 0.0001): Vector3[] {
    const unique: Vector3[] = []
    const seen = new Set<string>()

    vertices.forEach(vertex => {
        const key = [
            Math.round(vertex.x / epsilon) * epsilon,
            Math.round(vertex.y / epsilon) * epsilon,
            Math.round(vertex.z / epsilon) * epsilon,
        ].join(",")

        if (!seen.has(key)) {
            seen.add(key)
            unique.push(vertex)
        }
    })

    return unique
}

function projectVectorOntoPlane(vector: Vector3, normal: Vector3): Vector3 {
    const projected = vector.clone()
    const dot = projected.dot(normal)
    return projected.sub(normal.clone().multiplyScalar(dot))
}