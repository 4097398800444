import React from "react"
import { Select, Form } from "antd"
import styled from "styled-components"

export type Option = { text: string, value: string, };

type Props = {
  options: Option[],
  value: Option["value"],
  label?: React.ReactNode,
  setValue: React.Dispatch<React.SetStateAction<Option["value"]>>,
};

const CustomSelect = styled(Select)`
&&& .ant-select-selector {
    border-radius: 5px;
    color: #818181;
  }
`

const FormItemStyled = styled(Form.Item)`
  display: block;
  min-width: 103px;
  border-radius:5px;
  margin-right: 8px;
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  & > div:first-child {
    padding-bottom: 0;
  }
`

const getOptions = (options: Props["options"]) => {
  return options.map(({ value, text, }) => (
    <Select.Option value={value} key={value}>
      {text}
    </Select.Option>
  ))
}

const ComboBox = ({ options, value, setValue, label, }: Props) => {
  return (
    <FormItemStyled label={label}>
      <CustomSelect value={value} onChange={(value) => setValue(value as string)}
        dropdownMatchSelectWidth={false}>
        {getOptions(options)}
      </CustomSelect>
    </FormItemStyled>
  )
}

export default ComboBox
