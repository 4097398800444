/* eslint-disable max-statements */
import {
    Box3,
    BoxGeometry,
    DoubleSide,
    InstancedMesh,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Quaternion,
    Vector3
} from "three"

export function isInsideWithTolerance(point: Vector3, boundingBox: Box3, tolerance = 0.00001) {
    return (
        point.x >= boundingBox.min.x - tolerance
        && point.x <= boundingBox.max.x + tolerance
        && point.y >= boundingBox.min.y - tolerance
        && point.y <= boundingBox.max.y + tolerance
        && point.z >= boundingBox.min.z - tolerance
        && point.z <= boundingBox.max.z + tolerance
    )
}

function getOBBDimensions(bbox: Box3, expandOffset?: number, zTolerance = 0.001) {
    const depth = bbox.max.z - bbox.min.z + 2 * zTolerance
    const width = (bbox.max.x - bbox.min.x) + (expandOffset || 0)
    const height = (bbox.max.y - bbox.min.y) + (expandOffset || 0)

    return { width, height, depth, }
}

function createOBBMesh(width: number, height: number, depth: number) {
    const bboxGeometry = new BoxGeometry(width, height, depth)
    const bboxMaterial = new MeshBasicMaterial({
        color: 0x00ff00,
        wireframe: true,
        side: DoubleSide,
    })
    return new Mesh(bboxGeometry, bboxMaterial)
}

function adjustOBBMeshPosition(mesh: Mesh, bbox: Box3, bboxMesh: Mesh) {
    const center = new Vector3()
    bbox.getCenter(center)
    bboxMesh.position.copy(center)

    const worldPosition = new Vector3()
    const worldQuaternion = new Quaternion()
    mesh.getWorldPosition(worldPosition)
    mesh.getWorldQuaternion(worldQuaternion)

    bboxMesh.position.applyQuaternion(worldQuaternion).add(worldPosition)
    bboxMesh.quaternion.copy(worldQuaternion)
    bboxMesh.updateMatrixWorld(true)
}

export function getOBB(mesh: Mesh, expandOffset: number, zTolerance = 0.001) {
    const bbox = mesh.geometry.boundingBox
    if (!bbox) {
        console.error("Bounding box is null")
        return null
    }

    const worldScale = new Vector3()
    mesh.getWorldScale(worldScale)

    const scaledBbox = new Box3(
        bbox.min.clone().multiply(worldScale),
        bbox.max.clone().multiply(worldScale)
    )
    const { width, height, depth, } = getOBBDimensions(scaledBbox, undefined, zTolerance)
    const { width: widthExpanded, height: heightExpanded, depth: depthExpanded, }
        = getOBBDimensions(scaledBbox, expandOffset, zTolerance)

    const bboxMesh = createOBBMesh(width, height, depth)
    const bboxMeshExpanded = createOBBMesh(widthExpanded, heightExpanded, depthExpanded)

    adjustOBBMeshPosition(mesh, bbox, bboxMesh)
    adjustOBBMeshPosition(mesh, bbox, bboxMeshExpanded)

    return { bboxMesh, bboxMeshExpanded, }
}


export function getInstancedOBB(
    instancedMesh: InstancedMesh,
    instanceId: number,
    expandOffset: number,
    zTolerance = 0.001
) {
    const bbox = instancedMesh.geometry.boundingBox
    if (!bbox) {
        console.error("Bounding box is null")
        return null
    }

    // Get the instance's transform matrix
    const matrix = new Matrix4()
    instancedMesh.getMatrixAt(instanceId, matrix)

    // Extract position, rotation, and scale from matrix
    const position = new Vector3()
    const rotation = new Quaternion()
    const scale = new Vector3()
    matrix.decompose(position, rotation, scale)

    // Create scaled bbox
    const scaledBbox = new Box3(
        bbox.min.clone().multiply(scale),
        bbox.max.clone().multiply(scale)
    )

    const { width, height, depth, } = getOBBDimensions(scaledBbox, undefined, zTolerance)
    const {
        width: widthExpanded,
        height: heightExpanded,
        depth: depthExpanded,
    } = getOBBDimensions(scaledBbox, expandOffset, zTolerance)

    const bboxMesh = createOBBMesh(width, height, depth)
    const bboxMeshExpanded = createOBBMesh(widthExpanded, heightExpanded, depthExpanded)

    // Position the OBB meshes
    const center = new Vector3()
    bbox.getCenter(center)

    // Apply instance transform to both meshes
    for (const mesh of [bboxMesh, bboxMeshExpanded,]) {
        mesh.position.copy(center)
            .multiply(scale)
            .applyQuaternion(rotation)
            .add(position)
        mesh.quaternion.copy(rotation)
        mesh.scale.copy(scale)
        mesh.updateMatrixWorld(true)
    }

    return { bboxMesh, bboxMeshExpanded, }
}