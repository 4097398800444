/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from "react"
import styled, { css } from "styled-components"
import Loading from "./Loading"
import isMobile from "ismobilejs"

interface Props {
  $ifMobile?: JSX.Element;
  color?: string;
  $loadingColor?: string;
  action?: "primary" | "secondary";
  $full?: boolean;
  children: (JSX.Element | string)[] | JSX.Element | string;
  onClick?: (e: any) => void;
  $borderColor?: string;
  $labelColor?: string;
  disabled?: boolean;
  type?: "button" | "submit" | undefined;
  loading?: boolean;
  style?: React.CSSProperties;
  title?: string;
  $useWrapper?: boolean; // New prop to control the use of ButtonWrapper
}

const Button = (props: Props) => {
  const { loading, $useWrapper = true, ...restProps } = props // Default $useWrapper to true

  const getChildren = () => {
    if (props.$ifMobile && isMobile(window.navigator).phone) {
      return props.$ifMobile
    } else {
      return props.children
    }
  }

  const buttonContent = (
    <ButtonStyle
      type={props.type ? props.type : "button"}
      disabled={props.disabled}
      {...restProps}
      onClick={props.onClick}
    >
      <React.Fragment>
        {loading && (
          <Loading
            color={props.$loadingColor ? props.$loadingColor : props.color}
          />
        )}
        {getChildren()}
      </React.Fragment>
    </ButtonStyle>
  )

  return $useWrapper ? (
    <ButtonWrapper>
      {buttonContent}
      {props.title && <Tooltip>{props.title}</Tooltip>}
    </ButtonWrapper>
  ) : (
    <>
      {buttonContent}
      {props.title && <Tooltip>{props.title}</Tooltip>}
    </>
  )
}

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Tooltip = styled.div`
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;

  ${ButtonWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const ButtonStyle = styled.button<Props>`
  background-color: ${(props) =>
    (props.action === "primary" && props.color ? props.color : "white")};
  border: ${(props) =>
  (props.$borderColor || props.action === "secondary"
    ? `1px solid ${props.$borderColor}`
    : "none")};
  transition: all 0.16s ease-in-out;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 6px;
  padding: 8px;
  color: ${(props) =>
    (props.$labelColor ? props.$labelColor : props.action ? "white" : "black")};
  width: ${(props) => (props.$full ? "100%" : "auto")};
  font-weight: ${(props) => (props.action ? "bold" : "normal")};
  display: flex;
  justify-content: center;
  align-items: center;
  float:right;
  gap: 8px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  ${(props) => {
    if (!props.disabled) {
      return css`
        &:hover {
          background-color: ${props.action && props.color
          ? props.color
          : "#eaeaea"};
          opacity: ${props.action ? 0.8 : 1};
        }
      `
    }
  }}
`

export default Button
