import React, { useState } from "react"
import ActionButton from "../../../../../../../../../common/components/ActionButton"
import { ActionButtonContainer } from "../../ActionPanelStyles"
import { ROTATION_AXES_ENUM } from "../types/types"

type RotationAxesButtonsProps = {
    onRotationAxisChange: (axis: ROTATION_AXES_ENUM) => void,
    useAxisColors?: boolean,
}

const RotationAxesButtons = (
    { onRotationAxisChange, useAxisColors = false, }: RotationAxesButtonsProps
) => {
    const [selectedAxis, setSelectedAxis,] = useState(ROTATION_AXES_ENUM.X)

    const handleClick = (axis: ROTATION_AXES_ENUM) => {
        onRotationAxisChange(axis)
        setSelectedAxis(axis)
    }

    const getAxisColors = (axis: ROTATION_AXES_ENUM) => {
        if (!useAxisColors) {
            return { bg: undefined, selected: undefined, hover: undefined, }
        }

        const colors = {
            [ROTATION_AXES_ENUM.X]: { bg: "#ffebee", selected: "#ff9e9e", hover: "#ff9e9e", },
            [ROTATION_AXES_ENUM.Y]: { bg: "#e8f5e9", selected: "#a5d6a7", hover: "#a5d6a7", },
            [ROTATION_AXES_ENUM.Z]: { bg: "#e3f2fd", selected: "#90caf9", hover: "#90caf9", },
        }
        return colors[axis]
    }

    return <ActionButtonContainer>
        {Object.values(ROTATION_AXES_ENUM).map((axis) => {
            const colors = getAxisColors(axis)
            return (
                <ActionButton
                    key={axis}
                    onClick={() => handleClick(axis)}
                    minSize
                    selected={selectedAxis === axis}
                    backgroundColor={colors.bg}
                    selectedColor={colors.selected}
                    hoverColor={colors.hover}
                >
                    {axis.toLowerCase()}
                </ActionButton>
            )
        })}
    </ActionButtonContainer>
}

export default RotationAxesButtons