import React, { createContext, useContext } from "react"
import MiniTutorials from "../../components/main/DesignScreen/scene/MiniTutorials"

const MiniTutorialsContext = createContext({})

export const MiniTutorialsProvider = ({ children, }: { children: React.ReactNode, }) => {
    return (
        <MiniTutorialsContext.Provider value={{}}>
            {children}
            <MiniTutorials />
        </MiniTutorialsContext.Provider>
    )
}

export const useMiniTutorials = () => useContext(MiniTutorialsContext)