import * as React from "react"
import { useEffect, useRef } from "react"
import { useSetRecoilState } from "recoil"
import { PartTypeAPI } from "../../../../../common/api/Types"
import { renderConnectionsData } from "../../../../state/atoms"
import { AddPartState, RenderSwapType } from "../../../../state/types"
import styled from "styled-components"
import Button from "../../../../../common/components/Button"
import MessageBox from "../MessageBox"
import ReactDOM from "react-dom"
import {
    ActionSlot,
    Container,
    Content,
    Extra,
    Header,
    Main,
    Overlay,
    Title
} from "../../../../../common/components/DrawerShared"
import { ReactComponent as CrossIcon } from "../../../../../common/assets/icons/Cross.svg"

type Props = {
    partToAdd: PartTypeAPI,
    compatibleConnectionsIds: string[],
    onClose: () => void,
    onBack: () => void,
    source: NonNullable<AddPartState["step1"]["source"]>,
    swap?: RenderSwapType,
    ignoreSizeCompatibility?: boolean,
    sizeId: string,
}

const ConnectionModal = (props: Props) => {
    const setRender = useSetRecoilState(renderConnectionsData)
    const overlay = document.getElementById("drawer-overlay")
    const menuRef = useRef<HTMLDivElement | null>(null)

    //console.log("ConnectionModal props", props)
    //console.log("setRender", setRender)

    useEffect(() => {
        setRender({
            partToAdd: props.partToAdd,
            compatibleConnectionsIds: props.compatibleConnectionsIds,
            menuRef,
            ignoreSizeCompatibility: props.ignoreSizeCompatibility,
            sizeId: props.sizeId,
            connectionData: {
                posAndRot: props.source.posAndRot,
                partId: props.source.partId,
                markerName: props.source.markerName,
                swap: props.swap,
                type: props.source.type,
                canSlide: props.source.canSlide,
            },
        })
    }, [
        props.compatibleConnectionsIds,
        props.partToAdd,
        props.source,
        setRender,
    ])

    return (
        <Container>
            <Header>
                <Main>
                    <ActionSlot>
                        <Button onClick={props.onBack}>
                            Back
                        </Button>
                    </ActionSlot>
                    <Title>Connect Part</Title>
                    <ActionSlot>
                        <Button onClick={props.onClose}>
                            <CrossIcon />
                        </Button>
                    </ActionSlot>
                </Main>
            </Header>
            <Content ref={menuRef} />
            {overlay && (
                <>
                    {ReactDOM.createPortal(<Overlay onClick={props.onClose} />, overlay)}
                </>
            )}
        </Container>
    )
}

export const ImagesList = styled.div<{ isMobile: boolean, }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    img {
        margin: 0;
        cursor: pointer;
        transition: all 0.16s ease-in-out;
        border-radius: 6px;
        border: solid 1px #00000029;
        aspect-ratio: 16 / 9;
        width: 100%;
        box-shadow: 0 8px 6px -6px #0000001a;
        object-fit: ${(props) => (props.isMobile ? "initial" : "scale-down")};

        :hover {
            transform: scale(1.02);
        }
    }
`

export default ConnectionModal