/* eslint-disable no-console */
import * as Sentry from "@sentry/react"
import { addBreadcrumb } from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { EnvHelper } from "./EnvHelper"

if (EnvHelper.isProduction) {
    Sentry.init({
        // eslint-disable-next-line max-len
        dsn: "https://85cdfdadcbd78b799c034c7462231381@o4505870609809408.ingest.us.sentry.io/4505870611513344",
        release: EnvHelper.buildName,
        environment: EnvHelper.envType,
        integrations: [
            new BrowserTracing(),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

export const breadcrumb: typeof addBreadcrumb = (breadcrumb) => {
    addBreadcrumb(breadcrumb)
}