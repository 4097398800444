import styled from "styled-components"
import { Canvas } from "@react-three/fiber"

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FlexContainer = styled.div`
    display: flex;
    gap: 32px;
    align-items: flex-start;
    justify-content: center;
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 150px;
`

export const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Label = styled.div`
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-top: auto;
    padding-bottom: 8px;
`

export const HUDContainer = styled.div`
    width: 120px;
    height: 112px;
    position: relative;
    border-radius: 4px;
`

export const StyledCanvas = styled(Canvas)`
    pointer-events: auto;
    width: 100px !important;
    height: 100px !important;
    position: fixed;
    top: 0;
    left: 0;
`

// Input related styles
export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const InputRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const Input = styled.input`
    width: 48px;
    padding: 4px 8px;
    border: 1px solid #d2d3d2;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    
    &:focus {
        outline: none;
        border-color: #8866ff;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        opacity: 1;
    }

    @media (min-width: 768px) {
        width: 60px;
    }
`

export const UnitLabel = styled.div`
    font-size: 14px;
    color: #666;
`