/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { MutableRefObject, useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import { partConnections } from "../../../../../../../../state/scene/selectors"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SegmentedTubePart, XYZ, XYZW } from "../../../../../../../../utils/Types"
import { SegmentedTubeInfo, SEGMENTED_TUBE_UI } from "../types/types"

type UseHandleSlideProps = {
    tube: SegmentedTubePart,
    tubeUI: SEGMENTED_TUBE_UI,
    tubeInfo: MutableRefObject<SegmentedTubeInfo>,
    savePositionRotationChanges: (newConnectorValues: {
        posAndRot: {
            pos: XYZ,
            rot: XYZW,
        },
        rotationMarkerName: string,
    }, partIds: string[], newConnections: PartConnectionType[]) => void,
    canEditSlide: (sliderPartId?: string,) => {
        sliderMarker: string,
        partsToMove: string[],
    } | undefined,
    handleSegmentedTubeState?: () => void,
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void,
    saveSegmentedTubeSliderChanges: (partIds: string[], newConnections: PartConnectionType[]) => void,
    sliderPartId?: string,
    connectionIndex?: number,
}

const useHandleSlide = (props: UseHandleSlideProps) => {
    const [multipleMove, setMultipleMove,] = useState<{
        sliderMarker: string,
        partsToMove: string[],
        toSave: boolean,
        newConnections: PartConnectionType[],
    } | undefined>(undefined)
    const partConnectionsValue = useRecoilValue(partConnections(props.tube.id))

    useEffect(() => {
        return () => setMultipleMove(undefined)
    }, [])

    const resetMultipleMove = () => {
        setMultipleMove(undefined)
    }

    const setNewChangesToSave = (newConnections: PartConnectionType[]) => {
        setMultipleMove({ ...multipleMove!, toSave: true, newConnections, })
    }

    const saveMultipleMovePositionRotation = () => {
        const marker = props.tubeInfo.current.attachmentPoint!
        const pos = MeshUtils.copyWorldPosition(marker)

        const rot = props.tube.rotation
        props.savePositionRotationChanges(
            {
                posAndRot: {
                    pos: { x: pos.x, y: pos.y, z: pos.z, },
                    rot: { x: rot.x, y: rot.y, z: rot.z, w: rot.w, },
                },
                rotationMarkerName: marker.name,
            },
            multipleMove!.partsToMove,
            [...multipleMove!.newConnections,],
        )
        setMultipleMove(undefined)
    }

    useEffect(() => {
        if(props.tubeUI === SEGMENTED_TUBE_UI.SEGMENTED_SLIDER) {
            const canEdit = props.canEditSlide(props.sliderPartId)
            setMultipleMove(canEdit ? { ...canEdit, toSave: false, newConnections: [], } : undefined)
        }
        if (multipleMove?.toSave) {
            saveMultipleMovePositionRotation()
        } else if (props.tubeUI === SEGMENTED_TUBE_UI.CLOSE) {
            props.setTubeUI(SEGMENTED_TUBE_UI.NONE)
            breadcrumb({
                message: "start handle connector state",
                level: "info",
            })
            if (props.handleSegmentedTubeState) {
                props.handleSegmentedTubeState()
            }
        }
    }, [props.tubeUI, props.sliderPartId, props.connectionIndex,])

    useEffect(() => {
        const canEdit = props.canEditSlide(props.sliderPartId)
        setMultipleMove(canEdit ? { ...canEdit, ...(multipleMove ? multipleMove : {toSave: false, newConnections: [],}), } : undefined)
    }, [partConnectionsValue,])

    return {
        multipleMove,
        resetMultipleMove,
        setNewChangesToSave,
        saveMultipleMovePositionRotation,
    }
}

export default useHandleSlide