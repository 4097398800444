/* eslint-disable max-len */
import { ErrorBoundary as ErrorBoundarySentry, } from "@sentry/react"
import { getGPUTier, TierResult } from "detect-gpu"
import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import Button from "./common/components/Button"
import { getUserDesignIdsFromUrl } from "./common/utils/utils"
import { recordCrash } from "../src/builder/providers/localStorageBackupProvider/LocalStorageBackupProvider"


let gpuData: TierResult | undefined

(async () => {
    gpuData = await getGPUTier()
})()

export const ErrorBoundary: FC<{ children: ReactNode, }> = ({ children, }) => {
    return <ErrorBoundarySentry
        onError={(error) => {
            recordCrash(error)
        }}
        fallback={({ error, }) => {
            return <>
                <Background>
                    <Modal>
                        <Title>An error has occurred, please try again</Title>
                        <Subtitle>{error.toString()}</Subtitle>
                        <Button
                            color="#0088ff"
                            action="primary"
                            $full={true}
                            type="submit"
                            onClick={() => window.location.reload()}>
                            Reload
                        </Button>

                    </Modal>
                </Background>

            </>
        }}
        beforeCapture={(scope, error) => {
            const userDesignIds = getUserDesignIdsFromUrl()

            if (userDesignIds?.userId) {
                scope.setUser({ id: userDesignIds.userId, })
            }

            scope.setContext("User Data", {
                userId: userDesignIds?.userId,
                designId: userDesignIds?.designId,
            })

            if (gpuData) {
                scope.setContext("GPU Data", gpuData)
            }

        }}>{children}</ErrorBoundarySentry>
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #0000006b;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    background: white;
    border-radius: 6px;
    box-shadow: 0 11px 34px -9px #00000069;
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    max-width: 300px;
`
const Title = styled.h1`
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    padding: 0;
    width: 100%;
`

const Subtitle = styled.div`
  width: 100%;
  border: 1px solid #fe5b5a;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: #fe5b5a;
  font-size: 12px;
    
`