/* eslint-disable max-len */
import { getAuth } from "firebase/auth"
import { matchPath } from "react-router"
import * as rudderanalytics from "rudder-sdk-js"
import { EnvHelper } from "./EnvHelper"
import { getSessionTimer, setSessionTimer } from "./LocalPersistence"
import * as Sentry from "@sentry/react"
import { useGetPartsLength } from "../../builder/exportables"

let ip: string

type EventData = {
  totalPartsInDesign?: number | undefined,
  mainMaterial?: string,
  mainSubmaterial?: string,
  unit?: "cm" | "in",
}

type PartEventData = {
  part: {
    id: string,
    friendlyName: string,
  },
} & EventData

type SwapEventData = {
  part: {
    id: string,
    friendlyName: string,
  },
  partToSwap: {
    id: string,
    friendlyName: string,
  },
} & EventData

type CloneDesignEventData = {
  idClonedDesign: string,
} & EventData

function getSecondsDiff(startDate: number, endDate: number) {
  const msInSecond = 1000

  return Math.round(
    Math.abs(endDate - startDate) / msInSecond
  )
}

export const initRudderAnalytics = () => {
  if (EnvHelper.rudderAnalyticsConfig) {
    const { writeKey, dataPlaneUrl, } = EnvHelper.rudderAnalyticsConfig
    rudderanalytics.ready(() => { })
    rudderanalytics.load(writeKey, dataPlaneUrl)
    startSessionTimer()
  }
}

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
let gaInitialized = false

export const initAnalytics = () => {
  if (EnvHelper.useRudderAnalytics === "true") {
    const { writeKey, dataPlaneUrl, } = EnvHelper.rudderAnalyticsConfig
    rudderanalytics.ready(() => { })
    rudderanalytics.load(writeKey, dataPlaneUrl)
    startSessionTimer()
  } else {
    // Initialize Google Analytics
    if (typeof window !== "undefined" && !gaInitialized) {
      //console.log("init using google analytics ", EnvHelper.googleAnalyticsId)
      const script = document.createElement("script")
      script.src = `https://www.googletagmanager.com/gtag/js?id=${EnvHelper.googleAnalyticsId}`
      script.async = true
      document.head.appendChild(script)

      window.dataLayer = window.dataLayer || []
      const gtag = function (p0?: string, p1?: any) { window.dataLayer.push(arguments) }
      gtag("js", new Date())
      gtag("config", EnvHelper.googleAnalyticsId)

      gaInitialized = true
    }
    startSessionTimer()
  }
}

export const startSessionTimer = () => {
  const timer = getSessionTimer()

  if (!timer) {
    setSessionTimer(
      new Date()
        .getTime()
        .toString()
    )
  }
}

const track = async <T>(eventName: string, data: T) => {
  if (EnvHelper.rudderAnalyticsConfig) {
    const auth = getAuth()
    const email = auth.currentUser?.email ? auth.currentUser.email : undefined
    const authSession = getSessionTime()
    const ip = await getIP()

    const match = matchPath("/design/:userId/:designId", window.location.pathname)
    let designId: string | undefined
    let userId: string | undefined

    if (match?.params?.userId) {
      userId = match.params.userId
    }

    if (match && match.params?.designId) {
      designId = match.params.designId
    }

    const baseData = {
      desingOwnerUserId: userId,
      designId,
      email,
      sessionInSeconds: authSession,
      ip,
    }
    rudderanalytics.track(eventName, { ...baseData, ...data, })
  }
}

const incrementEventCount = (eventName: string) => {
  const key = `event_count_${eventName}`
  const currentCount = localStorage.getItem(key)
  const newCount = currentCount ? parseInt(currentCount, 10) + 1 : 1
  localStorage.setItem(key, newCount.toString())
}

const getEventCount = (eventName: string): number => {
  const key = `event_count_${eventName}`
  const count = localStorage.getItem(key)
  return count ? parseInt(count, 10) : 0
}

const trackEvent = async <T>(eventName: string, data: T) => {
  const auth = getAuth()
  const email = auth.currentUser?.email ? auth.currentUser.email : undefined
  const authSession = getSessionTime()
  const ip = await getIP()

  const match = matchPath("/design/:userId/:designId", window.location.pathname)
  let designId: string | undefined
  let userId: string | undefined

  if (match?.params?.userId) {
    userId = match.params.userId
  }

  if (match && match.params?.designId) {
    designId = match.params.designId
  }

  const baseData = {
    desingOwnerUserId: userId,
    designId,
    email,
    sessionInSeconds: authSession,
    ip,
  }

  const fullData = { ...baseData, ...data, }

  if (EnvHelper.useRudderAnalytics) {
    // Use RudderStack
    rudderanalytics.track(eventName, fullData)
  } else if (typeof window !== "undefined" && window.gtag) {
    // Fallback to Google Analytics
    const { email, sessionInSeconds, ip, ...eventParams } = fullData

    // Set user properties
    window.gtag("set", "user_properties", {
      email,
      session_duration: sessionInSeconds,
      ip_address: ip,
    })

    // Send the event
    window.gtag("event", eventName, {
      ...eventParams,
      send_to: EnvHelper.googleAnalyticsId,
    })
  }
  incrementEventCount(eventName)
}

const IPIFY_API_KEY = "at_qLV8TdxH9TRohD6mDx6mykBS13VE7" // Replace with your actual API key

const getIP = async () => {
  try {
    if (ip) {
      return ip
    }

    const response = await fetch(`https://geo.ipify.org/api/v2/country?apiKey=${IPIFY_API_KEY}`)
    const data = await response.json()
    ip = data.ip
    return ip
  } catch (err) {
    Sentry.captureException(err, {
      extra: { message: "IP can't be obtained from ipify API", },
    })
    return undefined
  }
}

export const getEventCounts = () => {
  return (Object.keys(events) as (keyof typeof events)[]).reduce((counts, eventName) => {
    counts[eventName] = getEventCount(eventName)
    return counts
  }, {} as Record<keyof typeof events, number>)
}

export const useEventsData = () => {
  const partsLength = useGetPartsLength()

  return <T extends {} = {}>(additionalData: T = {} as T): T & EventData => ({
    totalPartsInDesign: partsLength(),
    ...additionalData,
  })
}

const openPartsList = (data: EventData) => {
  trackEvent("OPEN_PARTS_LIST", data)
}

const openBuyPartsTab = (data: EventData) => {
  trackEvent("OPEN_BUY_PARTS_TAB", data)
}

const clickBuyParts = (data: EventData) => {
  trackEvent("CLICK_BUY_PARTS", data)
}

const addPart = (data: PartEventData) => {
  trackEvent("ADD_PART", data)
  const addPartCount = getEventCount("ADD_PART")
  //console.log("addPartCount", addPartCount)
  if (addPartCount === 10 || addPartCount === 15) {
    //console.log("Great job on your design. We add new parts all the time, would you like to get notified when we launch new parts?")
  }
}

const swapPart = (data: SwapEventData) => {
  trackEvent("SWAP_PART", data)
}

const saveDesign = (data: EventData) => {
  trackEvent("SAVE_DESIGN", data)
}

const shareDesign = (data: EventData) => {
  trackEvent("SHARE_DESIGN", data)
}

const signup = (data: EventData) => {
  trackEvent("SIGNUP", data)
}

const login = (data: EventData) => {
  trackEvent("LOGIN", data)
}

const cloneDesign = (data: CloneDesignEventData) => {
  trackEvent("CLONE_DESIGN", data)
}

const initialDesign = (data: EventData) => {
  trackEvent("INITIAL_DESIGN", data)
}

const clickStartCreating = (data: EventData) => {
  trackEvent("CLICK_START_CREATING", data)
}

const getSessionTime = () => {
  const sessionDateCache = getSessionTimer()
  if (sessionDateCache) {
    return getSecondsDiff(parseInt(sessionDateCache, 10), new Date().getTime())
  } else {
    setSessionTimer(
      new Date()
        .getTime()
        .toString()
    )
    return 0
  }
}

export const events = {
  openPartsList,
  clickBuyParts,
  addPart,
  swapPart,
  saveDesign,
  shareDesign,
  signup,
  login,
  cloneDesign,
  initialDesign,
  clickStartCreating,
  openBuyPartsTab,
}