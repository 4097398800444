import { useCallback, useContext } from "react"
import { modalContext, ModalState } from "./modalProvider"

const useModal = () => {
    const context = useContext(modalContext)

    const showModal = useCallback((props: ModalState) => {
        if (context) {
            context.onShowModal(props)
        }
    }, [context,])

    const onConfirm = useCallback(() => {
        if (context) {
            context.onConfirm()
        }
    }, [context,])

    return {
        showModal,
        onConfirm,
    }
}

export default useModal