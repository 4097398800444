import { selector } from "recoil"
import { initialData } from "./atoms"
import { initialDataType } from "./types"

const initialDataPropertySelector = <K extends keyof initialDataType>(
  key: string,
  initialDataKey: K,
  error?: string
) => {

  return selector({
    key,
    get: ({ get, }) => {
      const data = get(initialData)![initialDataKey]

      if (data === null) {
        throw new Error(error || `No ${key} Loaded`)
      }

      return data
    },
  })

}

export const sizesSelector = initialDataPropertySelector("sizesSelector", "sizes")
export const colorsSelector = initialDataPropertySelector("colorsSelector", "colors")
export const materialsSelector = initialDataPropertySelector("materialsSelector", "materials")
export const subclassesSelector = initialDataPropertySelector("subclassesSelector", "subclasses")
export const categoriesSelector = initialDataPropertySelector("categoriesSelector", "categories")
export const shopifyIdsSelector = initialDataPropertySelector("shopifyIdsSelector", "shopifyIds")
export const allPartsSelector = initialDataPropertySelector("allPartsSelector", "parts")
export const connectionTypesSelector = initialDataPropertySelector(
  "connectionTypesSelector",
  "connectionTypes"
)