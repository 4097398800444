/* eslint-disable max-len */
import { Box3Helper, Color, Vector3 } from "three"
import { Box3 } from "three"

export const showDimensions = (box: Box3, hide: boolean,
    unit: string, setSize: any) => {
    if (hide === false) {
        // Calculate dimensions
        const sizeVector = new Vector3()
        box.getSize(sizeVector)

        if (sizeVector.x > 0) {
            // Calculate both units
            const widthIn = sizeVector.x * 39.3701
            const heightIn = sizeVector.y * 39.3701
            const depthIn = sizeVector.z * 39.3701

            const widthCm = sizeVector.x * 100
            const heightCm = sizeVector.y * 100
            const depthCm = sizeVector.z * 100

            // Create readout strings for both units
            const inString = `${widthIn.toFixed(2)}" x ${heightIn.toFixed(2)}" x ${depthIn.toFixed(2)}"`
            const cmString = `${widthCm.toFixed(2)} x ${heightCm.toFixed(2)} x ${depthCm.toFixed(2)} cm`

            setSize({
                readout: unit === "in" ? inString : cmString,
                width: unit === "in" ? widthIn : widthCm,
                height: unit === "in" ? heightIn : heightCm,
                depth: unit === "in" ? depthIn : depthCm,
                unit: unit,
                // Store both measurements
                imperial: {
                    width: widthIn,
                    height: heightIn,
                    depth: depthIn,
                    readout: inString,
                },
                metric: {
                    width: widthCm,
                    height: heightCm,
                    depth: depthCm,
                    readout: cmString,
                },
            })
        }
    }
    else {
        setSize({ readout: "", })
    }
}