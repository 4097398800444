import { useLoader } from "@react-three/fiber"
import { TextureLoader, RepeatWrapping } from "three"
import wood_texture from "../../../../../../../assets/wood-pine.jpg"

const useWoodTexture = (color: string, u: number, v: number) => {
    const woodTexture = useLoader(TextureLoader, wood_texture)
    woodTexture.wrapS = RepeatWrapping
    woodTexture.wrapT = RepeatWrapping
    woodTexture.repeat.set(u, v)
    const material = color.includes("#") ? undefined : color

    return { material, woodTexture, }
}

export default useWoodTexture