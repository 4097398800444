/* eslint-disable max-statements */
import { walkSurface, findPointsAlongAxis } from "./PointsGenerator"
import { getFirstAndLastSides } from "./meshHelpers"
import { DragMesh, FreePositions } from "./types"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"
import {
    Box3,
    DoubleSide,
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    Quaternion,
    Scene,
    Vector3,
} from "three"

function getPointsArrayForEnds(
    markers: Mesh[],
    segmentLength = 0.25,
    extendSurface = false,
    stepDensity = 1,
    pieceLength?: number,
    singleLine?: boolean,
    referenceMesh?: Mesh
) {
    const firstAndLastSides = getFirstAndLastSides(markers)

    const firstSide = markers[firstAndLastSides[0]]
    const step = convertInToCm(segmentLength) / 100 / stepDensity
    const mesh = firstSide

    const tolerance = 2.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100
    const offset = pieceLengthInCm * tolerance

    mesh.visible = true
    if (mesh.material instanceof MeshBasicMaterial) {
        mesh.material.wireframe = true
    }
    mesh.updateMatrixWorld(true)
    mesh.geometry.computeBoundingBox()

    const { points, allPoints, } = walkSurface(
        mesh,
        step,
        offset,
        extendSurface,
        undefined,
    )
    const mapper = (p: Vector3) => {
        return {
            meshName: mesh.userData.pointsMarkerName || mesh.name,
            position: p,
        }
    }
    const boundingBoxPoints = allPoints.map(mapper)
    boundingBoxPoints.forEach(p => {
        // window.drawVector3Point(p.position, 0xff0000, 0.001, 5000)
    })
    const freePositions: FreePositions[] = points.map(mapper)
    if (singleLine) {
        return { freePositions: findPointsAlongAxis(freePositions, mesh), boundingBoxPoints, }
    }

    return { freePositions, boundingBoxPoints, }
}

function getPointsArrayForSingleEnd(mesh: any, segmentLength = 0.25, scene?: Scene) {
    const step = convertInToCm(segmentLength) / 100

    mesh.visible = true
    mesh.updateMatrixWorld(true)
    mesh.geometry.computeBoundingBox()

    const { points, allPoints, } = walkSurface(mesh, step, undefined, undefined, scene)
    const mapper = (p: Vector3) => {
        return {
            meshName: mesh.userData.pointsMarkerName || mesh.name,
            position: p,
        }
    }
    const boundingBoxPoints = allPoints.map(mapper)
    const freePositions = points.map(mapper)
    return { freePositions, boundingBoxPoints, }
}

function getDragMeshForEnds(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const mesh = firstAndLastSides.map(key => sides[key].mesh)[0]
    const cloneMesh = mesh.clone() as Mesh
    const globalPosition = new Vector3()
    mesh.getWorldPosition(globalPosition)
    cloneMesh.position.copy(globalPosition)
    const globalQuaternion = new Quaternion()
    mesh.getWorldQuaternion(globalQuaternion)
    cloneMesh.quaternion.copy(globalQuaternion)

    const worldScale = new Vector3()
    mesh.getWorldScale(worldScale)
    cloneMesh.scale.copy(worldScale)

    cloneMesh.updateMatrixWorld(true)

    const material = new MeshBasicMaterial({
        color: 0xff0000,
        opacity: viewSurfaceMesh ? 1 : 0,
        transparent: true,
        wireframe: false,
        side: DoubleSide,
    })

    cloneMesh.material = material

    const boundingBox = new Box3().setFromObject(cloneMesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    const planeGeometry = new PlaneGeometry(10, 10)
    const planeMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: viewDragMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
        side: DoubleSide,
    })
    const plane = new Mesh(planeGeometry, planeMaterial)

    mesh.updateMatrixWorld(true)
    plane.position.copy(cloneMesh.getWorldPosition(new Vector3()))
    plane.quaternion.copy(cloneMesh.getWorldQuaternion(new Quaternion()))

    return {
        dragMesh: plane,
        surfaceMesh: cloneMesh,
        boundingBox,
        center,
    } as DragMesh
}

export { getPointsArrayForEnds, getDragMeshForEnds, getPointsArrayForSingleEnd, }
