/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useThree } from "@react-three/fiber"
import React, { createContext, useContext, useRef } from "react"
import { useEffect } from "react"
import { Raycaster, Vector2 } from "three"
import { MarkerType } from "../../utils/Types"
import { instancedMeshContext } from "../instancedMesh/InstancedMeshProvider"
import { selectedItemID } from "../../state/atoms"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import isMobile from "ismobilejs"
import useInstancedMeshSegmentedTubes from "../instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"

interface Props {
    children: React.ReactNode;
}

type SelectablesType = {
    id: string,
    meshCallback: (() => void) | undefined,
}

export type InstancedMeshContextType = {
    registerSelectableMesh: (id: string, meshCallback: (() => void) | undefined) => void,
} | undefined

export const selectorContext = createContext<InstancedMeshContextType>(undefined)

const SelectorProvider: React.FC<Props> = ({ children, }) => {
    const raycaster = useRef<Raycaster>()
    const selectables = useRef<SelectablesType[]>([])
    const { scene, camera, gl, } = useThree()
    const instanceContext = useContext(instancedMeshContext)
    const resetSelectedItem = useResetRecoilState(selectedItemID)
    const mouseClick = useRef<{ x: number, y: number, } | undefined>(undefined)
    const isMultitouch = useRef(false)
    const setSelectedItemID = useSetRecoilState(selectedItemID)
    const lasMousePosition = useRef({ x: 0, y: 0, })
    const { handleTubeSelection, } = useInstancedMeshSegmentedTubes()

    const setMultitouch = (event: MouseEvent | TouchEvent) => {
        if (event.type.includes("touch")) {
            if ((event as TouchEvent).touches.length > 1) {
                isMultitouch.current = true
            }
            if ((event as TouchEvent).touches.length === 1) {
                isMultitouch.current = false
            }
        }
    }

    const getTouchCoordinates = (event: MouseEvent | TouchEvent) => {
        if ((event as any).touches
            && (event as any).touches[0]) {
            return {
                x: (event as any).touches[0].pageX,
                y: (event as any).touches[0].pageY,
            }
        }
        return { x: (event as any).pageX, y: (event as any).pageY, }
    }

    const onMouseMove = (event: MouseEvent | TouchEvent) => {
        lasMousePosition.current = getTouchCoordinates(event)
    }

    const onMouseDown = (event: MouseEvent | TouchEvent) => {
        const coordinates = getTouchCoordinates(event)
        lasMousePosition.current = coordinates
        setMultitouch(event)
        mouseClick.current = coordinates
    }

    // eslint-disable-next-line max-statements
    const onMouseUp = (event: MouseEvent | TouchEvent) => {
        if (isMultitouch.current) {
            return
        }

        if (mouseClick.current
            && Math.floor(lasMousePosition.current.x) === Math.floor(mouseClick.current.x)
            && Math.floor(lasMousePosition.current.y) === Math.floor(mouseClick.current.y)
        ) {
            if ((event.target as HTMLCanvasElement).nodeName === "CANVAS") {
                const mouse = lasMousePosition.current
                const clientX = mouse.x
                const clientY = mouse.y

                const rect = gl.domElement.getBoundingClientRect()

                mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1
                mouse.y = - ((clientY - rect.top) / rect.height) * 2 + 1

                if (raycaster.current) {
                    const mouseVector = new Vector2(mouse.x, mouse.y)
                    raycaster.current.setFromCamera(mouseVector, camera)
                    const intersections = raycaster.current.intersectObjects(scene.children)
                    let instanceId = -1
                    let meshName = ""
                    let objectSelected = false
                    const intersectionDifferentPlusButton = intersections.find(
                        i => [MarkerType.COLLISION, MarkerType.PLUS_BUTTON,]
                            .includes(i.object.userData.type))
                        ?.object.userData.type !== MarkerType.PLUS_BUTTON
                    if (intersectionDifferentPlusButton) {
                        if (intersections.length !== 0) {
                            //console.log("intersections", intersections)
                            intersections.forEach((intersection) => {
                                if (intersection.object
                                    && (intersection.object.type === "Mesh"
                                        || intersection.object.type === "InstancedMesh"
                                    )
                                ) {
                                    if (intersection.object.userData.type === "InstancedMesh"
                                        && instanceId === -1 && !objectSelected) {
                                        instanceId = intersection.instanceId!
                                        meshName = intersection.object.name
                                        if (intersection.object.userData.partType && intersection.object.userData.partType === "SECTIONED_TUBE") {
                                            // It is a segmented tube
                                            handleTubeSelection(meshName, instanceId)
                                        } else {
                                            // It is a connector
                                            instanceContext?.clickCallback(meshName, instanceId)

                                        }
                                        objectSelected = true
                                    }
                                    if (intersection.object.type === "Mesh" && !objectSelected) {
                                        const s = selectables.current.find((s) =>
                                            s.id === intersection.object.name)
                                        if (s) {
                                            s.meshCallback!()
                                            objectSelected = true
                                        }
                                    }
                                }
                                if (intersection.object.userData.type === "MergedMesh" && !objectSelected && intersection.object.userData.partId) {
                                    setSelectedItemID(intersection.object.userData.partId)
                                    objectSelected = true
                                }

                            })
                        }

                        if (!objectSelected) {
                            resetSelectedItem()
                        }
                    }
                }
            }
        }
        mouseClick.current = undefined
    }

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const viewParam = urlParams.get("viewOnly")
        if (viewParam !== "true") {
            raycaster.current = new Raycaster()
            if (isMobile(window.navigator).phone) {
                document.removeEventListener("touchstart", onMouseDown)
                document.addEventListener("touchstart", onMouseDown)
                document.removeEventListener("touchend", onMouseUp)
                document.addEventListener("touchend", onMouseUp)
                document.removeEventListener("touchmove", onMouseMove)
                document.addEventListener("touchmove", onMouseMove)
            } else {
                document.removeEventListener("mousedown", onMouseDown)
                document.addEventListener("mousedown", onMouseDown)
                document.removeEventListener("mouseup", onMouseUp)
                document.addEventListener("mouseup", onMouseUp)
                document.removeEventListener("mousemove", onMouseMove)
                document.addEventListener("mousemove", onMouseMove)
            }
        }
    }, [camera,])


    const registerSelectableMesh = (id: string, meshCallback: (() => void) | undefined) => {
        selectables.current.push({
            id,
            meshCallback,
        })
    }

    const init = () => {
        return {
            registerSelectableMesh,
        }
    }

    return <selectorContext.Provider value={init()} children={children} />
}

export default SelectorProvider