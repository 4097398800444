import React, { useRef, useEffect } from "react"
import { Mesh, BoxGeometry, MeshBasicMaterial, Vector3, PerspectiveCamera } from "three"
import { useGlobalAnimation } from "../../utils/animations/GlobalAnimationProvider"
import { useThree, useFrame } from "@react-three/fiber"

interface Props {
    position: Vector3;
    color: string;
}

const AlignmentPointHighlight: React.FC<Props> = ({ position, color, }) => {
    const meshRef = useRef<Mesh>(null)
    const { setIsAnimating, } = useGlobalAnimation()
    const { camera, } = useThree()

    // Update scale on every frame
    useFrame(() => {
        if (meshRef.current) {
            const distance = camera.position.distanceTo(meshRef.current.position)
            const scale = Math.max(0.001, 0.005 * distance) // Add minimum scale
            meshRef.current.scale.set(scale, scale, scale)
        }
    })

    useEffect(() => {
        if (meshRef.current) {
            // Set initial scale to very small to prevent flash
            meshRef.current.scale.set(0.001, 0.001, 0.001)
            meshRef.current.position.copy(position)
            setIsAnimating(true)

            const timeout = setTimeout(() => {
                setIsAnimating(false)
            }, 2500)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [position, setIsAnimating, camera,])

    return (
        <mesh ref={meshRef}>
            <boxGeometry args={[1, 1, 1,]} />
            <meshBasicMaterial color={color} transparent depthTest={false} opacity={0.5} />
        </mesh>
    )
}

export default AlignmentPointHighlight