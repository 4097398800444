import { MutableRefObject } from "react"
import {
  Intersection,
  Mesh,
  Object3D,
  Object3DEventMap,
  Scene,
  Vector3
} from "three"
import { ConnectionTypeAPI } from "../../../../../../../../../common/api/Types"
import {
  LengthUtils
} from "../../../../../../../../providers/multipleMovementProvider/utils/LengthUtils"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import {
  getOtherPartOfTheConnection, isInConnection
} from "../../../../../../../../state/scene/util"
import {
  addLines,
  getMarkerUserData,
  isMarkerUserData
} from "../../../../../../../../utils/MarkerUtil"
import { getFreeMarkers } from "../../../../../../../../utils/PartUtils"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { MarkerType, TubePart, TubeValues } from "../../../../../../../../utils/Types"
import { ObjDictionary } from "../../../../../../../../../common/utils/utils"
import { roundLength } from "../../../../../../../../utils/utils"
import { isCollineal, isParallel } from "../../../../../utils/utilsThree"
import { TubeInternalsType } from "../types/types"
import {
  convertFromMeterToInchFunction,
  proyectRay,
  updateTubeMesh,
  isCompatibleWith,
  sort,
  removeGuidelines,
  checkExactSnap,
  getOriginMarker
} from "./TubeUtils"
import { ConnectorMarkerType } from "../../connector/types/types"

export const useDetachFromMarker = (props: {
  tube: TubePart,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  scene: Scene,
  buildCollider: () => void,
  updateCollider: (connectedParts: string[]) => void,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
}) => {
  const { tube,
    tubeInternalsRef,
    scene,
    buildCollider,
    updateCollider,
    setInternals,
    compatibilityList,
  } = props
  return (
    marker: Mesh,
    connectedParts: string[],
    connectedMarkers: string[],
    checkCollisions?: boolean,
    resetGuidelines?: boolean
  ) => {
    const origin = getOriginMarker(tubeInternalsRef)
    scene.attach(origin!)
    if (resetGuidelines) {
      removeGuidelines(tubeInternalsRef, scene, setInternals)
    }
    if (checkCollisions) {
      const newConnections = checkExactSnap(
        scene,
        tubeInternalsRef.current,
        getFreeMarkers(tube, connectedMarkers),
        tube,
        compatibilityList,
      )
      buildCollider()
      if (newConnections.length === 0) {
        updateCollider(connectedParts)
      } else {
        updateCollider([
          ...connectedParts,
          ...newConnections.map(c => getOtherPartOfTheConnection(c, tube.id)),
        ])
        SoundHelper.playUnsnap()

      }
      return newConnections
    }
    return []
  }
}

const getHandleDrag = (
  length: number,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  tubeMesh: Mesh,
  setInternals: (newTubeInternals: TubeInternalsType) => void
) => {
  return (distance: number) => {
    const { mmSnapState, } = tubeInternalsRef.current
    if (!mmSnapState.isSnapped) {
      const distAux = convertFromMeterToInchFunction(distance)
      updateTubeMesh(length + distAux, tubeInternalsRef.current, tubeMesh)
      setInternals({
        ...tubeInternalsRef.current,
        dragState: {
          drag: true, dragValue: length + distAux,
        },
      })
    }
  }
}

const getHandleSnap = (
  length: number,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  tubeMesh: Mesh,
  setInternals: (newTubeInternals: TubeInternalsType) => void
) => {
  const DRAG_VALUE_OFFSET = 1
  return (newConnection: PartConnectionType) => {
    const { mmSnapState, } = tubeInternalsRef.current

    if ((length > mmSnapState.maxPossibleLength - DRAG_VALUE_OFFSET
      || length >= mmSnapState.maxPossibleLength)
      && !mmSnapState.isSnapped
    ) {
      SoundHelper.playUnsnap()
      updateTubeMesh(mmSnapState.maxPossibleLength, tubeInternalsRef.current, tubeMesh)
      setInternals({
        ...tubeInternalsRef.current,
        mmSnapState: {
          ...tubeInternalsRef.current.mmSnapState,
          isSnapped: true,
        },
      })
      tubeInternalsRef.current.mmSnapState.newConnection = newConnection
    }
  }
}

const checkAlignments = (props: {
  tube: TubeValues,
  scene: Scene,
  tubeInternalsRef: MutableRefObject<TubeInternalsType>,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  elongationDirection?: Vector3,
  connections: PartConnectionType[],
  lengthDirection?: number,
  intersectableMeshes?: Object3D[],
}) => {
  if (props.connections.filter(c => isInConnection(c, props.tube.id)).length < 2) {
    proyectRay(props.scene, props.tubeInternalsRef, props.setInternals, props.intersectableMeshes)
    const { rayState, movableMarkerMesh, } = props.tubeInternalsRef.current

    const rayStateObject = rayState.objects.find(o => {
      return (((o.face) && (o.object.userData.partId !== props.tube.id)
        && movableMarkerMesh && (o.object.userData.type === MarkerType.COLLISION)))
    })
    const DISTANCE_TO_ACTIVATE_GUIDELINE = 20
    if (rayStateObject) {
      if (rayStateObject.distance < DISTANCE_TO_ACTIVATE_GUIDELINE) {
        movableMarkerMesh!.getWorldDirection(rayState.movableMarkerDirection)
        rayStateObject.object.getWorldDirection(rayState.collisionPlaneDirection)

        if (isParallel(
          rayState.movableMarkerDirection, rayState.collisionPlaneDirection)
          && (!props.elongationDirection
            || !isParallel(rayState.movableMarkerDirection, props.elongationDirection))
        ) {
          const distanceToElongate = props.elongationDirection && props.lengthDirection
            ? LengthUtils.getDistanceToElongate(
              rayStateObject as unknown as Intersection<Object3D<Object3DEventMap>>,
              rayState.worldMovableMarkerPosition,
              props.elongationDirection,
              props.lengthDirection,
              props.scene
            ) : 0
          rayStateObject.object.getWorldPosition(rayState.colliderWorldPosition)
          const guidelines = addLines(
            props.scene,
            [rayState.worldMovableMarkerPosition, rayState.colliderWorldPosition,],
            props.tubeInternalsRef,
          )
          props.setInternals({
            ...props.tubeInternalsRef.current,
            guidelines,
          })
          if (!props.lengthDirection) {
            return {
              drag: true,
              dragDistance: 0,
            } as const
          }
          return {
            drag: true,
            dragDistance: props.lengthDirection < 0 ? -distanceToElongate : distanceToElongate,
          } as const
        }
      }
    }
  }
  return { drag: false, } as const
}

const checkSnap = (props: {
  tube: TubeValues,
  scene: Scene,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
  elongationDirection: Vector3,
}) => {
  if (props.compatibilityList) {
    proyectRay(props.scene, props.tubeInternalsRef, props.setInternals)
    const { rayState, movableMarkerMesh, } = props.tubeInternalsRef.current

    const filteredObjects = rayState.objects.filter(o => {
      const object = o.object as unknown as Object3D<Object3DEventMap>
      if (isMarkerUserData(object)) {
        const userData = getMarkerUserData(object)

        return (o.face
          && (userData.id !== props.tube.id) && movableMarkerMesh
          && (userData.type === MarkerType.COLLISION)
          && isCompatibleWith(props.tube, o, props.compatibilityList!)
        )
      } else {
        return false
      }
    })


    filteredObjects.sort(sort)

    const DISTANCE_TO_SNAP = 1
    if (movableMarkerMesh) {
      movableMarkerMesh.getWorldDirection(rayState.worldMovableMarkerDirection)
      movableMarkerMesh.getWorldPosition(rayState.worldMovableMarkerPosition)

      const rayStateObject = filteredObjects.filter((o) => {
        o.object.getWorldDirection(rayState.collisionPlaneDirection)
        o.object.getWorldPosition(rayState.colliderWorldPosition)

        return o.object.name.includes(ConnectorMarkerType.inner)
          && isCollineal(
            rayState.worldMovableMarkerDirection,
            rayState.worldMovableMarkerPosition,
            rayState.collisionPlaneDirection,
            rayState.colliderWorldPosition
          )
      })[0]

      if (
        isCollineal(
          rayState.worldMovableMarkerDirection,
          rayState.worldMovableMarkerPosition,
          props.elongationDirection,
          rayState.worldMovableMarkerPosition
        )
        && rayStateObject
        && rayStateObject.distance < DISTANCE_TO_SNAP
      ) {
        return {
          snap: true,
          snapDistance: rayStateObject.distance,
          selectedObject: rayStateObject,
          newConnections: [{
            partA: {
              partId: props.tube.id,
              markerName: movableMarkerMesh.name,
            },
            partB: {
              partId: rayStateObject.object.userData.partId,
              markerName: rayStateObject.object.userData.markerName,
            },
          },],
        }
      }
    }
  }
  return { snap: false, } as const
}

const getMaxLength = (props: {
  direction: Vector3,
  tube: TubeValues,
  scene: Scene,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  connections?: PartConnectionType[],
  compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
}) => {
  const tubeConnections = props.connections?.filter(c => isInConnection(c, props.tube.id))
  if (props.compatibilityList && (!tubeConnections || tubeConnections?.length < 2)) {
    proyectRay(props.scene, props.tubeInternalsRef, props.setInternals)
    const { rayState, movableMarkerMesh, } = props.tubeInternalsRef.current

    const filteredObjects = rayState.objects.filter(o => {
      const object = o.object as unknown as Object3D<Object3DEventMap>
      if (isMarkerUserData(object)) {
        const userData = getMarkerUserData(object)

        return (o.face
          && (userData.id !== props.tube.id) && movableMarkerMesh
          && (userData.type === MarkerType.COLLISION)
          && isCompatibleWith(props.tube, o, props.compatibilityList!)
        )
      } else {
        return null
      }
    })

    filteredObjects.sort(sort)

    if (movableMarkerMesh) {
      movableMarkerMesh.getWorldDirection(rayState.worldMovableMarkerDirection)
      movableMarkerMesh.getWorldPosition(rayState.worldMovableMarkerPosition)

      const rayStateObject = filteredObjects.filter((o) => {
        o.object.getWorldDirection(rayState.collisionPlaneDirection)
        o.object.getWorldPosition(rayState.colliderWorldPosition)

        return o.object.name.includes(ConnectorMarkerType.inner)
          && isCollineal(
            rayState.worldMovableMarkerDirection,
            rayState.worldMovableMarkerPosition,
            rayState.collisionPlaneDirection,
            rayState.colliderWorldPosition
          )
          && isCollineal(
            rayState.worldMovableMarkerDirection,
            rayState.worldMovableMarkerPosition,
            props.direction,
            rayState.worldMovableMarkerPosition
          )
      })[0]

      if (rayStateObject) {
        return roundLength(rayStateObject.distance)
      }
    }
  }
  return null
}

export const MultipleMovementUtils = {
  useDetachFromMarker,
  getHandleDrag,
  getHandleSnap,
  checkAlignments,
  checkSnap,
  getMaxLength,
}