import React from "react"
import { Empty as EmptyAnt } from "antd"
import styled from "styled-components"

const Empty = () => {
  return (
  <Container>
    <EmptyAnt />
  </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Empty