/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

export const SaveIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V3.33333L11.6667 0ZM7.5 13.3333C6.11667 13.3333 5 12.2167 5 10.8333C5 9.45 6.11667 8.33333 7.5 8.33333C8.88333 8.33333 10 9.45 10 10.8333C10 12.2167 8.88333 13.3333 7.5 13.3333ZM10 5H1.66667V1.66667H10V5Z" fill={color} />
        </svg>
    )
}

export const HambIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
            <g stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.665">
                <path transform="scale(3.6036)" d="m139 97h148v167h-240v-194h81z" fill={"transparent"} />
                <path transform="scale(3.6036)" d="m195 146h-148v118h240v-137h-82z" fill={color} fillRule="evenodd" />
            </g>
        </svg>
    )
}

export const UndoIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41699 1.66665C7.20866 1.66665 5.20866 2.49165 3.66699 3.83331L0.666992 0.833313V8.33331H8.16699L5.15033 5.31665C6.30866 4.34998 7.78366 3.74998 9.41699 3.74998C12.367 3.74998 14.8753 5.67498 15.7503 8.33331L17.7253 7.68331C16.567 4.19165 13.292 1.66665 9.41699 1.66665Z" fill={color} />
        </svg>
    )
}

export const RedoIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="18" height="9" viewBox="0 0 18 9" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3352 3.83325C12.7935 2.49159 10.7935 1.66659 8.58516 1.66659C4.71016 1.66659 1.43516 4.19159 0.285156 7.68325L2.25182 8.33325C3.12682 5.67492 5.62682 3.74992 8.58516 3.74992C10.2102 3.74992 11.6935 4.34992 12.8518 5.31659L9.83516 8.33325H17.3352V0.833252L14.3352 3.83325Z" fill={color} />
        </svg>

    )
}

export const PartListIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="32" height="20" viewBox="0 0 32 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill={color} />
            <path d="M21 18.6665H31.6667V15.9998H21V18.6665ZM21 2.6665V5.33317H31.6667V2.6665H21ZM21 11.9998H31.6667V9.33317H21V11.9998Z" fill={color} />
        </svg>
    )
}


export const CartIcon = ({ color, }: { color: string, }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
            fill="none">
            <path d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill={color} />
        </svg>
    )
}

export const GoogleIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M19.999 10.2222C19.999 9.39994 19.9309 8.79995 19.7836 8.17773H10.2031V11.8888H15.8266C15.7133 12.811 15.1011 14.1999 13.7405 15.1332L13.7214 15.2574L16.7506 17.5572L16.9605 17.5777C18.8879 15.8332 19.999 13.2666 19.999 10.2222Z" fill="#4285F4" />
            <path d="M10.2055 19.9998C12.9605 19.9998 15.2734 19.1109 16.9628 17.5776L13.7428 15.1331C12.8812 15.722 11.7247 16.1331 10.2055 16.1331C7.50707 16.1331 5.21686 14.3887 4.40046 11.9775L4.28079 11.9875L1.13103 14.3764L1.08984 14.4886C2.76782 17.7553 6.21451 19.9998 10.2055 19.9998Z" fill="#34A853" />
            <path d="M4.39903 11.9777C4.18362 11.3555 4.05895 10.6888 4.05895 9.99996C4.05895 9.31103 4.18362 8.64439 4.3877 8.02218L4.38199 7.88966L1.19275 5.4624L1.0884 5.51104C0.396828 6.86661 0 8.38886 0 9.99996C0 11.6111 0.396828 13.1332 1.0884 14.4888L4.39903 11.9777Z" fill="#FBBC05" />
            <path d="M10.2055 3.86663C12.1216 3.86663 13.4141 4.67773 14.151 5.35555L17.0309 2.59999C15.2622 0.98889 12.9606 0 10.2055 0C6.21454 0 2.76783 2.24442 1.08984 5.51105L4.38915 8.02219C5.21688 5.61109 7.50711 3.86663 10.2055 3.86663Z" fill="#EB4335" />
        </svg>

    )
}

export const FacebookIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_514_10578)" />
            <path d="M13.7207 13.0584L14.1649 10.236H11.3861V8.40517C11.3861 7.63282 11.7736 6.87951 13.0182 6.87951H14.2824V4.4766C14.2824 4.4766 13.1356 4.28589 12.0397 4.28589C9.75011 4.28589 8.25498 5.63799 8.25498 8.08478V10.236H5.71094V13.0584H8.25498V19.8819C8.76573 19.9601 9.28825 20.0002 9.82054 20.0002C10.3528 20.0002 10.8753 19.9601 11.3861 19.8819V13.0584H13.7207Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_514_10578" x1="10" y1="0" x2="10"
                    y2="19.9407" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#18ACFE" />
                    <stop offset="1" stop-color="#0163E0" />
                </linearGradient>
            </defs>
        </svg>

    )
}


export const ShareIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="13" height="19" viewBox="0 0 13 19" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66797 3.95841L8.5438 5.08258L7.28505 3.82383V12.6667H5.71755V3.82383L4.4588 5.08258L3.33464 3.95841L6.5013 0.791748L9.66797 3.95841ZM12.8346 7.91675V16.6251C12.8346 17.4959 12.1221 18.2084 11.2513 18.2084H1.7513C0.872552 18.2084 0.167969 17.4959 0.167969 16.6251V7.91675C0.167969 7.038 0.872552 6.33341 1.7513 6.33341H4.1263V7.91675H1.7513V16.6251H11.2513V7.91675H8.8763V6.33341H11.2513C12.1221 6.33341 12.8346 7.038 12.8346 7.91675Z" fill={color} />
        </svg>
    )
}


export const ShareLinkIcon = ({ color, }: { color: string, }) => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2.24782 11.2529C1.34999 10.3551 1.34999 8.89543 2.24782 7.9976L4.34801 5.89741L3.35042 4.89983L1.25023 7.00001C-0.198896 8.44914 -0.198896 10.8013 1.25023 12.2505C2.69936 13.6996 5.05157 13.6996 6.5007 12.2505L8.60088 10.1503L7.6033 9.1527L5.50311 11.2529C4.60528 12.1507 3.14565 12.1507 2.24782 11.2529ZM4.92556 9.62524L9.12593 5.42487L8.07584 4.37478L3.87547 8.57515L4.92556 9.62524ZM6.5007 1.74955L4.40051 3.84973L5.3981 4.84732L7.49829 2.74713C8.39612 1.8493 9.85575 1.8493 10.7536 2.74713C11.6514 3.64496 11.6514 5.10459 10.7536 6.00242L8.65339 8.10261L9.65098 9.1002L11.7512 7.00001C13.2003 5.55088 13.2003 3.19867 11.7512 1.74955C10.302 0.300417 7.94983 0.300417 6.5007 1.74955Z" fill={color} />
        </svg>

    )
}