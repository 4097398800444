import React from "react"
import type { TierResult } from "./detect-gpu"
import { useEffect, useState } from "react"
import styled from "styled-components"

const GPUBenchStyles = styled.div`
    position: fixed;
    pointer-events: none;
    bottom: 10px;
    left: 10px;
    color: #000;
    z-index: 10000;
    font-family: monospace;
    user-select: none;
`

const GPUBench = () => {
    const [gpu, setGpu,] = useState<TierResult>()

    const testGPU = async () => {
        const gpuTier = await (await import("./detect-gpu")).getGPUTier()
        setGpu(gpuTier)
    }

    useEffect(() => {
        testGPU()
    }, [])

    return (
        <GPUBenchStyles>
            <p>{`device: ${gpu?.device}`}</p>
            <p>{`fps: ${gpu?.fps}`}</p>
            <p>{`gpu: ${gpu?.gpu}`}</p>
            <p>{`is mobile: ${gpu?.isMobile}`}</p>
            <p>{`tier: ${gpu?.tier}`}</p>
            <p>{`type: ${gpu?.type}`}</p>
            <p id="drawcalls">{`draw calls: ${gpu?.type}`}</p>
            <ul id="memory-debug"></ul>
        </GPUBenchStyles>
    )
}

export default GPUBench