import React from "react"
import Button from "../../../../../../../../../common/components/Button"
import { ButtonContainer, SliderContainer } from "../../segmentedTube/ui/ActionPanelStyles"
import { DescriptionBodyContainer } from "../../../../../../../../../common/components/DrawerShared"
type Props = {
    onFinishEditing: () => void,
}

const CancelUI = (props: Props) => {
    const { onFinishEditing, } = props
    return <SliderContainer>
        <DescriptionBodyContainer>
        </DescriptionBodyContainer>
    </SliderContainer>
}

export default CancelUI