import React, { useEffect, useRef } from "react"
import paper from "paper"

const PaperCanvas = () => {
    const canvas = useRef<HTMLCanvasElement | null>(null)

    //Canvas SVG Tube
    useEffect(() => {
        if (canvas.current) {
            paper.setup(canvas.current)
        }
    }, [])

    return <canvas ref={canvas} width={100} height={0} style={{position: "absolute",}}></canvas>
}

export default PaperCanvas