import { Event, Intersection, Line, Mesh, Object3D, Raycaster, Vector3 } from "three"

export enum CONNECTOR_UI {
  NONE = "NONE",
  SLIDER = "SLIDER",
  ACTIONS = "ACTIONS",
  CLOSE = "CLOSE",
  SEGMENTED_SLIDER = "SEGMENTED_SLIDER",
  AFTER_CREATION = "AFTER_CREATION",
}

export enum ConnectorMarkerType {
  inner = "inner",
  outer = "outer",
  mesh = "mesh",
  plus = "plus",
}

export type RayStateType = {
  worldMarkerPosition: Vector3,
  worldMarkerDirection: Vector3,
  ray: Raycaster,
  markerDirection: Vector3,
  colliderWorldDirection: Vector3,
  colliderWorldPosition: Vector3,
  objects: Intersection<Object3D>[],
}

export type RayStatesType = {
  [name: string]: RayStateType,
}

export type ConnectorInternalsType = {
  markers: { [name: string]: Mesh, },
  rayStates: RayStatesType,
  guidelines: Line[],
}