import React from "react"
import { Line } from "@react-three/drei"
import { extend } from "@react-three/fiber"
import { Vector3 } from "three"

extend({ Line, })

interface TShapedLineProps {
    pointA: Vector3;
    pointB: Vector3;
    capLength?: number;
}

const TShapedLine = ({ pointA, pointB, capLength = 0.005, }: TShapedLineProps) => {
    // Calculate direction from A to B
    const direction = new Vector3().subVectors(pointB, pointA)
        .normalize()

    // Calculate perpendicular direction for the caps
    const perpendicularDirection = new Vector3(-direction.y, direction.x, 0).normalize()

    // Determine the cap points for pointA and pointB
    const capDirectionA1 = perpendicularDirection.clone().multiplyScalar(capLength)
    const capPointA1 = new Vector3().addVectors(pointA, capDirectionA1)

    const capDirectionA2 = perpendicularDirection.clone().multiplyScalar(-capLength)
    const capPointA2 = new Vector3().addVectors(pointA, capDirectionA2)

    const capDirectionB1 = perpendicularDirection.clone().multiplyScalar(capLength)
    const capPointB1 = new Vector3().addVectors(pointB, capDirectionB1)

    const capDirectionB2 = perpendicularDirection.clone().multiplyScalar(-capLength)
    const capPointB2 = new Vector3().addVectors(pointB, capDirectionB2)

    // Define the points for the T-shape
    const points = [
        capPointA1, // Starting point of the cap at pointA
        capPointA2, // Ending point of the cap at pointA
        pointA,     // Actual start point of the line
        pointB,     // Actual end point of the line
        capPointB1, // Starting point of the cap at pointB
        capPointB2, // Ending point of the cap at pointB
    ]

    return (
        <Line
            points={points}
            color="#000000"
            transparent={true}
            opacity={0.2}
            lineWidth={1}
            name="measurement-line"
            raycast={() => { }}
            depthTest={false}
        />
    )
}

export default TShapedLine
