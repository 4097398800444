import React from "react"
import { useRecoilValue } from "recoil"
import styled from "styled-components"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { scenePartsSelector } from "../../../../../../../state/scene/atoms"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { rowTemplate, partsListStyles } from "./partsListHelpers"
import { usePartsList } from "../usePartsList"
import { NoPartsInScene } from "./NoPartsInScene"
import UnitToggle from "../../../../scene/UnitToggle"
import PrintButton from "./Print/PrintButton"

interface PartsUsedListProps {
  parts: PartTypeAPI[];
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  showPrintButton: boolean;
}

export const PartsUsedList: React.FC<PartsUsedListProps> = ({
  parts,
  shopifyIds,
  listRef,
  showPrintButton,
}) => {
  const sceneAtomList = useRecoilValue(scenePartsSelector)
  const { getRows, } = usePartsList({ parts, shopifyIds, allowCm: true, })

  const partsRows = getRows(Object.values(sceneAtomList))
    .map(r => rowTemplate({ ...r, inStock: true, }))

  if (partsRows.length === 0) {
    return <NoPartsInScene />
  }

  return <Wrapper ref={listRef}>
    <partsListStyles.Header>
      {showPrintButton && <PrintButton listRef={listRef} />}
      <UnitToggle />
    </partsListStyles.Header>
    <partsListStyles.Labels>
      <partsListStyles.Label bold>Parts</partsListStyles.Label>
      <partsListStyles.LabelRightAlined>Qty</partsListStyles.LabelRightAlined>
    </partsListStyles.Labels>
    <partsListStyles.RowsContainer>{partsRows}</partsListStyles.RowsContainer>
  </Wrapper>
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`