/* eslint-disable */

import {
	EventDispatcher,
	Plane,
	Raycaster,
	Vector2,
	Vector3,
	SphereGeometry,
	MeshBasicMaterial,
	Mesh,
	ArrowHelper
} from "three";

const _raycaster = new Raycaster();
const _pointer = new Vector2();
const _intersection = new Vector3();

class _Event {
	constructor() {
		this.object = null;
		this.item = null;
	}
}

const EVENT_TYPES = {
	MOUSE_OVER: "mouseOver",
	MOUSE_OUT: "mouseOut",
	DRAG_AND_DROP: "dragAndDrop",
	CLICK: "click"
};

class EventControls extends EventDispatcher {
	constructor(_objects, _camera, _domElement, onPositionChange, onDragEndHandler, enableCamera, disableCamera, draggableObjectCenter, scene, drawVector3Point) {
		super();
		_domElement.style.touchAction = "none";
		let _selected = null,
			_hovered = null;
		const _intersections = [];
		const scope = this;
		let lastPoint = null;

		this.event = new _Event();
		this.plane = null;
		this.surface = null;
		this.previous = new Vector3();
		this.offset = new Vector3(0, 0, 0);
		this._mouseOverFlag = false;
		this._mouseOutFlag = false;
		this._dragAndDropFlag = false;
		this._mouseUpFlag = false;
		this._onclickFlag = false;
		this._mouseMoveFlag = false;
		this.dragAndDrop = function () { };
		this.mouseOver = function () { };
		this.mouseOut = function () { };
		this.mouseUp = function () { };
		this.mouseMove = function () { };
		this.onclick = function () { };
		this.draggable = false;
		this.drawVector3Point = drawVector3Point;
		let _dragOffset = new Vector3();
		this.draggableObjectCenter = draggableObjectCenter;

		this.setPlane = function (plane) {
			this.plane = plane;
		};
		this.setSurface = function (surface) {
			this.surface = surface;
		};
		this.setDraggable = function (_render, flag) {
			this.draggable = flag;
			if (flag) {
				this.addEventListener("drag", _render);
			}
			else {
				this.removeEventListener("drag", _render);
			}
		};
		this.attach = function (object) {
			_objects.push(object);
		};
		this.detach = function (object) {
			const item = _this.objects.indexOf(object);
			_objects.splice(item, 1);
		};
		this.attachEvent = function (event, handler) {
			switch (event) {
				case EVENT_TYPES.MOUSE_OVER:
					this.mouseOver = handler;
					scope._mouseOverFlag = true;
					break;
				case EVENT_TYPES.MOUSE_OUT:
					this.mouseOut = handler;
					scope._mouseOutFlag = true;
					break;
				case EVENT_TYPES.DRAG_AND_DROP:
					scope.dragAndDrop = handler;
					scope._dragAndDropFlag = true;
					scope.enabled = true;
					break;
				case EVENT_TYPES.CLICK:
					scope.onclick = handler;
					scope._onclickFlag = true;
					break;
				default:
					console.warn(`Unhandled event type: ${event}`);
			}
		};
		this.setDraggableObjectCenter = function (newCenter) {
			this.draggableObjectCenter = newCenter;
		};
		const touchmoveHandler = function (e) { e.preventDefault() }
		function activate() {
			_domElement.addEventListener("pointermove", onPointerMove);
			_domElement.addEventListener("pointerdown", onPointerDown);
			_domElement.addEventListener("pointerup", onPointerCancel);
			_domElement.addEventListener("pointerleave", onPointerCancel);
			_domElement.addEventListener("touchmove", touchmoveHandler, { passive: false })
		}
		function deactivate() {
			_domElement.removeEventListener("pointermove", onPointerMove);
			_domElement.removeEventListener("pointerdown", onPointerDown);
			_domElement.removeEventListener("pointerup", onPointerCancel);
			_domElement.removeEventListener("pointerleave", onPointerCancel);
			_domElement.removeEventListener("touchmove", touchmoveHandler, { passive: false })
			// scope.map.scale.set(1, 1, 1);

			_domElement.style.cursor = "";
		}
		function dispose() {
			deactivate();
		}
		function getObjects() {
			return _objects;
		}
		function getRaycaster() {
			return _raycaster;
		}
		function isInArea(event) {
			const targetArea = {
				x: 0,
				y: window.innerHeight - 150,
				width: 150,
				height: 150,
			}
			const withinXRange = event.clientX >= targetArea.x
				&& event.clientX <= targetArea.x + targetArea.width
			const withinYRange = event.clientY >= targetArea.y
				&& event.clientY <= targetArea.y + targetArea.height

			return withinXRange && withinYRange
		}
		function onPointerMove(event) {
			if (scope._mouseOverFlag === false && scope._dragAndDropFlag === false && scope.draggable === false) {
				return;
			}
			if (isInArea(event)) {
				return;
			}
			updatePointer(event);
			if (scope.draggable || scope._dragAndDropFlag) {
				_raycaster.setFromCamera(_pointer, _camera);

				if (_selected) {
					disableCamera()
					if (_raycaster.intersectObject(scope.plane, true).length > 0) {
						const intersection = _raycaster.intersectObject(scope.plane, false)[0]
						const point = intersection.point;
						let intersectsSurface = false
						point.sub(_dragOffset);

						if (_raycaster.intersectObject(scope.surface, true).length > 0) {
							intersectsSurface = true
						}

						onPositionChange(point, intersectsSurface);
						lastPoint = point
						scope.dragAndDrop();
					}
					scope.dispatchEvent({ type: "drag", object: _selected, });
					return;
				} else {
					enableCamera()
				}
			}

			if (event.pointerType === "mouse" || event.pointerType === "pen") {
				_intersections.length = 0;
				_raycaster.setFromCamera(_pointer, _camera);
				_raycaster.intersectObjects(_objects, true, _intersections);
				if (_intersections.length > 0) {
					const object = _intersections[0].object;
					const mapIntersections = _raycaster.intersectObject(scope.plane, false);
					if (mapIntersections.length > 0) {
						if (_hovered !== object && _hovered !== null) {
							if (scope._mouseOutFlag) {
								scope.event.object = _hovered;
								scope.mouseOut();
							}
							scope.dispatchEvent({ type: "hoveroff", object: _hovered, });
							_hovered = null;
						}
						if (_hovered !== object) {
							scope.dispatchEvent({ type: "hoveron", object: object, });
							_hovered = object;
							scope.event.item = _objects.indexOf(_hovered);
						}
						_domElement.style.cursor = "grab";
						if (scope._mouseOverFlag) {
							scope.event.object = _hovered;
							scope.mouseOver();
						}
					} else {
						if (_hovered === object) {
							_domElement.style.cursor = "auto";
						}
					}
				} else if (_hovered !== null) {
					if (scope._mouseOutFlag) {
						scope.event.object = _hovered;
						scope.mouseOut();
					}
					scope.dispatchEvent({ type: "hoveroff", object: _hovered, });
					_domElement.style.cursor = "auto";
					_hovered = null;
				}
			}
		}
		function onPointerDown(event) {
			updatePointer(event);
			_intersections.length = 0;
			_raycaster.setFromCamera(_pointer, _camera);
			_raycaster.intersectObjects(_objects, true, _intersections);
			if (_intersections.length > 0) {
				_selected = (scope.transformGroup === true) ? _objects[0] : _intersections[0].object;
				if (_selected.parent.isGroup) {
					_selected = _selected.parent;
				}
				scope.previous.copy(_selected.position);
				scope.offset.set(0, 0, 0);
				const int = _raycaster.intersectObject(scope.plane, false)[0];
				if (int) {
					const point = int.point;
					_dragOffset.copy(point).sub(scope.draggableObjectCenter);
				}

				if (scope.draggable) {
					_domElement.style.cursor = "move";
					scope.dispatchEvent({ type: "dragstart", object: _selected, });
				}
				scope.event.object = _selected;
				scope.event.item = _objects.indexOf(_selected);
				if (scope._onclickFlag) {
					scope.onclick();
				}
			}
			else {
				scope.event.object = null; scope.event.item = null;
			}
		}
		function onPointerCancel() {
			if (scope.draggable === false) {
				return
			}
			if (_selected) {
				enableCamera()
				scope.dispatchEvent({ type: "dragend", object: _selected, point: lastPoint });
				onDragEndHandler && lastPoint && onDragEndHandler(lastPoint)
				_selected = null;
				scope.event.object = null;
				scope.event.item = null;
			}
			_domElement.style.cursor = _hovered ? "grab" : "auto";
		}
		function updatePointer(event) {
			const rect = _domElement.getBoundingClientRect();
			_pointer.x = (event.clientX - rect.left) / rect.width * 2 - 1;
			_pointer.y = - (event.clientY - rect.top) / rect.height * 2 + 1;
		}
		activate();
		// API
		this.enabled = false;
		this.transformGroup = false;
		this.activate = activate;
		this.deactivate = deactivate;
		this.dispose = dispose;
		this.getObjects = getObjects;
		this.getRaycaster = getRaycaster;
	}
}

export { EventControls };