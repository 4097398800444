import React from "react"
import styled from "styled-components"
import { BoldText, LinkText } from "../../../../../../../common/components/DrawerShared"
import MessageBox from "../../../MessageBox"

const ModalFooter = () => {
    return <Footer>
        <Paragraph>
            <MessageBox
                message={<>
                    Don’t see a part you need?
                    <LinkText
                        href="mailto:navid@craftyamigo.com"
                        target="_blank"
                    >
                        Let us know and we'll add it!
                    </LinkText>
                    {/* <br /><br />
                    Want to attach
                    <BoldText> wood </BoldText> (shelfing),
                    <BoldText> fabric </BoldText> or
                    <BoldText> plastic </BoldText>
                    (greenhouse) to your PVC tubes?
                    <LinkText
                        href="https://shop.craftyamigo.com/pages/adding-shelving-plastic-covers"
                        target="_blank"
                    >
                        Click text
                    </LinkText> */}
                </>} />
        </Paragraph>
        {/* <Paragraph>
            <MessageBox
                message={<>
                    <BoldText>Connection Compatibility of the Same Diameter:</BoldText>
                    <br /> <br />
                    Female Threaded ↔ Male Threaded
                    <br />
                    Female SLIP ↔ Male SLIP or Tube
                    <br />
                    Barbed Hose ↔ Hose
                </>} />
        </Paragraph> */}
    </Footer>
}

const Footer = styled.div`
    flex-direction: column;
    gap: 8px;
    color: #6D6D6D;
`

const Paragraph = styled.div`
    border-top: 1px solid #00000012;
    padding: 20px 0;
`


export default ModalFooter