import React, { createContext, useRef } from "react"
import { InstancedMesh } from "three"

interface Props {
    children: React.ReactNode;
}

type TubeInstance = {
    id: string,
    clickCallback?: () => void,
    count: number,
    updateIndexes: (skipBoundingSphere?: boolean) => void,
};

export type InstancedMeshPool = {
    name: string,
    mesh: InstancedMesh,
    initialCount: number,
    instances: TubeInstance[],
};

export const InstancedMeshSegmentedTubesContext = createContext<
    React.MutableRefObject<InstancedMeshPool[]> | undefined
>(undefined)

const InstancedMeshSegmentedTubesProvider = (props: Props) => {
    const instancedMeshesPool = useRef<InstancedMeshPool[]>([])

    return (
        <InstancedMeshSegmentedTubesContext.Provider value={instancedMeshesPool}>
            {props.children}
        </InstancedMeshSegmentedTubesContext.Provider>
    )
}

export default InstancedMeshSegmentedTubesProvider