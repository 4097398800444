import React from "react"
import { ImagesList } from "./ConnectionModal"
import styled from "styled-components"
import Carousel from "./ImageAnimation"
import { Box3 } from "three"
import SingleConnection from "./SingleConnection"

const ErrorText = styled.div`
    color: red;
    width: 100%;
    text-align: center;
    font-size: 1.8vh;
    top: 5px;
    padding: 16px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export type ConnectionType = {
    images: (string | undefined)[],
    onClick: (degree: number) => void,
    canSlide?: boolean,
    box: Box3 | undefined,
    onDegreeChange: (degree: number, connection: ConnectionType) => void,
    index: number,
}


type ConnectionProps = {
    errorText: string | undefined,
    connections: ConnectionType[],
    isMobile: boolean,
    steps: number,
};


export default function Connection({ errorText, connections, isMobile, steps, }:
    ConnectionProps) {
    return (
        <ImagesList isMobile={isMobile}>
            {errorText && <ErrorText>{errorText}</ErrorText>}
            {connections.map((c, i) => {
                return (
                    <SingleConnection
                        key={i}
                        isMobile={isMobile}
                        steps={steps}
                        connection={c}
                    />
                )
            })}
        </ImagesList>
    )
}