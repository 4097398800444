import React from "react"
import { FC, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { useRecoilValue } from "recoil"
import { ApiClient } from "../../../../../../common/api/ApiClient"
import isMobile from "ismobilejs"
import { CategoryTypeAPI, MaterialAPI, PartTypeAPI } from "../../../../../../common/api/Types"
import {
  ListGrid,
  ListGroupTitle,
  ListGroupWrapper,
  MaterialAdditionalInfo
} from "../../../../../../common/components/DrawerShared"
import { materialsSelector, subclassesSelector } from "../../../../../state/initialDataSelectors"
import { GroupedBy, toDictionary } from "../../../../../../common/utils/utils"
import CategoryCard from "./cards/CategoryCard"
import { BottomSheet } from "../../../../../../common/components/BottomSheet/BottomSheet"
import dompurify from "dompurify"
import PartCard from "./cards/PartCard"
import { PartsListType } from "./PartsModal"
import { ReactComponent as InfoIcon } from "../../../../../../common/assets/icons/info.svg"
import { scrollContainerId } from "./utils/helper"
import useGetDebugVariables from "../../utils/useGetDebugVariables"
import RadixTooltip from "../../../../../../common/components/Tooltip/RadixTooltip"
import { getRecentParts, sortPartsByRecent } from "../../../../../../common/utils/RecentParts"

export interface CategoriesAndPartsProps {
  categories: CategoryTypeAPI[];
  partOnClick: (part: PartTypeAPI) => void;
  partsGrouped: PartsListType[];
  categoryOnClick: (partsByCategory: GroupedBy<PartTypeAPI>) => void;
  disabled?: boolean;
}

const InfoIconContainer = styled.div`
  position: inherit;
  bottom: 0px;
  right: 8px;
  z-index: 4;
`

const TooltipContent = styled.div`
a {
        color: #6D6D6D;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
            color: #6D6D6D;
        }
    }
`

const Content = (props: { content: string, }) => {
  return useMemo(
      () => <TooltipContent
          dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(props.content, { ADD_ATTR: ["target",], }),
          }} />,
      [props.content,]
  )
}

const GroupList = (
  props: ({ group: GroupedBy<PartTypeAPI>[], materialId: string, subclassId: string, }
    & CategoriesAndPartsProps)
) => {
  const [isButtonSheetShowed, setIsButtonSheetShowed,] = useState(false)
  const materials = useRecoilValue(materialsSelector)
  const subclasses = useRecoilValue(subclassesSelector)
  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin
  const hideGroup = props.group.every(g => g.values.every(p => p.hideFromSidebar === true))

  const getList = (partsGroupedBycategory: GroupedBy<PartTypeAPI>[], material: MaterialAPI) => {
    const singleParts = partsGroupedBycategory
      .filter(p => p.values.length === 1)
      .map(p => p.values[0])

    const filteredParts = ApiClient.removeDuplicatedParts(singleParts)

    const parts = partsGroupedBycategory.filter(p => {
      if (p.values.length > 1) {
        return true
      }
      return filteredParts.some(f => f.id === p.values[0].id)
    }).reduce((acc, current) => {
      const cValues = current.values
      if (cValues.length === 1) {
        if (!acc.find(({ values, }) => values.length === 1 && values[0].id === cValues[0].id)) {
          acc.push(current)
        }
      } else {
        acc.push(current)
      }
      return acc
    }, [] as GroupedBy<PartTypeAPI>[])

    return parts.map((group) => {
      const category = props.categories.find(
        (cat) => cat.id === group.key
      )

      if (category === undefined) {
        console.warn(`Category with id: ${group.key} doesn't exists`)
        return <></>
      }

      if (group.values.length > 1) {
        const categoryClick = () => {
          const scroll = document.getElementById(scrollContainerId)
          if (scroll) {
            scroll.scrollTop = 0
          }
          props.categoryOnClick(group)
        }

        const partsAvailable = isAdmin
          ? group.values : group.values.filter(part => !part.hideFromSidebar)

        if (!partsAvailable.length && !isAdmin) {
          return null
        }

        let image = category.image

        if (category.imagesForMaterial) {
          image = category.imagesForMaterial[material.id] || category.image
        }
        return (
          <CategoryCard
            {...category}
            image={image}
            onClick={categoryClick}
            parts={partsAvailable}
            key={`category_${category.id}`}
            disabled={props.disabled}
          />
        )
      }

      const part = group.values[0]
      const partClick = () => {
        props.partOnClick(part)
      }
      return (
        <PartCard
          {...part}
          onClick={partClick}
          key={`part${part.id}`}
          disabled={props.disabled}
          furtherInfo={part.furtherInfo}
        />
      )
    })
  }

  if (hideGroup && !isAdmin) {
    return null
  }

  const material = materials.find(m => m.id === props.materialId)
  const subclass = subclasses.find(s => s.id === props.subclassId)

  const handleOnclick = (e: React.MouseEvent<unknown, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsButtonSheetShowed(!isButtonSheetShowed)
}

  return <ListGroupWrapper>
    <ListGroupTitle>
      <span>{`${material?.materialName}, ${subclass?.subclassName}`}</span>
      <MaterialAdditionalInfo>
      {subclass?.additionalInfo && <React.Fragment>
                            {
                                !isMobile(window.navigator).phone
                                && <RadixTooltip overridePosition={true}>
                                    <Content content={subclass.additionalInfo} />
                                </RadixTooltip>
                            }
                            {
                                isMobile(window.navigator).phone
                                && <InfoIconContainer onClick={(e) => handleOnclick(e)}>
                                    <InfoIcon pointerEvents="none" />
                                </InfoIconContainer>
                            }
                            {
                                isMobile(window.navigator).phone && isButtonSheetShowed
                                && <BottomSheet
                                    children={<Content content={subclass.additionalInfo} />}
                                    isOpen={isButtonSheetShowed}
                                    onClose={(e) => handleOnclick(e)}
                                    title="Further Information"
                                />}
                        </React.Fragment>}
      </MaterialAdditionalInfo>
    </ListGroupTitle>
    <ListGrid>
      {getList(props.group, material!)}
    </ListGrid>
  </ListGroupWrapper>
}

type GroupPropsType = {
  group: PartsListType,
}


const SubClassGroup = (props: (GroupPropsType
  & CategoriesAndPartsProps
  & { materialId: string, })) => {
  return <GroupList
    {...props}
    group={props.group.values as GroupedBy<PartTypeAPI>[]}
    materialId={props.materialId}
    subclassId={props.group.key}
  />
}

const MaterialGroup = (props: GroupPropsType & CategoriesAndPartsProps) => {
  return <div key={props.group.key}>
    {props.group.values.map(
      g => <SubClassGroup
        {...props}
        key={(g as PartsListType).key}
        materialId={props.group.key}
        group={g as PartsListType}
      />
    )}
  </div>
}

const getPartsSortedByMaterial = (materials: MaterialAPI[], groupedParts: PartsListType[]) => {
  const materialsDictionary = toDictionary(materials, m => m.id)
  return groupedParts.sort((g1, g2) =>
    materialsDictionary[g1.key].materialOrder - materialsDictionary[g2.key].materialOrder)
}

const getPartsSortedByRecentlyUsed = (parts: PartsListType[]): PartsListType[] => {
  const allRecentParts = getRecentParts()
  const recentMaterialsIds = allRecentParts.map(p => p.materialId)
  const recentSubmaterialsIds = allRecentParts.map(p => p.subclassId)
  const recentCategories = allRecentParts.map(p => p.categories)

  const sortedMaterials = sortPartsByRecent(parts, recentMaterialsIds)

  sortedMaterials.forEach(m => {
    m.values = sortPartsByRecent(m.values, recentSubmaterialsIds)
    m.values.forEach(s => {
      s.values = sortPartsByRecent(s.values, recentCategories, true)
    })
  })

  return sortedMaterials
}

const CategoriesAndParts: FC<CategoriesAndPartsProps> = (props: CategoriesAndPartsProps) => {
  const materials = useRecoilValue(materialsSelector)
  const partsSortedByMaterial = getPartsSortedByMaterial(materials, props.partsGrouped)
  const partsSortedByRecentlyUsed = getPartsSortedByRecentlyUsed(partsSortedByMaterial)

  return <div>
    {partsSortedByRecentlyUsed.map(pg => <MaterialGroup
      {...props}
      group={pg}
      key={pg.key}
    />)}
  </div>
}


export default CategoriesAndParts