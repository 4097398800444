import React, { useState } from "react"
import { useMatch } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import Button from "../../../../../../../../common/components/Button"
import { LoginPopUp } from "../../../../../../../../common/components/LoginPopup/LoginPopUp"
import {
  authState
} from "../../../../../../../../common/state/LoginState/RecoilAuthState"
import {
  pendingChangesAtom,
  saveDesignAtom,
  selectedItemID
} from "../../../../../../../exportables"
import { EnvHelper } from "../../../../../../../../common/utils/EnvHelper"
import { GenericPartState, PartTypeEnum } from "../../../../../../../utils/Types"
import { GroupByList, ObjDictionary } from "../../../../../../../../common/utils/utils"
import {
  TUBE_LENGTH_MAX_BUY,
  getSegmentedTubeLength
} from "../usePartsList"
import { events, useEventsData } from "../../../../../../../../common/utils/rudderAnalyticsUtils"
import { RoutesHelper } from "../../../../../../../../common/utils/routesHelper"

type BuyPartsButtonProps = {
  shopifyIds: ObjDictionary<ShopifyIdAPI>,
  parts: GenericPartState[],
}

export const BuyPartsButton = ({ parts, shopifyIds, }: BuyPartsButtonProps) => {
  const auth = useRecoilValue(authState)
  const [buyPartsClicked, setBuyPartsClicked,] = useState(false)
  const setSaveDesign = useSetRecoilState(saveDesignAtom)
  const match = useMatch(RoutesHelper.idParam)
  const pendingChanges = useRecoilValue(pendingChangesAtom)
  // const location = useLocation()
  const eventsData = useEventsData()
  const setSelectedItemID = useSetRecoilState(selectedItemID)

  // useEffect(() => {
  //   if (auth.user && buyPartsClicked && !pendingChanges && match) {
  //     generateShopifyUrl()
  //     events.clickBuyParts(eventsData())
  //     setBuyPartsClicked(false)
  //   }
  // }, [pendingChanges, location.pathname, auth.user, buyPartsClicked,])

  const generateShopifyUrl = () => {
    const partsGroupedByApiId = GroupByList(parts, ({ apiTypeId, }) => apiTypeId)
    const partsVariantId = partsGroupedByApiId.map(partsGroup => {
      const partsShopifyIds = partsGroup.values[0]
      const shopifyId = shopifyIds[partsShopifyIds.apiTypeId].variantId
      if (partsShopifyIds.type === PartTypeEnum.connector) {
        return { shopifyId, quantity: partsGroup.values.length, }
      }
      else {
        let quantity = 0
        partsGroup.values.forEach(part => {
          if (part.type === PartTypeEnum.tube) {
            const tubeLength = Number(part.length.toFixed(0))
            if (tubeLength < TUBE_LENGTH_MAX_BUY) {
              quantity += Number(part.length.toFixed(0))
            }
          }

          if (part.type === PartTypeEnum.segmentedTube) {
            const segmentedTubeLength = Number((getSegmentedTubeLength(part)).toFixed(0))
            if (segmentedTubeLength < TUBE_LENGTH_MAX_BUY) {
              quantity += segmentedTubeLength
            }
          }
        })
        return { shopifyId, quantity, }
      }
    },)

    const partsToRoute = partsVariantId.map(p => {
      if (p !== undefined) {
        return `${p.shopifyId}:${p.quantity}`
      }
      console.error("Cannot generate shop link")
      throw new Error("Cannot generate shop link")
    }).filter(Boolean)
      .join(",")

    const shopUrl = EnvHelper.shopUrl

    const attributes = [
      ["designID", match!.params.designId,],
      ["email", auth.user!.email,],
      ["userID", auth.user!.uid,],
    ]

    const attributesStr = attributes
      .map(([key, value,]) => `attributes[${key}]=${value}`)
      .join("&")
    const url = `${shopUrl}/cart/${partsToRoute}?${attributesStr}`
    window.open(url, "about:blank",)
  }

  const shopifyRoute = () => {
    setSelectedItemID(null)
    setSaveDesign(true)
    if (auth.user && match) {
      generateShopifyUrl()
      events.clickBuyParts(eventsData())
    }
  }

  const onLoginCancel = () => {
    setBuyPartsClicked(false)
  }

  return (
    <>
      {(!auth.user && buyPartsClicked) && <LoginPopUp onCancel={onLoginCancel} />}
      <Button
        color="#3B86F7"
        $loadingColor="#fff"
        action="primary"
        onClick={shopifyRoute}> Purchase </Button>
    </>
  )
}