import { useState, useEffect } from "react"
import isMobileJs from "ismobilejs"

export const useIsMobile = () => {
    const [isMobile, setIstMobile,] = useState(isMobileJs().any)

    useEffect(() => {
        const eventHandler = () => {
            setIstMobile(isMobileJs().any)
        }
        window.addEventListener("resize", eventHandler)

        return () => {
            window.removeEventListener("resize", eventHandler)
        }
    }, [isMobile,])
    return isMobile
}
