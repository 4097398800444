import React, { createContext, useRef } from "react"
import { Mesh } from "three"

interface Props {
    children: React.ReactNode;
}

type MarkersCache = {
    [partId: string]: {
        markers: Mesh[],
    },
}

export type CloseMarkersContextType = {
    updateMarkers: (partId: string, markers: Mesh[]) => void,
    getPartMarkers: (partId: string) => Mesh[],
    deletePart: (partId: string) => void,
} | undefined

export const CloseMarkersContext = createContext<CloseMarkersContextType>(undefined)

const CloseMarkersProvider: React.FC<Props> = ({ children, }) => {
    const cache = useRef<MarkersCache>({})

    const updateMarkers = (partId: string, markers: Mesh[]) => {
        if (cache.current) {
            cache.current[partId] = { markers, }
        }
    }

    const getPartMarkers = (partId: string) => {
        if (cache.current && cache.current[partId]) {
            return cache.current[partId].markers
        } else {
            return []
        }
    }

    const deletePart = (partId: string) => {
        delete cache.current[partId]
    }

    const init = () => {
        return {
            updateMarkers,
            getPartMarkers,
            deletePart,
        }
    }

    return <CloseMarkersContext.Provider value={init()} children={children} />
}

export default CloseMarkersProvider