import React from "react"
import styled from "styled-components"
import { DesignTypeAPI } from "../../../../common/api/Types"
import DesignItem from "./DesignItem/DesignItem"

type Props = {
    designs: DesignTypeAPI[],
    onDuplicateDesign: (positionId: string, copy: DesignTypeAPI) => void,
    removeDesign: (id: string) => void,
}

const DesignsList = (props: Props) => {
    const getDesigns = () => {
        return props.designs.map(
            d => <DesignItem
                design={d}
                key={d.id}
                removeDesign={props.removeDesign}
                onDuplicateDesign={props.onDuplicateDesign}
            />
        )
    }

    return <Container>{getDesigns()}</Container>
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 4px;
    padding: 8px;
`

export default DesignsList