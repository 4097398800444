import { useRecoilValue } from "recoil"
import { partsSelector } from "../../../../../../../../state/scene/atoms"
import { useUpdateSegmentedTube } from "../../../../../../../../state/scene/setters"
import { SegmentedTubePart, } from "../../../../../../../../utils/Types"

const useSegmentedTube = (id: string) => {
    const tubeState = useRecoilValue(partsSelector({ id, }))
    const tube = tubeState as SegmentedTubePart
    const updateTubeValues = useUpdateSegmentedTube()

    return {
        tube,
        updateTubeValues,
    }
}

export default useSegmentedTube