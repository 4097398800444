import CameraControls from "camera-controls"
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import useCamera from "../../../../providers/cameraProvider/useCamera"
import ReactDOM from "react-dom"
import Button from "../../../../../common/components/Button"
import { PartListIcon } from "../../../../../common/components/icons/Icons"
import { useRecoilState } from "recoil"
import { layoutState } from "../../../../state/layoutState"
import isMobile from "ismobilejs"
import produce from "immer"
import useViewOnly from "../../../../providers/viewerProvider/useViewOnly"
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question_mark.svg"
import { BottomSheet } from "../../../../../common/components/BottomSheet/BottomSheet"
import ButtonLinks from "../../../../../common/components/ButtonLinks/ButtonLinks"
import helpLinks from "../../../../../common/utils/HelpLinks"
import styled from "styled-components"
import {
    ReactComponent as RefreshIcon
} from "../../../../assets/icons/RefreshIcon.svg"
import {
    ReactComponent as FocusIcon
} from "../../../../assets/icons/FocusIcon.svg"
import {
    ReactComponent as FocusIconWhite
} from "../../../../assets/icons/FocusIconWhite.svg"
import { showCameraControlsState } from "../../../../state/scene/atoms"

interface Props {
    cameraControls: React.MutableRefObject<CameraControls | null>;
    autofocusMode: boolean;
    setAutofocusMode: (value: boolean) => void;
    userSelectionAutoFocusMode: React.MutableRefObject<boolean | null>;
}

const StyledRefreshIcon = styled(RefreshIcon) <{ isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.isMobile ? "auto" : 12)}px;
  height: ${props => (props.isMobile ? "auto" : 12)}px;

`

const StyledFocusIcon = styled(FocusIcon) <{ isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.isMobile ? "auto" : 12)}px;
  height: ${props => (props.isMobile ? "auto" : 12)}px;

`

const StyledFocusIconWhite = styled(FocusIconWhite) <{ isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.isMobile ? "auto" : 12)}px;
  height: ${props => (props.isMobile ? "auto" : 12)}px;

`

const Footer = (props: Props) => {
    const { resetCameraRotation, } = useCamera()
    const [showCameraControls,] = useRecoilState(showCameraControlsState)
    const [updateLayout, setUpdateLayout,] = useState(0)
    const cameraContainer = document.getElementById("camera_button")
    const [layoutValue, setLayout,] = useRecoilState(layoutState)
    const [isBottomSheetShowed, setIsBottomSheetShowed,] = useState(false)

    const isViewOnly = useViewOnly()

    useLayoutEffect(() => {
        setUpdateLayout(updateLayout + 1)
    }, [])

    const handlePartList = () => {
        setLayout(produce((draft) => {
            draft.showPartList = true
            draft.showDesignsList = false
            draft.isDrawerOpen = true
        }))
    }

    const toggleAutofocus = useCallback(() => {
        const newMode = !props.autofocusMode
        props.userSelectionAutoFocusMode.current = newMode
        props.setAutofocusMode(newMode)
    }, [props.autofocusMode, props.setAutofocusMode, props.userSelectionAutoFocusMode,])
    return (
        cameraContainer
        && ReactDOM.createPortal(
            <React.Fragment>
                {
                    showCameraControls
                    && <Button
                        onClick={() => resetCameraRotation(props.cameraControls.current!)}
                        title="Reset Camera"
                    >
                        <StyledRefreshIcon isMobile={isMobile(window.navigator).phone} />
                    </Button>
                }
                {
                    showCameraControls
                    && <Button
                        onClick={toggleAutofocus}
                        style={{
                            ...(props.autofocusMode && { backgroundColor: "black", }),
                        }}
                        title={props.autofocusMode ? "Autofocus On" : "Autofocus Off"}
                    >
                        {props.autofocusMode
                            ? <StyledFocusIconWhite isMobile={isMobile(window.navigator).phone} />
                            : <StyledFocusIcon isMobile={isMobile(window.navigator).phone} />
                        }
                    </Button>
                }
                {!isViewOnly && isMobile(window.navigator).phone && <Button
                    $ifMobile={<PartListIcon color="#767676" />}
                    disabled={layoutValue.isDrawerOpen}
                    onClick={handlePartList}>Parts List</Button>}
                {!isViewOnly && isMobile(window.navigator).phone && <Button
                    $ifMobile={<QuestionIcon />}
                    onClick={() => setIsBottomSheetShowed(true)}>Help</Button>}
                {isBottomSheetShowed
                    && <BottomSheet
                        children={
                            <ButtonLinks
                                listOfLinks={helpLinks}
                                isMobile={isMobile(window.navigator).phone}
                            />
                        }
                        isOpen={isBottomSheetShowed}
                        onClose={() => setIsBottomSheetShowed(false)}
                        title="Help"
                    />}
            </React.Fragment>,
            cameraContainer)


    )
}

export default Footer