import * as React from "react"
import { useContext, useMemo } from "react"
import {
    UNSAFE_NavigationContext as NavigationContext,
    UNSAFE_LocationContext as LocationContext,
    UNSAFE_RouteContext as RouteContext,
} from "react-router-dom"





export const useRouterContexts = () => {
    const navigation = useContext(NavigationContext)
    const location = useContext(LocationContext)
    const route = useContext(RouteContext)

    return useMemo(() => {
        return {
            navigation,
            location,
            route,
        }
    }, [navigation, location, route,])
}

export type RouterContexts = ReturnType<typeof useRouterContexts>

export const RouterProviders = (props: {
    values: RouterContexts,
    children: React.ReactNode,
}) => {
    return <NavigationContext.Provider
        value={props.values.navigation}
    >
        <LocationContext.Provider
            value={props.values.location}
        >
            <RouteContext.Provider
                value={props.values.route}
            >
                {props.children}
            </RouteContext.Provider>
        </LocationContext.Provider>
    </NavigationContext.Provider>
}

export const useRouterBridge = () => {
    const contexts = useRouterContexts()

    return useMemo(
        () => (props: { children: React.ReactNode, }) =>
            <RouterProviders values={contexts}>
                {props.children}
            </RouterProviders>,
        [contexts,]
    )

}