import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "./common/utils/sentrySetup"
import { RecoilRoot } from "recoil"
import { BrowserRouter } from "react-router-dom"
import { EnvHelper } from "./common/utils/EnvHelper"

const container = document.getElementById("root")

if (!container) {
  throw new Error("No root element found")
}

const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter basename={EnvHelper.pathBaseName}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
