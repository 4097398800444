import { info } from "console"
import { MutableRefObject, useEffect, useState } from "react"
import { Vector3 } from "three"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import { filterWithValue } from "../../../../../../../../../common/utils/utils"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { getMarkerNumber } from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SEGMENTED_TUBE_UI, SegmentedTubeInfo, SegmentedTubeMarkers } from "../types/types"

interface useHandleLengthChangeParams {
    tubeInternalRef: MutableRefObject<SegmentedTubeInfo>;
    saveChanges: (partIds: string[], newConnections: PartConnectionType[]) => void;
    tubeUI: SEGMENTED_TUBE_UI;
    canEdit: (markers: SegmentedTubeMarkers, unsnappedMarkersNames: string[]) => {
        sliderMarker: string,
        partsToMove: string[],
    } | undefined;
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>;
    checkMaxLength: (partsIds: string[], direction: Vector3) => number | null;
    updateMaxMinPossibleLength: (partsToMove?: string[]) => void;
}

export const useHandleLengthChange = ({
    tubeInternalRef,
    saveChanges,
    tubeUI,
    canEdit,
    direction,
    checkMaxLength,
    updateMaxMinPossibleLength,
}: useHandleLengthChangeParams) => {
    const [multipleMove, setMultipleMove,] = useState<{
        sliderMarker: string,
        partsToMove: string[],
    } | undefined>(undefined)

    useEffect(() => {
        return () => setMultipleMove(undefined)
    }, [])

    const resetMultipleMove = () => {
        setMultipleMove(undefined)
    }

    const saveMultipleMoveLength = (newConnections: PartConnectionType[]) => {
        saveChanges(multipleMove!.partsToMove, newConnections)
        getMultipleMoves()
    }

    const getSectionUnsnapedMarkeName = (snapped: boolean, section: SegmentedTubeMarkers) => {
        if(!snapped) {
            const markerKey = Object.keys(section)
                .find(
                    key => !section[key].outer!.userData.lateralFacing
                )
            if(markerKey) {
                return section[markerKey].inner!.userData.markerName
            }
        }
    }

    const getUnsnapedMarkersNames = () => {
        const startMarkerName = getSectionUnsnapedMarkeName(
            tubeInternalRef.current.snapped.start,
            tubeInternalRef.current.startSection
        )
        const endMarkerName = getSectionUnsnapedMarkeName(
            tubeInternalRef.current.snapped.end,
            tubeInternalRef.current.endSection
        )
        return filterWithValue([startMarkerName, endMarkerName,])
    }

    const getMultipleMoves = () => {
        const markers = direction === SegmentedTubeSectionType.START
            ? tubeInternalRef.current.startSection : tubeInternalRef.current.endSection
        const canEdit_ = canEdit(markers, getUnsnapedMarkersNames())
        if(canEdit_ && canEdit_.partsToMove.length > 0) {
            const markersByDirection = direction === SegmentedTubeSectionType.START
                ? tubeInternalRef.current.startSection : tubeInternalRef.current.endSection
            const marker = markersByDirection[
                getMarkerNumber(canEdit_.sliderMarker)].inner!
            const markerDirection = MeshUtils.copyWorldDirection(marker)
            const maxLength = checkMaxLength(canEdit_?.partsToMove, markerDirection)
            tubeInternalRef.current.multipleMove.maxPossibleLength = maxLength === null
                ? undefined : maxLength
            tubeInternalRef.current.multipleMove.connected = true
        } else {
            tubeInternalRef.current.multipleMove.maxPossibleLength = undefined
            tubeInternalRef.current.multipleMove.connected = false
        }
        setMultipleMove(canEdit_)
        updateMaxMinPossibleLength(canEdit_?.partsToMove)
    }

    useEffect(() => {
        if (tubeUI === SEGMENTED_TUBE_UI.SLIDER) {
            getMultipleMoves()
        }
    }, [tubeUI, direction,])

    return {
        multipleMove,
        resetMultipleMove,
        saveMultipleMoveLength,
    }
}