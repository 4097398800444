import styled from "styled-components"

const Link = styled.a<{
    disabled?: boolean,
    isMobile?: boolean,
}>`
    border: none;
    background: none;
    width: 100%;
    text-align: left; 
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    &:hover {
        background-color: ${(props) => (props.disabled ? "white" : "#F0F0F0")};
        color: black;
    }
    line-height: 1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: ${(props) => (props.isMobile ? "40px" : "25px")};
    padding: 2px 8px;
    position: relative;
    user-select: none;
    outline: none;
    color: black;
    text-decoration: none;
`

const Separator = styled.hr`
    width: 100%;
    border: 1px solid #F0F0F0;
`

const Icon = styled.span`
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    svg {
        width: 16px;
        height: 16px;
    }
`

export const ButtonLinksStyles = {
    Link,
    Separator,
    Icon,
}
