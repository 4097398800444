import { PartTypeAPI } from "../../../../../../../common/api/Types"
import { AddPartState } from "../../../../../../state/types"
import { useAddConnector } from "./addPartHooks/AddConnector"
import { useAddInitialPart } from "./addPartHooks/AddInitialPart"
import { useAddTube } from "./addPartHooks/AddTube"
import { useHandleSwapPart } from "./addPartHooks/SwapPart"
import { useOnboardingModal } from "../../../utils/useOnboardingModal"
import {setRecentParts} from "../../../../../../../common/utils/RecentParts"
import {userQueryState} from "../../../../../../state/atoms"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"



export enum addPartCaseEnum {
  INITIAL_PART = "Initial Part",
  ADD_NEW_PART = "Add New Part",
  SWAP = "Swap"
}

export type CommonProps = {
  hideCategoryParts: () => void,
  source: Exclude<AddPartState["step1"]["source"], "swap">,
  ignoreSizeCompatibility?: boolean,
  part: PartTypeAPI,
  clearModal: () => void,
  partsData: {
      readonly parts: PartTypeAPI[],
      readonly suggestedParts: PartTypeAPI[] | undefined,
      readonly compatibleConnections: string[] | undefined,
  } | null,
}

type InitialPartProps = {
  case: addPartCaseEnum.INITIAL_PART,
}

type AddNewPartProps = {
  case: addPartCaseEnum.ADD_NEW_PART,
}

export type SwapProps = {
  case: addPartCaseEnum.SWAP,
  swap: NonNullable<NonNullable<AddPartState["step1"]["source"]>["swap"]>,
}

export type AddPartProps
= CommonProps
  & (InitialPartProps
  | AddNewPartProps
  | SwapProps)

export const useAddPart = () => {
  const addInitialPart = useAddInitialPart()
  const addTube = useAddTube()
  const addConnector = useAddConnector()
  const handleSwapPart = useHandleSwapPart()
  const { reportPart, } = useOnboardingModal()


  return (props: AddPartProps) => {
    setRecentParts(props.part)
    reportPart(props.part)
    props.hideCategoryParts()

    switch(props.case) {
      case addPartCaseEnum.INITIAL_PART:
        addInitialPart(props)
      break
      case addPartCaseEnum.ADD_NEW_PART:
        if(props.part.tube) {
          addTube(props)
        } else {
          addConnector(props)
        }
        break
      case addPartCaseEnum.SWAP:
        handleSwapPart(props)
        break
      default:
        throw Error("Part type unknown")
    }
  }
}