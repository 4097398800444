import styled from "styled-components"

const Container = styled.div`
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0px 1px 8px rgb(0 0 0 / 30%);
  margin: auto;
  width: 100%;
  height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const OuterContent = styled.div`
  pointer-events: all;
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  overflow: hidden;
`

const AnimatedSheet = styled.div`
  pointer-events: all;
  position: absolute;
  bottom: 0;
  max-height: 80vh;
  width: 100%;
  background: white;
  overflow: auto;
  transform: translateY(100%);
  animation: slideup 0.3s ease-in-out forwards;

  @keyframes slideup {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`

const Content = styled.div`
    pointer-events: fill;
    padding: 16px;
    height: 100%;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    position: sticky;
    top: 0;
    width: 100%;
    background: white;
    z-index: 1;
`

const Title = styled.h2`
    font-size: 20px;
    font-weight: 500;
    margin: 0;
`

const Portal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    pointer-events: none;
`

export const BottomSheetStyled = {
  Container,
  AnimatedSheet,
  Content,
  Header,
  Title,
  OuterContent,
  Portal,
}