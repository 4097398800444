import { useContext } from "react"
import { selectorContext } from "./SelectorProvider"

const useSelector = (id: string, callback: (() => void) | undefined) => {
    const context = useContext(selectorContext)

    const registerInstancedMesh = () => {
        context?.registerSelectableMesh(id, undefined)
    }

    const registerRegularMesh = () => {
        context?.registerSelectableMesh(id, callback)
    }

    return {
        registerInstancedMesh,
        registerRegularMesh,
    }

}

export default useSelector